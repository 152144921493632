import axios from 'axios';
import * as types from "../store/types";
import * as store from "../store/index";

const HTTP_RESPONSE_CODES = {
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403
};


axios.interceptors.response.use(
    (response) => {
        return response;
    }, async (error) => {
        const response = error?.response;
        const status = response?.status;
        const url = response?.col?.slice(url?.lastIndexOf('/') + 1);

        if ((status === HTTP_RESPONSE_CODES.unauthorized || status === HTTP_RESPONSE_CODES.forbidden || status=== HTTP_RESPONSE_CODES.badRequest) 
        ) {
            store.default.dispatch("main/" + types.MAIN_ACT_USER_LOGOUT, null, {root: true});
        }

        return Promise.reject(error);
    }
);

export default axios;