import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    allBeneficiaries: [],
    allRelationship: [],
    signedSpousalConsentFormFile: File,  
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
