<template>
    <div>
        <lottie-animation
            v-if="light"
            :animationData="require('@/assets/animations/loading-icon-white.json')"
            loop
            ref="onrampSpinner"
            :auto-play=false            
        />
        <lottie-animation
            v-else
            :animationData="require(`@/assets/animations/${logoSource}`)"
            loop
            ref="onrampSpinner"
            :auto-play=false
        />
    </div>
</template>
<script>
/**
 * @author Team Awesome
 */
import { LottieAnimation } from "lottie-web-vue";
import { isClient } from "@/utils";
import { WHITE_LABELED_ORG_IDS } from "@/env";

export default {
    props: {
        light: { type: Boolean, default: false },
    },
    data() {
        return {
            logoSource: this.logoSources(),            
        };
    },
    methods: {
        logoSources() {
            const poid = localStorage.getItem("onrampinvest.poid")
        
            if ( isClient() && poid && WHITE_LABELED_ORG_IDS.includes(Number(poid)))  {
                //Logo Found, sending their org id related logo pointer
                return "loading-icon-wlPartner.json"
            } else {
                //Logo Not found, Sending the Default onramp logo pointer
                return "loading-icon.json"  
            }
        },        
    },
    mounted() {        
        const localSpinner = this.$refs.onrampSpinner;
        setTimeout(function() { localSpinner.play(); }, 1500);
    },
    components: {
        LottieAnimation,
    },
};
</script>
