import { upperCase } from "lodash";
import { mapPrice } from "../common/mapPrice";
import { mapClientName } from "../common/mapClientName";
import { mapUnixDateToLocaleDate } from "../common/mapUnixDateToLocaledate";
import { getProviderName } from "@/utils.js";
import { HELD_AWAY, TRADABLE, CLOSED } from "@/account_access_types";
import { getProviderNameFromStaticMap } from "@/static_contents_utils";
export const mapTransactionsDtoToModel = (transactionsDto, providers) => {
    const priceOfTransaction = mapPrice(transactionsDto.transaction_usd_price);
    const amountAsDecimal = mapAmount(transactionsDto.transaction_amount, transactionsDto.transaction_symbol);
    const unitPrice = mapPrice(transactionsDto.transaction_unit_price);
    const status = mapAccountAccessStatus(transactionsDto.status);
    return {
        first_name: transactionsDto.first_name,
        last_name: transactionsDto.last_name,
        settled: mapUnixDateToLocaleDate(transactionsDto.transaction_date),
        client_name: mapClientName(transactionsDto.first_name, transactionsDto.last_name),
        account_id: transactionsDto.account_id,
        account_holder_id: transactionsDto.client_id,
        account_groups: transactionsDto.rep_code,
        firmName: transactionsDto.firm_name,
        details: mapTransactionDetail(
            transactionsDto.transaction_action, 
            amountAsDecimal, 
            transactionsDto.transaction_symbol, 
            unitPrice),
        type: upperCase(transactionsDto.transaction_action),
        symbol: transactionsDto.transaction_symbol,
        quantity: amountAsDecimal,
        amount: priceOfTransaction,
        provider_id: getProviderNameFromStaticMap(transactionsDto.custodian_id),
        custodian: getProviderName(transactionsDto.custodian_id, providers),
        unitPrice: unitPrice,
        heldAway: status,
        fee_amount: transactionsDto.fee_amount,
        numberOfOccurrences: transactionsDto.number_of_occurrences,
    };
};

const mapAmount = (amount, symbol) => {
    switch(symbol) {
        case "USD":
            return amount.toFixed(2);
        case "ETH":
            return amount.toFixed(6);
        case "BTC":
            return amount.toFixed(8);
        default:
            return amount;
    }
};

const mapTransactionDetail = (action, amount, currencyAmount, price) => {
    return `${action} ${amount} ${currencyAmount} at ${price}`;
};

const mapAccountAccessStatus = (accessStatus) => {
    if (accessStatus.is_account_closed) {
        return CLOSED;
    }

    return accessStatus.is_account_held_away ? HELD_AWAY : TRADABLE;
};