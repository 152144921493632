/*
    Storing data that do not change often as static data to avoid having to make
    unnecessary API calls.
*/


/*
    Providers
*/
export const PROVIDER_COINBASE          = { "provider_source" : "coinbase"    ,     "id": 1 , "provider_name" : "Coinbase"          }
export const PROVIDER_GEMINI            = { "provider_source" : "gemini"      ,     "id": 2 , "provider_name" : "Gemini"            }
export const PROVIDER_PRIMETRUST        = { "provider_source" : "prime trust" ,     "id": 33, "provider_name" : "PT"                }
export const PROVIDER_COINBASE_PRIME    = { "provider_source" : "coinbase prime",   "id": 44, "provider_name" : "Coinbase Prime"    }
export const PROVIDER_SECURITIZE        = { "provider_source" : "securitize",       "id": 55, "provider_name" : "Securitize"        }
export const PROVIDER_ANCHORAGE         = { "provider_source" : "anchorage",        "id": 66, "provider_name" : "Anchorage"         }


export const SUPPORTED_PROVIDERS = [
    PROVIDER_COINBASE
,   PROVIDER_GEMINI
,   PROVIDER_PRIMETRUST
,   PROVIDER_COINBASE_PRIME
,   PROVIDER_SECURITIZE
,   PROVIDER_ANCHORAGE
]


/*
    Rebalance types
*/
export const REBALANCE_TYPE_AUTOMATIC   = { "id": 1, "value": 1, "code": "AUTOMATIC", "text": "Automatic"   , "description": "Rebalances the portfolio automatically"  , "title": "Automatic"  }
export const REBALANCE_TYPE_MANUAL      = { "id": 2, "value": 2, "code": "MANUAL"   , "text": "Manual"      , "description": "Portfolio rebalancing is trigerred manually", "title": "Manual"  }

export const SUPPORTED_REBALANCE_TYPES  = [
    REBALANCE_TYPE_AUTOMATIC
,   REBALANCE_TYPE_MANUAL
]
export const PERCT_TYPE                 = { id: 0, value: 0, text: "%" }
export const DOLLAR_TYPE                = { id: 1, value: 1, text: "$" }
export const CASH_RESERVE_UNIT          = [ PERCT_TYPE,  DOLLAR_TYPE ]
export const DEFAULT_RESERVE_PERCT      = "5"

/*
    Model types
*/
export const MODEL_TYPE_DYNAMIC = { "text": "Dynamic"   , "id": 1, "value": 1 , "title": "Dynamic"}
export const MODEL_TYPE_STATIC  = { "text": "Static"    , "id": 2, "value": 2 , "title": "Static"}

export const SUPPORTED_MODEL_WEIGHTS_TYPES = [
    MODEL_TYPE_DYNAMIC
,   MODEL_TYPE_STATIC
]


/*
    Rebalance frequencies    
*/
export const REBALANCE_FREQ_NA                  = { "id": -1, "code": null                , "title": "N/A"                                      , "value": null                , "description": "N/A"                                     }
export const REBALANCE_FREQ_MONTHLY_CALENDAR    = { "id":  1, "code": "MONTHLY_CALENDAR"  , "title": "Monthly - Based on Calendar"              , "value": "MONTHLY_CALENDAR"  , "description": "Monthly - Based on Calendar"             }
export const REBALANCE_FREQ_MONTHLY_ACCOUNT     = { "id":  2, "code": "MONTHLY_ACCOUNT"   , "title": "Monthly - Based on Account Inception"     , "value": "MONTHLY_ACCOUNT"   , "description": "Monthly - Based on Account Inception"    }
export const REBALANCE_FREQ_YEARLY_CALENDAR     = { "id":  5, "code": "YEARLY_CALENDAR"   , "title": "Yearly - Based on Calendar"               , "value": "YEARLY_CALENDAR"   , "description": "Yearly - Based on Calendar"              }
export const REBALANCE_FREQ_YEARLY_ACCOUNT      = { "id":  6, "code": "YEARLY_ACCOUNT"    , "title": "Yearly - Based on Account Inception"      , "value": "YEARLY_ACCOUNT"    , "description": "Yearly - Based on Account Inception"     }
export const REBALANCE_FREQ_TRACK_INDEX         = { "id":  7, "code": "TRACK_INDEX"       , "title": "Track Index"                              , "value": "TRACK_INDEX"       , "description": "Track Index"                             }
export const REBALANCE_FREQ_QUARTERLY_CALENDAR  = { "id":  3, "code": "QUARTERLY_CALENDAR", "title": "Quarterly - Based on Calendar"            , "value": "QUARTERLY_CALENDAR", "description": "Quarterly - Based on Calendar"           }
export const REBALANCE_FREQ_QUARTERLY_ACCOUNT   = { "id":  4, "code": "QUARTERLY_ACCOUNT" , "title": "Quarterly - Based on Account Inception"   , "value": "QUARTERLY_ACCOUNT" , "description": "Quarterly - Based on Account Inception"  }

export const SUPPORTED_REBALANCE_FREQS = [
    REBALANCE_FREQ_MONTHLY_CALENDAR  
,   REBALANCE_FREQ_MONTHLY_ACCOUNT   
,   REBALANCE_FREQ_YEARLY_CALENDAR   
,   REBALANCE_FREQ_YEARLY_ACCOUNT    
,   REBALANCE_FREQ_TRACK_INDEX       
,   REBALANCE_FREQ_QUARTERLY_CALENDAR
,   REBALANCE_FREQ_QUARTERLY_ACCOUNT 
]

export const COST_BASIS_CALC_METHOD_FIFO = { "id": 1, "value": 1, "text": "FIFO"}
export const COST_BASIS_CALC_METHOD_LIFO = { "id": 2, "value": 2, "text": "LIFO"}
export const COST_BASIS_CALC_METHOD_HIFO = { "id": 3, "value": 3, "text": "HIFO"}

export const GAIN_LOSS_REPORT_REALIZED_SUB_TYPE     = 1;
export const GAIN_LOSS_REPORT_UNREALIZED_SUB_TYPE   = 2;

export const SUPPORTED_COST_BASIS_CALC_METHODS = [
    COST_BASIS_CALC_METHOD_HIFO
,   COST_BASIS_CALC_METHOD_FIFO
,   COST_BASIS_CALC_METHOD_LIFO
]

/*
    Genders
*/
export const GENDER_TYPE_MALE       = { "value": "male"         , "title": "Male"    , "id": 1 }
export const GENDER_TYPE_FEMALE     = { "value": "female"       , "title": "Female"  , "id": 2 }
export const GENDER_TYPE_OTHER      = { "value": "unspecified"  , "title": "Other"   , "id": 3 }

export const SUPPORTED_GENDER_TYPES = [
    GENDER_TYPE_MALE
,   GENDER_TYPE_FEMALE
,   GENDER_TYPE_OTHER
]


/*
    Provider Document Types
*/
export const PT_DOC_TYPE_DRIVER_LICENSE         = { "id": 1    , "title": "Driver‘s license"      , "provider_id": 33, "side": 1, "value": "drivers_license"   , "document_category": 1  }
export const PT_DOC_TYPE_GOVERNMENT_ID          = { "id": 2    , "title": "Government ID"         , "provider_id": 33, "side": 1, "value": "government_id"     , "document_category": 1  }
export const PT_DOC_TYPE_OTHER                  = { "id": 3    , "title": "Other"                 , "provider_id": 33, "side": 0, "value": "other"             , "document_category": 0  }
export const PT_DOC_TYPE_PASSPORT               = { "id": 4    , "title": "Passport"              , "provider_id": 33, "side": 0, "value": "passport"          , "document_category": 1  }
export const PT_DOC_TYPE_RESIDENT_PERMIT        = { "id": 5    , "title": "Resident Permit"       , "provider_id": 33, "side": 0, "value": "residence_permit"  , "document_category": 1  }
export const PT_DOC_TYPE_UTILITY_BILL           = { "id": 6    , "title": "Utility bill"          , "provider_id": 33, "side": 0, "value": "utility_bill"      , "document_category": 0  }
export const PT_DOC_TYPE_DRIVER_LICENSE_BACK    = { "id": 7    , "title": "Driver‘s license back" , "provider_id": 33, "side": 2, "value": "drivers_license"   , "document_category": 11 }
export const PT_DOC_TYPE_GOVERNMENT_ID_BACK     = { "id": 8    , "title": "Government ID back"    , "provider_id": 33, "side": 2, "value": "government_id"     , "document_category": 11 }
  
export const GEM_DOC_TYPE_DRIVER_LICENSE        = { "id": 9    , "title": "Driver‘s license"      , "provider_id": 2 , "side": 1, "value": "drivers_license"   , "document_category": 1  }
export const GEM_DOC_TYPE_GOVERNMENT_ID         = { "id": 10   , "title": "Government ID"         , "provider_id": 2 , "side": 1, "value": "government_id"     , "document_category": 1  }
export const GEM_DOC_TYPE_OTHER                 = { "id": 11   , "title": "Other"                 , "provider_id": 2 , "side": 0, "value": "other"             , "document_category": 0  }
export const GEM_DOC_TYPE_PASSPORT              = { "id": 12   , "title": "Passport"              , "provider_id": 2 , "side": 0, "value": "passport"          , "document_category": 1  }
export const GEM_DOC_TYPE_UTILITY_BILL          = { "id": 13   , "title": "Utility bill"          , "provider_id": 2 , "side": 0, "value": "utility_bill"      , "document_category": 0  }
export const GEM_DOC_TYPE_DRIVER_LICENSE_BACK   = { "id": 14   , "title": "Driver‘s license back" , "provider_id": 2 , "side": 2, "value": "drivers_license"   , "document_category": 11 }
export const GEM_DOC_TYPE_GOVERNMENT_ID_BACK    = { "id": 15   , "title": "Government ID back"    , "provider_id": 2 , "side": 2, "value": "government_id"     , "document_category": 11 }

export const SUPPORTED_PROVIDER_DOC_TYPES = [
    PT_DOC_TYPE_DRIVER_LICENSE      
,   PT_DOC_TYPE_GOVERNMENT_ID       
,   PT_DOC_TYPE_OTHER               
,   PT_DOC_TYPE_PASSPORT            
,   PT_DOC_TYPE_RESIDENT_PERMIT     
,   PT_DOC_TYPE_UTILITY_BILL        
,   PT_DOC_TYPE_DRIVER_LICENSE_BACK 
,   PT_DOC_TYPE_GOVERNMENT_ID_BACK  
,   GEM_DOC_TYPE_DRIVER_LICENSE     
,   GEM_DOC_TYPE_GOVERNMENT_ID      
,   GEM_DOC_TYPE_OTHER              
,   GEM_DOC_TYPE_PASSPORT           
,   GEM_DOC_TYPE_UTILITY_BILL       
,   GEM_DOC_TYPE_DRIVER_LICENSE_BACK
,   GEM_DOC_TYPE_GOVERNMENT_ID_BACK 
]

/*
 RELATIONSHIP TYPE
*/
export const RELATIONSHIP_TYPE_SPOUSE       = { "id": 1 ,  "code": "SPOUSE"      , "text": "Spouse"        , "description": "Spouse"             }
export const RELATIONSHIP_TYPE_PARENT       = { "id": 2 ,  "code": "PARENT"      , "text": "Parent"        , "description": "Parent"             }
export const RELATIONSHIP_TYPE_CHILD        = { "id": 3 ,  "code": "CHILD"       , "text": "Child"         , "description": "Child"              }
export const RELATIONSHIP_TYPE_SIBLING      = { "id": 4 ,  "code": "SIBLING"     , "text": "Sibling"       , "description": "Sibling"            }
export const RELATIONSHIP_TYPE_GRANDPARENT  = { "id": 5 ,  "code": "GRANDPARENT" , "text": "Grandparent"   , "description": "Grandparent"        }
export const RELATIONSHIP_TYPE_GRANDCHILD   = { "id": 6 ,  "code": "GRANDCHILD"  , "text": "Grandchild"    , "description": "Grandchild"         }
export const RELATIONSHIP_TYPE_AUNT         = { "id": 7 ,  "code": "AUNT"        , "text": "Aunt"          , "description": "Aunt"               }
export const RELATIONSHIP_TYPE_UNCLE        = { "id": 8 ,  "code": "UNCLE"       , "text": "Uncle"         , "description": "Uncle"              }
export const RELATIONSHIP_TYPE_COUSIN       = { "id": 9 ,  "code": "COUSIN"      , "text": "Cousin"        , "description": "Cousin"             }
export const RELATIONSHIP_TYPE_OTHER_RELATIVE  = { "id": 10,  "code": "OTHERLATIVE" , "text": "OtherRelative" , "description": "OtherRelative"      }
export const RELATIONSHIP_TYPE_OTHER        = { "id": 11,  "code": "OTHER"       , "text": "Other"         , "description": "Other"              }

export const SUPPORTED_RELATIONSHIP_TYPES = [
     RELATIONSHIP_TYPE_SPOUSE
,    RELATIONSHIP_TYPE_PARENT     
,    RELATIONSHIP_TYPE_CHILD      
,    RELATIONSHIP_TYPE_SIBLING    
,    RELATIONSHIP_TYPE_GRANDPARENT
,    RELATIONSHIP_TYPE_GRANDCHILD 
,    RELATIONSHIP_TYPE_AUNT       
,    RELATIONSHIP_TYPE_UNCLE      
,    RELATIONSHIP_TYPE_COUSIN     
,    RELATIONSHIP_TYPE_OTHER_RELATIVE
,    RELATIONSHIP_TYPE_OTHER      
]

export const PRIMETRUST_BENEFICIARIES_RELATIONSHIP_TYPES = [
     RELATIONSHIP_TYPE_SPOUSE
,    RELATIONSHIP_TYPE_PARENT     
,    RELATIONSHIP_TYPE_CHILD      
,    RELATIONSHIP_TYPE_OTHER_RELATIVE
,    RELATIONSHIP_TYPE_OTHER      
]
/*
 MARITAL STATUS TYPE
*/
export const MARITAL_STATUS_TYPE_MARRIED    = { "id": 1    ,   "code": "MARRIED"   , "title": "Married"     , "description": "A formal union and social and legal contract between two individuals that unites their lives legally, economically, and emotionally"}
export const MARITAL_STATUS_TYPE_SINGLE     = { "id": 2    ,   "code": "SINGLE"    , "title": "Single"      , "description": "A separate individual person"                                                                                                       }
export const MARITAL_STATUS_TYPE_DIVORCED   = { "id": 3    ,   "code": "DIVORCED"  , "title": "Divorced"    , "description": "No longer married because the marriage has been legally dissolved."                                                                                                                              }
export const MARITAL_STATUS_TYPE_WIDOWED    = { "id": 4    ,   "code": "WIDOWED"   , "title": "Widowed"     , "description": "Become a widow or widower; lose ones spouse through death."                                                                                                                              }
export const MARITAL_STATUS_TYPE_COMMONLAW  = { "id": 5    ,   "code": "COMMON-LAW", "title": "Common-Law"  , "description": "Denoting a partner in a marriage by common law (which recognized unions created by mutual agreement and public behavior), not by a civil or ecclesiastical ceremony."                                                                                                                              }

export const SUPPORTED_MARITAL_STATUS_TYPES = [
    MARITAL_STATUS_TYPE_MARRIED,
    MARITAL_STATUS_TYPE_SINGLE,
    MARITAL_STATUS_TYPE_DIVORCED,
    MARITAL_STATUS_TYPE_WIDOWED,
    MARITAL_STATUS_TYPE_COMMONLAW
]

export const GENERAL_ACCOUNT_TYPE_IDS = Object.freeze({
    INDIVIDUAL_ACCOUNT:  1,
    TRUST_ACCOUNT:  2,
    CUSTODIAN_ACCOUNT:  3,
    REMITTANCE_ACCOUNT:  4,
    JTWROS_ACCOUNT:  5,
    JTIC_ACCOUNT:  6,
    ROTH_IRA_ACCOUNT:  7,
    IRA_ACCOUNT:  8,
    ROLLOVER_IRA_ACCOUNT:  9,
});
export const PRIME_TRUST_ACCOUNT_TYPE_DISPLAY_NAMES = Object.freeze({
    INDIVIDUAL_ACCOUNT:  "Individual",
    ROTH_IRA_ACCOUNT:  "Roth IRA",
    IRA_ACCOUNT:  "IRA",
});


export const DATA_OVER_TIME_FILTER_RANGES = [
  { title: "Five Days"       , value: "5d"     },
  { title: "Month-to-Date"   , value: "mtd"    },
  { title: "Thirty Days"     , value: "1mo"    },
  { title: "Previous Quarter", value: "prevQ"  },
  { title: "Year-to-Date"    , value: "ytd"    },
  { title: "One Year"        , value: "1y"     },
];

export const DATA_OVER_TIME_CHART_MODES = [
    { title: "Balance", value: "balance" },
    { title: "Performance", value: "performance" },
]

// extracted from /api/v1/portfolios/advisor/{id}/portfolio-allocations/All
export const ASSETS_SYMBOL_NAME_PAIRS = [
  { "symbol": "1INCH"   , "name" : "1inch"                       } 
, { "symbol": "AAVE"    , "name" : "Aave"                        } 
, { "symbol": "ADA"     , "name" : "Cardano"                     } 
, { "symbol": "ALCX"    , "name" : "Alchemix"                    } 
, { "symbol": "ALI"     , "name" : "ALI"                         } 
, { "symbol": "AMP"     , "name" : "Amp"                         } 
, { "symbol": "ANKR"    , "name" : "Ankr"                        } 
, { "symbol": "APE"     , "name" : "APE"                         } 
, { "symbol": "API3"    , "name" : "API3"                        } 
, { "symbol": "ASH"     , "name" : "ASH"                         } 
, { "symbol": "AUDIO"   , "name" : "Audius"                      } 
, { "symbol": "AXS"     , "name" : "Axie Infinity"               }
, { "symbol": "BAL"     , "name" : "Balancer"                    } 
, { "symbol": "BAT"     , "name" : "Basic Attention Token"       } 
, { "symbol": "BCH"     , "name" : "Bitcoin Cash"                }
, { "symbol": "BICO"    , "name" : "BICO"                        } 
, { "symbol": "BNT"     , "name" : "Bancor"                      } 
, { "symbol": "BOND"    , "name" : "BarnBridge"                  } 
, { "symbol": "BTC"     , "name" : "Bitcoin"                     }     
, { "symbol": "CHZ"     , "name" : "Chiliz"                      } 
, { "symbol": "COMP"    , "name" : "Compound"                    } 
, { "symbol": "CRV"     , "name" : "Curve DAO Token"             }
, { "symbol": "CTX"     , "name" : "Cryptex Finance"             }
, { "symbol": "CUBE"    , "name" : "Somnium Space Cubes"         } 
, { "symbol": "CVC"     , "name" : "Civic"                       } 
, { "symbol": "DAI"     , "name" : "Dai"                         } 
, { "symbol": "DOGE"    , "name" : "Dogecoin"                    }    
, { "symbol": "DOT"     , "name" : "Polkadot"                    }     
, { "symbol": "DPI"     , "name" : "DPI"                         } 
, { "symbol": "EFIL"    , "name" : "Ethereum Wrapped Filecoin"   }
, { "symbol": "ELON"    , "name" : "Dogelon Mars"                }
, { "symbol": "ENJ"     , "name" : "Enjin Coin"                  } 
, { "symbol": "ENS"     , "name" : "ENS"                         } 
, { "symbol": "ERN"     , "name" : "Ethernity Chain"             }
, { "symbol": "ETH"     , "name" : "Ethereum"                    }     
, { "symbol": "EUL"     , "name" : "EUL"                         } 
, { "symbol": "FET"     , "name" : "Fetch.ai"                    }
, { "symbol": "FIDA"    , "name" : "Bonfida"                     } 
, { "symbol": "FIL"     , "name" : "Filecoin"                    } 
, { "symbol": "FRAX"    , "name" : "Frax"                        } 
, { "symbol": "FTM"     , "name" : "Fantom"                      } 
, { "symbol": "FXS"     , "name" : "FXS"                         } 
, { "symbol": "GAL"     , "name" : "GAL"                         } 
, { "symbol": "GALA"    , "name" : "Gala"                        } 
, { "symbol": "GFI"     , "name" : "GFI"                         } 
, { "symbol": "GMT"     , "name" : "GMT"                         } 
, { "symbol": "GRT"     , "name" : "The Graph"                   } 
, { "symbol": "GUSD"    , "name" : "Gemini Dollar"               } 
, { "symbol": "IMX"     , "name" : "IMX"                         } 
, { "symbol": "INDEX"   , "name" : "INDEX"                       } 
, { "symbol": "INJ"     , "name" : "Injective Protocol"          }
, { "symbol": "IOTX"    , "name" : "IoTeX"                       } 
, { "symbol": "JAM"     , "name" : "JAM"                         } 
, { "symbol": "KNC"     , "name" : "Kyber Network Crystal v2"    }
, { "symbol": "KP3R"    , "name" : "KP3R"                        } 
, { "symbol": "LDO"     , "name" : "Lido DAO Token"              } 
, { "symbol": "LINK"    , "name" : "Chainlink"                   } 
, { "symbol": "LN"      , "name" : "LINK"                        } 
, { "symbol": "LPT"     , "name" : "Livepeer"                    } 
, { "symbol": "LQTY"    , "name" : "LQTY"                        } 
, { "symbol": "LRC"     , "name" : "Loopring"                    } 
, { "symbol": "LTC"     , "name" : "Litecoin"                    } 
, { "symbol": "LUNA"    , "name" : "Terra"                       } 
, { "symbol": "LUSD"    , "name" : "Liquity USD"                 } 
, { "symbol": "MANA"    , "name" : "Decentraland"                } 
, { "symbol": "MASK"    , "name" : "Mask Network"                } 
, { "symbol": "MATIC"   , "name" : "Polygon"                     } 
, { "symbol": "MC"      , "name" : "MC"                          } 
, { "symbol": "MCO2"    , "name" : "Moss Carbon Credit"          }
, { "symbol": "METIS"   , "name" : "METIS"                       } 
, { "symbol": "MIM"     , "name" : "MIM"                         } 
, { "symbol": "MIR"     , "name" : "Mirror Protocol"             }
, { "symbol": "MKR"     , "name" : "Maker"                       } 
, { "symbol": "MPL"     , "name" : "MPL"                         } 
, { "symbol": "NMR"     , "name" : "Numeraire"                   } 
, { "symbol": "ORCA"    , "name" : "ORCA"                        } 
, { "symbol": "OXT"     , "name" : "Orchid"                      } 
, { "symbol": "PAXG"    , "name" : "PAX Gold"                    }
, { "symbol": "PLA"     , "name" : "PlayDapp"                    } 
, { "symbol": "QNT"     , "name" : "Quant"                       } 
, { "symbol": "QRDO"    , "name" : "QRDO"                        } 
, { "symbol": "RAD"     , "name" : "Radicle"                     } 
, { "symbol": "RARE"    , "name" : "SuperRare"                   } 
, { "symbol": "RAY"     , "name" : "Raydium"                     } 
, { "symbol": "RBN"     , "name" : "Ribbon Finance"              } 
, { "symbol": "REN"     , "name" : "Ren"                         } 
, { "symbol": "REVV"    , "name" : "REVV"                        } 
, { "symbol": "RLY"     , "name" : "Rally"                       } 
, { "symbol": "RNDR"    , "name" : "Render Token"                } 
, { "symbol": "SAMO"    , "name" : "SAMO"                        } 
, { "symbol": "SAND"    , "name" : "The Sandbox"                 } 
, { "symbol": "SBR"     , "name" : "SBR"                         } 
, { "symbol": "SHIB"    , "name" : "SHIBA INU"                   } 
, { "symbol": "SKL"     , "name" : "SKALE Network"               } 
, { "symbol": "SLP"     , "name" : "Smooth Love Potion"          }
, { "symbol": "SNX"     , "name" : "Synthetix"                   } 
, { "symbol": "SOL"     , "name" : "Solana"                      }     
, { "symbol": "SPELL"   , "name" : "SPELL"                       } 
, { "symbol": "STORJ"   , "name" : "Storj"                       } 
, { "symbol": "SUSHI"   , "name" : "SushiSwap"                   } 
, { "symbol": "TOKE"    , "name" : "TOKE"                        } 
, { "symbol": "TRU"     , "name" : "TrueFi"                      } 
, { "symbol": "UMA"     , "name" : "UMA"                         } 
, { "symbol": "UNI"     , "name" : "Uniswap"                     } 
, { "symbol": "USDC"    , "name" : "USD Coin"                    }    
, { "symbol": "UST"     , "name" : "TerraUSD"                    } 
, { "symbol": "WCFG"    , "name" : "WCFG"                        } 
, { "symbol": "XTZ"     , "name" : "Tezos"                       } 
, { "symbol": "YFI"     , "name" : "yearn.finance"               } 
, { "symbol": "ZBC"     , "name" : "ZBC"                         } 
, { "symbol": "ZEC"     , "name" : "Zcash"                       } 
, { "symbol": "ZRX"     , "name" : "0x"                          }     
, { "symbol": "USD"     , "name" : "USD"                         }    
, { "symbol": "AVAX"    , "name" : "Avalanche"                   }    
]


// Dashboard 
export const SIGULAR_FIRM_CODE = "Firm Code"
export const PLURAL_FIRM_CODE = "Firm Codes"
