<template>
    <div class="c-dialog-seperator" />
</template>
<script>
/**
 * @author
 * 
 * Unfortunately due to the high variety of dialogs it is likely that this seperator will be needed in a lot
 * of custom work.
 */
export default {
}
</script>
<style lang="scss" scoped>
    @import "@/styles/_colors.scss";
    .c-dialog-seperator {
        width: 100%;
        border-top: 1px solid var(--color__grayscale--light);
    }
</style>