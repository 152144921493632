import {CLEARING_PARTY_ID, PROVIDER_ID} from "./utils";
import {ASSETS_SYMBOL_NAME_PAIRS} from "./static_contents.js";

const UNDEFINED_PROVIDER_NAME = "N/A"

export const PROVIDER_ID_TO_NAME_MAP = new Map([
    [ PROVIDER_ID.UNDEFINED         , UNDEFINED_PROVIDER_NAME  ],
    [ PROVIDER_ID.COINBASE          , "Coinbase"               ],
    [ PROVIDER_ID.COINBASE_PRIME    , "Coinbase Prime"         ],
    [ PROVIDER_ID.SECURITIZE        , "Securitize"             ],
    [ PROVIDER_ID.GEMINI            , "Gemini"                 ],
    [ PROVIDER_ID.PRIMETRUST        , "PrimeTrust"             ],
    [ PROVIDER_ID.FALCONX           , "FalconX"                ],
    [ PROVIDER_ID.ANCHORAGE         , "Anchorage"              ],
]);

export function getProviderNameFromStaticMap(providerId)
{
    let name = PROVIDER_ID_TO_NAME_MAP.get(providerId);

    return name === undefined ? UNDEFINED_PROVIDER_NAME 
                              : name;    
}

export const getCustodianField = (account) => {
    return getProviderNameFromStaticMap(getProviderID(account))
};

export const getProviderID = (account) => {
    if (account.clearing_party_id == CLEARING_PARTY_ID.FALCONX && account.provider_id == PROVIDER_ID.GEMINI) {
        return PROVIDER_ID.FALCONX
    } else {
        return account.provider_id
    }
};

export const getAssetNameFromSymbol = (symbol) => {
    const assetSymbolNamePair   = ASSETS_SYMBOL_NAME_PAIRS.find(elem => elem.symbol === symbol);

    // In case does not have the entry, fall back to using the symbol as the name
    return assetSymbolNamePair ? assetSymbolNamePair.name.trim() : symbol;
}
