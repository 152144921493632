<template>
  <div>
        <Button 
            :activateTooltip="activateTooltip"
            :tooltipWidth="tooltipMaxWidth"
            :text="text" 
            :width="width" 
            :height="height" 
            :inactive="inactive"
            :icon="icon"
            :tooltipPosition="tooltipPosition"
            outlined
            textColor="var(--color__secondary)"
            class="button--secondary"
            @btnClick="onButtonClick"
        >
            <template v-slot:content>
                <LoadingAnimation v-if="loading" class="button--secondary__loading-animation"/>
            </template>
            <template v-if="!loading">
                <slot></slot>
            </template>
        </Button>
  </div>
</template>

<script>
/**
 * @author Team Awesome
 */
import Button from "@/components/Buttons/Base";
import LoadingAnimation from "@/components/Animations/LoadingAnimation";

export default {
  name: "SecondaryButton",
  components: {
      Button,
      LoadingAnimation
  },
  props: {
    activateTooltip: {
        type: Boolean, default: false
    },
    tooltipMaxWidth: {
        type: String, default: "100%"
    },
    text: {
        type: String, default: ""
    },
    width: {
        type: String, default: "100%"
    },
    minWidth: {
        type: String, default: "0"
    },
    height: {
        type: String, default: "56"
    },
    inactive: {
        type: Boolean, default: false
    },
    icon:{
        type: String, dafault: ""
    },
    loading: {
        type: Boolean, default: false
    },
    /**
     * The position that the tooltip displays in alignment to the button.
     * Note: This is set when the button is initialized as no use case exists for dynamic positioning.
     * @param {string} tooltipPosition - can be "bottom", "top", "left", "right"
     * @default "bottom"
     */
    tooltipPosition: {
        type: String,
        default: "bottom"
    },
  },
  methods: {
      onButtonClick() {
        this.$emit("btnClick");
      }
  }
};
</script>
<style scoped lang="scss">
@import "@/styles/_colors.scss";

.button--secondary:deep(.base-button.v-btn) {
    background:var(--color__light) !important;
    border:2px solid;
}

.button--secondary:deep(.base-button.v-btn:hover) {
    background:#C8E0FF !important;
    opacity:1 !important;

}

.button--secondary:deep(#base-button.base-button.v-btn--disabled) {
   border: 2px solid #AFB2BF;
   color: var(--color__grayscale--medium) !important;
}

.button--secondary__loading-animation {
    height: 25px;
    width: 25px;
}
</style>