import { apiUrl } from '@/env';
import axios from 'axios';
import { USER_ID, requestOptions } from './shared-api-constants';
import { getTokenHeaders } from './api-utils';
import { GET_TRANSACTIONS_CALLER_CLIENT_CODE } from "@/api/shared-api-constants.js";

export const transactionsApi = {
    async getAllTransactions(caller, dateRange) {
        return this.get(caller, {"date_range": dateRange});
    },
    async getTransactionsByClientID(caller, clientID) {
        if (caller === GET_TRANSACTIONS_CALLER_CLIENT_CODE) {
          // No need to pass in the clientID again if the current user is already a client
          return this.get(caller);          
        }
        else {
          return this.get(caller, {"client_id": clientID});
        }                
    },
    async getTransactionsByAccountID(caller, accountID) {
        return this.get(caller, {"account_id": accountID});
    },
    async get(caller, params) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/transactions/${caller}/${userId}`, { ...requestOptions, params });
    }
}