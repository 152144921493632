const ELLIPSIS = "...";

export function truncateWithEllipsis(inputStr, maxLength) {
  return inputStr.length > maxLength ? inputStr.substring(0, maxLength - ELLIPSIS.length) + ELLIPSIS
                                     : inputStr;
}

export function areListsOfStringsEqual(list1, list2) {
  const sortedList1 = list1.slice().sort();
  const sortedList2 = list2.slice().sort();

  return sortedList1.toString() === sortedList2.toString();
}
