import * as types from "../types";

export default {
    [types.TRADE_GETTER_STATE_RAW]: (state) => {
        return state;
    },
    [types.TRADE_GETTER_POSITIONS]: (state) => {
        const notionalBalances =
            state.notional_balances[state.active_account_id].positions || [];
        return notionalBalances;
    },
    [types.TRADE_GETTER_TRADE_INFO]: (state) => {
        const tradingInfo = new Object();
        tradingInfo.unit_cost = state.cost_per_unit;
        tradingInfo.unit_cost_formatted = state.cost_per_unit;
        tradingInfo.unit_count = state.unit_count;
        tradingInfo.quote_id = state.quote_id;
        tradingInfo.trade_amount = state.trade_amount;
        tradingInfo.fee_amount = state.fee_amount;
        tradingInfo.total = state.fee_amount + state.trade_amount;
        tradingInfo.symbol = state.asset_name;
        return tradingInfo;
    },
    [types.TRADE_GETTER_GEM_PRICES]: (state) => {
        return state.prices;
    },
    [types.TRADE_GETTER_EXECUTED]: (state) => {
        const tradingExecuted = new Object();
        tradingExecuted.transaction = state.trade_id;
        tradingExecuted.unit_count_executed = state.unit_count_executed;
        tradingExecuted.trade_amount_executed = state.trade_amount_executed;
        tradingExecuted.status = state.confirmation;
        return tradingExecuted;
    },
    [types.TRADE_GETTER_NOTIONAL_BALANCES]: (state) => {
        return state.notional_balances;
    },
    [types.TRADE_GETTER_TRADEABLE_SYMBOLS]: (state) => {
        return state.tradableSymbolsMap;
    },    
};