import {
    MUTATION_SET_ALL_ORDERS,
    MUTATION_SET_OPEN_ORDERS,
    MUTATION_ALL_ORDERS_FOR_ADVISOR_CLIENT,
    MUTATION_ALL_ORDERS_FOR_ADVISOR_ACCOUNT,
    MUTATION_OPEN_ORDERS_FOR_ADVISOR_CLIENT,
    MUTATION_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT,
    MUTATION_GET_BATCH_TRADE_ORDERS,
    MUTATION_REFRESH_BATCH_TRADE_PRICES,
    MUTATION_REVIEWABLE_TRADE_BATCHES,
    MUTATION_STAGE_REVIEWABLE_BATCHES,
    MUTATION_SET_PENDING_TRADE_BATCHES,
    MUTATION_GET_APPROVED_BATCHES,
    MUTATION_SET_EXECUTABLE_TRADE_BATCHES, MUTATION_SET_EXECUTED_BATCHES, MUTATION_SET_EXECUTION_ACCEPTED_TRADE_BATCHES,
    MUTATION_EXECUTE_TRADE_BATCHES,
    MUTATION_EXECUTED_BATCHE_STATUS,
    MUTATION_ORDER_MAKER_PRICE_SOURCE
} from "./types";
import { mapOrdersDtoToModel } from "@/services/mappers/orders/orders-mapper";
import { convertObjectToMap } from "@/object_utils.js"

import * as STATIC_CONTENTS from "@/static_contents"

function localMapOrders(models, orders) {
    const providers = STATIC_CONTENTS.SUPPORTED_PROVIDERS;
    return (orders || []).map((order) =>
    mapOrdersDtoToModel(order, providers, models)
);
}

export default {
    [MUTATION_SET_ALL_ORDERS]: async (state, dataPack ) => {                
        state.allTradeOrders = localMapOrders(dataPack.models, dataPack.orders);
    },
    [MUTATION_SET_OPEN_ORDERS]: async (state, dataPack) => {        
        state.openTradeOrders = localMapOrders(dataPack.models, dataPack.orders);
    },
    [MUTATION_ALL_ORDERS_FOR_ADVISOR_CLIENT]: async (state, dataPack) => {        
        state.allTradeOrdersForClient = localMapOrders(dataPack.models, dataPack.orders);
    },
    [MUTATION_ALL_ORDERS_FOR_ADVISOR_ACCOUNT]: async (state, dataPack) => {        
        state.allTradeOrdersForAccount = localMapOrders(dataPack.models, dataPack.orders);        
    },
    [MUTATION_OPEN_ORDERS_FOR_ADVISOR_CLIENT]: async (state, dataPack) => {
        state.openTradeOrdersForClient = localMapOrders(dataPack.models, dataPack.orders);
    },
    [MUTATION_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT]: async (state, dataPack) => {        
        state.openTradeOrdersForAccount = localMapOrders(dataPack.models, dataPack.orders);        
    },
    [MUTATION_GET_BATCH_TRADE_ORDERS]: async (state, dataPack) => {
        state.batchTradeOrders = dataPack;        
    },
    [MUTATION_REFRESH_BATCH_TRADE_PRICES]: async (state, dataPack) => {
        // Convert the response from the endpoint to a Map for ease of use        
        if (dataPack && Object.hasOwn(dataPack, "prices")) {            
            state.batchTradePrices = convertObjectToMap(dataPack["prices"]);        
        } else {
            state.batchTradePrices.clear();
        }        
    },
    [MUTATION_REVIEWABLE_TRADE_BATCHES]: async (state, dataPack) => {
        state.reviewableBatches = dataPack;
    },
    [MUTATION_STAGE_REVIEWABLE_BATCHES]: async (state, dataPack) => {
        state.stagedReviewableBatches = dataPack;
    },
    [MUTATION_GET_APPROVED_BATCHES]: async (state, dataPack) => {
        state.approvedBatches = dataPack;
    },
    [MUTATION_SET_EXECUTED_BATCHES]: async (state, dataPack) => {
        state.executedBatches = dataPack;
    },
    [MUTATION_SET_PENDING_TRADE_BATCHES]: async (state, dataPack) => {
        state.pendingTradeBatches = dataPack;
    },
    [MUTATION_SET_EXECUTABLE_TRADE_BATCHES]: async (state, dataPack) => {
        state.executableBatches = dataPack;
    },
    [MUTATION_SET_EXECUTION_ACCEPTED_TRADE_BATCHES]: async (state, dataPack) => {
        state.executionAcceptedBatches = dataPack;
    },
    [MUTATION_EXECUTE_TRADE_BATCHES]: async (state, dataPack) => {
        state.executedTradeBatches = dataPack;
    },
    [MUTATION_EXECUTED_BATCHE_STATUS]: async (state, dataPack) => {
        state.executedTradeBatchStatus = dataPack;
    },
    [MUTATION_ORDER_MAKER_PRICE_SOURCE]: (state, value) => {
        state.orderMakerPriceSource = value;
    },
};
