export const ACTION_DEPOSIT_VIEW = "ACTION_DEPOSIT_VIEW";
export const MUTATION_DEPOSIT_VIEW = "MUTATION_DEPOSIT_VIEW";
export const GETTER_DEPOSIT_VIEW = "GETTER_DEPOSIT_VIEW";

export const ACTION_DEPOSIT_EDIT = "ACTION_DEPOSIT_EDIT";
export const MUTATION_DEPOSIT_EDIT = "MUTATION_DEPOSIT_EDIT";
export const GETTER_DEPOSIT_EDIT = "GETTER_DEPOSIT_EDIT";

export default {
    namespaced: true,
    ACTION_DEPOSIT_EDIT,
    MUTATION_DEPOSIT_EDIT,
    GETTER_DEPOSIT_EDIT,
};
