import * as types from "../types";
import { AccountStatusMapper } from "@/services/mappers/accountStatuses/account-statuses-mapper";

export default {
  [types.ORGANIZATION_MUT_SET_USERS]: (state, payload) => {
    state.users = payload;
  },
  [types.ORGANIZATION_MUT_SET_CLIENTS]: (state, payload) => {
    state.clients = payload.clients;
  },
  [types.ORGANIZATION_MUT_SET_STATS]: (state, payload) => {
    state.aum = payload.aum;
    state.clients = payload.clients;
    state.num_clients = payload.num_clients;
    state.num_accounts = payload.num_accounts;
    state.accounts = payload.account_list;
    state.one_day_cli_diff = payload.one_day_cli_diff;
    state.five_day_cli_diff = payload.five_day_cli_diff;
    state.mtd_cli_diff = payload.mtd_cli_diff;
    state.qtd_cli_diff = payload.qtd_cli_diff;
    state.prev_q_cli_diff = payload.prev_q_cli_diff;
    state.ytd_cli_diff = payload.ytd_cli_diff;
    state.one_y_cli_diff = payload.one_y_cli_diff;
    state.one_day_act_diff = payload.one_day_act_diff;
    state.five_day_act_diff = payload.five_day_act_diff;
    state.mtd_act_diff = payload.mtd_act_diff;
    state.qtd_act_diff = payload.qtd_act_diff;
    state.prev_q_act_diff = payload.prev_q_act_diff;
    state.ytd_act_diff = payload.ytd_act_diff;
    state.one_y_act_diff = payload.one_y_act_diff;
    state.dashboardFirmTotalsNumberOfClients = payload.num_clients;
    state.dashboardFirmTotalsNumberOfAccounts = payload.num_accounts;
  },
  [types.ORGANIZATION_MUT_SET_CLIENT_STATS]: (state, payload) => {
    state.aum = payload.aum;
    state.accounts = payload.account_list;
  },
  [types.ORGANIZAITON_MUT_ACT_DETAIL]: (state, payload) => {
    state.curr_act_detail = payload;
  },
  [types.ORGANIZATION_MUT_CLIENT_DETAIL]: (state, payload) => {
    state.curr_client_detail = payload;
  },
  [types.ORGANIZATION_MUT_CHART]: (state, payload) => {  
    let chart_data = payload.chart_data      
    const values = chart_data ? Object.values(chart_data) : []
    const initial = { "CurrentBalance": 0, "1Day": 0, "TimeChange": 0 }
    const total = values.reduce(
        (prev, curr) => 
            { 
                return { "CurrentBalance": prev["CurrentBalance"] + curr["CurrentBalance"], 
                         "1Day"          : prev["1Day"]           + curr["1Day"],
                         "TimeChange"    : prev["TimeChange"]     + curr["TimeChange"] }; 
            }, initial);
    state.chart_data        = payload;
    state.dyn_aum           = total.CurrentBalance;
    state.aum_diff_1d       = total["1Day"];
    state.aum_diff_filter   = total.TimeChange;
  },

  [types.ORGANIZATION_NEW_FORM_ONE]: (state, payload) => {
    state.formPartOne = payload;
  },
  [types.ORGANIZATION_NEW_FORM_TWO]: (state, payload) => {
    state.formPartTwo = payload;
  },
  [types.ORGANIZATION_NEW_FORM_THREE]: (state, payload) => {
    // payload example: { 'files': [FileObj] }
    state.formPartThree = payload;
  },
  [types.ORGANIZATION_MUT_SELECTED_CUSTODIAN]: (state, payload) => {
    state.selectedCustodian = payload;
  },
  [types.ORGANIZATION_MUT_ADV_AGREEMENT]: (state, payload) => {
    // payload example: { 'files': [FileObj] }
    state.optionalAgreement = payload;
  },
  [types.ORGANIZATION_SET_FORM]: (state, payload) => {
    state.formSet = payload;
  },
  [types.ORGANIZATION_DISABLE_NOTE]: (state, payload) => {
    state.leftNavNote = payload;
  },
  [types.ORGANIZATION_MUT_SET_ADVISOR_FEATURE]: (state, payload) => {
    state.firm_onboarding_info = {        
        "ptOnboarded"                             : payload?.ptOnboarded
    ,   "geminiOnboarded"                         : payload?.geminiOnboarded
    ,   "cbOnboarded"                             : payload?.cbOnboarded
    ,   "anchorageOnboarded"                      : payload?.anchorageOnboarded
    ,   "advisorTradingPerm"                      : payload?.advisorTradingPerm
    ,   "feeBillingEnabled"                       : payload?.feeBillingEnabled
    ,   "modelsViewEnabled"                       : payload?.modelsViewEnabled
    ,   "orderMakerEnabled"                       : payload?.orderMakerEnabled
    ,   "cryptoFundingEnabled"                    : payload?.cryptoFundingEnabled
    ,   "advisorAccountRestrictions"              : payload?.advisorAccountRestrictions
    ,   "restrictSingleTradesWhenOrderMakerIsOn"  : payload?.restrictSingleTradesWhenOrderMakerIsOn
    ,   "riaEnabledOrganizations"                 : payload?.riaEnabledOrganizations
    }    
  },
  [types.ORGANIZATION_MUT_SET_NEW_ACCOUNT_STATUS]: (state, statuses) => {
    state.newAccounts = (statuses || []).map((status) =>
      AccountStatusMapper.mapAccountStatusesDtoToModel(status)
    );
  },
  [types.ORGANIZATION_MUT_GET_ADVISOR_TOTAL_AUM]: (state, totalAum) => {
    state.totalAdvisorAccountAssetsFormatted = totalAum.toFixed(2);
    state.totalAdvisorAccountAssetsNumeric = totalAum;
    state.advisorClientsTotalAssetsFormatted = totalAum.toFixed(2);
    state.advisorClientsTotalAssetsNumeric = totalAum;
  },
  [types.ORGANIZATION_MUT_SET_CLIENTS_FOR_ADVISOR]: (state, payload) => {
    state.advisorClients = payload;
    state.num_clients = payload.length;
  },
  [types.ORGANIZATION_MUT_SET_ACCOUNTS_FOR_ADVISOR]: (state, payload) => {
    const advisorAccountsTotalAssets = (payload || []).map(account => account.total_assets)
                                                             .reduce((sum, assets) => sum + assets, 0);
    state.totalAdvisorAccountAssetsFormatted = advisorAccountsTotalAssets.toFixed(2);
    state.totalAdvisorAccountAssetsNumeric = advisorAccountsTotalAssets;

    const uniqueAccounts = [...new Set(payload || []).map(account => account.id)].length
    state.num_accounts = uniqueAccounts;
    state.dashboardFirmTotalsNumberOfAccounts = uniqueAccounts
    state.accounts = payload;
  },
  [types.ORGANIZATION_MUT_SET_HELDAWAY_ACCOUNTS_FOR_ADVISOR]: (
    state,
    payload
  ) => {
    const heldawayAccountsAssetTotal = (payload || []).map(account => account.total_assets)
                                                      .reduce((sum, assets) => sum + assets, 0);
    state.totalHeldawayAccountAssetsFormatted = heldawayAccountsAssetTotal.toFixed(2);
    state.totalHeldawayAccountAssetsNumeric = heldawayAccountsAssetTotal;
    state.numHeldawayAccounts = (payload || []).length;
  },
  [types.ORGANIZATION_MUT_AM_ACCOUNT_DASHBOARD_DATA]: (state, data) => {
    state.amDashboardAccountData = data;    
  },
  [types.ORGANIZATION_MUT_GET_ORGANIZATION_COMPLIANCE_CONTACT]: (state, data) => {
    state.organizationComplianceContacts = data;
  },
};
