export const TICKER_LOOKUP = {
    DASH: "Dash",
    OXT: "Orchid",
    ZRX: "0x",
    "1INCH": "1inch",
    DOGE: "Dogecoin",
    OGN: "Orgin",
    AAVE: "Aave",
    ENJ: "Enjin",
    DOT: "Polkadot",
    ALGO: "Algorand",
    MLN: "Enzyme",
    MATIC: "Polygon",
    AMP: "Amp",
    EOS: "EOS",
    QNT: "Quant",
    FORTH: "Ampleforth",
    ETH: "Ethereum",
    REN: "Ren",
    ANKR: "Ankr",
    ETC: "Ethereum Classic",
    SKL: "SKALE",
    BAL: "Balancer",
    FIL: "Filecoin",
    SOL: "Solana",
    BNT: "Bancor",
    GTC: "Gitcoin",
    XLM: "Stellar",
    BAND: "Band",
    RLC: "iExec",
    STORJ: "STORJ",
    BOND: "BarnBridge",
    ICP: "Internet Comp",
    SUSHI: "SushiSwap",
    BAT: "Basic Attention",
    KEEP: "Keep",
    SNX: "Synthetix",
    BTC: "Bitcoin",
    KNC: "Kyber",
    TRB: "Tellor",
    BCH: "Bitcoin cash",
    LPT: "Livepeer",
    USDT: "Tether",
    ADA: "Cardano",
    LTC: "Litecoin",
    XTZ: "Tezos",
    CTSI: "Cartesi",
    LOOM: "Loom",
    GRT: "Graph",
    CGLD: "Celo",
    LRC: "Loopring",
    UMA: "UMA",
    CHZ: "Chiliz",
    MIR: "Mirro",
    UNI: "Uniswap",
    COMP: "Compound",
    NKN: "NKN",
    USDC: "USD coin",
    ATOM: "Cosmos",
    NU: "NuCypher",
    WTBC: "Wrapped BTC",
    CRV: "Curve DAO",
    NMR: "Numeraire",
    YFI: "Yearn.finance",
    DAI: "Dai",
    OMG: "OMG",
    ZEC: "Zcash",
};

export const SECURITIZE_CARD_STATUS = {
    NOT_STARTED: "Not Started",
    INCOMPLETE: "Incomplete",
    PENDING: "Pending",
    COMPLETE: "Complete",
};

export const SECURITIZE_CARD_INVESTMENT_RECOMMENDATION_INCOMPLETE = {
    title: "Investment Recommendation",
    content: SECURITIZE_CARD_STATUS.INCOMPLETE,
    clickable: true,
    contentColor: "red",
    backgroundColor: "white",
    showArrow: true,
}

export const SECURITIZE_CARD_INVESTMENT_RECOMMENDATION_COMPLETE = {
    title: "Investment Recommendation",
    content: SECURITIZE_CARD_STATUS.COMPLETE,
    clickable: false,
    contentColor: "green",
    backgroundColor: "white",
    showArrow: true,
}

export const ACCREDITATION_STATUS = {
    ACCREDITED: 'ACCREDITED',
    NOT_ACCREDITED: 'NOT_ACCREDITED',
    UNKNOWN: 'UNKNOWN',
};

export const CASH_RESERVE              = "CASH_RESERVE";
export const CASH_RESERVE_DISPLAY_NAME = "USD - Reserve";

export const NUM_DECIMAL_PLACES_DONT_CARE = -1;

export const DEFAULT_NO_ORGANIZATION_ID = -1;