import { PROVIDER_ID } from "@/utils.js";

export const MODEL_AVAILABLE_ASSETS_INTERSECTION    = "Intersection"
export const MODEL_AVAILABLE_ASSETS_GEMINI          = "Gemini"
export const MODEL_AVAILABLE_ASSETS_PRIMETRUST      = "Primetrust"    // IMPORTANT: this is case-sensitive in the back-end!
export const MODEL_AVAILABLE_ASSETS_COINBASE_PRIME  = "CoinbasePrime" 
export const MODEL_AVAILABLE_ASSETS_ANCHORAGE       = "Anchorage" 

export const FE_MODEL_AVAILABLE_ASSETS_INTERSECTION = "All Custodians";

export const CUSTODIAN_ID_MAP = Object.freeze({
    "coinbase"        :  PROVIDER_ID.COINBASE,
    "gemini"          :  PROVIDER_ID.GEMINI,
    "coinbase prime"  :  PROVIDER_ID.COINBASE_PRIME,
    "securitize"      :  PROVIDER_ID.SECURITIZE,
    "anchorage"       :  PROVIDER_ID.ANCHORAGE
});


export const FE_DEFAULT_CUSTODIAN_LIST =  ["Gemini", "Coinbase Prime", "Anchorage"]
export const FE_TO_BE_AVAILABLE_ASSETS_PROVIDER_MAP = new Map([
  ["gemini"                                             , MODEL_AVAILABLE_ASSETS_GEMINI       ],
  ["coinbaseprime"                                      , MODEL_AVAILABLE_ASSETS_COINBASE_PRIME],
  ["anchorage"                                          , MODEL_AVAILABLE_ASSETS_ANCHORAGE    ]
]);

export const PROVIDER_ID_TO_BACKEND_AVAILABLE_ASSETS_PROVIDER_MAP = new Map([
  [PROVIDER_ID.GEMINI         , MODEL_AVAILABLE_ASSETS_GEMINI         ]
, [PROVIDER_ID.COINBASE_PRIME , MODEL_AVAILABLE_ASSETS_COINBASE_PRIME ]
, [PROVIDER_ID.ANCHORAGE      , MODEL_AVAILABLE_ASSETS_ANCHORAGE ]
]);

export const BACKEND_AVAILABLE_ASSETS_PROVIDER_TO_PROVIDER_ID_MAP = new Map([
  [MODEL_AVAILABLE_ASSETS_GEMINI         , PROVIDER_ID.GEMINI         ]
, [MODEL_AVAILABLE_ASSETS_COINBASE_PRIME , PROVIDER_ID.COINBASE_PRIME ]
, [MODEL_AVAILABLE_ASSETS_ANCHORAGE      , PROVIDER_ID.ANCHORAGE      ]
]);

export const MODEL_AVAILABLE_ASSETS_PROVIDER_TO_FE_MAP = new Map([
  [MODEL_AVAILABLE_ASSETS_GEMINI        , "Gemini"       ]
, [MODEL_AVAILABLE_ASSETS_COINBASE_PRIME, "Coinbase Prime"]
, [MODEL_AVAILABLE_ASSETS_ANCHORAGE     , "Anchorage"    ]
]);

export const ALLOCATION_SYMBOL_TYPE_UNDEFINED = -1
export const ALLOCATION_SYMBOL_TYPE_COIN      = 1   // an actual coin, like "BTC"
export const ALLOCATION_SYMBOL_TYPE_MODEL     = 2   // an existing advisor model, because we allow nesting of model
export const ALLOCATION_SYMBOL_TYPE_FIRM      = 3   // the ID of the Asset Management firm that the current advisor has a relationship with...
export const ALLOCATION_SYMBOL_TYPE_AM_MODEL  = 4   // The models that the AM had shared with the advisor

export const ASSET_MANAGER_ALLOCATION_SYMBOL_TYPES = [ALLOCATION_SYMBOL_TYPE_FIRM, ALLOCATION_SYMBOL_TYPE_AM_MODEL];

export const MODEL_ALLOCATION_RESPONSE_COINS_KEY           = "coins"
export const MODEL_ALLOCATION_RESPONSE_ADVISOR_MODELS_KEY  = "advisor_models"
export const MODEL_ALLOCATION_RESPONSE_AM_MODELS_KEY       = "am_models"

export const PORTFOLIO_VISIBILITY_TYPE_PUBLIC                            = 1;
export const PORTFOLIO_VISIBILITY_TYPE_PRIVATE_NOT_SHARED                = 2;
export const PORTFOLIO_VISIBILITY_TYPE_PRIVATE_SHARED_BY_AM_WITH_ADVISOR = 3;

// This is used to group symbols and limit how many symbol in a group can be selected
export const UNDEFINED_SYMBOL_GROUP_ID = -1;

export const UNDEFINED_ALLOCATION_COIN = {
  asset_data  : { symbol: null, type: ALLOCATION_SYMBOL_TYPE_UNDEFINED, groupId: UNDEFINED_SYMBOL_GROUP_ID, title:"", value: null },
  asset_value : "0",    // Has to be a string because of the text field component expecting a string
  options     : []
}

export const SUB_HEADER_ASSET_MANAGERS                  = "Asset Managers"
export const SUB_HEADER_ASSETS_MANAGED_BY               = "Assets Managed by "

export const ADD_ASSET_MANAGER_BUTTON_LABEL             = "Add Asset Manager"
export const ADVISOR_AS_ASSET_MANAGER_ALLOCATION_INDEX  = 0

export const MODEL_STRUCTURE_TYPE_UNDEFINED                              = -1
export const MODEL_STRUCTURE_TYPE_NORMAL                                 = 1   // This model type can be assigned to accounts and subaccounts
export const MODEL_STRUCTURE_TYPE_META                                   = 2   // This model type can only be assigned to accounts
export const MODEL_STRUCTURE_TYPE_IMPLEMENTATION                         = 3   // This model type is the implementation of the meta model
export const MODEL_STRUCTURE_TYPE_CONCRETION_OF_IMPL_SEGMENT             = 4   // This model type is a concretion of a segment of the implementation model
export const MODEL_STRUCTURE_TYPE_CONCRETION_OF_UNASSIGNED_IMPL_SEGMENT  = 5   // This model type is a concretion of an unassigned segment of the implementation model


export const MODEL_CONSTRAINT_TYPE_UNDEFINED     = -1;
export const MODEL_CONSTRAINT_TYPE_UNCONSTRAINED = 0;
export const MODEL_CONSTRAINT_TYPE_CONSTRAINED   = 1;

export const MODEL_CONSTRAINT_UNCONSTRAINED_TEXT  = "Unconstrained";
export const MODEL_CONSTRAINT_CONSTRAINED_TEXT    = "Constrained";

export const MODEL_CONSTRAINT_TYPE_TEXT = new Map([
  [MODEL_CONSTRAINT_TYPE_UNDEFINED    , MODEL_CONSTRAINT_UNCONSTRAINED_TEXT]    // Treat undefined as unconstrained
, [MODEL_CONSTRAINT_TYPE_UNCONSTRAINED, MODEL_CONSTRAINT_UNCONSTRAINED_TEXT]
, [MODEL_CONSTRAINT_TYPE_CONSTRAINED  , MODEL_CONSTRAINT_CONSTRAINED_TEXT]
]);

export const SAVE_META_MODEL_EDIT_CONFIRMATION_MSG  = "Changes to this model will affect all accounts assigned.  Proceed anyway?"


export const MODEL_ASSIGNMENT_RESULT_CODE = Object.freeze({
  UNDEFINED                 : 0,
  SUCCESSFUL                : 1,
  FAILURE_DUE_TO_HELD_FUNDS : 2,
  FAILURE_OTHER             : 3
});

export const MODEL_UI_MODE = Object.freeze({
  UNDEFINED                 : 0,
  CREATE                    : 1,
  EDIT                      : 2,
  DUPLICATE                 : 3,
  VIEW                      : 4
});

export const UI_ADVISOR_ALLOCATION_NAME_MAX_LENGTH      = 50;
export const UI_ASSET_MANAGEMENT_FIRM_NAME_MAX_LENGTH   = 20;
export const UI_ASSET_MANAGEMENT_MODEL_NAME_MAX_LENGTH  = 30;

export const ASSIGNMENT_UI_ADVISOR_ALLOCATION_NAME_MAX_LENGTH      = 40;
export const ASSIGNMENT_UI_ASSET_MANAGEMENT_FIRM_NAME_MAX_LENGTH   = 15;
export const ASSIGNMENT_UI_ASSET_MANAGEMENT_MODEL_NAME_MAX_LENGTH  = 25;

export const ALLOCATION_NUM_DECIMAL_PLACES  = 2;

export const MODEL_TARGET_TYPE_ACCOUNT     = 1;
export const MODEL_TARGET_TYPE_SUBACCOUNT  = 2;

export const UNDEFINED_ACCOUNT_ID     = -1;
export const UNDEFINED_SUBACCOUNT_ID  = -1;
export const UNDEFINED_PORTFOLIO_ID   = -1;

export const PAGINATION_NUM_VISIBLE_PAGES = 5;
export const ACCOUNT_SUBACCOUNT_ID_DELIMITER = "|";

export const NO_MODEL_NAME = "[No model assigned]";


export const MODEL_ACCESS_PERMISSION = Object.freeze({  
  NO_ACCESS       : 1,
  VIEW_ONLY       : 2,
  FULL_ACCESS     : 3
});

export const INSUFFICIENT_MODEL_PERMISSIONS = "You do not have sufficient permissions to access this feature."
