import { determineOS } from '../src/utils.js';

const configOverrides = JSON.parse(localStorage.getItem("configOverrides")) || window.configOverrides
const getEnvironmentVariable = ((variable_name) => {
  return (configOverrides && configOverrides[variable_name]) ||
      (window.config && window.config[variable_name]) ||
      process.env[variable_name];
})

const os = determineOS()
// general
export const ENABLE_DEBUG_LOGGING = getEnvironmentVariable("enableDebugLogging")
export const env = getEnvironmentVariable("VUE_APP_ENV");
export const IS_LOCAL_ENVIRONMENT = env === "local";

let appDomain = getEnvironmentVariable("VUE_APP_DOMAIN");
let useHttpsForOnrampApis = getEnvironmentVariable("VUE_APP_USE_HTTPS_FOR_SERVER");

// The OR statement below is needed in order to support both the unified and split advisor backend and frontend.
// Once only the split version exists for all environments the OR can be removed (only ..SERVICE_URI should be used).
let apiDomain = getEnvironmentVariable("VUE_APP_ADVISOR_BACKEND_SERVICE_DOMAIN") || getEnvironmentVariable("VUE_APP_DOMAIN");

// set up the environment
let envApiUrl = (useHttpsForOnrampApis ? 'https://' : 'http://') + apiDomain;
let envModelTradingApiUrl = (useHttpsForOnrampApis ? 'https://' : 'http://') + (getEnvironmentVariable("VUE_APP_MODEL_TRADING_API"));

// Coinbase
let cb_redirectUrl = `${getEnvironmentVariable("VUE_APP_CB_AUTH_BASE_URL")}?response_type=code&client_id=${getEnvironmentVariable("VUE_APP_CB_CLIENT_ID")}&redirect_uri=${getEnvironmentVariable("VUE_APP_CB_REDIRECT_URI")}&state=${getEnvironmentVariable("VUE_APP_CB_STATE")}&scope=${getEnvironmentVariable("VUE_APP_CB_SCOPES")}&account=all`;
// Gemini
let gem_redirectUrl = `${getEnvironmentVariable("VUE_APP_GEM_AUTH_BASE_URL")}?response_type=code&client_id=${getEnvironmentVariable("VUE_APP_GEM_CLIENT_ID")}&redirect_uri=${getEnvironmentVariable("VUE_APP_GEM_REDIRECT_URI")}&state=${getEnvironmentVariable("VUE_APP_GEM_STATE")}&scope=${getEnvironmentVariable("VUE_APP_GEM_SCOPES")}`;
// Securitize
const securitize_auth_base_url = getEnvironmentVariable("VUE_APP_SECURITIZE_AUTH_BASE_URL");
const securitize_issuer_id = getEnvironmentVariable("VUE_APP_SECURITIZE_ISSUER_ID");
const securitize_scopes = getEnvironmentVariable("VUE_APP_SECURITIZE_SCOPES");
const securitize_redirect_url = getEnvironmentVariable("VUE_APP_SECURITIZE_REDIRECT_URL");
const securitize_redirectUrl = `${securitize_auth_base_url}/#/authorize?issuerId=${securitize_issuer_id}&scope=${securitize_scopes}&redirecturl=${securitize_redirect_url}`;

const plaidEnvironment  = getEnvironmentVariable("VUE_APP_PLAID_ENV");
const plaidWebhookUrl   = getEnvironmentVariable("VUE_APP_PLAID_WEBHOOK");
const plaidClient       = getEnvironmentVariable("VUE_APP_PLAID_CLIENT_NAME");
const plaidCountryCodes = JSON.parse(getEnvironmentVariable("VUE_APP_PLAID_COUNTRY_CODES"));
const plaidProducts     = JSON.parse(getEnvironmentVariable("VUE_APP_PLAID_PRODUCTS"));


// Stripe
let stripePublishableKey = getEnvironmentVariable("VUE_APP_STRIPE_PUBLISHABLE_KEY");

export const apiUrl = envApiUrl;
export const modelTradingApiUrl = envModelTradingApiUrl;
export const rootDomain = appDomain;
let sentryUrl = appDomain;
if(env==="development") {
  sentryUrl = "localhost";
}
export const sentryDomain = sentryUrl;
export const appName = getEnvironmentVariable("VUE_APP_NAME");
// coinbase
export const coinbaseRedirectUrl = cb_redirectUrl;
// gemini
export const geminiRedirectUrl = gem_redirectUrl;
// securitize
export const securitizeRedirectUrl = securitize_redirectUrl;

export const BASE_URL = getEnvironmentVariable("BASE_URL")

export const STRIPE_PUBLISHABLE_KEY = stripePublishableKey;
export const FEATURE_TOGGLE_KYC_WORKFLOW = getEnvironmentVariable("VUE_APP_FEATURE_TOGGLE_KYC_WORKFLOW");
export const FEATURE_TOGGLE_NEW_ACCOUNT_STATUS = parseInt(getEnvironmentVariable("VUE_APP_FEATURE_TOGGLE_NEW_ACCOUNT_STATUS"));
export const FEATURE_TOGGLE_FUND_FROM_WALLET_PT = parseInt(getEnvironmentVariable("VUE_APP_FEATURE_TOGGLE_FUND_FROM_WALLET_PT"));
export const FEATURE_TOGGLE_FUND_FROM_WALLET_GEMINI = parseInt(getEnvironmentVariable("VUE_APP_FEATURE_TOGGLE_FUND_FROM_WALLET_GEMINI"));
export const ALLOWED_PT_FUND_FROM_WALLET_CLIENTS = parseInt(getEnvironmentVariable("VUE_APP_FUND_FROM_WALLET_LIMITED_CLIENTS"));
export const FEATURE_TOGGLE_OCR = false;

export const FEATURE_TOGGLE_POSITIONS_TABLE = parseInt(getEnvironmentVariable("VUE_APP_FEATURE_TOGGLE_POSITIONS_TABLE"));

export const PRICE_REFRESH_INTERVAL_GEM = getEnvironmentVariable("VUE_APP_PRICE_REFRESH_INTERVAL_GEM");
export const PRICE_REFRESH_INTERVAL_PT = getEnvironmentVariable("VUE_APP_PRICE_REFRESH_INTERVAL_PT");
export const NOTIONAL_BALANCES_REFRESH_INTERVAL = getEnvironmentVariable("VUE_APP_NOTIONAL_BALANCES_REFRESH_INTERVAL");
export const VUEX_CACHE_TIMEOUT = getEnvironmentVariable("VUE_APP_VUEX_CACHE_TIMEOUT");

export const PRIVACY_POLICY = "privacy-policy.pdf"
export const PLAT_SERVICES_AGREEMENT = "platform-services-agreement.pdf"
export const ACT_HOLDER_SERVICES_AGREEMENT = "account-holder-service-agreement.pdf"

export const PLAID_ENV = plaidEnvironment;
export const PLAID_CLIENT = plaidClient;
export const PLAID_PRODUCTS = plaidProducts;
export const PLAID_COUNTRY_CODES = plaidCountryCodes;
export const PLAID_WEBHOOK = plaidWebhookUrl;

// FILE SIZE UPLOAD LIMITS
export const FILE_SIZE_LIMIT_IN_KB = getEnvironmentVariable("VUE_APP_FILE_SIZE_LIMIT_IN_KB");
export const FILE_SIZE_LIMIT_TEXT  = getEnvironmentVariable("VUE_APP_FILE_SIZE_LIMIT_TEXT");

// Account Detail Navigation Items
export const FEATURE_TOGGLE_ACCOUNT_HOLDER_ACCOUNT_DETAIL_NAV = false;
// v2 Account Holder Dashboard Toggle
export const FEATURE_TOGGLE_V2_ACT_HOLDER_DASH = parseInt(getEnvironmentVariable("VUE_APP_FEATURE_TOGGLE_V2_ACT_HOLDER_DASH"));

//portfolios
export const ALL_MODELS_URL = "https://"+ getEnvironmentVariable("VUE_APP_ACADEMY_DOMAIN") + "/market-models/";

// v2 Trading
export const FEATURE_TOGGLE_V2_TRADING = 1;

export const FEATURE_FLAG_SECURITIZE_NAVBAR = getEnvironmentVariable("VUE_APP_FEATURE_FLAG_SECURITIZE_NAVBAR")
export const FEATURE_FLAG_SECURITIZE_LINK = getEnvironmentVariable("VUE_APP_FEATURE_FLAG_SECURITIZE_LINK")

export const OKTA_LOGOUT_URL    = getEnvironmentVariable("VUE_APP_OKTA_LOGOUT_URL");
export const USER_INACTIVITY_LIMIT = getEnvironmentVariable("VUE_APP_USER_INACTIVITY_LIMIT");
export const USERS_LOGOUT_MODAL_COUNTDOWN_INITAL_VALUE = getEnvironmentVariable("VUE_APP_USERS_LOGOUT_MODAL_COUNTDOWN_INITAL_VALUE");
export const RECAPTCHA_SITE_KEY = getEnvironmentVariable("VUE_APP_RECAPTCHA_SITE_KEY")

export const ACADEMY_URL = getEnvironmentVariable("VUE_APP_ACADEMY_URL");
export const ACADEMY_FACT_CARD_URL = getEnvironmentVariable("VUE_APP_ACADEMY_FACT_CARD_URL");

export const SENTRY_DSN = getEnvironmentVariable("VUE_APP_SENTRY_DSN");
export const ERROR_MONITORING_COMMIT_SHA = getEnvironmentVariable("VUE_APP_ERROR_MONITORING_COMMIT_SHA");
export const ERROR_MONITORING_ENVIRONMENT = getEnvironmentVariable("VUE_APP_ERROR_MONITORING_ENVIRONMENT");
export const ERROR_MONITORING_TRACING_SAMPLE_RATE = getEnvironmentVariable("VUE_APP_ERROR_MONITORING_TRACING_SAMPLE_RATE");
export const ERROR_MONITORING_ENABLED = getEnvironmentVariable("VUE_APP_ERROR_MONITORING_ENABLED") === "1";

//Whitelabel
export const WHITE_LABELED_ORG_IDS = getEnvironmentVariable("VUE_APP_WHITE_LABELED_ORG_IDS");

export const QUALIFIED_ACCOUNTS_ENABLED = getEnvironmentVariable("VUE_APP_QUALIFIED_ACCOUNTS_ENABLED")
export const QUALIFIED_ACCOUNTS_ORG_IDS = getEnvironmentVariable("VUE_APP_QUALIFIED_ACCOUNTS_ORG_IDS")

// ACH Transfer
export const FEATURE_FLAG_ACH_ENABLED = getEnvironmentVariable("VUE_APP_FEATURE_FLAG_ACH_ENABLED")

// Mixpanel
export const MIXPANEL_PROJECT_TOKEN = getEnvironmentVariable("VUE_APP_MIXPANEL_PROJECT_TOKEN")

export const KEYCLOAK_URL = getEnvironmentVariable("VUE_APP_KEYCLOAK_URL");
export const KEYCLOAK_REALM = getEnvironmentVariable("VUE_APP_KEYCLOAK_REALM");
export const KEYCLOAK_CLIENT_ID = getEnvironmentVariable("VUE_APP_KEYCLOAK_CLIENT_ID");

export const KEYCLOAK_LOGOUT_URL = OKTA_LOGOUT_URL;