import {api} from "@/api.js";
import {get} from "lodash";
import {AES} from "crypto-js";
import {TICKER_LOOKUP} from "@/constants";
import {SMA_TRADE_PREVIEW_CURRENCY_PLACEHOLDER} from "@/views/Consolidated Views/sma/dashboard/sma-constants.js"
import { getAuthRef } from '@/main';
import { HELD_AWAY, TRADABLE } from "@/account_access_types";

export const USER_ROLE_UNDEFINED        = -1;
export const USER_ROLE_ADVISOR          = 1;
export const USER_ROLE_ADMIN            = 2;
export const USER_ROLE_AUDITOR          = 3;
export const USER_ROLE_ACCOUNT_HOLDER   = 4;
export const USER_ROLE_BULK_TRADER      = 5;
export const USER_ROLE_ACCOUNT_PARTY    = 6;
export const USER_ROLE_ASSET_MANAGER    = 7

export const SUBSCRIPTION_STATUS_CODE_UNDEFINED                                 = -1;
export const SUBSCRIPTION_STATUS_CODE_NOT_REQUIRED                              = 0;
export const SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_VALID                        = 2;
export const SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_VALID_ACADEMY_ACCESS_ONLY    = 3;
export const SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_TRIAL_AVAILABLE              = 10;
export const SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_TRIAL_EXPIRED                = 11;
export const SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL                     = 12;
export const SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL_ACADEMY_ACCESS_ONLY = 13;

export const SUBSCRIPTION_TYPE_FOR_ACADEMY_URL_ADVISOR                          = "advisor";
export const SUBSCRIPTION_TYPE_FOR_ACADEMY_URL_ACADEMY                          = "academy";

export const ONRAMP_INVALID_PRICE_CODE                                          = -1000;
export const ONRAMP_ACADEMY_MONTHLY_PRICE_CODE                                  = -10;
export const ONRAMP_STANDARD_PRICE_CODE                                         = 1;
export const ONRAMP_PRO_PRICE_CODE                                              = 500;
export const ONRAMP_ENTERPRISE_PRICE_CODE                                       = 1000;

export const PASSWORD_STATUS_CODE_OK                                            = 1;
export const PASSWORD_STATUS_CODE_FORCE_CHANGE                                  = 2;

export const PASSWORD_UPDATE_SUCCESSFUL_ACTION_NA                               = 1;
export const PASSWORD_UPDATE_SUCCESSFUL_ACTION_LOGOUT                           = 2;

export const PASSWORD_UPDATE_TYPE_NA                                            = 0;
export const PASSWORD_UPDATE_TYPE_WITH_CURRENT_PASSWORD                         = 1;
export const PASSWORD_UPDATE_TYPE_WITHOUT_CURRENT_PASSWORD                      = 2;

export const FIRST_TIME_LOGIN_STORAGE_KEY                                       = "first_time_login";
export const PAPER_WORK_NOTIFICATION_STORAGE_KEY                                = "paper_work_notification";
export const SELECTED_PROVIDER_STORAGE_KEY                                      = "sel-prov";

export const PRIME_TRUST_PAPERWORK_NOTIFICATION_TYPE                            = "pt-kyc-started";
export const GEMINI_PAPERWORK_NOTIFICATION_TYPE                                 = "gem-mgmt-act-pending";

export const DEPOSIT_TYPE_WIRE                                                  = "Wire";
export const DEPOSIT_TYPE_ACH                                                   = "ACH";

export const PRIME_TRUST_PROVIDER_TABLE_NAME                                    = "PT";
export const PRIME_TRUST_TITLE                                                  = "PrimeTrust";

export const GEMINI_WALLET_TITLE                                                = "Gemini";
export const PRIME_TRUST_WALLET_TITLE                                           = "Prime Trust";

export const MANUAL                                                             = "MANUAL";
export const HTTP_404_SERVER_ERROR                                              = 404;
export const HTTP_500_INTERNAL_SERVER                                           = 500;

export const PERCENTAGE                                                         = 0;
export const AMOUNT                                                             = 1;

export const SIZE_SMALL                                                         = "small";
export const SIZE_NORMAL                                                        = "normal";

export const GENERIC_SIZES = Object.freeze({
    SMALL        : SIZE_SMALL,
    NORMAL       : SIZE_NORMAL,
});

export const PROVIDER_CONNECTION_STATUS = Object.freeze({
    NOT_CONNECTED: 0,
    CONNECTED    : 1,
    INVALID      : 2,
});

export const PROVIDER_ID = Object.freeze({
    UNDEFINED      : -1,
    COINBASE       :  1,
    GEMINI         :  2,
    FALCONX        : 22,
    PRIMETRUST     : 33,
    COINBASE_PRIME : 44,
    SECURITIZE     : 55,
    ANCHORAGE      : 66,
});
export const CLEARING_PARTY_ID = Object.freeze({
    FALCONX     : 1,
});

export const GENERAL_ACCOUNT_TYPE_IDS = Object.freeze({
    INDIVIDUAL_ACCOUNT  :  1,
    TRUST_ACCOUNT       :  2,
    CUSTODIAN_ACCOUNT   :  3,
    REMITTANCE_ACCOUNT  :  4,
    JTWROS_ACCOUNT      :  5,
    JTIC_ACCOUNT        :  6,
    ROTH_IRA_ACCOUNT    :  7,
    IRA_ACCOUNT         :  8,
    ROLLOVER_IRA_ACCOUNT:  9,
});
export const PRIME_TRUST_ACCOUNT_TYPE_NAMES = Object.freeze({
    INDIVIDUAL_ACCOUNT:  "Individual",
    ROTH_IRA_ACCOUNT  :  "RIRA",
    IRA_ACCOUNT       :  "IRA",
});


export const PRIME_TRUST_ACCOUNT_TYPE_DISPLAY_NAMES = Object.freeze({
    INDIVIDUAL_ACCOUNT:  "Individual",
    ROTH_IRA_ACCOUNT:  "Roth IRA",
    IRA_ACCOUNT:  "IRA",
});

export const PRIME_TRUST_ACCOUNT_TYPES = [
    PRIME_TRUST_ACCOUNT_TYPE_DISPLAY_NAMES.IRA_ACCOUNT,
    PRIME_TRUST_ACCOUNT_TYPE_DISPLAY_NAMES.INDIVIDUAL_ACCOUNT,
    PRIME_TRUST_ACCOUNT_TYPE_DISPLAY_NAMES.ROTH_IRA_ACCOUNT
];

//This is to be what prime trust expects as a definition of marriage.
export const GENERAL_MARTIAL_TYPE_OUTGOING  = Object.freeze({
    SINGLE:  "single",
    MARRIED:  "married",
});
//This is what we display to the user in the ui.
export const GENERAL_MARTIAL_TYPE_DISPLAY_NAMES  = Object.freeze({
    SINGLE:  "Single",
    MARRIED:  "Married",
});
export const PRIME_TRUST_MARITAL_TYPES = [
    GENERAL_MARTIAL_TYPE_DISPLAY_NAMES.SINGLE,
    GENERAL_MARTIAL_TYPE_DISPLAY_NAMES.MARRIED
];

export const PROVIDER_TITLE_FOR_ENDPOINTS = Object.freeze({
    PRIMETRUST    : "primetrust",
    GEMINI        : "gemini",
    COINBASEPRIME : "coinbaseprime",
    ANCHORAGE     : "anchorage"
});

export const FUNDED            = "Funded";
export const BANK_ADDED        = "Bank Added";
export const PENDING_BANK_LINK = "Bank Pending";
export const REJECTED          = "Rejected";
export const HA_LINKED         = "Linked";
export const HA_UNLINKED       = "Un-linked";
export const KYC_FAILED        = "KYC Failed";
export const PENDING           = "Pending";
export const CLOSED            = "Closed";

export const LABEL_TO_COLOR = Object.freeze({
    [FUNDED]           : "#00B84E",
    [BANK_ADDED]       : "#0000ff",
    [PENDING_BANK_LINK]: "#0000ff",
    [REJECTED]         : "#ff0000",
    [HA_LINKED]        : "#00B84E",
    [HA_UNLINKED]      : "#ff0000",
    [KYC_FAILED]       : "#ff0000",
    [PENDING]          : "#ff6600",
    [CLOSED]           : "#ff0000",
});

export const STATUS_FLAGS_TO_LABEL = new Object({
    is_account_funded         : FUNDED,
    is_account_bank_added     : BANK_ADDED,
    is_account_trade_enabled  : PENDING_BANK_LINK,
    is_account_client_rejected: REJECTED,
    is_account_held_away      : HA_LINKED,
    is_account_kyc_failed     : KYC_FAILED,
    is_account_pending        : PENDING,
    is_account_closed         : CLOSED,
});

/*
 RELATIONSHIP TYPE
*/
export const RELATIONSHIP_TYPE_SPOUSE       = { "id": 1 ,  "code": "SPOUSE"      , "text": "Spouse"        , "description": "Spouse"             }
export const RELATIONSHIP_TYPE_PARENT       = { "id": 2 ,  "code": "PARENT"      , "text": "Parent"        , "description": "Parent"             }
export const RELATIONSHIP_TYPE_CHILD        = { "id": 3 ,  "code": "CHILD"       , "text": "Child"         , "description": "Child"              }
export const RELATIONSHIP_TYPE_SIBLING      = { "id": 4 ,  "code": "SIBLING"     , "text": "Sibling"       , "description": "Sibling"            }
export const RELATIONSHIP_TYPE_GRANDPARENT  = { "id": 5 ,  "code": "GRANDPARENT" , "text": "Grandparent"   , "description": "Grandparent"        }
export const RELATIONSHIP_TYPE_GRANDCHILD   = { "id": 6 ,  "code": "GRANDCHILD"  , "text": "Grandchild"    , "description": "Grandchild"         }
export const RELATIONSHIP_TYPE_AUNT         = { "id": 7 ,  "code": "AUNT"        , "text": "Aunt"          , "description": "Aunt"               }
export const RELATIONSHIP_TYPE_UNCLE        = { "id": 8 ,  "code": "UNCLE"       , "text": "Uncle"         , "description": "Uncle"              }
export const RELATIONSHIP_TYPE_COUSIN       = { "id": 9 ,  "code": "COUSIN"      , "text": "Cousin"        , "description": "Cousin"             }
export const RELATIONSHIP_TYPE_OTHERLATIVE  = { "id": 10,  "code": "OTHERLATIVE" , "text": "OtherRelative" , "description": "OtherRelative"      }
export const RELATIONSHIP_TYPE_OTHER        = { "id": 11,  "code": "OTHER"       , "text": "Other"         , "description": "Other"              }

export const SUPPORTED_RELATIONSHIP_TYPES = [
     RELATIONSHIP_TYPE_SPOUSE
,    RELATIONSHIP_TYPE_PARENT
,    RELATIONSHIP_TYPE_CHILD
,    RELATIONSHIP_TYPE_SIBLING
,    RELATIONSHIP_TYPE_GRANDPARENT
,    RELATIONSHIP_TYPE_GRANDCHILD
,    RELATIONSHIP_TYPE_AUNT
,    RELATIONSHIP_TYPE_UNCLE
,    RELATIONSHIP_TYPE_COUSIN
,    RELATIONSHIP_TYPE_OTHERLATIVE
,    RELATIONSHIP_TYPE_OTHER
]

export const PRIMETRUST_BENEFICIARIES_RELATIONSHIP_TYPES = [
     RELATIONSHIP_TYPE_SPOUSE
,    RELATIONSHIP_TYPE_PARENT
,    RELATIONSHIP_TYPE_CHILD
,    RELATIONSHIP_TYPE_OTHERLATIVE
,    RELATIONSHIP_TYPE_OTHER
]

export const CUSTODIAN_META_MODEL_ACCESS_CONTROL = new Map([
    [ PROVIDER_ID.UNDEFINED     , false ],
    [ PROVIDER_ID.COINBASE      , true  ],
    [ PROVIDER_ID.COINBASE_PRIME, true  ],
    [ PROVIDER_ID.ANCHORAGE     , false ],
    [ PROVIDER_ID.GEMINI        , true  ],
    [ PROVIDER_ID.PRIMETRUST    , true  ],
    [ PROVIDER_ID.SECURITIZE    , true  ],
    [ PROVIDER_ID.FALCONX       , true  ],
]);

export const MAX_VALUE_BEFORE_CURRENCY_ABBREVIATION = 9999999;
export const findSetStatusFlag = (statusFlags) => {
    for (let [key, val] of Object.entries(statusFlags)) {
        if (val) {
            return key;
        }
    }
};
export const providerDepositFunctionFinder = () => {
    var providerDepositObjLiteral = new Object();
    for (const [key, value] of Object.entries(PROVIDER_ID)) {
        providerDepositObjLiteral[value] = {
            account_holder: { DEPOSIT_TYPE_WIRE: null, DEPOSIT_TYPE_ACH: null },
            advisor: { DEPOSIT_TYPE_WIRE: null, DEPOSIT_TYPE_ACH: null },
        };
    }
    return providerDepositObjLiteral;
};

export const PROVIDER_BASE_LOGO_MAP = new Map([
    [ PROVIDER_ID.COINBASE      , require("@/assets/svg/custodians/coinbase.svg")     ],
    [ PROVIDER_ID.COINBASE_PRIME, require("@/assets/svg/custodians/coinbaseprime.svg")],
    [ PROVIDER_ID.ANCHORAGE     , require("@/assets/svg/custodians/anchorage.svg")    ],
    [ PROVIDER_ID.GEMINI        , require("@/assets/svg/custodians/gemini.svg")       ],
    [ PROVIDER_ID.PRIMETRUST    , require("@/assets/svg/custodians/primetrust.svg")   ],
    [ PROVIDER_ID.SECURITIZE    , require("@/assets/svg/custodians/securitize.svg")   ],
    [ PROVIDER_ID.FALCONX       , require("@/assets/falconx_black.png")               ],
    [ PROVIDER_ID.ANCHORAGE     , require("@/assets/svg/custodians/anchorage.svg")    ],
]);

// HT-TODO - move all the pricing code into its own file.
export const putCurrentUserOnTrialSubscription = async (
    multipleLicenses,
    priceCode
) => {
    const response = await api.startSubscriptionTrial({
        requires_multi_user_licenses: multipleLicenses,
        onramp_price_code: priceCode,
    });

    return response.data.trial_subscription_status_code;
};

export const isSubscriptionTrialSuccess = (status_code) => {
    return (
        status_code == SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL ||
        status_code ==
            SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL_ACADEMY_ACCESS_ONLY
    );
};

// - @end func's to be consolidated
export const determineOS = () => {
    var userOS = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userOS)) {
        os = "Android";
    } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
    }
    return os;
};

export const isClient = (role) => {
    // parse token data for role
    // :return: false if not a client
    // :return: true if client
    if(role) {
        return parseInt(role) === USER_ROLE_ACCOUNT_HOLDER;
    }
    const user_type = sessionStorage.getItem("current_user_type");
    console.debug("isClient()", "user_type:", user_type)
    return parseInt(user_type) === USER_ROLE_ACCOUNT_HOLDER;
};

export const userHasAccessToApp = () => {
    const subscriptionCode = getSubscriptionStatusCode();

    return (
        subscriptionCode === SUBSCRIPTION_STATUS_CODE_NOT_REQUIRED ||
        subscriptionCode === SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_VALID ||
        subscriptionCode === SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL
    );
};

function extractUserRole(role = undefined)
{
    const user_type = sessionStorage.getItem('current_user_type');
    const role_to_use = role !== undefined ? role : user_type;

    return parseInt(role_to_use);
}

export const isAdvisor = (role = undefined) => {
    return extractUserRole(role) === USER_ROLE_ADVISOR;
}

export const isAssetManager = (role = undefined) => {
    return extractUserRole(role) === USER_ROLE_ASSET_MANAGER;    
}

export const canUserManageAccount = (role = undefined) => {    
    return isAssetManager(role) || isAdvisor(role);
}

export const hasAccessToAcademyOnly = () => {
    return [SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_VALID_ACADEMY_ACCESS_ONLY,
        SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL_ACADEMY_ACCESS_ONLY].includes(getSubscriptionStatusCode())
}

export const userHasAccessToAcademy = () => {
    const subscriptionCode = getSubscriptionStatusCode();
    return (
        subscriptionCode === SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_VALID ||
        subscriptionCode ===
            SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_VALID_ACADEMY_ACCESS_ONLY ||
        subscriptionCode === SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL ||
        subscriptionCode ===
            SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL_ACADEMY_ACCESS_ONLY
    );
};

export const userPassesSubscriptionCheck = () => {
    return userHasAccessToApp() || userHasAccessToAcademy();
};

export const isUserEligibleForFreeTrial = () => {
    const subscriptionCode = getSubscriptionStatusCode();

    return (
        subscriptionCode ===
        SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_TRIAL_AVAILABLE
    );
};

export const isUserFreeTrialExpired = () => {
    const subscriptionCode = getSubscriptionStatusCode();

    return (
        subscriptionCode === SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_TRIAL_EXPIRED
    );
};

export const TEMPORARY_generateAcademyUrl = () => {
    const baseUrl   = "https://academy.onrampinvest.com/?session=";
    const key       = "LerUnwc49671";
    const input     = TEMPORARY_generateAcademySecretAnswer();
    const encrypted = AES.encrypt(input, key);
    const encoded   = encodeURIComponent(encrypted);

    return `${baseUrl}${encoded}`;
};

const TEMPORARY_generateAcademySecretAnswer = () => {
    const date = new Date();

    return (
        "mtrNoDpumP1s129-109mawaiPrt8wa" +
        "_" +
        date.getUTCFullYear().toString() +
        "-" +
        (date.getUTCMonth() + 1).toString() +
        "-" +
        date.getUTCDate().toString()
    );
};

export const getAcademyUrlFromProfilePackageType = () => {
    return getAcademyUrl(userHasAccessToAcademy(), userHasAccessToApp());
};

export const getAcademyUrl = (hasAccessAcademy, hasAccessApp) => {
    const url = TEMPORARY_generateAcademyUrl();
    const queryParam = "&packageType=";
    const value =
        hasAccessAcademy && !hasAccessApp
            ? SUBSCRIPTION_TYPE_FOR_ACADEMY_URL_ACADEMY
            : SUBSCRIPTION_TYPE_FOR_ACADEMY_URL_ADVISOR;
    return `${url}${queryParam}${value}`;
};

export const userMustChangePassword = () => {
    const passwordStatusCode = sessionStorage.getItem("password_status_code");
    if (passwordStatusCode) {
        return (
            parseInt(passwordStatusCode) === PASSWORD_STATUS_CODE_FORCE_CHANGE
        );
    }
    return false;
};

function getSubscriptionStatusCode() {
    const subscription_status_code = sessionStorage.getItem(
        "subscription_status_code"
    );
    if (subscription_status_code) {
        return parseInt(subscription_status_code);
    }

    return SUBSCRIPTION_STATUS_CODE_UNDEFINED;
}

export async function getOrscc() {
    const subscriptionStatusCode = sessionStorage.getItem(
        "subscription_status_code"
    );
    if (subscriptionStatusCode) {
        return parseInt(subscriptionStatusCode);
    }
    const authRef = getAuthRef();
    const idToken = await authRef.idTokenParsed
    if (idToken) {
        return idToken.orscc;
    }
}


export function getCustodianIcon(providerName) 
{    
    return providerName?.length > 0
        ? require("@/assets/svg/custodians/" + providerName.toLowerCase().split(" ").join("") + ".svg")
        : "";
}

export function formatAsPercentage(number) 
{
    return new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number / 100);
}

export function  getCurrencyIcon(data) {
    try {
      return require("@/assets/svg/Crypto/" + data.toLowerCase() + ".svg");
    } catch {
      return require("@/assets/svg/Crypto/money.svg");
    }
  }

export function  generateOptions(modelName,label,series) {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 220,
        width:220
      },
      styledMode: true,
      title: updateTitle(modelName, label),
      tooltip: {
        pointFormat:
          "{series.name}: <b>{point.percentage:.1f}%</b><br /> ${point.y:.2f}",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          size: '120%',
          cursor: "pointer",
          colors: pieColors(),
          innerSize: "80%",
          showInLegend: true,
          dataLabels: {
            enabled: false,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            formatter: function () {
              return this.y > -1 ? this.point.name : null;
            },
          },
        },
      },
      legend: {
        enabled: false,
        useHTML: true,
        itemHeight: "100%",
        maxHeight: 150,
        layout: "vertical",
        align: "center",
        itemStyle: {
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
          color: "#424450"
        },
        navigation: {
          activeColor: "#3E576F",
          animation: true,
          arrowSize: 12,
          inactiveColor: "#CCC",
          style: {
            fontWeight: "bold",
            color: "#333",
            fontSize: "13px"
          },
        },
        labelFormatter: function () {
          return (
            "<span style='display:inline-block;width:150px;'>" +
            this.name +
            "&nbsp" +
              "<span style='font-size:12px;color:#AFB2BF;'>" +
              this.ticker +
              "</span>" +
            "</span>" +
            "<span style='color:#424450;display:inline-block;min-width:60px'>" +
            ((this.y / this.total) * 100).toFixed(2) +
            "%</span>" +
            "<span style='font-size:14px;color:black;display:inline-block;'>" +
            "&nbsp" +
            fmtActBal(this.y) +
            "</span>" +
            "</span>" +
            "<div style='border-top: 1px solid #E7E8EE; margin-top: 10px; margin-bottom: 10px;'></div>"
          );
        },
      },
      credits: {
        enabled: false,
      },
      series: generateAllocationChartData(series),
    };
}

export function updateTitle(modelName, label){
  let result = {};
  let text = "";
  if(label === "MODEL"){
    text= `
          <span style="color: #AFB2BF; font-size: 12px; white-space: nowrap;">${label}</span>
          </br>
          <span style="font-size: 16px;">${modelName}</span>
          `;
  }else{
      text= `
          <small style="color: #AFB2BF; font-size: 12px;">${label}</small>
          <br/>
          `;
    }
  result = {
      text: text,
      verticalAlign: "middle",
      floating: "true",
      horizontalAlign: "middle",
      y: 0,
      style: {
        fontSize: "28px",
        fontFamily: "'Benton-Sans', Helvetica, Arial",
        fontWeight: "700",
      },
    };
  return result;
}

export function pieColors() {
    return ["#00B793","#00C7EA","#9100FF","#FF6545","#5CCEB7",
    "#023E8A","#0177B6","#02B4D8","#90E0EF","#264653",
    "#2A9D8F","#5AC8BB","#E77051","#F4A261","#FFD05E"]
}

export function valueToGainLossColor(value) {
    if(value >= 0)
    {
        return "green";

    } else {
        return "red"
    }
}

export const unixToLocal = (unixTime) => {
    //*
    // { param } unix time from queried_at key of gemini_stub /ticker endpoint
    // { returns } a local time to report on the dashboard the time the price was last updated
    //*
    let date = new Date(unixTime).toLocaleTimeString();
    return date;
};

export const dateOnlyString = (unixTime) => {
    let date = new Date(unixTime);
    let options = { year: "numeric", month: "numeric", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
};
export const unixToDate = (dateString) => {
    let date = Date.parse(dateString);
    return date;
};

export const hasConnections = (connections) => {
    //*
    // { param } connections object in the main module of state
    // { returns } boolean :true: if any connections exist
    //                     :false: if no connections exist
    //*
    let hasCons = false;
    for (const provider in connections) {
        if (
            connections[provider].status ===
            PROVIDER_CONNECTION_STATUS.CONNECTED
        ) {
            hasCons = true;
        }
    }
    return hasCons;
};

export const fmtActBal = (balStr, minDecPlaces, signDisplay = false) => {
    //*
    // { param } balStr ('balance string') - a string
    //              representing an account balance formatted
    //              as: 36930000.00
    // { param } minDecPlaces ('minimum decimal places') - a number
    //              represents how many minimum decimal places want to be used, if it is not sent, the default is two
    // { param } signDisplay - bool - whether to turn on signDisplay or not
    // { returns } formatted: $369,300,00.00
    const parsed = parseFloat(balStr);
    // Option to format possible numbers with decimals
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        signDisplay: !signDisplay ? "never" : "always",
        // These options are needed to round to whole numbers if that's what you want.
        maximumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.1 as $2,500.00 decimal places depend on parameter)
        minimumFractionDigits: minDecPlaces !== undefined ? minDecPlaces : 2,
    });
    return formatter.format(parsed);
};

export const fmtActPerf = (perfStr, minDecPlaces, signDisplay = false) => {
    const parsed = parseFloat(perfStr);
    const formatter = new Intl.NumberFormat("en-US", {
        style: "percent",
        signDisplay: !signDisplay ? "never" : "always",
        maximumFractionDigits: 2,
        minimumFractionDigits: minDecPlaces !== undefined ? minDecPlaces : 2,
    });
    return formatter.format(parsed);
};

export const formatGainLoss = (value) => {
    // value can be either a string, or a number
    
    const parsedNumber = parseFloat(value);

    if (isNaN(parsedNumber))
    {
        return "--"
    }
    else
    {
        const shouldDisplaySign = parsedNumber.toFixed(2) < 0;
        return fmtActBal(parsedNumber, 2, shouldDisplaySign)
    }    
};

export const formatGainLossBrackets = (value) => {
    // value can be either a string, or a number
    
    const parsedNumber = parseFloat(value);

    if (isNaN(parsedNumber))
    {
        return "--"
    }
    else
    {
        const isNegative = parsedNumber < 0;
        let formattedValue = fmtActBal(parsedNumber, 2, false);
        if (isNegative) {
            formattedValue = "(" + formattedValue + ")"
        }

        return formattedValue
    }   
}

export const formatWeightToPercentage = (weightStr) => {
    const parsed = parseFloat(weightStr);
    var formatter = new Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
    return formatter.format(parsed);
};

export const extractNumberValFromCurrencyString = (currencyString) => {
    let value =
        typeof currencyString === "string"
            ? currencyString
            : currencyString.toString();
    return Number(value.replace(/[^0-9-.]+/g, ""));
};

const findSign = (value, applySign) => {
    let sign = "";
    if (applySign) {
        sign = extractNumberValFromCurrencyString(value) > 0 ? "+" : "-";
    }
    return sign;
};

export const formatCurrencySummaryValue = (val, applySign = false) => {
    let value = typeof val === "string" ? val : val.toString();
    let sign = findSign(value, applySign);
    let numVal = Number(value.replace(/[$,+-]/g, ""));
    if (numVal > MAX_VALUE_BEFORE_CURRENCY_ABBREVIATION) {
        return !applySign
            ? formatAllocationChartTotalValue(numVal)
            : `${sign}${formatAllocationChartTotalValue(numVal)}`;
    }
    return value;
};

export const formatPercentageSummaryValue = (val, applySign = false) => {
    let value = typeof val === "string" ? val : val.toString();
    let sign = findSign(value, applySign);
    let numVal = Number(value.replace(/[%]/g, ""));
    return !applySign
        ? formatWeightToPercentage(numVal)
        : `${sign}${formatWeightToPercentage(numVal)}`;
};

export const formatAllocationChartTotalValue = (balFloat) => {
    // Intended for use in the center of the Allocation Chart...
    // ...Component

    const DECIMAL_PLACES = 2;

    const lookup = [
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "B" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "K" },
        { value: 1, symbol: "" },
    ];
    var item = lookup.find(function (item) {
        return balFloat >= item.value;
    });
    return item
        ? (balFloat / item.value).toFixed(DECIMAL_PLACES) + item.symbol
        : "0";
};

/**
 * Returns formatted number with two decimal places (for example, formats 1234.567 to 1,234.57)
 *
 * @param {string|number} number
 * @param {number} the number of decimal places that should display eg 3 -> 0.001
 * @returns {number}
 */
export const formatDecimalNumber = (number, precision = 2) => {
    const parsed = parseFloat(number);
    let formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    });
    return formatter.format(parsed);
};

export const PRECISION_MAP = new Map([
    ["BTC", 8],
    ["LTC", 5],
    ["PAXG", 8],
    ["USD", 2],
]);

export const DEFAULT_QUANTITY_PRECISION = 6;

export const formatQuantityForDisplay = (
    value,
    symbol = "USD",
    showSymbol = true
) => {    
    let formattedQuantity = "";

    if (value != null && !isNaN(value)) {
        const entry = PRECISION_MAP.get(symbol);
        const precision = entry || DEFAULT_QUANTITY_PRECISION;

        formattedQuantity = formatDecimalNumber(value, precision);
    }

    return showSymbol
        ? `${formattedQuantity} ${symbol}`
        : `${formattedQuantity}`;
};

export const formatHoldingsQuantityToDisplay = (
    activeAsset,
    decPrecision,
    symbol
) => {
    let formattedQuantity = "";

    if (
        activeAsset.available_balance != null &&
        !isNaN(activeAsset.available_balance)
    ) {
        formattedQuantity = formatDecimalNumber(
            activeAsset.available_balance,
            decPrecision
        );
    }
    return `${formattedQuantity} ${symbol.toUpperCase()}`;
};

export const generateAllocationChartData = (portfolio) => {
    const highChartData = [];
    for (const asset of portfolio) {
        highChartData.push({
            name: get(TICKER_LOOKUP, asset.currency, asset.currency),
            ticker: asset.currency,
            y: asset.asset_value,
        });
    }
    return [
        {
            name: "Asset Allocation",
            colorByPoint: true,
            data: highChartData,
        },
    ];
};

export const getProviderMetaID = (provider_id) => {
    var baseID = provider_id;

    return baseID;
};

const capitalizeFirstChar = (value) => {
    return value && typeof value === "string"
        ? value.charAt(0).toUpperCase() + value.slice(1)
        : "";
};

export const getProviderName = (providerId, providersLookup) => {
    const provider = providersLookup?.find((entry) => entry.id === providerId);
    if (providerId === PROVIDER_ID.PRIMETRUST) {
        provider.provider_name = PRIME_TRUST_TITLE;
    }
    return provider ? capitalizeFirstChar(provider.provider_name) : "";
};

export const getProviderBaseLogo = (provider_id) => {
    try {
        return PROVIDER_BASE_LOGO_MAP.get(getProviderMetaID(provider_id));
    } catch (err) {
        return "";
    }
};

export const fmtPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
            ""
        );
    }
    return null;
};

export const getColumnHeaders = (json_obj, view) => {
    const headers = Object.keys(json_obj[0]);
    const labelled_headers = [];
    if (view === "act") {
        labelled_headers.push({
            headerName: "ACTIONS",
            field: "id",
            cellRenderer: "quickActAct",
            cellClass: "dd",
            pinnedRowCellRenderer: null,
            cellRendererParams: {
                clicked: function (field) {},
                editable: false,
            },
        });
    }
    for (let i = 1; i < headers.length; i++) {
        var header_arr = headers[i].split("_");
        for (let j = 0; j < header_arr.length; j++) {
            header_arr[j] = header_arr[j].toUpperCase();
        }

        if (view === "cli" && headers[i] === "last_name") {
            labelled_headers.push({
                field: headers[i],
                headerName: header_arr.join(" "),
                cellRenderer: "linkLn",
            });
        } else if (view === "act" && headers[i] === "onramp_id") {
            labelled_headers.push({
                field: headers[i],
                headerName: header_arr.join(" "),
                cellRenderer: "linkId",
            });
        } else if (headers[i] === "custodian" || headers[i] === "custodians") {
            labelled_headers.push({
                field: headers[i],
                headerName: header_arr.join(" "),
                cellRenderer: "custodians",
            });
        } else if (
            headers[i] === "market_value" ||
            headers[i] === "total_assets"
        ) {
            labelled_headers.push({
                field: headers[i],
                headerName: header_arr.join(" "),
            });
        } else {
            labelled_headers.push({
                field: headers[i],
                headerName: header_arr.join(" "),
            });
        }
    }
    return labelled_headers;
};

function providerIdToString(providerId) {
    if (providerId === 2) {
        return "GEMINI";
    }
}

export const TradingActNameFmt = (firstName, lastName, providerId) => {
    let custodian = providerIdToString(providerId);
    // format trading act label
    return `${firstName}-${lastName}-${custodian}`;
    //     var ext = Math.floor(Math.random()*(999-100+1)+100); // three digit num
    //     return `${provider_id}-${lastName}-${ext}`
};

export const createGeminiKycPayload = (
    provider_id,
    client_id,
    formOne,
    formTwo
) => {
    // [TODO] check account holder id reference
    let repcode = "";
    if (typeof formOne.rep_code == "string") {
        repcode = formOne.rep_code;
    } else {
        repcode = formOne.rep_code.label;
    }
    return {
        account_holder_id: client_id,
        provider_id      : provider_id,
        rep_code         : repcode,
        user_email       : formOne.email,
        first_name       : formOne.first_name,
        last_name        : formOne.last_name,
        address1         : formTwo.addr1,
        address2         : formTwo.addr2,
        city             : formTwo.city,
        state            : formTwo.state,
        zip_code         : formTwo.zip,
        birth_date       : formOne.dob,
        phone            : formOne.phone,
        ssn              : formOne.ssn,
        country          : formTwo.country,
    };
};

export const handleGeminiKycSubmission = async (data) => {
    // api call to onramp-advisor to submit gemini kyc account
    return await api.gemCreateSubAct(data);
};

export const handleGeminiDocUpload = async (docs, act_id) => {
    // Arg: list of file objects - KYC DOCS
    return await api.gemCliDocs(act_id, docs);
};

export const handleGeminiAgreementUpload = async (agreements, act_id) => {
    // Arg: list of file objects - Advisor Agreements (Optional)
    return await api.gemAdvisorAgreementUpload(act_id, agreements);
};

export const updateBankLinkEnabledAccountArray = (
    providerListArr,
    accountArr,
    firstName,
    lastName
) => {
    let newArr = accountArr.map((v) => ({
        ...v,
        formattedAccountLabel: formatBankLinkEnabledAccountLabel(
            v,
            firstName,
            lastName,
            providerListArr
        ),
    }));
    return newArr;
};

export const providerMapLookup = (providerArr, providerId) => {
    let providerObj = providerArr.filter((prov) => prov.id === providerId)[0];
    return providerObj.provider_source;
};

export const providerNameMapLookup = (providerArr, providerId) => {
    let provider_name = "";
    let providerObj = providerArr.filter((prov) => prov.id === providerId)[0];
    if ("provider_name" in providerObj) {
        provider_name = providerObj.provider_name;
    }
    if (provider_name === PRIME_TRUST_PROVIDER_TABLE_NAME) {
        provider_name = PRIME_TRUST_TITLE;
    }
    return provider_name;
};

export const modelNameLookup = (allModels, modelId) => {
    let modelName = "--";
    let modelObj = allModels.filter((model) => model.id === modelId)[0];
    if (modelObj?.name) {
        modelName = modelObj.name;
    }

    return modelName;
};

export const mapAccountAccessStatus = (accessStatus) => {
    if (accessStatus.is_account_closed) {
        return CLOSED;
    }

    return accessStatus.is_account_held_away ? HELD_AWAY : TRADABLE;
};

const formatBankLinkEnabledAccountLabel = (
    custAccountObj,
    firstName,
    lastName,
    providerListArr
) => {
    let providerName = providerMapLookup(
        providerListArr,
        custAccountObj.provider_id
    );
    return `${firstName} ${lastName}'s ${capitalizeFirstLetter(
        providerName
    )} Account`;
};

const capitalizeFirstLetter = (providerSource) => {
    var separateWord = providerSource.toLowerCase().split(" ");
    for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] =
            separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
    }
    return separateWord.join(" ");
};

export const createPortfolioKeyMetricsObj = (marketValue, cash) => {
    return {
        marketValue: fmtActBal(marketValue).toString(),
        cashBalance: fmtActBal(cash).toString(),
    };
};

export const formAccountNavItem = (account, providerList) => {
    let name = createAccountNavItemName(account, providerList);
    return {
        title: name,
        path: `/main/accounts/${account.id}`,
        name: "account-holder-accounts",
        accountId: account.id,
    };
};

export const handlePaperWorkNotification = async () => {
    const isFirstTimeLogin = JSON.parse(
        localStorage.getItem(FIRST_TIME_LOGIN_STORAGE_KEY)
    );
    const primeTrustNotification = JSON.parse(
        sessionStorage.getItem(PRIME_TRUST_PAPERWORK_NOTIFICATION_TYPE)
    );
    const geminiNotification = JSON.parse(
        sessionStorage.getItem(GEMINI_PAPERWORK_NOTIFICATION_TYPE)
    );

    if (isFirstTimeLogin) {
        const updateNotifications = [];

        if (primeTrustNotification) {
            updateNotifications.push(
                getUpdateNotificationRequest(primeTrustNotification)
            );
        }

        if (geminiNotification) {
            updateNotifications.push(
                getUpdateNotificationRequest(geminiNotification)
            );
        }

        if (updateNotifications.length > 0) {
            await Promise.all(updateNotifications);

            localStorage.removeItem(FIRST_TIME_LOGIN_STORAGE_KEY);
            sessionStorage.removeItem(PRIME_TRUST_PAPERWORK_NOTIFICATION_TYPE);
            sessionStorage.removeItem(GEMINI_PAPERWORK_NOTIFICATION_TYPE);
        }
    }
};

const getUpdateNotificationRequest = (notification) => {
    return api.updateNotification({
        notification_id: notification.id,
        status: notification.status,
        accept: 0,
    });
};

const createAccountNavItemName = (account, providerList) => {
    let name = providerNameMapLookup(providerList, account.provider_id);
    name = capitalizeFirstLetter(name);
    return `${name} - *${account.id}`;
};

export const exportItems = [
    { text: "CSV", value: "CSV", ext: ".csv" }
    , { text: "Excel", value: "Excel", ext: ".xls" }
];

export const mapAccountType = (accountType) => {
    switch (accountType) {
        case PRIME_TRUST_ACCOUNT_TYPE_DISPLAY_NAMES.INDIVIDUAL_ACCOUNT:
            return GENERAL_ACCOUNT_TYPE_IDS.INDIVIDUAL_ACCOUNT;
        case PRIME_TRUST_ACCOUNT_TYPE_DISPLAY_NAMES.ROTH_IRA_ACCOUNT:
            return GENERAL_ACCOUNT_TYPE_IDS.ROTH_IRA_ACCOUNT;
        case PRIME_TRUST_ACCOUNT_TYPE_DISPLAY_NAMES.IRA_ACCOUNT:
            return GENERAL_ACCOUNT_TYPE_IDS.IRA_ACCOUNT;
        default:
            return null;
    }
}

export const supportedAccountTypes = Object.freeze({
    1 : PRIME_TRUST_ACCOUNT_TYPE_NAMES.INDIVIDUAL_ACCOUNT,
    7 : PRIME_TRUST_ACCOUNT_TYPE_NAMES.ROTH_IRA_ACCOUNT,
    8 : PRIME_TRUST_ACCOUNT_TYPE_NAMES.IRA_ACCOUNT
})


export const groupSelectedAccountsByModel = (selectedAccounts) => {
    const groups = selectedAccounts.reduce((groups, item) => {
        if (item.model && item.model != "--") { // Ignore accounts without model
            const group = (groups[item.model]?.accounts || []);
            group.push(item);
            groups[item.model] = {"accounts" : group};
        }

        return groups;
    }, {});
    
    return groups;
};

export const currencyFormatter = (params) => {
    if (params.value === "N/A") {
        return params.value
    }
    if (parseFloat(params.value)) {
        return '$' + parseFloat(params.value).toFixed(2);
    }
    else {
        return SMA_TRADE_PREVIEW_CURRENCY_PLACEHOLDER
    }
};
export const SUPPORT_EMAIL_ADDRESS = "support@onrampinvest.com"

export function trunctateFloat(value, numDecimalPlaces) 
{
  return Math.trunc(value * Math.pow(10, numDecimalPlaces)) / Math.pow(10, numDecimalPlaces);
}

export const formatCurrencyValue = (currencyVal = 0) => {
    return fmtActBal(currencyVal.toString(), 2);
}

//generic comparator for sorting numbers, strings, and currency values
//(replace with specific comparators eventually?)
export const baseComparator = (valueA, valueB) => {
    if (!valueA) return -1;
    if (!valueB) return 1;
    if (typeof valueA === "number") {
        return (valueA > valueB ? 1 : (valueA < valueB ? -1 : 0));
    }
    if (valueA.includes("$")) {
        valueA = valueA.replace('$','').replace(',','');
        valueB = valueB.replace('$','').replace(',','');
        return parseFloat(valueA) - parseFloat(valueB);
    }
    else {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }
    
}

export const defaultRowComparator = (valueA, valueB) => {
    if (valueA == valueB) return 0;
    return (valueA > valueB) ? 1 : -1;
}

export const priceComparator = (valueA, valueB) => {
    //Transfrom the currency like value into a number
    valueA = valueA.replace('$','').replace(',','');
    valueB = valueB.replace('$','').replace(',','');
    return parseFloat(valueA) - parseFloat(valueB) ;
}


/*
Providers List
*/

export const PROVIDER_LIST =[
    { "text": "coinbase"      , "id": 1  }
  , { "text": "gemini"        , "id": 2  }
  , { "text": "primetrust"    , "id": 33 }
  , { "text": "coinbaseprime" , "id": 44 }
  , { "text": "securitize"    , "id": 55 }
  , { "text": "anchorage"     , "id": 66 }
  ]

export function doesUserHaveAnyCustodiansPermissions(permissions) {
    return permissions?.geminiOnboarded || permissions?.ptOnboarded || permissions?.cbOnboarded || permissions?.anchorageOnboarded
}

export function singleTradesDisabledByOrderMaker(permissions) {
    if( permissions?.orderMakerEnabled?.trading_approval_required && 
        permissions.restrictSingleTradesWhenOrderMakerIsOn) 
    {
        return true;
    }
    return false;
}

export const GEMINI_PROVIDER_TITLE = "gemini";
export const PRIMETRUST_PROVIDER_TITLE = "prime-trust";
export const COINBASEPRIME_PROVIDER_TITLE = "coinbase-prime";
export const ANCHORAGE_PROVIDER_TITLE = "anchorage";

export const PROVIDER_TITLE_FOR_ENDPOINT = Object.freeze({
    [PROVIDER_ID.PRIMETRUST]     : PRIMETRUST_PROVIDER_TITLE,
    [PROVIDER_ID.GEMINI]         : GEMINI_PROVIDER_TITLE,
    [PROVIDER_ID.COINBASE_PRIME] : COINBASEPRIME_PROVIDER_TITLE,
    [PROVIDER_ID.ANCHORAGE]      : ANCHORAGE_PROVIDER_TITLE
});

export function getProviderCodeName(providerId) {
    const providerTitle = PROVIDER_TITLE_FOR_ENDPOINT[providerId];
    return providerTitle ? providerTitle : "";

}