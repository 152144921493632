<template>
  <div>    
    <v-tooltip
        class="base-button-tooltip"
        color="black"
        :bottom="tooltipPositionBottom"
        :left="tooltipPositionLeft"
        :right="tooltipPositionRight"
        :top="tooltipPositionTop"
        :max-width="tooltipMaxWidth"
        :close-delay="3000"
        :disabled="!activateTooltip">
            <template v-slot:activator="{ props }">
                <div v-bind="props">
                    <v-btn
                        id="base-button"
                        class="base-button text-none"
                        rounded
                        :cssProps="cssProps"
                        :outlined="outlined"
                        :disabled="inactive"
                        :style="cssProps"                        
                        :width="width"
                        :height="height"
                        :ripple="false"
                        elevation="0"
                        @click="onButtonClick"
                    >
                        <slot name="content">
                            <v-icon v-if="icon" left dark> {{icon}} </v-icon>
                                {{ text }}
                        </slot>
                    </v-btn>
                </div>
            </template>
            <slot></slot>
    </v-tooltip>
  </div>
</template>

<script>
/**
 * @author Team Awesome
 * @private
 * Use the Secondary or Primary buttons only for feature development.
 */
export default {
  props: {
    activateTooltip: {
        type: Boolean,
        default: false
    },
    tooltipMaxWidth: {
        type: String,
        default: "100%"
    },
    /**
     * The position that the tooltip displays in alignment to the button.
     * Note: This is set when the button is initialized as no use case exists for dynamic positioning.
     * @param {string} tooltipPosition - can be "bottom", "top", "left", "right"
     * @default "bottom"
     */
    tooltipPosition: {
        type: String,
        default: "bottom"
    },
    text: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "117",
    },
    minWidth: {
        type: String,
        default: "0"
    },
    height: {
      type: String,
      default: "56",
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "var(--color__secondary)",
    },
    textColor: {
      type: String,
      default: "#FFF",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    cssProps() {
      return {
        "--text-color": this.textColor,
        "--background-color": this.backgroundColor,
        "--min-width": this.minWidth
      };
    },
  },
  data() {
      // WR to investigate if we can consolidate all this state for the tooltip
      return {
          tooltipPositionLeft: false,
          tooltipPositionRight: false,
          tooltipPositionTop: false,
          tooltipPositionBottom: true
      }
  },
  methods: {
    onButtonClick() {      
      this.$emit("btnClick");
    },
    setTooltipPosition() {
        switch (this.tooltipPosition) {
            case "top":
                this.tooltipPositionTop = true;
                this.tooltipPositionBottom = false;
                break;
            case "right":
                this.tooltipPositionRight = true;
                this.tooltipPositionBottom = false;
                break;
            case "left":
                this.tooltipPositionLeft = true;
                this.tooltipPositionBottom = false;
                break;
            case "bottom":
                break;
            default:
                console.error("Expected a tooltip position of 'top', 'bottom', 'left' or 'right', instead received ", this.tooltipPosition);
        }
    }
  },
  created() {
      this.setTooltipPosition();
  }
};
</script>
<style scoped lang="scss">
@import "@/styles/objects/_paragraphs.scss";
.base-button-tooltip {
    border-radius: 14px;
}

.base-button.v-btn {
  @include o-paragraph;
  color: var(--text-color);
  min-width: var(--min-width);
  border-radius: 50px;
  letter-spacing: 0;
}
</style>