import lookup from "./lookup/index";
import main from "./main/index";
import organization from "./organization/index";
import price from "./price/index";
import trade from "./trade/index";
import orders from "./orders/index";
import models from "./models/index";
import funding from "./funding/index";
import transactions from "./transactions/index";
import userPreferences from "./userPreferences/index";
import beneficiary from "./beneficiary/index";
import deposit from "./deposit/index";
import { createStore as _createStore } from 'vuex';

const store = _createStore({
	modules: {
        lookup,
        main,
        organization,
        price,
        orders,
        models,
        trade,
        funding,
        transactions,
        userPreferences,
        beneficiary,
        deposit,
	},
});

export default store;