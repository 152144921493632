export const ACTION_GET_USER_PREFERENCES = 'ACTION_GET_USER_PREFERENCES';
export const ACTION_SET_USER_PREFERENCES = "ACTION_SET_USER_PREFERENCES";
export const ACTION_UPDATE_USER_PREFERENCES = "ACTION_UPDATE_USER_PREFERENCES";
export const ACTION_PERSIST_USER_PREFERENCES = 'ACTION_PERSIST_USER_PREFERENCES';
export const MUTATION_USER_PREFERENCES = 'MUTATION_USER_PREFERENCES';
export const GETTER_USER_PREFERENCES = 'GETTER_USER_PREFERENCES';

export default {
    namespaced: true,
    ACTION_GET_USER_PREFERENCES,
    ACTION_SET_USER_PREFERENCES,
    ACTION_UPDATE_USER_PREFERENCES,
    ACTION_PERSIST_USER_PREFERENCES,
    MUTATION_USER_PREFERENCES,
    GETTER_USER_PREFERENCES
}