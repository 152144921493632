import { Map } from "core-js";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    allModels: [],
    settings: [],
    allocationData: [],
    confirmation: {},
    rebalanceError: false,
    settingsError: false,
    openViewModal: false,
    openEditModel: false,
    openDuplicateModal: false,
    openDeleteModel: false,
    dataViewModal: null,
    modelSummary: [],
    modeltypes: [],
    modelGraphDatapoints: null,
    customModel         : {},
    multipleCustomModels: [],
    deleteModelModal: [],    
    allRelationship               : [],
    accountDeposits               : [],
    linkedAssetManagementFirmsInfo: null,
    availableCoinsForModels       : new Map(),
    batchEditAccountSettingsData  : [],
    batchSetAccountSettingsData   : [],
    batchRebalanceAccountsData    : {},
    batchUnsubscribeAccountsData  : [],
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
