import actions  from './actions';
import getters from './getters';
import mutations  from './mutations';


const state = {
    userPreferences: {}
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}