const DEFAULT_CLEARING_PARTY_ID = -1;


import {mapActions, mapGetters} from "vuex";
import * as types from "@/store/types";
import { CustodianFactory } from './views/main/clients/trading/services/Custodians/TRA-custodian-factory';
import { SMA_TRADE_BUY_TAB, SMA_TRADE_SELL_TAB, TRADE_UNIT_CRYPTO_QUANTITY, TRADE_UNIT_USD_AMOUNT } from "./views/Consolidated Views/sma/dashboard/sma-constants";


export const CoinsByProvider = {
    // This is a mixin that loads all tradeable symbols for given providers
    // NOTE: not a singleton, so the provider_ids Set is not shared between instances
    data() {
        return {
            provider_ids: new Set()
        }
    },
    computed: {
        ...mapGetters({
            allTradeableSymbols: "trade/" + types.TRADE_GETTER_TRADEABLE_SYMBOLS
        })
    },
    methods: {
        ...mapActions({
            loadTradeableSymbols: "trade/" + types.TRADING_LOAD_TRADEABLE_SYMBOLS
        }),

        addProvider(provider_id) {
            this.provider_ids.add(provider_id)
        },
        addProviders(ids) {
            ids.forEach(provider_id => this.addProvider(provider_id))
        },
    
        coinIsTradeableOnProvider(symbol, provider_id) {
            let tradeable_symbols = getTradeableSymbolsFromMapCache(provider_id, null, this.allTradeableSymbols)
            tradeable_symbols = tradeable_symbols || {data: []}
            return tradeable_symbols.data.find(coin => coin.symbol === symbol) !== undefined
        },
        async loadSymbols() {
            let results = []
            this.provider_ids.forEach(provider_id => {
                results.push(this.loadSymbolsByProvider(provider_id))
            })
        
            await Promise.all(results)
        },
        async loadSymbolsByProvider(provider_id) {
            let custodian = CustodianFactory.create(provider_id)
            const tradeableSymbolsParams = {
              "func"              : custodian.constructor.getTradableAssets,
              "provider_id"       : provider_id,
              "clearing_party_id" : null
            }
            return await this.loadTradeableSymbols(tradeableSymbolsParams)
        }
            
    }
}

export function accountCanAfford(account, purchaseSummary) {
    //buying crypto with USD
    if(purchaseSummary.tradeDirection === SMA_TRADE_BUY_TAB.id) {
        return accountHasAmount(account, "USD", purchaseSummary.assetPurchaseAmount);
    }
    //selling crypto by coin quantity
    else if(purchaseSummary.tradeDirection === SMA_TRADE_SELL_TAB.id 
        && purchaseSummary.tradeUnit === TRADE_UNIT_CRYPTO_QUANTITY.code) {
        return accountHasQuantity(account, purchaseSummary.selectedAsset, purchaseSummary.assetPurchaseQuantity);
    }
    //selling crypto by USD amount
    else if(purchaseSummary.tradeDirection === SMA_TRADE_SELL_TAB.id 
        && purchaseSummary.tradeUnit === TRADE_UNIT_USD_AMOUNT.code) {
        return accountHasAmount(account, purchaseSummary.selectedAsset, purchaseSummary.assetPurchaseAmount);
    }
    return false
}

function accountHasQuantity(account, symbol, quantity) {
    let asset = account.assets.find(asset => asset.symbol === symbol)
    return asset && asset.quantity >= quantity;
}
function accountHasAmount(account, symbol, amount) {
    let asset = account.assets.find(asset => asset.symbol === symbol)
    return asset && asset.mv >= amount;
}

export function getClearingPartyIdForCache(clearingPartyId) {
    return clearingPartyId === null || clearingPartyId === undefined ? DEFAULT_CLEARING_PARTY_ID
                                                                     : clearingPartyId;
}

export function getTradeableSymbolsFromMapCache(providerId, clearingPartyId, symbolsMap) {    
    const providerSymbols = symbolsMap.get(providerId);    

    return providerSymbols?.get(getClearingPartyIdForCache(clearingPartyId))
}