import beneficiary from ".";
import {
    MUTATION_RELATIONSHIP_TYPE,
    MUTATION_ACCOUNT_BENEFICIARY,
    MUTATION_SIGNED_SPOUSAL_CONSENT_FORM,
} from "./types";

export default {
    [MUTATION_RELATIONSHIP_TYPE]: async (state, relationtype) => {
        state.allRelationship = relationtype;
    },
    [MUTATION_ACCOUNT_BENEFICIARY]: async (state, beneficiaries) => {
        state.allBeneficiaries = beneficiaries;
    },
    [MUTATION_SIGNED_SPOUSAL_CONSENT_FORM]: async (state, signedSpousalConsentFormFile) => {
        state.signedSpousalConsentFormFile = signedSpousalConsentFormFile;
    }
};
