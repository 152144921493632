import { apiUrl } from '@/env';
import axios from 'axios';
import { getTokenHeaders } from './api/api-utils'
import { cloneDeep } from "lodash";
import { PROVIDER_ID, PROVIDER_TITLE_FOR_ENDPOINTS, getProviderCodeName } from './utils';
import { API_ACCOUNT_IDS_PATH } from "./api/asset-manager-api-constants.js";

const USER_ID = 'current_user_id';

const requestOptions = {
    withCredentials: true
}

function noAuthHeaders() {
  return {
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    withCredentials: false
  };  
}

export const api = {
    async getSSOCookie() {
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/sandbox/sso/cookie`, requestOptions);
    },
    async loginHistory(username, success) {
        const data = {
            "success": success,
            "email": username
        }
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/login/user/${userId}/history`, data, config);                
    },
    async ssoLogin(username, password) {
        const params = new URLSearchParams()
        params.append('username', username)
        params.append('password', password)
        return axios.post(`${apiUrl}/api/v1/sandbox/sso/login`, params, requestOptions)
    },
    async register(data) {
        return axios.post(`${apiUrl}/api/v1/users/register`, data, requestOptions);
    },
    async getVerificationCode(data) {
        return axios.post(`${apiUrl}/api/v1/users/get-verification-code`, data, requestOptions);
    },
    async getMe() { 
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/users/user-profile`, requestOptions);
    },
    async updateMe(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.put(`${apiUrl}/api/v1/users/user/${userId}/user-profile`, data, requestOptions);
    },
    
    async createStripeCheckoutSession(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/users/advisor/${userId}/stripe-create-checkout-session`, data, requestOptions);
    },
    async synchronizeStripeSubscriptionStatus() {
      // Apparently it is not possible to do a POST without a body.  Other HTTP methods are not suitable, as this
      // API call can mutate the DB
      const userId = sessionStorage.getItem(USER_ID);
      requestOptions['headers'] = getTokenHeaders();
      return axios.post(`${apiUrl}/api/v1/users/advisor/${userId}/stripe-sync-sub-status`, {'' : ''}, requestOptions);
    },
    async startSubscriptionTrial(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/subscription/advisor/${userId}/start-trial`, data, requestOptions);
    },    
    async sendFundingEmails(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/funding/client/${userId}/account/${data.account_id}/deliver-wire-info`, data, requestOptions)
    },
    async notifyClientLinkBankAccount(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/funding/advisor/${userId}/notify-client-link-funding`, data, requestOptions);
    },
    async notifyClientFundAccount(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/funding/advisor/${userId}/notify-client-funding`, data, requestOptions)
    },
    async passwordRecovery(data) {
        if(data.passcode) {
            requestOptions['headers'] = getTokenHeaders();
            return axios.post(`${apiUrl}/api/v1/password-recovery`, data, requestOptions);
        } else {
            return axios.post(`${apiUrl}/api/v1/password-recovery`, data);
        }
    },
    async resetPassword(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.put(`${apiUrl}/api/v1/user/${userId}/reset-pw`, data, requestOptions);
    },
    async passwordRecoveryValidate(data) {
        return axios.post(`${apiUrl}/api/v1/password-recovery/validate`, data, requestOptions);
    },
    async getPrice() {
        // non-dynamic asset price <--> BTC ONLY
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/trade/price/btcusd`, requestOptions)
    },
    async getPlaidConnectedBankAccount(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/plaid/client/${userId}/account/${accountId}/get-connected-account`, requestOptions);
    },
    async refreshClientLinkedAccountBalance(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();     
        return axios.get(`${apiUrl}/api/v1/plaid/user/${userId}/account/${accountId}/refresh-account-balance`, requestOptions);      
    },

    async getPlaidAchCheck(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/plaid/user/${userId}/account/${accountId}/check-ach`, requestOptions);
    },
    async sendWireTransferInfo(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/plaid/advisor/${userId}/account/${accountId}/send-wire-info`, requestOptions);
    },
    async clientInformation(id){
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/manage/advisor/${userId}/client/${id}/client-info`, requestOptions)
    },
    
    async gemCreateSubAct(data) {
        // @request
        // gRPC gemini_stub.CreateManagedAccount
        //account_holder_id: int,
        //name: str, email: str,
        //authorized_signature: str,
        //phone_number: str,
        //'dob': str,
        // city: str,
        //state: str,
        //country: str,
        //street: str,
        //ssn: int
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/kyc/gemini/advisor/${userId}/create-managed-account`, data, requestOptions)
    },
    async gemCliDocs(act_id, data) {
        let formData = new FormData();
        const fileArr = data.files;
        Array.from(fileArr).forEach(file => {
            formData.append("files", file)
        })
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/kyc/gemini/advisor/${userId}/account/${act_id}/document-upload`, formData, requestOptions)
    },
    async gemAdvisorAgreementUpload(act_id, data) {
        let params = new URLSearchParams();
        let formData = new FormData();
        formData.append("files", data.files)

        const config = requestOptions
        config.params = params
        const userId = sessionStorage.getItem(USER_ID);
        config['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/kyc/gemini/advisor/${userId}/account/${act_id}/advisor-agreement-upload`, formData, config)
    },
    async getFirms() {
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/organizations`)
    },
    async addTeam(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/users/advisor/${userId}/add-team`, data, requestOptions);
    },
    async checkEmail(data) {
        return axios.post(`${apiUrl}/api/v1/users/check-email`, data)
    },
    async connect(provider, data) {
        const lowerCaseProviderName = provider.toLowerCase()
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/connect/client/${userId}/${lowerCaseProviderName}`, data, requestOptions);
    },
    async disconnect(providerId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/connect/client/${userId}/disconnect-from-provider`, { provider_id: providerId }, requestOptions);
    },
    async checkForPendingPlaidLink(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/plaid/user/${userId}/account/${accountId}/verification-pending`, requestOptions);
    },
    async plaidLink(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/plaid/client/${userId}/account/${accountId}/link-token`, requestOptions);
    },
    async plaidToken(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/plaid/client/${userId}/register-item`, data, requestOptions)
    },
    async resendConfEmail() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/users/user/${userId}/resend`, requestOptions)
    },
    async getClients() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/manage/advisor/${userId}/clients`, requestOptions)
    },
    async getStats(filter) {        
        const options = await this.getOptionsForQueryParams();
        options.params.append('filter_repcode', String((filter?.id || []).join(',')));
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/manage/advisor/${userId}/stats`, options);
    },
    async getClientStats() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/manage/client/${userId}/client-stats`, requestOptions)
    },
    async getAdvisorChart(filter) {
        const options = await this.getOptionsForQueryParams();

        if (filter?.id) {
            options.params.append('filter_repcode', String((filter.id).join(',')));
        }
        
        if (filter?.time) {
            options.params.append('filter_val', filter.time);
        }
        
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/manage/advisor/${userId}/chart-data`, options)
    },
    async getClientChart(filter, client_id) {
        const options = await this.getOptionsForQueryParams();
        options.params.append('filter_val', filter);
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/manage/user/${userId}/client/${client_id}/client-chart-data`, options);
    },
    async getAccountsBotGraphData(filter, account_ids) {
        const options = await this.getOptionsForQueryParams();
        const userId = sessionStorage.getItem(USER_ID);
        return axios.post(`${apiUrl}/api/v1/manage/advisor/${userId}/${API_ACCOUNT_IDS_PATH}/account-chart-data`, { account_ids: account_ids, filter_val: filter }, options);
    },
    async getAccountsHoldingData(account_ids) {
        const options = await this.getOptionsForQueryParams();
        const userId = sessionStorage.getItem(USER_ID);
        return axios.post(`${apiUrl}/api/v1/accounts/advisor/${userId}/${API_ACCOUNT_IDS_PATH}/holdings`, { account_ids: account_ids }, options);
    },    
    async getAccountChart(filter, account_id) {
        const options = await this.getOptionsForQueryParams();
        options.params.append('filter_val', filter);
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/manage/user/${userId}/account/${account_id}/account-chart-data`, options);
    },
    async clientActDetail(provider_id) {
        const options = await this.getOptionsForQueryParams();
        options.params.append('provider_id', provider_id);
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/connect/client/${userId}/account-details`, options);
    },
    async getAccountPermission(account_id) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/manage/advisor/${userId}/account/${account_id}/check-account-permissions`, requestOptions);
    },
    async actDetail(act_id) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/manage/user/${userId}/account/${act_id}`, requestOptions)
    },
    async clientDetail(client_id) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/manage/advisor/${userId}/client/${client_id}`, requestOptions)
    },
    async getPrefilledClientDetail(prefillId, accountHolderId) {
        const userId = sessionStorage.getItem(USER_ID);
        const options = await this.getOptionsForQueryParams()
        if(accountHolderId) {
            options.params.append('account_holder_id', accountHolderId);
        }
        return axios.get(`${apiUrl}/api/v1/clients/advisor/${userId}/prefilled/${prefillId}`, options)
    },
    async updateBalances(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        const provider = getProviderCodeName(data.provider_id);
        return axios.put(`${apiUrl}/api/v1/trade/${provider}/advisor/${userId}/account/${data.account_id}/balances`, data, requestOptions);
    },
    async updateBalancesSuppressErrors(data) {
        try {
            return await this.updateBalances(data);
        }
        catch (error) {
            // Intentionally suppressing errors
        }
    },
    async ptQoute(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/prime-trust/advisor/${userId}/account/${data.account_id}/quotes`, data, requestOptions);
    },
    async ptExecute(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/prime-trust/advisor/${userId}/account/${data.account_id}/execute`, data, requestOptions);
    },
    async gemOrderBook(data) {
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/gemini/order-book`, data, requestOptions);
    },

    async createGeminiNewAddress(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/gemini/client/${userId}/account/${data.account_id}/create-new-address`, data, requestOptions);
    },

    async createPrimeTrustNewAddress(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/prime-trust/client/${userId}/account/${data.account_id}/create-new-address`, data, requestOptions);
    },

    async createCoinbasePrimeTrustNewAddress(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/coinbase-prime/client/${userId}/account/${data.account_id}/create-new-address`, data, requestOptions);
    },
    async createAnchorageWalletAddress(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/anchorage/client/${userId}/account/${data.account_id}/create-new-address`, data, requestOptions);
    },    
    async gemPrices(currencySymbol) {      
      const userId = sessionStorage.getItem(USER_ID);
      requestOptions['headers'] = getTokenHeaders();
      const config = cloneDeep(requestOptions);
      const params = new URLSearchParams();
    
      params.append("currency_symbol", currencySymbol);    
      config.params = params;  

      return axios.get(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/price`, config);
    },
    async getGeminiFeePct(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${accountId}/fee-schedule`, requestOptions);
    },
    async placeGeminiOrder(      
      accountId,
      cryptoCurrencyType, // BTC or Eth, or?
      bufferedPrice,      // max_bid for buy, min_ask for sell
      amount,             // amount to buy or sell
      transactionType     // buy or sell
    ) {
        const data = { account_id: accountId, 
                        currency: cryptoCurrencyType, 
                        price: bufferedPrice, 
                        quantity: amount, 
                        action: transactionType};

        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${accountId}/order/market`, data, requestOptions);
    },  
    async placeGeminiLimitOrder(
        accountId,
        cryptoCurrencyType, // BTC or Eth, or?
        bufferedPrice, // max_bid for buy, min_ask for sell
        amount, // amount to buy or sell
        transactionType, // buy or sell
        expiry // unix timestamp int
    ) {
        const data = { account_id: accountId,
                        currency: cryptoCurrencyType,
                        price: bufferedPrice,
                        quantity: amount,
                        action: transactionType,
                        expiry: expiry};
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${accountId}/order/limit`, data, requestOptions);
    },
    async gemOrderStatus(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${data.account_id}/order-status`, data, requestOptions);
    },
    async updateGeminiOrderStatusSuppressErrors(data) {
        try {
            return await this.gemOrderStatus(data);
        }
        catch (error) {
            // Intentionally suppressing errors
        }
    },
    async gemCancelOrder(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${data.account_id}/cancel-order`, data, requestOptions);
    },
     async getUserNotifications() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/users/user/${userId}/notifications`, requestOptions);
    },
    async getKycNotifications() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/users/user/${userId}/notifications?kyc=true`, requestOptions);
    },
    async updateNotification(data) {        
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/users/user/${userId}/update-notification`, data, requestOptions)
    },
    async getDocuments(account_id) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/users/user/${userId}/account/${account_id}/get-documents`, requestOptions)
    },
    async updateAccountSignature(account_id, signature, agreement_accepted, timestamp) {
        const options = await this.getOptionsForQueryParams();
        options.params.append('signature', signature);
        options.params.append('agreement_accepted', agreement_accepted);
        options.params.append('timestamp', timestamp);
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/users/user/${userId}/account/${account_id}/update-account`, options)
    },
    async ptActOpenCli(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/advisor/${userId}/prime-trust/open-account`, data, requestOptions);
    },
    async gemActOpenCli(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/trade/advisor/${userId}/gemini/finalize`, data, requestOptions);
    },
    async getCoinMarketCapLatestListing(symbols = []) {
        const options = await this.getOptionsForQueryParams();
        options.params.append('symbols', symbols);
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/coinmarketcap/user/${userId}/coin-market-cap/latest-listing`, options)
    },
    async sendFailedKycInformationToOnboarding(account_id) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/kyc/gemini/advisor/${userId}/account/${account_id}/send-safely-dump`, null, requestOptions)
    },
    async getAdvisorFeatures(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/advisorfeature/advisor/${userId}/firm-fully-onboarded`, data, requestOptions);
    },    
    async createPrimeTrustManagedAccount(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/kyc/prime-trust/advisor/${userId}/create-managed-account`, data, requestOptions);
    },
    async savePrimeTrustManagedAccountDocument(accountID, document) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/kyc/prime-trust/advisor/${userId}/account/${accountID}/upload-document`, document, requestOptions);
    },    
    async acceptPrimeTrustKyc(kycConsent) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/kyc/prime-trust/client/${userId}/consent`, kycConsent, requestOptions);
    },
    async getClientInvitations(repcodes) {
        const options = await this.getOptionsForQueryParams();
        (repcodes || []).forEach((repcode) => {
            options.params.append("repcodes", repcode);
        });
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/users/user/${userId}/client-invitation-url`, options);
    },
    // [TODO]: Security for new endpoints
    async deliverPrimeTrustWireInstructions(wireInfo) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/funding/client/${userId}/account/${wireInfo.account_id}/deliver-prime-trust-wire-info`, wireInfo, requestOptions)
    },
    async deliverPrimeTrustPushAchInstructions(pushAchInfo, accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/funding/client/${userId}/account/${accountId}/primetrust/deliver-push-info`, pushAchInfo, requestOptions)
    },
    async initiatePrimeTrustAchDeposit(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/funding/client/${userId}/account/${data.account_id}/primetrust/pull/ach-deposit`, data, requestOptions);
    },
    //marking for removal once we have advisor and client ui fund projects complete. -BD
    async advisorInitiatePrimeTrustAchDeposit(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/funding/advisor/${userId}/account/${data.account_id}/primetrust/pull/ach-deposit`, data, requestOptions);
    },
    async getPrimeTrustDisbursableBalance(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/funding/client/${userId}/account/${accountId}/primetrust/cash-total`, requestOptions);
    },
    async getGeminiDisbursableBalance(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/funding/client/${userId}/account/${accountId}/gemini/withdrawable-balance`, requestOptions); 
    },
    async initiatePrimeTrustAchWithdrawal(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/funding/client/${userId}/account/${data.onramp_account_id}/primetrust/ach-withdrawal`, data, requestOptions);
    },
    async initiatePrimeTrustWireWithdrawal(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        let provider = ""
        provider = data.provider_id === PROVIDER_ID.PRIMETRUST ? PROVIDER_TITLE_FOR_ENDPOINTS.PRIMETRUST : PROVIDER_TITLE_FOR_ENDPOINTS.COINBASEPRIME
        return axios.post(`${apiUrl}/api/v1/funding/client/${userId}/account/${data.onramp_account_id}/${provider}/wire-withdrawal`, data, requestOptions);
    },
    async getAccountStatuses() {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/account-status/${userId}`, config);
    },
    async getAllClientsForAdvisor() {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/clients/advisor/${userId}`, config);
    },
    async getAllAccountsForAdvisor() {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/accounts/advisor/${userId}`, config);
    },
    async getAdvisorTotalAum() {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/manage/advisor/${userId}/aum-total`, config);
    },
    async getAllHeldawayForAdvisor() {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/accounts/advisor/${userId}/read_only`, config);
    },
    async getAllOrdersForAdvisor() {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/orders/advisor/${userId}`, config);
    },
    async getOpenOrdersForAdvisor() {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/orders/advisor/${userId}/open`, config);
    },    
    async getWalletAddresses(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/trade/gemini/client/${userId}/account/${accountId}/wallet-addresses`, config);
    },
    async getOptionsForQueryParams() {
        requestOptions['headers'] = getTokenHeaders();
        const options = cloneDeep(requestOptions);
        options.params = new URLSearchParams();
        return options;
    },
     async verifyDocuments(documentPayload, clientInfo) {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        const formData = new FormData();
        formData.append('document', documentPayload)
        formData.set("client_info_json_str", JSON.stringify(clientInfo))
        config['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/ocr/advisor/${userId}/detect_text`, formData, config);
    },
    async getFirmPerformanceDeltas(repcodes) {        
        const options = await this.getOptionsForQueryParams();
        options['headers'] = getTokenHeaders();
        const userId = sessionStorage.getItem(USER_ID);
        const repcodesQueryParams = repcodes.map((repcode) => (`rep_codes=${repcode}`)).reduce((acc, curr) => {
            if (acc === "") return curr;
            return `${acc}&${curr}`;
        }, "");

        return axios.get(`${apiUrl}/api/v1/firm-performance/advisor/${userId}?${repcodesQueryParams}`, options);
    },
    async createSession(idToken, accessToken) {
        console.debug("createSession()", "idToken:", idToken, "accessToken:", accessToken)
        return axios.post(`${apiUrl}/api/v1/session/create`, {id_token:idToken, access_token: accessToken});
    },
    async deleteSession(idToken, accessToken) {
        console.debug("deleteSession()", "idToken:", idToken, "accessToken:", accessToken)
        return axios.post(`${apiUrl}/api/v1/session/delete`, {id_token:idToken, access_token: accessToken});
    },
    async getGainLossReport(payload) {
        const userId        = sessionStorage.getItem(USER_ID);
        const config        = requestOptions;
        const accountId     = payload?.accountId;
        const year          = payload?.year;
        const calc_method   = payload?.calc_method;
        const sub_type      = payload?.sub_type;

        config['headers'] = getTokenHeaders();        

        return axios.get(`${apiUrl}/api/v1/reports/user/${userId}/gainloss/account/${accountId}/calc_method/${calc_method}/sub_type/${sub_type}/year/${year}`, requestOptions);
    },
    async getGainLossSummary(payload) {
        const userId        = sessionStorage.getItem(USER_ID);
        const config        = requestOptions;
        const accountId     = payload?.accountId;
        const calc_method   = payload?.calc_method;
        const sub_type      = payload?.sub_type;

        config['headers'] = getTokenHeaders();        

        return axios.get(`${apiUrl}/api/v1/reports/user/${userId}/gainloss/summary/account/${accountId}/calc_method/${calc_method}/sub_type/${sub_type}`, requestOptions);
    },
    async saveBeneficiaries(account_beneficiaries, accountId) {
        try {
                const userId = sessionStorage.getItem(USER_ID);
                requestOptions["headers"] = getTokenHeaders();
                const response = await axios.post(
                    `${apiUrl}/api/v1/kyc/prime-trust/advisor/${userId}/account/${accountId}/beneficiaries`,
                    account_beneficiaries,
                    requestOptions
                );
                return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    },
    async getBeneficiaries(account) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.get(
            `${apiUrl}/api/v1/kyc/prime-trust/advisor/${userId}/account/${account.accountId}/beneficiaries`,
            requestOptions
        )
    },
    async getAssetPriceHistory(asset, start, end) {
        const userId = sessionStorage.getItem(USER_ID);
        const url = `${apiUrl}/api/v1/manage/advisor/${userId}/asset/${asset}?start=${start}&end=${end}`
        requestOptions["headers"] = getTokenHeaders();
         return axios.get(
            url,
            requestOptions
        )
    },
    async getAssetQuote(quote) {
        const userId = sessionStorage.getItem(USER_ID);
        const url = `${apiUrl}/api/v1/manage/advisor/${userId}/asset/quote`
        requestOptions["headers"] = getTokenHeaders();
         return axios.post(
            url,
            quote,
            requestOptions
        )
    },
    async getRebalanceEvents() {
        const userId = sessionStorage.getItem(USER_ID);
        const url = `${apiUrl}/api/v1/model-trading/advisor/${userId}/rebalance_event`
        requestOptions["headers"] = getTokenHeaders();
        return (await axios.get(url, requestOptions)).data
    },
    async setEventViewed(eventId) {
        const userId = sessionStorage.getItem(USER_ID);
        const url = `${apiUrl}/api/v1/model-trading/advisor/${userId}/event/${eventId}`
        requestOptions["headers"] = getTokenHeaders();
        return (await axios.put(url, {}, requestOptions)).data
    },
    async linkSecuritizeAccount(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/accounts/user/${userId}/securitize/link_account`, data, requestOptions);
    },
    async getOrderConstructionData() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/order-maker/advisor/${userId}/order-construction-data`, requestOptions);
    },
    async createBatch(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch`, data, requestOptions);
    },
    async getTradeBatchOrders(stage) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch?current_stage=${stage}`, requestOptions);
    },
    async deleteBatches(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/delete`, data, requestOptions);
    },
    async stageBatches(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/stage`, data, requestOptions);
    },
    async getOrganizationComplianceContactInformation(){
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/organization-compliance-contact/${userId}/organization-compliance-contact`, requestOptions);
    },
    async upsertOrganizationComplianceContactInformation(payload){
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.put(`${apiUrl}/api/v1/organization-compliance-contact/${userId}/organization-compliance-contact`, payload, requestOptions);
    }
};
