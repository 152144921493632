import * as types from "../types";
import { currency } from "@/filters";

export default {
    [types.TRADING_MUT_GET_PT_QUOTE]: (state, payload) => {
        state.quote_id = payload["quote_id"];
        state.cost_per_unit = payload["price_per_unit"];
        state.unit_count = payload["unit_count"];
        state.trade_amount = payload["base_amount"];
        state.fee_amount = payload["fee_amount"];
    },
    [types.TRADING_MUT_GET_GEM_PRICES]: (state, payload) => {
        state.prices = payload["price"];
    },
    [types.TRADING_MUT_PT_TRADE]: (state, payload) => {
        state.trade_id = payload["trade_id"];
        state.cost_per_unit = payload["price_per_unit"];
        state.unit_count_executed = payload["unit_count"];
        state.trade_amount_executed = payload["base_amount"];
        state.fee_amount = payload["fee_amount"];
        state.confirmation = payload["status"];
    },
    [types.TRADING_MUT_NOTIONAL_BALANCES]: (state, payload) => {
        const account_id = payload.account_id;
        const timestamp = payload.timestamp;
        const clonedNotionalBalances = JSON.parse(
            JSON.stringify(state?.notional_balances || {})
        );

        if (clonedNotionalBalances[account_id] == null) {
            clonedNotionalBalances[account_id] = {};
        }

        const balances = payload.notional_balances;
        clonedNotionalBalances[account_id].positions = [];
        balances.forEach((balance) => {
            let positionsObj = {};
            positionsObj.available_balance =
                balance.available;
            positionsObj.asset_balance_notional =
                balance.availableNotional;
            positionsObj.currency =
                balance.currency.toLowerCase();
            if (balance.currency === "USD") {
                positionsObj.formatted =
                currency(balance.available);
            }

            clonedNotionalBalances[account_id].positions.push(positionsObj)
        });

        clonedNotionalBalances[account_id].timestamp = timestamp;

        state.notional_balances = clonedNotionalBalances;
    },
    [types.TRADING_MUT_ACTIVE_ACCOUNT_ID]: (state, account_id) => {
        state.active_account_id = account_id;
    },
    [types.TRADING_MUT_SET_CANCEL_MODAL_DATA]: (state, payload) => {
        state.cancelTradeOrder = payload
    },
    [types.TRADING_MUT_TRADEABLE_SYMBOLS]: (state, payload) => {
        const providerSymbols   = state.tradableSymbolsMap.get(payload.provider_id);
        
        if (providerSymbols) {
            providerSymbols.set(payload.clearing_party_id, payload.data)
        }
        else {
            const clearingPartyIdToTradeSymbolsMap = new Map();
            clearingPartyIdToTradeSymbolsMap.set(payload.clearing_party_id, payload.data)
            state.tradableSymbolsMap.set(payload.provider_id, clearingPartyIdToTradeSymbolsMap)
        }        
    },    
};

// export default mutations;
