import { GET_ACTION_ALL_TRANSACTIONS, GET_ACTION_TRANSACTIONS_BY_CLIENT, GET_ACTION_TRANSACTIONS_BY_ACCOUNT, MUTATION_SET_TRANSACTIONS } from './types';
import { transactionsApi } from "@/api/transactions-api";
import { api } from "@/api.js";
import * as STATIC_CONTENTS from "@/static_contents"

export default {
    [GET_ACTION_ALL_TRANSACTIONS]: async ({ commit }, params) => {
        commit(MUTATION_SET_TRANSACTIONS, []);        
        return transactionsApi.getAllTransactions(params.caller, params.dateRange)
            .then(
                (transactions) => commit(MUTATION_SET_TRANSACTIONS, { transactions, providerLookups: STATIC_CONTENTS.SUPPORTED_PROVIDERS }),
                (_e) => null
            );
    },
    [GET_ACTION_TRANSACTIONS_BY_CLIENT]: async ({ commit }, params) => {
        commit(MUTATION_SET_TRANSACTIONS, []);        
        return transactionsApi.getTransactionsByClientID(params.caller, params.clientId)
            .then(
                (transactions) => commit(MUTATION_SET_TRANSACTIONS, { transactions, providerLookups: STATIC_CONTENTS.SUPPORTED_PROVIDERS }),
                (_e) => null
            );
    },
    [GET_ACTION_TRANSACTIONS_BY_ACCOUNT]: async ({ commit }, params) => {
        commit(MUTATION_SET_TRANSACTIONS, []);        
        return transactionsApi.getTransactionsByAccountID(params.caller, params.accountId)
            .then(
                (transactions) => commit(MUTATION_SET_TRANSACTIONS, { transactions, providerLookups: STATIC_CONTENTS.SUPPORTED_PROVIDERS }),
                (_e) => null
            );
    }
};