import { api } from "../../api.js";
import {ACADEMY_URL, KEYCLOAK_LOGOUT_URL, WHITE_LABELED_ORG_IDS} from "@/env";
import { logoutKeyCloak } from '@/main';
import {
    USER_ROLE_ACCOUNT_HOLDER,
    PROVIDER_CONNECTION_STATUS,
    handlePaperWorkNotification,
    isAssetManager,
} from "../../utils.js";
import { styleTheDOMbyOrgID } from "@/whitelabel.js"

import * as types from "../types";
import { getAuthRef } from '@/main';
import { WHITE_LABELED_ORGAZATION_IDS } from "@/env";
import { resetWhitelabelEffect } from "@/whitelabel.js"
import { getTokenHeaders } from "@/api/api-utils";
function mapProviderTokensToConnections(providerTokens) {
    return providerTokens?.map(
        (token) => {
            const connectionStatus = token.is_invalid ? PROVIDER_CONNECTION_STATUS.INVALID : PROVIDER_CONNECTION_STATUS.CONNECTED;
            return {
                providerId: token.provider_id,
                status: connectionStatus
            };
        }
    );
}

export default {
    [types.MAIN_ACT_GET_USER_PROFILE]: async (context) => {
        try {            
            const response = await api.getMe();            
            if (response.data) {
                if (response.data.user_type === USER_ROLE_ACCOUNT_HOLDER) {
                    const authRef = getAuthRef();
                    if (authRef.authenticated) {
                        const idToken = authRef.idTokenParsed;
                        const preferredOrgID = idToken.oroid;
                        localStorage.setItem("onrampinvest.poid", preferredOrgID);
                        if (preferredOrgID && WHITE_LABELED_ORG_IDS.includes(Number(preferredOrgID))) {
                            styleTheDOMbyOrgID(preferredOrgID)
                        }
                    }
                    //Account holder in PW Reset state. No action.
                }
                sessionStorage.setItem("current_user_id", response.data.id);
                sessionStorage.setItem('current_user_type', response.data.user_type);
                sessionStorage.setItem('subscription_status_code', response.data.subscription_status_code);
                sessionStorage.setItem('accounts', JSON.stringify(response.data.account));
                // set the profile;
                sessionStorage.setItem('password_status_code', response.data.password_status_code);
                console.debug(types.MAIN_ACT_GET_USER_PROFILE, "sessionStorage:", JSON.parse(JSON.stringify(sessionStorage)))
                context.commit(
                    types.MAIN_MUT_SET_USER_PROFILE,
                    response.data
                );
                context.commit(
                    types.MAIN_MUT_SET_USER_PROFILE,
                    response.data
                );
                try {
                    window.mixpanel.identify(`${response.data.id}_${response.data.user_type}`);
                    const connections = mapProviderTokensToConnections(response.data.provider_token);
                    context.commit(types.MAIN_MUT_CON_STATUS, {
                        connections: connections,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
            return true;
        } catch (error) {
            await context.dispatch(types.MAIN_ACT_CHECK_API_ERROR, error);
        }
        return false;

    },
    [types.MAIN_ACT_UPDATE_USER_PROFILE]: async (context, payload) => {
        try {
            const loadingNotification = {
                content: "saving",
                showProgress: true,
            };
            context.commit(
                types.MAIN_MUT_ADD_NOTIFICATION,
                loadingNotification
            );
            const response = (
                await Promise.all([
                    api.updateMe(payload),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            // set the profile
            context.commit(types.MAIN_MUT_SET_USER_PROFILE, response.data);

            // // check for connections
            if (response.data.provider_token) {
                const connections = mapProviderTokensToConnections(response.data.provider_token);
                context.commit(types.MAIN_MUT_CON_STATUS, {
                    connections: connections,
                });
            }
            context.commit(
                types.MAIN_MUT_REMOVE_NOTIFICATION,
                loadingNotification
            );
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Profile successfully updated",
                color: "success",
            });
            return true;
        } catch (error) {
            await context.dispatch(types.MAIN_ACT_CHECK_API_ERROR, error);
            return false;
        }
    },
    [types.MAIN_ACT_REGISTER_CON]: async (context, payload) => {
        try {
            // state.connections is an object with keys representing provider_id's and values representing obj's of provider info.
            const providerName =
                context.state.connections[payload.provider].provider;
            context.commit(types.MAIN_MUT_HELD_AWAY_CONNECTION_STATUS, false);
            const response = (
                await Promise.all([
                    api.connect(
                        providerName,
                        payload.data
                    ),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.MAIN_MUT_CON_STATUS, {
                connections: [{ providerId: payload.provider, status: PROVIDER_CONNECTION_STATUS.CONNECTED }]
            });
            context.commit(types.MAIN_MUT_CON_RESP, response.data);
            context.commit(types.MAIN_MUT_HELD_AWAY_CONNECTION_STATUS, true);
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Connection established.",
                color: "success",
            });
        } catch (error) {
            await context.dispatch(types.MAIN_ACT_CHECK_API_ERROR, error);
        }

    },
    [types.MAIN_ACT_DISCONNECT_PROVIDER]: async (context, payload) => {
        try {
            await api.disconnect(
                payload.provider);
            context.commit(types.MAIN_MUT_CON_STATUS, {
                connections: [{ providerId: payload.provider, status: PROVIDER_CONNECTION_STATUS.NOT_CONNECTED }]
            });
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Disconnected from provider",
                color: "success",
            });
        } catch (error) {
            await context.dispatch(types.MAIN_ACT_CHECK_API_ERROR, error);
            return Promise.reject(error);
        }
    },
    [types.MAIN_ACT_CHECK_LOGGED_IN]: async (context) => {
        if (!context.state.isLoggedIn) {
            try {
                await context.dispatch(types.MAIN_ACT_GET_USER_PROFILE);
                context.commit(types.MAIN_MUT_SET_LOGGED_IN, true);
            } catch (error) {
                await context.dispatch(types.MAIN_ACT_REMOVE_LOGIN);
            }
        }
    },
    [types.MAIN_ACT_REMOVE_LOGIN]: async (context) => {
        context.commit(types.MAIN_MUT_SET_LOGGED_IN, false);
    },
    //SoftSignOut Flag:
    //A logout which ends up on a page not presenting a login widget. This
    //is meant to pair up for a logout call used after a user has been idle
    //for an hour. We want these types of users on a special page to both
    //alert them that we signed them out for inactivity, and to present them
    //with a button to take them to the log in widget. We would just normally send them
    //all the way there in the first place, but, okta has a limitation. If a user is then idle
    //for >45 minutes at login widget, they'll get an error after entering credentials.
    //This flag works around that./
    [types.MAIN_ACT_LOGOUT]: async ( context, softSignoutFlag) => {
        const tokens = getTokenHeaders();
        console.debug(types.MAIN_ACT_LOGOUT, "tokens:", tokens)
        await handlePaperWorkNotification()
        await context.commit(types.MAIN_MUT_SET_USER_PROFILE, null);
        await context.dispatch(types.MAIN_ACT_REMOVE_LOGIN);
        context.commit(types.MAIN_MUT_SET_LOGIN_EXPIRED_TRIAL_ERROR, false);
        context.commit(types.MAIN_MUT_SET_LOGIN_ERROR, false);
        try {
            window.mixpanel.reset();
            if (tokens && tokens.accessToken && tokens.idToken) {
                await api.deleteSession(tokens.idToken, tokens.accessToken)
                console.debug(types.MAIN_ACT_LOGOUT, "sessionStorage:", JSON.parse(JSON.stringify(sessionStorage)))
            }
        } catch (ex) {
            // ignore delete session error
            // it is possible user clicked logout after backend session expired(15min idle)
            // in that case delete session call will fail remote verification and throw
            // we should allow user continue frontend sign out
            console.debug(types.MAIN_ACT_LOGOUT, "(ignored exception)", JSON.parse(JSON.stringify(ex)))
        }
        console.debug(types.MAIN_ACT_LOGOUT, "softSignoutFlag:", softSignoutFlag)
        const authRef = getAuthRef();

        resetWhitelabelEffect()
        let logoutRedirect = KEYCLOAK_LOGOUT_URL
        if (softSignoutFlag) {
            logoutRedirect = KEYCLOAK_LOGOUT_URL + "/inactive";
        }
        logoutKeyCloak(logoutRedirect);
    },
    [types.MAIN_ACT_USER_LOGOUT]: async (context) => {
        await context.dispatch(types.MAIN_ACT_LOGOUT);
        context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
            content: "Logged out",
            color: "success",
        });
    },
    [types.MAIN_ACT_CHECK_API_ERROR]: async (context, payload) => {
        if (!payload.response || payload.response.status === 401) {
            await context.dispatch(types.MAIN_ACT_LOGOUT);
        }
    },
    // [ex arg 2: payload = { notification: {}, timeout: 0 }]
    [types.MAIN_ACT_REMOVE_NOTIFICATION]: async (context, payload) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                context.commit(
                    types.MAIN_MUT_REMOVE_NOTIFICATION,
                    payload.notiication
                );
                resolve(true);
            }, payload.timeout);
        });
    },
    [types.MAIN_ACT_PASSWORD_RECOVERY]: async (context, payload) => {
        const response = (
            await Promise.all([
                api.passwordRecovery(payload.data),
                await new Promise((res) => setTimeout(res, 500)),
            ])
        )[0];
        if (response.data.success)
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Password updated",
                color: "success",
            });
        else
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Error resetting password",
                color: "error",
            });
        return response.data.success
    },
    [types.MAIN_ACT_RESET_PASSWORD]: async (context, payload) => {
        try {
            const response = await api.resetPassword(payload);
            if (response.data.success) {
                context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Password successfully updated",
                color: "success",});
            }
            return true;
        } catch (error) {
            await context.dispatch(types.MAIN_ACT_CHECK_API_ERROR, error);
            return false;
        }
    },
    [types.MAIN_ACT_PASSWORD_RECOVERY_VALIDATE]: async (context, payload) => {
        const loadingNotification = {
            content: "Sending password recovery email",
            showProgress: true,
        };

        let success = false;

        try {
            context.commit(
                types.MAIN_MUT_ADD_NOTIFICATION,
                loadingNotification
            );
            const response = (
                await Promise.all([
                    api.passwordRecoveryValidate(payload.data),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            if (response.data) {
                sessionStorage.setItem('current_user_id', response.data.id);
                sessionStorage.setItem('current_user_type', response.data.user_type);
                sessionStorage.setItem('subscription_status_code', response.data.subscription_status_code);
                sessionStorage.setItem('password_status_code', response.data.password_status_code)

                success = response.data.success;
            } else if (response.data.error) {
                context.commit(
                    types.MAIN_MUT_REMOVE_NOTIFICATION,
                    loadingNotification
                );
                context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                    content: response.data.error,
                    color: "error",
                });
            } else {
                context.commit(
                    types.MAIN_MUT_REMOVE_NOTIFICATION,
                    loadingNotification
                );
                context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                    content: "Password recovery email sent",
                    color: "success",
                });
            }

            return success;
        } catch (error) {
            context.commit(
                types.MAIN_MUT_REMOVE_NOTIFICATION,
                loadingNotification
            );
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                color: "error",
                content: "Incorrect username",
            });
            return success;
        }
    },
    [types.MAIN_ACT_RESEND_CONF_EMAIL]: async (context, payload) => {
        const notification = { content: "Email sent!", color: "success" };
        try {
            const response = (
                await Promise.all([
                    api.resendConfEmail(),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, notification);
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Resend failed.",
                color: "error",
            });
        }
    },


    [types.MAIN_ACT_CHECK_EMAIL]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.checkEmail(payload),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            if (response.data.result !== true) {
                context.commit(types.MAIN_MUT_CHECK_EMAIL, true);
            } else {
                // context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                //     color: "error",
                //     content: "unique email",
                // });
                context.commit(types.MAIN_MUT_CHECK_EMAIL, false)
            }
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                color: "error",
                content: "Request failed",
            });
        }
    },

    [types.MAIN_ACT_REGISTER_USER]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.register(payload.data),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            if (response.data) {
                sessionStorage.setItem('current_user_id', response.data.id);
                sessionStorage.setItem('current_user_type', response.data.user_type);
                sessionStorage.setItem('subscription_status_code', response.data.subscription_status_code);
                context.commit(types.MAIN_MUT_SET_LOGGED_IN, true);
                context.commit(types.MAIN_MUT_SET_LOGIN_ERROR, false);
            } else {
                context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                    color: "error",
                    content: response.data.error,
                });
            }
            return response;
        } catch (error) {
            console.log(error);
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                color: "error",
                content: error,
            });

            return error?.response;
        }
    },
    [types.MAIN_ACT_GET_VERIFICATION]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.getVerificationCode(payload),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];

            return response;
        } catch (error) {
            console.log(error);
            return error?.response;
        }
    },
    // [ex]: payload = {'provider_id': 1 or 2 }
    [types.MAIN_CLIENT_PROV_ACCOUNT_DETAIL]: async (context, payload) => {
        const notification = {
            content: "Fetched act details",
            color: "success",
        };

        try {
            const response = (
                await Promise.all([
                    api.clientActDetail(payload.provider_id),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, notification);
            return response;
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.MAIN_ACT_GET_NOTIFICATIONS]: async (context) => {
        try {
            const response = (
                await Promise.all([
                    api.getUserNotifications(),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.MAIN_MUT_GET_NOTIFICATIONS, response.data);
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_GET_NOTIFICATIONS, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.MAIN_ACT_SET_NOTIFICATION_STATUS]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.updateNotification(payload),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.MAIN_MUT_NOTIFICACTION_STATUS, response.data);
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_NOTIFICACTION_STATUS, {
                content: "Failed to set notification status.",
                color: error,
            });
        }
    },
    [types.MAIN_ACT_GET_DOCUMENTS]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.getDocuments(payload.account_id),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.MAIN_MUT_DOCUMENTS, response.data);
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_GET_NOTIFICATIONS, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.MAIN_ACT_UPDATE_ACT_SIGNATURE]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.updateAccountSignature(payload.account_id, payload.signature, payload.agreement_accepted, payload.timestamp),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.MAIN_MUT_UPDATE_ACT_SIGNATURE, response.data);
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_GET_NOTIFICATIONS, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.MAIN_ACT_PT_ACT_OPEN_CLI]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.ptActOpenCli(payload),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.MAIN_MUT_ACT_OPEN_CLI, response.data);
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_ACT_OPEN_CLI, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.MAIN_ACT_GEM_ACT_OPEN_CLI]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.gemActOpenCli(payload),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.MAIN_MUT_ACT_OPEN_CLI, response.data);
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_ACT_OPEN_CLI, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.MAIN_ACT_GET_COIN_MARKET_CAP_LATEST_LISTINGS]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.getCoinMarketCapLatestListing(payload.symbols),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
        )[0];
            context.commit(types.MAIN_MUT_COIN_MARKET_CAP_LATEST_LISTINGS, response.data);
        } catch (error) {
            context.commit(types.MAIN_ACT_GET_COIN_MARKET_CAP_LATEST_LISTINGS, {
                content: "Failed to get latest listings",
                color: "error",
            });
        }
    },
    [types.MAIN_ACT_ACCEPT_PRIMETRUST_KYC]: async (_context, kycConsent) => {
        try {
            const response = await api.acceptPrimeTrustKyc(kycConsent);
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    },
    [types.MAIN_ACT_GET_INVITATIONS_FOR_REPCODE]: async (_context, repcodes) => {
        try {
            const response = await api.getClientInvitations(repcodes);
            return response?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    [types.MAIN_SET_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS]: async (context, refresh) => 
    {
        context.commit(types.MAIN_MUT_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS, refresh)
    },
    [types.MAIN_ACT_SET_SELECTED_NOTIFICATION]: (context, notification) => {
        context.commit(types.MAIN_MUT_SET_SELECTED_NOTIFICATION, notification);
    },
};

