<template>
  <div :style="cssProps" :class="classObject" class="c-header c-alignment">{{ displayText }}</div>
</template>

<script>
/**
 * @author Team Awesome
 */
export default {
  props: {
    value: {
        type: String, default: ""
    },
    size: { type: String, default: 'header-2' },
    width: { type: String, default: "100%" },
    height: { type: String, default: "100%" },
    color: { type: String, default: "var(--color__secondary)" },
    alignment: { type: String, default: "center" },
    wrapping: {type: String, default: "normal" }
  },
  computed: {
      displayText: function() {
          return this.value;
      },
      classObject: function() {
          return {
              'c-header-1': this.size === 'header-1',
              'c-header-2': this.size === 'header-2',
              'c-header-3': this.size === 'header-3',
              'c-header-4': this.size === 'header-4',
              'c-header-4-benton': this.size === 'header-4-benton',
              'c-header-5-benton': this.size === 'header-5-benton'

          }
      },
      cssProps: function() {
          return {
              '--width' : this.width,
              '--height': this.height,
              '--color': this.color,
              '--alignment': this.alignment,
              'white-space': this.wrapping
          }
      }
  },
};
</script>
<style scoped lang="scss">
  @import "../../../styles/objects/_headers.scss";
  .c-alignment {
      text-align: var(--alignment);
  }
  .c-header {
      height: var(--height);
      width: var(--width);
      color: var(--color);
      font-family: "Nocturne-Serif-Bold", Helvetica, Arial;
  }
  .c-header-1 {
      @include o-header--1;
  }
  .c-header-2 {
      @include o-header--2;
  }
  .c-header-3 {
      @include o-header--3;
  }
  .c-header-4 {
      @include o-header--4;
  }
  .c-header-4-benton {
      @include o-header--4-benton;
  }
  .c-header-5-benton {
    @include o-header--5-benton;
  }
</style>