import { getAuthRef } from '@/main';
import { USER_ID } from "@/api/shared-api-constants";

export function getTokenHeaders() {
    const authRef       = getAuthRef();
    const accessToken   = authRef.token;
    const idToken       = authRef.idToken;
    if (idToken && accessToken) {
        return {
            "accessToken"   : accessToken,
            "idToken"       : idToken
        }
    }
}

export function getCurrentUserId() {
    return sessionStorage.getItem(USER_ID);
}