import * as types from "../types";
import * as STATIC_CONTENTS from "@/static_contents"

export default {
    [types.LOOKUP_GET_PROVIDERS]: (state) => {        
        return STATIC_CONTENTS.SUPPORTED_PROVIDERS;
    },
    [types.LOOKUP_GETTER_GENDERS]: (state) => STATIC_CONTENTS.SUPPORTED_GENDER_TYPES,    
    [types.LOOKUP_GETTER_PROVIDER_DOCUMENT_TYPES]: (state) => STATIC_CONTENTS.SUPPORTED_PROVIDER_DOC_TYPES,    
};
