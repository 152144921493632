import {
    ORDER_TYPE_LIMIT,
    ORDER_TYPE_MARKET,
    DIRECTION_SELL,
    DIRECTION_BUY,
    TIF_DAY,
} from "../../constants/TRA-trading-state";
import {
    SHOW_TRADE_ERROR,
    SHOW_TRADE_CONFIRMATION
} from "../../constants/TRA-modals";
import {api} from "../../TRA-api";
import {CustodianService} from "./TRA-custodian-service";
import {PRICE_REFRESH_INTERVAL_GEM} from "@/env";


export class CoinbasePrimeService extends CustodianService {
    static getApiPathName() {
        return "coinbase-prime"
    }

    static async getAccountHealth(accountId) {
        try {
            const response = await api.getAccountHealth(this.getApiPathName(), accountId);
            return response.data;
        } catch (error) {
            return this.handleGetAccountHealthError(error);
        }
    }

    static async getPrice(priceData, accountClearingPartyId) {
        try {
            let response = await api.coinbasePrimePrices(priceData.account_id, priceData.currency)
            return this.mapPriceResponse(response, priceData.currency)
        } catch (error) {
            return this.handleGetPriceError(error);
        }
    }

    static async getBalances(accountId) {
        try {
            let response = await api.coinbasePrimeBalances({'account_id': accountId});
            return this.formatBalanceData(accountId, response.data);
        } catch (error) {
            return this.handleGetBalancesError(error);
        }
    }

    static mapPriceResponse(response, currency) {
        var price = JSON.parse(response.data.price)
        return {
            'symbol':    currency,
            'buy':       parseFloat(price.buy),
            'sell':      parseFloat(price.sell),
            'fee':       parseFloat(price.fee),
            'fee_ratio': parseFloat(price.fee_ratio),
        }
    }

    static async getTradableAssets() {
        try {
            return await api.getCoinbasePrimeTradableAssets();
        } catch (error) {
            return this.errorHandler(error);
        }
    }

    static setOrderTypeOptions(accountClearingPartyId) {
        return [ORDER_TYPE_MARKET, ORDER_TYPE_LIMIT];
    }

    static findPriceToUseForTrade(prices, activeDirection, activeOrderType, accountClearingPartyId) {
        switch (activeOrderType) {
            case ORDER_TYPE_LIMIT:
                return this.setLimitPriceBasedOnActiveOrderDirection(prices, activeDirection);
            case ORDER_TYPE_MARKET:
                return this.getMarketOrderExecutionPrice(prices, activeDirection, accountClearingPartyId);
            default:
                return this.getMarketOrderExecutionPrice(prices, activeDirection, accountClearingPartyId);
        }
    }

    static setLimitPriceBasedOnActiveOrderDirection(prices, activeDir = DIRECTION_BUY) {
        switch (activeDir) {
            // In theory, this should just be limit price.
            case DIRECTION_BUY:
                return prices.buy;
            case DIRECTION_SELL:
                return prices.sell;
            default:
                return prices.buy;
        }
    }

    static formTradeDetails(
        selectedCurrency,
        tradeInputs,
        activeDirection,
        activeOrderType,
        activeTimeInForce,
        latestTradeData,
        accountId,
        accountClearingPartyId
    ) {

        let costDetails = this.tradeFeeAndTotalCalculation(activeOrderType, {...tradeInputs, ...latestTradeData}, accountClearingPartyId);
        let totalBeforeFee = costDetails.total - costDetails.fee;

        let price =
            activeOrderType === ORDER_TYPE_LIMIT ?
                tradeInputs.limitOrderPrice :
                this.getMarketOrderExecutionPrice(latestTradeData, activeDirection, accountClearingPartyId);

        return {
            'accountId':   accountId,
            'unitCount':   tradeInputs.baseCurrency === "USD" ? totalBeforeFee / price.toFixed(selectedCurrency.decPrecision) : (tradeInputs.toAmount / price).toFixed(selectedCurrency.decPrecision),
            'unitCost':    price,
            'tradeAmount': totalBeforeFee,
            'feeAmount':   costDetails.fee,
            'totalAmount': costDetails.total,
            'timeInForce': activeTimeInForce,
            'currency':    selectedCurrency,
            'direction':   activeDirection,
            'type':        activeOrderType,
        }
    }

    static tradeFeeAndTotalCalculation(orderType, tradeInputs, accountClearingPartyId) {
        if (tradeInputs.baseCurrency === "USD" || orderType === ORDER_TYPE_MARKET) {
            return {
                'fee': tradeInputs.fromAmount * tradeInputs.fee_ratio,
                'total': tradeInputs.fromAmount || 0
            }
        } else {
            let feeBasis = tradeInputs.fromAmount * tradeInputs.limitOrderPrice;
            return {
                'fee': feeBasis * tradeInputs.fee_ratio,
                'total': feeBasis || 0
            }
        }
    }

    static getRefreshInterval() {
        return PRICE_REFRESH_INTERVAL_GEM;
    }

    static getMarketOrderExecutionPrice(tradeData, activeDirection, accountClearingPartyId) {
        return this.getCoinbasePrimeMarketOrderExecutionPrice(tradeData, activeDirection);
    }

    static getCoinbasePrimeMarketOrderExecutionPrice(tradeData, activeDirection) {
        if (tradeData?.buy && tradeData?.sell) {
            return activeDirection === DIRECTION_BUY ?
                tradeData.buy :
                tradeData.sell;
        }
        return 0;
    }

    static submitOrder(tradeDetails, accountClearingPartyId) {
        switch (tradeDetails.type) {
            case ORDER_TYPE_MARKET:
                return this.submitMarketOrder(tradeDetails);
            case ORDER_TYPE_LIMIT:
                return this.submitLimitOrder(tradeDetails);
        }
    }

    static async submitMarketOrder(tradeDetails) {
        return this.submitCoinbasePrimeMarketOrder(tradeDetails)
    }

    static async submitCoinbasePrimeMarketOrder(tradeDetails) {
        try {
            const response = await api.placeCoinbasePrimeMarketOrder(
                tradeDetails.accountId,
                tradeDetails.currency.abbr,
                tradeDetails.direction,
                tradeDetails.totalAmount,
            );
            const orderSubmissionDetails = this.handleTradeResponse(response);

            return {...orderSubmissionDetails, ...tradeDetails};
        } catch (error) {
            return this.handleSubmitOrderError(error);
        }
    }

    static async submitLimitOrder(tradeDetails) {
        return this.submitCoinbasePrimeLimitOrder(tradeDetails)

    }

    static async submitCoinbasePrimeLimitOrder(tradeDetails) {
        try {
            const expiry = this.calculateExpiry(tradeDetails.timeInForce);
            const response = await api.placeCoinbasePrimeLimitOrder(
                tradeDetails.accountId,
                tradeDetails.currency.abbr,
                tradeDetails.direction,
                tradeDetails.totalAmount,
                tradeDetails.unitCost,
                expiry
            )
            const orderSubmissionDetails = this.handleTradeResponse(response);

            return {...orderSubmissionDetails, ...tradeDetails};
        } catch (error) {
            return this.handleSubmitOrderError(error);
        }
    }

    static handleTradeResponse(res) {
        if (res?.data?.order_id !== null) {
            return (SHOW_TRADE_CONFIRMATION, this.getConfirmationDetails(res.data, SHOW_TRADE_CONFIRMATION));
        }

        return this.getConfirmationDetails(res.data, SHOW_TRADE_ERROR);
    }

    static getConfirmationDetails(data, status) {
        return {
            'unitCountExecuted': data.executed_quantity || null,
            'transaction': data.custodian_order_id || null,
            'modalType': status
        }
    }

    static calculateExpiry(timeInForce) {
        let now = new Date();
        return timeInForce === TIF_DAY ?
            // last second of current day
            Math.floor(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) / 1000) - 1 :
            // [NOTE]: Standard convention for GTC is to expiry automatically after at most 90 days
            null;
    }
}
