const ACCOUNT_STATUS_KYC_STARTED                  = 0x00000001
const ACCOUNT_STATUS_KYC_SIGNED                   = 0x00000002
const ACCOUNT_STATUS_KYC_DOC_CHECK                = 0x00000004
const ACCOUNT_STATUS_KYC_FAILED                   = 0x00000008
const ACCOUNT_STATUS_TRADE_ENABLED                = 0x00000010
const ACCOUNT_STATUS_TRADE_PENDING                = 0x00000020
const ACCOUNT_STATUS_BILLING_ACTIVE               = 0x00000040
const ACCOUNT_STATUS_BANK_ADDED                   = 0x00000080
const ACCOUNT_STATUS_FUNDED                       = 0x00000100
const ACCOUNT_STATUS_HELD_AWAY                    = 0x00000200
const ACCOUNT_STATUS_AGREEMENT_PENDING_SIGNATURE  = 0x00000400
const ACCOUNT_STATUS_ACCREDITED                   = 0x00000800
const ACCOUNT_STATUS_CLOSED                       = 0x00001000

export const HELD_AWAY                                     = "Held-Away";
export const TRADABLE                                      = "Tradable";
export const CLOSED                                        = "Closed";
export const PENDING_TRADABLE                              = "Pending-Tradable";

export function getSmaDashboardAccountAccessType(accountFlags)
{
    let accessType = ""    

    if ((accountFlags & ACCOUNT_STATUS_HELD_AWAY) === ACCOUNT_STATUS_HELD_AWAY)
    {
        accessType = HELD_AWAY;
    }
    else if ((accountFlags & ACCOUNT_STATUS_TRADE_ENABLED) === ACCOUNT_STATUS_TRADE_ENABLED)
    {
        accessType = TRADABLE;
    }
    else if ((accountFlags & ACCOUNT_STATUS_TRADE_PENDING) === ACCOUNT_STATUS_TRADE_PENDING)
    {
        accessType = PENDING_TRADABLE;
    }

    return accessType;
}

export function isAccountTypeHeldaway(accountFlags)
{
    return (accountFlags & ACCOUNT_STATUS_HELD_AWAY) === ACCOUNT_STATUS_HELD_AWAY;
}
