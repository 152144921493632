import { currency } from "@/filters";
import _ from "lodash";
import { DIRECTION_BUY } from "../../constants/TRA-trading-state";

export class CustodianService {
    static getApiPathName() {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static async getAccountHealth(accountId) {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static async getPrice(priceData, accountClearingPartyId) {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static async getBalances(accountId) {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static async getTradableAssets(clearingPartyId) {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static setOrderTypeOptions(accountClearingPartyId) {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static formTradeDetails() {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static tradeFeeAndTotalCalculation() {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static findPriceToUseForTrade(activeDirection = DIRECTION_BUY) {
    /* implementations should be able to handle arbitrary args if:
        - The Custodian's implementation does not need to identify
          the active direction of the order to identify the price
          to use
    */
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static submitOrder() {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static handleOrderResponse() {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static getConfirmationDetails() {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }    
    static getRefreshInterval() {
        throw new Error("Cannot be implemented by CustodianService abstract class")
    }
    static calculateToAmountCurrency(fromAmount, price = 0) {
        return price ? fromAmount / price : 0;
    }
    static calculateFromAmountCurrency(toAmount, price = 0) {
        return price ? toAmount * price : 0;
    }
    static formatBalanceData(accountId, payload, history={}) {
        const clonedNotionalBalances = _.cloneDeep(history.notional_balances || {})

        if (clonedNotionalBalances[accountId] == null) {
            clonedNotionalBalances[accountId] = {};
        }
        clonedNotionalBalances[accountId].positions = [];
        payload.forEach((balance) => {
            let positionsObj = {};
            positionsObj.available_balance      = balance.available;
            positionsObj.asset_balance_notional = balance.availableNotional;
            positionsObj.quantity               = balance.amount;
            positionsObj.quantity_notional      = balance.amountNotional;
            positionsObj.currency               = balance.currency.toLowerCase();

            if (balance.currency === "USD") {
                positionsObj.formatted =
                currency(balance.available);
            }
            clonedNotionalBalances[accountId].positions.push(positionsObj)
        });
        clonedNotionalBalances[accountId].timestamp = new Date().valueOf();

        return clonedNotionalBalances;       
    }    
    static errorHandler(error) {
        // do something based on specific error codes
        return error.response.status;
        //return null;
    }
    static handleGetAccountHealthError(error) {        
        return {
            "status_code"   : error.response.status,
            "show"          : true,
            "message"       : "Failed to validate the account's health status. Please refresh and try again or contact support@onrampinvest.com if you continue to have issues."
        }
    }
    static handleGetBalancesError(error) {        
        return {
            "status_code"   : error.response.status,
            "show"          : true,
            "message"       : "The custodian was unable to validate available trade balances. Please refresh and try again or contact support@onrampinvest.com if you continue to have issues."
        }
    }
    static handleGetPriceError(error) {        
        return {
            "status_code"   : error.response.status,
            "show"          : true,
            "message"       : "We encountered an error getting a quote price. Please refresh and try again or contact support@onrampinvest.com if you continue to have issues."
        }
    }
    static handleSubmitOrderError(error) {        
        return {
            "status_code"   : error.response.status,
            "show"          : true,
            "message"       : "We were unable to confirm your trade. Please refresh and try again or contact support@onrampinvest.com if you continue to have issues."
        }
    }
}
