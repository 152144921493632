<template>
    <HeaderBase :alignment="alignment" :color="color" :width="width" :height="height" :value="value" :wrapping="wrapping" size="header-4"/>
</template>

<script>
/**
 * @author Team Awesome
 * import HeaderFour from "@/components/Typographical/Header/Header-four"
 */
import HeaderBase from "@/components/Typographical/Header/index.vue";
export default {
    components: {
        HeaderBase
    },
    props: {
        value: {
            type: String, default: "header-4"
        },
                color: { type: String, default: "var(--color__secondary)" },
        width: { type: String, default: "100%" },
        height: { type: String, default: "100%" },
        alignment: { type: String, default: "center" },
        wrapping: {type: String, default: "normal" }
    },
    computed: {
        displayText: function() {
            return this.value;
        }
    }
};
</script>