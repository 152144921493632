import { PrimeTrustService } from "./TRA-prime-trust-service";
import { GeminiService } from "./TRA-gemini-service";
import { CoinbasePrimeService } from "./TRA-coinbase-prime-service";
import { AnchorageService } from "./TRA-anchorage-service";
import { PROVIDER_ID } from "@/utils";

export class CustodianFactory {
    static create(providerId) {
        switch (providerId) {
            case PROVIDER_ID.GEMINI:
                return new GeminiService();
            case PROVIDER_ID.PRIMETRUST:
                return new PrimeTrustService();
            case PROVIDER_ID.COINBASE_PRIME:
                return new CoinbasePrimeService();
            case PROVIDER_ID.ANCHORAGE:
                return new AnchorageService();
            default:
                return null
        }
    }
}

