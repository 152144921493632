import actions  from './actions';
import getters from './getters';
import mutations  from './mutations';
import { DEFAULT_PRICE_SOURCE } from "@/views/Account Management/order-maker/price-source-constants.js";


const state = {
    allTradeOrders:[],
    openTradeOrders: [],
    allTradeOrdersForClient: [],
    allTradeOrdersForAccount: [],
    openTradeOrdersForClient: [],
    openTradeOrdersForAccount: [],
    batchTradeOrders: {},
    reviewableBatches: {},
    pendingTradeBatches: {},
    executionAcceptedBatches: {},
    batchTradePrices: new Map(),
    stagedReviewableBatches: {},
    approvedBatches: {},
    executedBatches: {},
    executableBatches: {},
    executedTradeBatches: {},
    executedTradeBatchStatus: [],
    orderMakerPriceSource: DEFAULT_PRICE_SOURCE,
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
