import { api } from "../../api";
import * as types from "../types";
import { NOTIONAL_BALANCES_REFRESH_INTERVAL } from '@/env';
import {
    getClearingPartyIdForCache
,   getTradeableSymbolsFromMapCache
} from "@/trade_utils.js";

export default {
   
    [types.TRADING_GET_BALANCES]: async (context, payload) => {
        try {
            const PROVIDER_ID_GEMINI      = 2;

            const currentTime = new Date().valueOf();
            const requestPayload = payload.request_payload;
            const provider = payload.provider;
            const notionalBalances = context?.state?.notional_balances || {};
            const notionalBalancesForAccountID = notionalBalances[requestPayload.account_id];
            context.commit(types.TRADING_MUT_ACTIVE_ACCOUNT_ID, requestPayload.account_id);

            if (
                payload.force_refresh ||
                !notionalBalancesForAccountID ||
                currentTime - notionalBalancesForAccountID.timestamp > NOTIONAL_BALANCES_REFRESH_INTERVAL
            ) {
                let response = {};
                if (provider == PROVIDER_ID_GEMINI){
                    response = (
                        await Promise.all([
                            api.gemBalances(
                                requestPayload
                            ),
                            await new Promise((resolve, reject) =>
                                setTimeout(() => resolve(), 500)
                            ),
                        ])
                    )[0];

                }
                else{
                    response = (
                        await Promise.all([
                            api.ptBalances(
                                requestPayload
                            ),
                            await new Promise((resolve, reject) =>
                                setTimeout(() => resolve(), 500)
                            ),
                        ])
                    )[0];
                }

                const mutation = {
                    account_id: requestPayload.account_id,
                    timestamp: currentTime,
                    notional_balances: response.data
                };
                context.commit(types.TRADING_MUT_NOTIONAL_BALANCES, mutation);
            }
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.TRADING_GET_PT_QUOTE]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.ptQoute(
                        payload
                    ),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.TRADING_MUT_GET_PT_QUOTE, response.data);
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.TRADING_ACT_GET_GEM_PRICES]: async (context, currencySymbol) => {        
        try {
            const response = (
                await Promise.all([
                    api.gemPrices(currencySymbol),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            context.commit(types.TRADING_MUT_GET_GEM_PRICES, response.data);
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.TRADING_GET_PT_EXECUTE]: async (context, payload) => {        
        try {
            const response = (
                await Promise.all([
                    api.ptExecute(
                        payload
                    ),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
                )[0];            
                context.commit(types.TRADING_MUT_PT_TRADE, response.data);
                //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.TRADING_ACT_CANCEL_TRADE_MODAL]: async (context, payload) => {
            context.commit(types.TRADING_MUT_SET_CANCEL_MODAL_DATA, payload) 
    },
    [types.TRADING_LOAD_TRADEABLE_SYMBOLS]: async (context, params) => {        
        try 
        {
            const providerId       = params.provider_id;                                 
            const tradeableSymbols = getTradeableSymbolsFromMapCache(providerId, params.clearing_party_id, context.state.tradableSymbolsMap);
            
            if (!tradeableSymbols)             
            {                                
                const getTradeableAssetsFunc = params.func;   
                const response               = await getTradeableAssetsFunc(params.clearing_party_id);      
                const mutationPayload = {
                    "provider_id"       : providerId
                ,   "clearing_party_id" : getClearingPartyIdForCache(params.clearing_party_id)
                ,   "data"              : response?.data
                }          
                context.commit(types.TRADING_MUT_TRADEABLE_SYMBOLS, mutationPayload);
            }            
        } 
        catch (error) 
        {
            // Do nothing
        }
    },
}

        
    