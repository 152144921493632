import { mapPrice } from "../common/mapPrice";
import { mapClientName } from "../common/mapClientName";
import { mapUnixDateToLocaleDate } from "../common/mapUnixDateToLocaledate";
import { defaultTo, lowerCase } from "lodash";
import { getProviderName, modelNameLookup } from "@/utils.js";

export const mapOrdersDtoToModel = (orderDto, providers, allModels) => {
    const requestedPrice = mapPrice(orderDto.price);
    const requestedQuantity = mapAmount(orderDto.quantity, orderDto.currency);
    const executedPrice = mapExecutedPriceByStatus(orderDto);
    const executedQuantity = mapAmount(
        orderDto.executed_quantity,
        orderDto.currency
    );
    // [TODO] check reference to account_holder_id
    return {
        timeReported: mapUnixDateToLocaleDate(orderDto.time_reported),
        clientName: mapClientName(orderDto.first_name, orderDto.last_name),
        accountID: orderDto.account_id,
        // WR - These are required for the legacy cellRenderers. We should standardize these to camelCase properties here and elsewhere.
        account_id: orderDto.account_id,
        account_holder_id: orderDto.account_holder_id,
        repCodes: orderDto.rep_code,
        firmName: orderDto.firm_name,
        details: mapOrderDetail(
            orderDto.action,
            executedQuantity,
            requestedQuantity,
            orderDto.currency,
            executedPrice,
            requestedPrice,
            orderDto.status
        ),
        type: `${orderDto.order_type} ${orderDto.action || ""}`,
        symbol: orderDto.currency,
        quantity: requestedQuantity,
        provider_id: orderDto.custodian,
        custodian: getProviderName(orderDto.custodian, providers),
        unitPrice: mapPriceByType(requestedPrice, orderDto.order_type),
        status: orderDto.status,
        netAmount: mapPrice(orderDto.net_amount),
        timeInForce: orderDto.time_in_force,
        orderID: orderDto.order_id,
        model_id: modelNameLookup(allModels, orderDto.model_id),
        execPrice: executedPrice,
        numberOfOccurrences: orderDto.number_of_occurrences,
    };
};

const mapPriceByType = (requestedPrice, type) => {
    return type === "Market" ? "- -" : requestedPrice;
};

const mapOrderType = ({ order_type, action }) => {
    const type = defaultTo(order_type, "");
    const _action = defaultTo(action, "");

    return type === "" ? _action : `${type} ${lowerCase(_action)}`;
};

const mapAmount = (amount, symbol) => {
    switch (symbol) {
        case "USD":
            return amount.toFixed(2);
        case "ETH":
            return amount.toFixed(6);
        case "BTC":
            return amount.toFixed(8);
        default:
            return amount;
    }
};

const mapOrderDetail = (
    action,
    executedQuantity,
    requestedQuantity,
    currencyAmount,
    executedPrice,
    requestedPrice,
    status
) => {
    return ["Open", "Cancelled", "Expired"].includes(status)
        ? `${action} ${requestedQuantity} ${currencyAmount} at ${requestedPrice}`
        : `${action} ${executedQuantity} ${currencyAmount} at ${executedPrice}`;
};

const mapExecutedPriceByStatus = (order) => {
    return ["Cancelled", "Expired"].includes(order.status)
        ? order.executed_price
        : mapPrice(order.executed_price);
};
