import { apiUrl } from '@/env';
import axios from 'axios';
import { getTokenHeaders } from '@/api/api-utils'
import { cloneDeep } from "lodash";

const USER_ID = 'current_user_id';

const requestOptions = {
    withCredentials: true
}

function noAuthHeaders() {
  return {
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    withCredentials: false
  };  
}

export const api = {
    async getTradingAccounts() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/accounts/advisor/${userId}/trading-accounts`, requestOptions)
    },
    async gemBalances(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.put(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${data.account_id}/balances`, data, requestOptions);
    },
    async anchorageBalances(data){
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.put(`${apiUrl}/api/v1/trade/anchorage/advisor/${userId}/account/${data.account_id}/balances`, data, requestOptions);
    },

    async coinbasePrimeBalances(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.put(`${apiUrl}/api/v1/trade/coinbase-prime/advisor/${userId}/account/${data.account_id}/balances`, data, requestOptions);
    },
    async gemPrices(currencySymbol) {
      const userId = sessionStorage.getItem(USER_ID);
      requestOptions['headers'] = getTokenHeaders();
      const config = cloneDeep(requestOptions);
      const params = new URLSearchParams();
    
      params.append("currency_symbol", currencySymbol);    
      config.params = params;  
        return axios.get(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/price`, config);
    },
    async anchoragePrices(accountId, currencySymbol) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        const config = cloneDeep(requestOptions);
        const params = new URLSearchParams();
    
        params.append("currency_symbol", currencySymbol);    
        config.params = params;  
        return axios.get(`${apiUrl}/api/v1/trade/anchorage/advisor/${userId}/account/${accountId}/price`, config);
    },
    async coinbasePrimePrices(accountId, currencySymbol) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        const config = cloneDeep(requestOptions);
        const params = new URLSearchParams();

        params.append("currency_symbol", currencySymbol);
        config.params = params;
        return axios.get(`${apiUrl}/api/v1/trade/coinbase-prime/advisor/${userId}/account/${accountId}/price`, config);
    },
    async getGeminiFeePct(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.get(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${accountId}/fee-schedule`, requestOptions);
    },
    async placeGeminiOrder(      
      accountId,
      cryptoCurrencyType, // BTC or Eth, or?
      bufferedPrice,      // max_bid for buy, min_ask for sell
      amount,             // amount to buy or sell
      transactionType     // buy or sell
    ) {
        const data = { account_id: accountId, 
                        currency: cryptoCurrencyType, 
                        price: bufferedPrice, 
                        quantity: amount, 
                        action: transactionType };

        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${accountId}/order/market`, data, requestOptions);
    },  
    async placeGeminiLimitOrder(
        accountId,
        cryptoCurrencyType, // BTC or Eth, or?
        bufferedPrice, // max_bid for buy, min_ask for sell
        amount, // amount to buy or sell
        transactionType, // buy or sell
        expiry // unix timestamp int
    ) {
        const data = { account_id: accountId,
                        currency: cryptoCurrencyType,
                        price: bufferedPrice,
                        quantity: amount,
                        action: transactionType,
                        expiry: expiry};
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${accountId}/order/limit`, data, requestOptions);
    },
    async placeCoinbasePrimeMarketOrder(
        accountId,
        symbol,
        side,
        amount,
    ) {
        const data = {
            account_id: accountId,
            symbol,
            side,
            amount,
        };
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/coinbase-prime/advisor/${userId}/account/${accountId}/order/market`, data, requestOptions);
    },
    async placeCoinbasePrimeLimitOrder(
        accountId,
        symbol,
        side,
        amount,
        price,
        expiry // Unix time
    ) {
        const data = {
            account_id: accountId,
            symbol,
            side,
            amount,
            price,
            expiry
        };
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/coinbase-prime/advisor/${userId}/account/${accountId}/order/limit`, data, requestOptions);
    },
    async placeAnchorageMarketOrder(
        accountId,
        symbol,
        side,
        amount,
    ) {
        const data = {
            account_id: accountId,
            symbol,
            side,
            amount,
        };
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/anchorage/advisor/${userId}/account/${accountId}/order/market`, data, requestOptions);
    },
    async ptBalances(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.put(`${apiUrl}/api/v1/trade/prime-trust/advisor/${userId}/account/${data.account_id}/balances`, data, requestOptions);
    },
    async ptQoute(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/prime-trust/advisor/${userId}/account/${data.account_id}/quotes`, data, requestOptions);
    },
    async ptExecute(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/prime-trust/advisor/${userId}/account/${data.account_id}/execute`, data, requestOptions);
    },
    async getGeminiTradableAssets(clearingPartyId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        const config = cloneDeep(requestOptions);
        const params = new URLSearchParams();
    
        if (clearingPartyId) {
            params.append("clearing_party_id", clearingPartyId);  
        }

        config.params = params;  

        return axios.get(`${apiUrl}/api/v1/trade/gemini/user/${userId}/available-assets`, config);
    },
    async getCoinbasePrimeTradableAssets() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.get(`${apiUrl}/api/v1/trade/coinbase-prime/user/${userId}/available-assets`, requestOptions);
    },
    async getAnchorageTradableAssets() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.get(`${apiUrl}/api/v1/trade/anchorage/user/${userId}/available-assets`, requestOptions);
    },
    async getPrimeTrustTradableAssets() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.get(`${apiUrl}/api/v1/trade/prime-trust/user/${userId}/available-assets`, requestOptions);
    },
    async getCoinMarketCapLatestListing(symbols = []) {
        const params = new URLSearchParams();
        params.append('symbols', symbols);
        const config = requestOptions;
        config.params = params;
        const userId = sessionStorage.getItem(USER_ID);
        
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/coinmarketcap/user/${userId}/coin-market-cap/latest-listing`, config)
    },

    async geminiGetQuote(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${data.account_id}/getQuote`, data, requestOptions);
    },
    async anchorageGetQuote(data){
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/anchorage/advisor/${userId}/account/${data.account_id}/getQuote`, data, requestOptions); 
    },
    async anchorageExecuteQuote(data){
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/anchorage/advisor/${userId}/account/${data.account_id}/executeQuote`, data, requestOptions); 
    },
    async geminiExecuteQuote(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();

        return axios.post(`${apiUrl}/api/v1/trade/gemini/advisor/${userId}/account/${data.account_id}/executeQuote`, data, requestOptions);
    },
    async getAccountHealth(apiPathName, accountId) {      
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        const config = cloneDeep(requestOptions);
        const params = new URLSearchParams();
      
        params.append("account_id", accountId);    
        config.params = params;  
          return axios.get(`${apiUrl}/api/v1/trade/${apiPathName}/advisor/${userId}/accountHealth`, config);
    },    
    async getTradingOverlayData() {
        requestOptions['headers'] = getTokenHeaders();
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/trade/trading-overlay/advisor/${userId}/trading-overlay-data`, { ...requestOptions });
    }, 
    async getTradingOverlayDetailData() {
        requestOptions['headers'] = getTokenHeaders();
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/trade/trading-overlay/advisor/${userId}/trading-overlay-detail-data`, { ...requestOptions });
    }        
}
