import { mapClientName } from "../common/mapClientName";
import { mapRepcodes } from "../common/mapRepcodes";
import { mapUnixDateToLocaleDate } from "../common/mapUnixDateToLocaledate";
import { mapPrice } from "../common/mapPrice";
import { PROVIDER_ID_TO_NAME_MAP } from "@/static_contents_utils.js";
export class AccountStatusMapper {
    // [TODO] check reference to account_holder_id
    static mapAccountStatusesDtoToModel(status) {
        return {
            clientName: mapClientName(status.first_name, status.last_name),
            repCodes: mapRepcodes(status.rep_codes),
            firmName: status.firm_name,
            accountID: status.account_id,
            accountType: status.account_type,
            custodian: PROVIDER_ID_TO_NAME_MAP.get(status.custodian),
            submissionDate: mapUnixDateToLocaleDate(status.submission_date),
            balance: mapPrice(status.balance),
            status: status.status,
            buyingPower: mapPrice(status.buying_power),
            openDate: mapUnixDateToLocaleDate(status.open_date),
            account_id: status.account_id,
            account_holder_id: status.account_holder_id
        };
    }
}