import mutations from "./mutations";
import getters from "./getters";

const state = {
    withdrawModalOpen: false,
    bankLinkModalOpen: false,
    depositModalOpen: false,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}