import { apiUrl } from '@/env';
import axios from 'axios';
import { USER_ID, requestOptions } from './shared-api-constants';
import { getTokenHeaders } from './api-utils';

export const ordersApi = {
    async getAllOrders(dateRange) {
        return this.get({"date_range": dateRange});
    },
    async getOrdersByClientID(clientID) {
        return this.get({"client_id": clientID});
    },
    async getOrdersByAccountID(accountID) {
        return this.get({"account_id": accountID});
    },
    async get(params) {
        const userId = sessionStorage.getItem(USER_ID);
        let config = requestOptions;
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/orders/advisor/${userId}`, { ...requestOptions, params });
    },
    async getOpenOrders(dateRange) {
        return this.getOpen({"date_range": dateRange});
    },
    async getOpenOrdersByClientID(clientID) {
        return this.getOpen({"client_id": clientID});
    },
    async getOpenOrdersByAccountID(accountID) {
        return this.getOpen({"account_id": accountID});
    },
    async getOpen(params) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/orders/advisor/${userId}/open`, { ...requestOptions, params });
    },
    async getTradeBatchOrders(stages, priceSource) {
        const params            = stages.join('&current_stage=')
        const stagesFilter      = `current_stage=${params}`;
        const priceSourceFilter = `price_source=${priceSource}`;
        const userId            = sessionStorage.getItem(USER_ID);

        requestOptions['headers'] = getTokenHeaders();
        
        return axios.get(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch?${stagesFilter}&${priceSourceFilter}`, requestOptions);
    },
    async getTradeBatchPrices(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/order-maker/advisor/${userId}/real-time-price`, data, requestOptions);
    },
    async getReviewableTradeBatches() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/review`, requestOptions);
    },
    async stageReviewedOrders(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/review`, data, requestOptions);
    },
    async getApprovedTradeBatches() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/approved`, requestOptions);
    },
    async getExecutedTradeBatches() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/executed`, requestOptions);
    },
    async fetchBatchTradeOrders(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/fetch-orders`, data, requestOptions);
    },
    async executeTradeBatches(data) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/execute`, data, requestOptions);
    },
    async getExecutedBatchOrderStatus(batchId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/${batchId}/status`, requestOptions);
    },
    async pollBatchOrderStatus(batchIds) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        const data = { data: batchIds };
        return axios.post(`${apiUrl}/api/v1/order-maker/advisor/${userId}/trade-batch/fetch-status`, data, requestOptions);
    }
}
