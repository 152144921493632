<template>
  <div :style="cssProps" class="c-subtitle center-align">{{ displayText }}</div>
</template>

<script>
/**
 * @author Team Awesome
* A typography component that styles text in the same style as Subtitle (Nocturne Serif) / Bold from the design system.
* Can be colored differently and act as it's own container.
* 
* import SubtitleNocturne from '@/components/Typographical/Subtitle/Subtitle-bold-nocturne'
**/
export default {
  props: {
    /**
    * The text to be displayed as a sub-title.
    * @default ""
    **/
    value: {
        value: String, default: "",
    },
    /**
    * The width of the text when it acts as it's own container. Keep 100% if you want it to be responsive
    * to structural css.
    * @default "100%"
    **/
    width: { type: String, default:  () => "100%" },
    /**
    * The height of the text when it acts as it's own container. Keep 100% if you want it to be responsive
    * to structural css.
    * @default "100%"
    **/
    height: { type: String, default: () => "100%" },
    /**
    * The color to display the text in, should match a standard color within themes/_default.scss
    * @default "#000000"
    **/
    color: { type: String, default: () => "var(--color__secondary)" },
    /**
    * The text alignment of the subtitle
    * @default "center"
    **/
    alignment: { type: String, default: () => "center" }
  },
  computed: {
        displayText: function() {
            return this.value;
        },
        cssProps() {
            return {
                '--width'  : this.width,
                '--height' : this.height,
                '--color'  : this.color,
                '--alignment': this.alignment
            }
        }
    },
};
</script>
<style scoped lang="scss">
  @import "../../../../styles/objects/_subtitle.scss";
  .center-align {
      text-align: var(--alignment);
  }
  .c-subtitle {
      @include o-subtitle-bold;
      color: var(--color);
      width: var(--width);
      height: var(--height);
  }
</style>