<template>
    <div>
        <v-idle 
            class="v-idle-component"
            @idle="startLogoutCountdown"
            :duration="idleDuration / 1000"
        ></v-idle> 

        <div 
            class="overlay" v-if="isIdle"
            @mousemove="resetIdle"
            @keyup="resetIdle"
        >      
    
            <Dialog
                :show="true"
                title="Inactivity Detected"
                width="400px"
            >
                <h3>Logging out in: {{ timeUntilLogout / 1000 }}s</h3>
            </Dialog>
        </div>
    </div>
</template>
<script>

import { mapActions } from "vuex";
import {MAIN_ACT_LOGOUT} from "@/store/types";
import Dialog from "@/components/Dialogs/Dialog Buttonless";
import { USERS_LOGOUT_MODAL_COUNTDOWN_INITAL_VALUE,
        USER_INACTIVITY_LIMIT } from "@/env";
export default {
    data() {
		return {
			idleDuration: USER_INACTIVITY_LIMIT,
            timeUntilLogout: USERS_LOGOUT_MODAL_COUNTDOWN_INITAL_VALUE,
            isIdle: false,
            timerId: null,
		}
	},
	computed: {
        second() {
            return this.time / 1000;
        }
    },
    components: {
        Dialog,
    },
    methods: {
        ...mapActions({
            logout: "main/" + MAIN_ACT_LOGOUT,
        }),
        logoutOnIdle() {
            const useIdleSignoutURL = true
            this.logout(useIdleSignoutURL);
        },
        startLogoutCountdown() {
            this.isIdle = true;
            this.logoutCountdown();
        },
        logoutCountdown() {
            this.timerId = setInterval(() => {
                if(!this.isIdle) {
                    this.resetIdle();
                }
                this.timeUntilLogout -= 1000;
                if (this.timeUntilLogout === 0) {
                    clearInterval(this.timerId);
                    this.logoutOnIdle();
                }
            }, 1000);
        },
        resetIdle() {
            this.isIdle = false;
            this.timeUntilLogout = USERS_LOGOUT_MODAL_COUNTDOWN_INITAL_VALUE;

            clearInterval(this.timerId)
        }
    },
    mounted() {
        document.addEventListener("mousemove", this.resetIdle)
        document.addEventListener("keyup", this.resetIdle)
    },
}
</script>
<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: flex;
    align-items: left;
    justify-content: left;
}
.modal {
    margin: 9px;
    width: 500px;
    box-shadow: 1px 2px 4px rgb(196, 198, 207);
    border-radius: 4px;
    background-color: white;    
}
.v-idle-component {
    display: none;
}
</style>
