
import './plugins/interceptors';
import './registerServiceWorker';
import './styles/index.scss';
import '@babel/polyfill';
import 'ag-grid-enterprise'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'typeface-roboto/index.css'
import * as Sentry from "@sentry/vue";
import App from './App.vue';
import Vidle from "v-idle-3";
import router from './router';
import store from '@/store';
import Keycloak from 'keycloak-js';
import vuetify from './plugins/vuetify';
import { createApp } from 'vue';
import {vMaska} from 'maska'
import {
  ENABLE_DEBUG_LOGGING,
  ERROR_MONITORING_COMMIT_SHA,
  ERROR_MONITORING_ENABLED,
  ERROR_MONITORING_ENVIRONMENT,
  ERROR_MONITORING_TRACING_SAMPLE_RATE,
  SENTRY_DSN,
  sentryDomain
} from '@/env.js';
import { Integrations } from "@sentry/tracing";
import { LicenseManager } from "ag-grid-enterprise";
import axios from 'axios';
import {KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL, KEYCLOAK_LOGOUT_URL} from "./env";
const appRef = createApp(App);
appRef.directive("maska", vMaska)
appRef.use(router);
appRef.use(vuetify);
appRef.use(store);

router.app = appRef;


LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-044847 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Onramp Invest LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Onramp Invest )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Onramp Invest )_need_to_be_licensed___( Onramp Invest )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 5 August 2024 )____[v2]_MTcyMjgxMjQwMDAwMA==b180951c4401cf8508dd62524813d75b");


if (ERROR_MONITORING_ENABLED) {
  Sentry.init({
    app: appRef,
    dsn: SENTRY_DSN,
    release: ERROR_MONITORING_COMMIT_SHA,
    environment: ERROR_MONITORING_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [sentryDomain],
      }),
    ],
    tracesSampleRate: ERROR_MONITORING_TRACING_SAMPLE_RATE
  });
}


if (ENABLE_DEBUG_LOGGING) {
  const storeLogging = ((type, payload, method) => {
    if (payload) {
      console.debug("[" + method + "]", type, {payload: JSON.stringify(payload)})
    } else {
      console.debug("[" + method + "]", type)
    }
  })
  store.subscribe(({type, payload}) => storeLogging(type, payload, "mutation"))
  store.subscribeAction(({type, payload}) => storeLogging(type, payload, "action"))

  axios.interceptors.request.use(request => {
    console.debug('Starting Request', JSON.stringify(request, null, 2))
    return request
  })

  axios.interceptors.response.use(response => {
    console.debug('Response:', JSON.stringify(response, null, 2))
    return response
  })
} else {
  window["console"]["debug"] = function() {};
}


appRef.use(Vidle);

let initOptions = {
  url: KEYCLOAK_URL, realm: KEYCLOAK_REALM, clientId: KEYCLOAK_CLIENT_ID,
}


let keycloak = new Keycloak(initOptions);

let refreshIntervalId;
function stopTokenRefresh() {
  if (refreshIntervalId) {
    clearInterval(refreshIntervalId);
    refreshIntervalId = null;
  }
}

function startTokenRefresh() {
  const refreshInterval = 60 * 1000; // Check every minute (60000ms)
  refreshIntervalId = setInterval(() => {
    keycloak.updateToken(30).then(refreshed => {
      if (refreshed) {
        console.log('Token successfully refreshed');
      } else {
        console.warn('Token not refreshed, still valid');
      }
    }).catch(err => {
      console.error('Failed to refresh token', err)
    });
  }, refreshInterval);
}

export function logoutKeyCloak(redirectionUrl) {
  stopTokenRefresh(); // Stop the token refresh process
  keycloak.logout({redirectUri:redirectionUrl}).finally(() => {
     sessionStorage.clear();
  });
}





async function initAuth() {
  try {
    const authenticated = await keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${location.origin}/silent-checksso.html`,
      pkceMethod: 'S256',
      redirectUri: `${KEYCLOAK_LOGOUT_URL}/loginCallBack`
    });
    if(authenticated){
      startTokenRefresh()
    }
    return keycloak;
  } catch (error) {
    console.error('Keycloak failed to initialize', error)
  }
}

export function getAuthRef() {
    return keycloak;
}

const FORGOT_PASSWORD_PATH = '/forgot-password';
const INACTIVITY = '/inactive';
const SIGNUP_PATH = '/signup';

const PUBLIC_PATHS = [FORGOT_PASSWORD_PATH, INACTIVITY, SIGNUP_PATH];

function isPublicPath() {
  const currentPath = window.location.href
  for (let i = 0; i < PUBLIC_PATHS.length; i++) {
    if (currentPath.includes(PUBLIC_PATHS[i])) {
      return true;
    }
  }
  return false
}

if(isPublicPath()) {
  appRef.config.globalProperties.$authRequired = false;
  appRef.config.globalProperties.$kc = {authenticated:false}
  appRef.mount('#app');
} else {
  appRef.config.globalProperties.$authRequired = true;
  initAuth().then((kc) => {
    appRef.config.globalProperties.$kc = kc;
    appRef.mount('#app');
  }).catch((error) => {
      console.error('Keycloak js failed to initialize', error)
  })
}



