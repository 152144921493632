export const GET_ACTION_ALL_TRANSACTIONS = 'GET_ACTION_ALL_TRANSACTIONS'
export const GET_ACTION_TRANSACTIONS_BY_CLIENT = 'GET_ACTION_TRANSACTIONS_BY_CLIENT'
export const GET_ACTION_TRANSACTIONS_BY_ACCOUNT = 'GET_ACTION_TRANSACTIONS_BY_ACCOUNT'
export const GETTER_TRANSACTIONS = 'GETTER_TRANSACTIONS'
export const MUTATION_SET_TRANSACTIONS = 'MUTATION_SET_TRANSACTIONS'

export default {
    namespaced: true,
    GET_ACTION_ALL_TRANSACTIONS,
    GET_ACTION_TRANSACTIONS_BY_CLIENT,
    GET_ACTION_TRANSACTIONS_BY_ACCOUNT,
    GETTER_TRANSACTIONS,
    MUTATION_SET_TRANSACTIONS
}