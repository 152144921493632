// import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';
// import 'material-design-icons-iconfont/dist/material-design-icons.css';
// import '@mdi/font/css/materialdesignicons.css';

// Vue.use(Vuetify, { iconfont: "mdi" });

// export default new Vuetify({
//   theme: {
//       options: {
//         customProperties: true,
//       },
//     themes: {
//       light: {
//         primary: '#737583',
//         secondary: '#424242',
//         accent: '#82B1FF',
//         error: '#FF5252',
//         info: '#2196F3',
//         success: '#4CAF50',
//         warning: '#FFC107'
//       },
//     },
//   },
// });

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
