export const ACTION_GET_ALL_ORDERS = 'ACTION_GET_ALL_ORDERS';
export const ACTION_GET_OPEN_ORDERS = 'ACTION_GET_OPEN_ORDERS';
export const ACTION_GET_ALL_ORDERS_FOR_ADVISOR_CLIENT = 'ACTION_GET_ALL_ORDERS_FOR_ADVISOR_CLIENT';
export const ACTION_GET_ALL_ORDERS_FOR_ADVISOR_ACCOUNT = 'ACTION_GET_ALL_ORDERS_FOR_ADVISOR_ACCOUNT';
export const ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_CLIENT = 'ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_CLIENT';
export const ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT = 'ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT';
export const ACTION_GET_BATCH_TRADE_ORDERS = 'ACTION_GET_BATCH_TRADE_ORDERS';
export const ACTION_REFRESH_BATCH_TRADE_PRICES = 'ACTION_REFRESH_BATCH_TRADE_PRICES';
export const ACTION_STAGE_REVIEWABLE_BATCHES = 'ACTION_STAGE_REVIEWABLE_BATCHES';
export const ACTION_GET_REVIEWABLE_BATCHES = 'ACTION_GET_REVIEWABLE_BATCHES';
export const ACTION_GET_PENDING_TRADE_BATCHES = 'ACTION_GET_PENDING_TRADE_BATCHES';
export const ACTION_GET_APPROVED_BATCHES = 'ACTION_GET_APPROVED_BATCHES';
export const ACTION_GET_EXECUTED_BATCHES = 'ACTION_GET_EXECUTED_BATCHES';
export const ACTION_GET_EXECUTABLE_TRADE_BATCHES = 'ACTION_GET_EXECUTABLE_TRADE_BATCHES';
export const ACTION_GET_EXECUTION_ACCEPTED_TRADE_BATCHES = 'ACTION_GET_EXECUTION_ACCEPTED_TRADE_BATCHES';
export const ACTION_EXECUTE_TRADE_BATCHES = 'ACTION_EXECUTE_TRADE_BATCHES';
export const ACTION_GET_EXECUTED_BATCH_STATUS = 'ACTION_GET_EXECUTED_BATCH_STATUS';
export const ACTION_SAVE_ORDER_MAKER_PRICE_SOURCE = 'ACTION_SAVE_ORDER_MAKER_PRICE_SOURCE';

export const GETTER_ALL_TRADE_ORDERS = 'GETTER_ALL_TRADE_ORDERS';
export const GETTER_OPEN_TRADE_ORDERS = 'GETTER_OPEN_TRADE_ORDERS';
export const GETTER_ALL_ORDERS_FOR_ADVISOR_CLIENT = 'GETTER_ALL_ORDERS_FOR_ADVISOR_CLIENT';
export const GETTER_ALL_ORDERS_FOR_ADVISOR_ACCOUNT = 'GETTER_ALL_ORDERS_FOR_ADVISOR_ACCOUNT';
export const GETTER_OPEN_ORDERS_FOR_ADVISOR_CLIENT = 'GETTER_OPEN_ORDERS_FOR_ADVISOR_CLIENT';
export const GETTER_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT = 'GETTER_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT';
export const GETTER_BATCH_TRADE_ORDERS = 'GETTER_BATCH_TRADE_ORDERS';
export const GETTER_BATCH_TRADE_PRICES = 'GETTER_BATCH_TRADE_PRICES';
export const GETTER_REVIEWABLE_BATCHES = 'GETTER_REVIEWABLE_BATCHES';
export const GETTER_PENDING_TRADE_BATCHES = 'GETTER_PENDING_TRADE_BATCHES';
export const GETTER_REVIEWABLE_BATCH_MODELS = 'GETTER_REVIEWABLE_BATCH_MODELS';
export const GETTER_STAGED_REVIEWABLE_BATCHES = 'GETTER_STAGED_REVIEWABLE_BATCHES';
export const GETTER_APPROVED_BATCHES = 'GETTER_APPROVED_BATCHES';
export const GETTER_EXECUTED_BATCHES = 'GETTER_EXECUTED_BATCHES';
export const GETTER_APPROVED_BATCH_MODELS = 'GETTER_APPROVED_BATCH_MODELS';
export const GETTER_EXECUTED_BATCH_MODELS = 'GETTER_EXECUTED_BATCH_MODELS';
export const GETTER_EXECUTABLE_TRADE_BATCHES = 'GETTER_EXECUTABLE_TRADE_BATCHES';
export const GETTER_EXECUTION_ACCEPTED_TRADE_BATCHES = 'GETTER_EXECUTION_ACCEPTED_TRADE_BATCHES';
export const GETTER_EXECUTED_BATCH_STATUS = 'GETTER_EXECUTED_BATCH_STATUS';
export const GETTER_ORDER_MAKER_PRICE_SOURCE = 'GETTER_ORDER_MAKER_PRICE_SOURCE';

export const MUTATION_SET_ALL_ORDERS = 'MUTATION_SET_ALL_ORDERS';
export const MUTATION_SET_OPEN_ORDERS = 'MUTATION_SET_OPEN_ORDERS';
export const MUTATION_ALL_ORDERS_FOR_ADVISOR_CLIENT = 'MUTATION_ALL_ORDERS_FOR_ADVISOR_CLIENT';
export const MUTATION_ALL_ORDERS_FOR_ADVISOR_ACCOUNT = 'MUTATION_ALL_ORDERS_FOR_ADVISOR_ACCOUNT';
export const MUTATION_OPEN_ORDERS_FOR_ADVISOR_CLIENT = 'MUTATION_OPEN_ORDERS_FOR_ADVISOR_CLIENT';
export const MUTATION_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT = 'MUTATION_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT';
export const MUTATION_GET_BATCH_TRADE_ORDERS = 'MUTATION_GET_BATCH_TRADE_ORDERS';
export const MUTATION_STAGE_REVIEWABLE_BATCHES = 'MUTATION_STAGE_REVIEWABLE_BATCHES';
export const MUTATION_REFRESH_BATCH_TRADE_PRICES = 'MUTATION_REFRESH_BATCH_TRADE_PRICES';
export const MUTATION_REVIEWABLE_TRADE_BATCHES = 'MUTATION_REVIEWABLE_TRADE_BATCHES';
export const MUTATION_GET_APPROVED_BATCHES = 'MUTATION_GET_APPROVED_BATCHES';
export const MUTATION_SET_EXECUTED_BATCHES = 'MUTATION_SET_EXECUTED_BATCHES';
export const MUTATION_SET_PENDING_TRADE_BATCHES = 'MUTATION_SET_PENDING_TRADE_BATCHES';
export const MUTATION_SET_EXECUTABLE_TRADE_BATCHES = 'MUTATION_SET_EXECUTABLE_TRADE_BATCHES';
export const MUTATION_SET_EXECUTION_ACCEPTED_TRADE_BATCHES = 'MUTATION_SET_EXECUTION_ACCEPTED_TRADE_BATCHES';
export const MUTATION_EXECUTE_TRADE_BATCHES = 'MUTATION_EXECUTE_TRADE_BATCHES';
export const MUTATION_EXECUTED_BATCHE_STATUS = 'MUTATION_EXECUTE_BATCHE_STATUS';
export const MUTATION_ORDER_MAKER_PRICE_SOURCE = 'MUTATION_ORDER_MAKER_PRICE_SOURCE';

export default {
    namespaced: true,
    ACTION_GET_ALL_ORDERS,
    ACTION_GET_OPEN_ORDERS,
    ACTION_GET_ALL_ORDERS_FOR_ADVISOR_CLIENT,
    ACTION_GET_ALL_ORDERS_FOR_ADVISOR_ACCOUNT,
    ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_CLIENT,
    ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT,
    ACTION_GET_EXECUTED_BATCH_STATUS,
    GETTER_ALL_TRADE_ORDERS,
    GETTER_OPEN_TRADE_ORDERS,
    GETTER_ALL_ORDERS_FOR_ADVISOR_CLIENT,
    GETTER_ALL_ORDERS_FOR_ADVISOR_ACCOUNT,
    GETTER_OPEN_ORDERS_FOR_ADVISOR_CLIENT,
    GETTER_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT,
    GETTER_STAGED_REVIEWABLE_BATCHES,
    GETTER_EXECUTED_BATCH_STATUS,
    ACTION_EXECUTE_TRADE_BATCHES,
    MUTATION_SET_ALL_ORDERS,
    MUTATION_SET_OPEN_ORDERS,
    MUTATION_STAGE_REVIEWABLE_BATCHES,
    MUTATION_ALL_ORDERS_FOR_ADVISOR_CLIENT,
    MUTATION_ALL_ORDERS_FOR_ADVISOR_ACCOUNT,
    MUTATION_OPEN_ORDERS_FOR_ADVISOR_CLIENT,
    MUTATION_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT,
    MUTATION_EXECUTE_TRADE_BATCHES,
    MUTATION_EXECUTED_BATCHE_STATUS
}
