import actions  from './actions';
import getters from './getters';
import mutations  from './mutations';


const state = {
    eth_available_balance: null,
    eth_asset_balance_notional: null,
    btc_available_balance: null,
    btc_asset_balance_notional: null,
    usd_balance: null,
    usd_asset_balance_notional: null,
    notional_balances: {},
    active_account_id: null,
    cost_per_unit: null,
    unit_count: null,
    asset_name: null,
    trade_amount: null,
    fee_amount: null,
    quote_id: '',
    confirmation: '',
    trade_id: '',
    unit_count_executed: null,
    trade_amount_executed: null,
    prices: [],
    transactions: [],
    cancelTradeOrder:{},    
    tradableSymbolsMap: new Map(),
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
