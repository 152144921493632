// organization getters
export const ORGANIZATION_GETTER_ORGANIZATION_USERS = 'ORGANIZATION_GETTER_ORGANIZATION_USERS'
export const ORGANIZATION_GETTER_CLIENT_TABLE_SORT = 'ORGANIZATION_GETTER_CLIENT_TABLE_SORT'
export const ORGANIZATION_GET_CLIENTS_FOR_ADVISOR = 'ORGANIZATION_GET_CLIENTS_FOR_ADVISOR'
export const ORGANIZATION_GETTER_TOTAL_CLIENT_ASSETS = 'ORGANIZATION_GETTER_TOTAL_CLIENT_ASSETS'
export const ORGANIZATION_GETTER_TOTAL_CLIENT_ASSETS_NUMERIC = 'ORGANIZATION_GETTER_TOTAL_CLIENT_ASSETS_NUMERIC'
export const ORGANIZATION_GETTER_AUM = 'ORGANIZATION_GETTER_AUM'
export const ORGANIZATION_GETTER_UNFORMATTED_AUM = "ORGANIZATION_GETTER_UNFORMATTED_AUM";
export const ORGANIZATION_GETTER_NUM_CLIENTS = 'ORGANIZATION_GETTER_NUM_CLIENTS'
export const ORGANIZATION_GETTER_NUM_ACCOUNTS = 'ORGANIZATION_GETTER_NUM_ACCOUNTS'
export const ORGANIZATION_GETTER_DASHBOARD_FIRM_TOTALS_NUMBER_OF_CLIENTS = 'ORGANIZATION_GETTER_DASHBOARD_FIRM_TOTALS_NUMBER_OF_CLIENTS'
export const ORGANIZATION_GETTER_DASHBOARD_FIRM_TOTALS_NUMBER_OF_ACCOUNTS = 'ORGANIZATION_GETTER_DASHBOARD_FIRM_TOTALS_NUMBER_OF_ACCOUNTS'
export const ORGANIZATION_GETTER_ACCOUNTS = 'ORGANIZATION_GETTER_ACCOUNTS'
export const ORGANIZATION_GETTER_TOTAL_ACCOUNT_ASSETS = 'ORGANIZATION_GETTER_TOTAL_ACCOUNT_ASSETS'
export const ORGANIZATION_GETTER_TOTAL_ACCOUNT_ASSETS_NUMERIC = 'ORGANIZATION_GETTER_TOTAL_ACCOUNT_ASSETS_NUMERIC'
export const ORGANIZATION_GETTER_ACT_CLIENT_INFO = 'ORGANIZATION_GETTER_ACT_CLIENT_INFO'
export const ORGANIZATION_GETTER_ACT_OTHER_ACTS = 'ORGANIZATION_GETTER_ACT_OTHER_ACTS'
export const ORGANIZATION_GETTER_ACT_POSITIONS = 'ORGANIZATION_GETTER_ACT_POSITIONS'
export const ORGANIZATION_GETTER_COST_BASIS = 'ORGANIZATION_GETTER_COST_BASIS'
export const ORGANIZATION_GETTER_COST_BASIS_CLIENT = 'ORGANIZATION_GETTER_COST_BASIS_CLIENT'
export const ORGANIZATION_GETTER_COST_BASIS_FORMATED = 'ORGANIZATION_GETTER_COST_BASIS_FORMATED'
export const ORGANIZATION_GETTER_ACT_SUMMARY = 'ORGANIZATION_GETTER_ACT_SUMMARY'
export const ORGANIZATION_GETTER_CLI_PERSONAL = 'ORGANIZATION_GETTER_CLI_PERSONAL'
export const ORGANIZATION_GETTER_CLI_CONTACT = 'ORGANIZATION_GETTER_CLI_CONTACT'
export const ORGANIZATION_GETTER_CLI_ACCOUNTS = 'ORGANIZATION_GETTER_CLI_ACCOUNT'
export const ORGANIZATION_GETTER_CLI_ACT_DIFFS = 'ORGANIZATION_GETTER_CLI_ACT_DIFFS'
export const ORGANIZATION_GETTER_CLI_ALLOC_CHART = 'ORGANIZATION_GETTER_CLI_ALLOC_CHART'
export const ORGANIZATION_GETTER_ACT_ALLOC_CHART = 'ORGANIZATION_GETTER_ACT_ALLOC_CHART'
export const ORGANIZATION_GETTER_ALLOC_ACT_SUB_TABLE = 'ORGANIZATION_GETTER_ALLOC_ACT_SUB_TABLE'
export const ORGANIZATION_GETTER_CHART = 'ORGANIZATION_GETTER_CHART'
export const ORGANIZATION_GETTER_AUM_DIFF_1D = 'ORGANIZATION_GETTER_AUM_DIFF_1D'
export const ORGANIZATION_GETTER_AUM_DIFF_FILTER = 'ORGANIZATION_GETTER_AUM_DIFF_FILTER'
export const ORGANIZATION_GETTER_UNFORMATTED_AUM_DIFF_FILTER = "ORGANIZATION_GETTER_UNFORMATTED_AUM_DIFF_FILTER";
export const ORGANIZATION_GETTER_DYN_AUM = 'ORGANIZATION_GETTER_DYN_AUM'
export const ORGANIZATION_CLIENT_BASIC = 'ORGANIZATION_CLIENT_BASIC'
export const ORGANIZATION_GETTER_CLI_INFO = 'ORGANIZATION_GETTER_CLI_INFO'
export const ORGANIZATION_GETTER_CLI_FORM = 'ORGANIZATION_GETTER_CLI_FORM'
export const ORGANIZATION_GETTER_NEW_FORM_ONE = 'ORGANIZATION_GETTER_NEW_FORM_ONE'
export const ORGANIZATION_GETTER_NEW_FORM_TWO = 'ORGANIZATION_GETTER_NEW_FORM_TWO'
export const ORGANIZATION_GETTER_NEW_FORM_THREE = 'ORGANIZATION_GETTER_NEW_FORM_THREE'
export const ORGANIZATION_GETTER_SELECTED_CUSTODIAN = 'ORGANIZATION_GETTER_SELECTED_CUSTODIAN';
export const ORGANIZATION_GETTER_ADV_AGREEMENT = 'ORGANIZATION_GETTER_ADV_AGREEMENT'
export const ORGANIZATION_GETTER_FORM_SET = 'ORGANIZATION_GETTER_FORM_SET'
export const ORGANIZATION_GETTER_ACTIVE_PERMISSIONS = 'ORGANIZATION_GETTER_ACTIVE_PERMISSIONS'
export const ORGANIZATION_GETTER_ACTIVE_PERMISSIONS_IS_LOADING = 'ORGANIZATION_GETTER_ACTIVE_PERMISSIONS_IS_LOADING'
export const ORGANIZATION_GETTER_LEFT_NAV_DISABLE = 'ORGANIZATION_GETTER_LEFT_NAV_DISABLE'
export const ORGANIZATION_GETTER_NEW_ACCOUNT_STATUS = 'ORGANIZATION_GETTER_NEW_ACCOUNT_STATUS'
export const ORGANIZATION_GET_ALL_CLIENTS_FOR_ADVISOR = 'ORGANIZATION_GET_ALL_CLIENTS_FOR_ADVISOR'
export const ORGANIZATION_GET_ALL_HELDAWAY_ACCOUNTS_FOR_ADVISOR ='ORGANIZATION_GET_ALL_UNTRADEABLE_ACCOUNTS_FOR_ADVISOR'
export const ORGANIZATION_GETTER_TOTAL_HELDAWAY_ACCOUNTS_ASSETS = 'ORGANIZATION_GETTER_TOTAL_UNTRADEABLE_ACCOUNTS_ASSETS'
export const ORGANIZATION_GETTER_TOTAL_HELDAWAY_ACCOUNTS_ASSETS_NUMERIC = 'ORGANIZATION_GETTER_TOTAL_HELDAWAY_ACCOUNTS_ASSETS_NUMERIC'
export const ORGANIZATION_GETTER_NUM_HELDAWAY_ACCOUNTS = 'ORGANIZATION_GETTER_NUM_UNTRADEABLE_ACCOUNTS'
export const ORGANIZATION_GETTER_ACCOUNTS_TABLE = 'ORGANIZATION_GETTER_ACCOUNTS_TABLE'
export const ORGANIZATION_GETTER_ADVISOR_TOTAL_AUM = "ORGANIZATION_GETTER_ADVISOR_TOTAL_AUM";

export const ORGANIZATION_GETTER_BOT_GRAPH_CHART_DATA       = "ORGANIZATION_GETTER_BOT_GRAPH_CHART_DATA";
export const ORGANIZATION_GETTER_AM_ACCOUNT_DASHBOARD_DATA  = "ORGANIZATION_GETTER_AM_ACCOUNT_DASHBOARD_DATA";
// export const ADMIN_GETTER_ONE_USER = 'admin/ADMIN_GETTER_ONE_USER'

// main getters
export const MAIN_GETTER_HAS_ADMIN_ACCESS = 'MAIN_GETTER_HAS_ADMIN_ACCESS'
export const MAIN_GETTER_LOGIN_ERROR = 'MAIN_GETTER_LOGIN_ERROR'
export const MAIN_GETTER_LOGIN_ERROR_EXPIRED_TRIAL = 'MAIN_GETTER_LOGIN_ERROR_EXPIRED_TRIAL'
export const MAIN_GETTER_DASHBOARD_SHOW_DRAWER = 'MAIN_GETTER_DASHBOARD_SHOW_DRAWER'
export const MAIN_GETTER_DASHBOARD_MINI_DRAWER = 'MAIN_GETTER_DASHBOARD_MINI_DRAWER'
export const MAIN_GETTER_USER_PROFILE = 'MAIN_GETTER_USER_PROFILE'
export const MAIN_GETTER_IS_LOGGED_IN = 'MAIN_GETTER_IS_LOGGED_IN'
export const MAIN_GETTER_FIRST_NOTIFICATION = 'MAIN_GETTER_FIRST_NOTIFICATION'
export const MAIN_GETTER_CHECK_EMAIL = 'MAIN_GETTER_CHECK_EMAIL'
export const MAIN_GETTER_CHECK_CB = 'MAIN_GETTER_CHECK_CB'
export const MAIN_GETTER_CHECK_GEM = 'MAIN_GETTER_CHECK_GEM'
export const MAIN_GETTER_CHECK_SEC = 'MAIN_GETTER_CHECK_SEC'
export const MAIN_GETTER_ALL_CONNECTIONS = 'MAIN_GETTER_ALL_CONNECTIONS' // relabel - effects main/portfolio/index
export const MAIN_GETTER_CONNECTED_LIST = 'MAIN_GETTER_CONNECTED_LIST'
export const MAIN_GETTER_EMAIL_CONFIRMED = 'MAIN_GETTER_EMAIL_CONFIRMED'
export const MAIN_GETTER_ACCOUNTS = 'MAIN_GETTER_ACCOUNTS'
export const MAIN_GETTER_ACT_TABLE_SORT = 'MAIN_GETTER_ACT_TABLE_SORT'
export const MAIN_GETTER_ASSET_TABLE_SORT = 'MAIN_GETTER_ASSET_TABLE_SORT'
export const MAIN_GETTER_ACCOUNT_GROUP_SORTED = 'MAIN_GETTER_ACCOUNT_GROUP_SORTED'
export const MAIN_GETTER_FIRM_CODES = 'MAIN_GETTER_FIRM_CODES'
export const MAIN_GETTER_FIRST_NOTIFICATION_CONTENT = 'MAIN_GETTER_FIRST_NOTIFICATION_CONTENT'
export const MAIN_GETTER_AG_LINK_OUTSIDE_ACT = 'MAIN_GETTER_AG_LINK_OUTSIDE_ACT'
export const MAIN_GETTER_ACCOUNT_GROUP_INFO = 'MAIN_GETTER_ACCOUNT_GROUP_INFO'
export const MAIN_GETTER_ACT_OPEN_NOTIFICATION = 'MAIN_GETTER_ACT_OPEN_NOTIFICATION'
export const MAIN_GETTER_NOTIFICATION_STATUS_RESPONSE = 'MAIN_GETTER_NOTIFICATION_STATUS_RESPONSE'
export const MAIN_GETTER_DOCUMENTS = 'MAIN_GETTER_DOCUMENTS'
export const MAIN_GETTER_ACT_SIGNATURE_STATUS_RESPONSE = 'MAIN_GETTER_ACT_SIGNATURE_STATUS_RESPONSE'
export const MAIN_GETTER_ACT_OPEN_CONF = 'MAIN_GETTER_ACT_OPEN_CONF'
export const MAIN_GETTER_COIN_MARKET_CAP_LATEST_LISTINGS = 'MAIN_GETTER_COIN_MARKET_CAP_LATEST_LISTINGS'
export const MAIN_GETTER_FUNDABLE_ACCOUNTS = 'MAIN_GETTER_FUNDABLE_ACCOUNTS'
export const MAIN_GETTER_BANK_LINKABLE_ACCOUNTS = 'MAIN_GETTER_BANK_LINKABLE_ACCOUNTS'
export const MAIN_GETTER_LINK_BANK_CLOSE = 'MAIN_GETTER_LINK_BANK_CLOSE'
export const MAIN_GETTER_ADVISOR_PERMISSIONS = 'MAIN_GETTER_ADVISOR_PERMISSIONS'
export const MAIN_GETTER_CONNECTION_STATUS = 'MAIN_GETTER_CONNECTION_STATUS'
export const MAIN_GETTER_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS = "MAIN_GETTER_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS" 
export const MAIN_GETTER_SELECTED_NOTIFICATION = "MAIN_GETTER_SELECTED_NOTIFICATION"

export const MAIN_GETTER_ACCOUNT_HOLDER_PORTFOLIO_METRICS = 'MAIN_GETTER_ACCOUNT_HOLDER_PORTFOLIO_METRICS'
export const MAIN_GETTER_USER_FIRST_NAME = 'MAIN_GETTER_USER_FIRST_NAME'

// price getters
export const PRICE_GETTER = 'PRICE_GETTER';
export const PRICE_LAST_UPDATED_GETTER = 'PRICE_LAST_UPDATED_GETTER';
export const PRICE_GETTER_IS_EARLY_UPGRADE = 'PRICE_GETTER_IS_EARLY_UPGRADE';

// trade getters
export const TRADE_GETTER_STATE_RAW = 'TRADE_GETTER_STATE_RAW'
export const TRADE_GETTER_POSITIONS = 'TRADE_GETTER_POSITIONS'
export const TRADE_GETTER_TRADE_INFO = 'TRADE_GETTER_TRADE_INFO'
export const TRADE_GETTER_EXECUTED = 'TRADE_GETTER_EXECUTED'
export const TRADE_GETTER_GEM_PRICES = 'TRADE_GETTER_GEM_PRICES'
export const TRADE_GETTER_NOTIONAL_BALANCES = 'TRADE_GETTER_NOTIONAL_BALANCES';
export const TRADE_GETTER_ACTIVE_ACCOUNT_ID = 'TRADE_GETTER_ACTIVE_ACCOUNT_ID';
export const TRADE_GETTER_TRADEABLE_SYMBOLS = "TRADE_GETTER_TRADEABLE_SYMBOLS";

// organization mutations
export const ORGANIZATION_MUT_SET_USERS = 'ORGANIZATION_MUT_SET_USERS'
export const ORGANIZATION_MUT_SET_CLIENTS = 'ORGANIZATION_MUT_SET_CLIENTS'
export const ORGANIZATION_MUT_SET_STATS = 'ORGANIZATION_MUT_SET_STATS'
export const ORGANIZATION_MUT_SET_CLIENT_STATS = 'ORGANIZATION_MUT_SET_CLIENT_STATS'
export const ORGANIZAITON_MUT_ACT_DETAIL = 'ORGANIZATION_MUT_ACT_DETAIL'
export const ORGANIZATION_MUT_CLIENT_DETAIL = 'ORGANIZATION_MUT_CLIENT_DETAIL'
export const ORGANIZATION_MUT_CHART = 'ORGANIZATION_MUT_CHART'
export const ORGANIZATION_NEW_FORM_ONE = 'ORGANIZATION_NEW_FORM_ONE'
export const ORGANIZATION_NEW_FORM_TWO = 'ORGANIZATION_NEW_FORM_TWO'
export const ORGANIZATION_NEW_FORM_THREE = 'ORGANIZATION_NEW_FORM_THREE'
export const ORGANIZATION_MUT_SELECTED_CUSTODIAN = 'ORGANIZATION_MUT_SELECTED_CUSTODIAN';
export const ORGANIZATION_MUT_ADV_AGREEMENT = 'ORGANIZATION_MUT_ADV_AGREEMENT'
export const ORGANIZATION_SET_FORM = 'ORGANIZATION_SET_FORM'
export const ORGANIZATION_DISABLE_NOTE = 'ORGANIZATION_DISABLE_NOTE'
export const ORGANIZATION_MUT_SET_ADVISOR_FEATURE = 'ORGANIZATION_MUT_SET_ADVISOR_FEATURE'
export const ORGANIZATION_MUT_SET_NEW_ACCOUNT_STATUS = 'ORGANIZATION_MUT_SET_NEW_ACCOUNT_STATUS'
export const ORGANIZATION_MUT_SET_CLIENTS_FOR_ADVISOR = 'ORGANIZATION_MUT_SET_CLIENTS_FOR_ADVISOR'
export const ORGANIZATION_MUT_SET_ACCOUNTS_FOR_ADVISOR = 'ORGANIZATION_MUT_SET_ACCOUNTS_FOR_ADVISOR'
export const ORGANIZATION_MUT_SET_HELDAWAY_ACCOUNTS_FOR_ADVISOR= 'ORGANIZATION_MUT_SET_UNTRADEABLE_ACCOUNTS_FOR_ADVISOR'
export const ORGANIZATION_MUT_GET_ADVISOR_TOTAL_AUM = "ORGANIZATION_MUT_GET_ADVISOR_TOTAL_AUM";
export const ORGANIZATION_MUT_AM_ACCOUNT_DASHBOARD_DATA = "ORGANIZATION_MUT_AM_ACCOUNT_DASHBOARD_DATA";
// export const ADMIN_MUT_SET_USER = 'admin/ADMIN_MUT_SET_USER'

// main mutations 
export const MAIN_MUT_SET_LOGGED_IN = 'MAIN_MUT_SET_LOGGED_IN'
export const MAIN_MUT_SET_LOGIN_ERROR = 'MAIN_MUT_SET_LOGIN_ERROR'
export const MAIN_MUT_SET_LOGIN_EXPIRED_TRIAL_ERROR = 'MAIN_MUT_SET_LOGIN_EXPIRED_TRIAL_ERROR'
export const MAIN_MUT_SET_USER_PROFILE = 'MAIN_MUT_SET_USER_PROFILE'
export const MAIN_MUT_SET_DASHBOARD_MINI_DRAWER = 'MAIN_MUT_SET_DASHBOARD_MINI_DRAWER'
export const MAIN_MUT_SET_DASHBOARD_SHOW_DRAWER = 'MAIN_MUT_SET_DASHBOARD_SHOW_DRAWER'
export const MAIN_MUT_ADD_NOTIFICATION = 'MAIN_MUT_ADD_NOTIFICATION'
export const MAIN_MUT_REMOVE_NOTIFICATION = 'MAIN_MUT_REMOVE_NOTIFICATION'
export const MAIN_MUT_CHECK_EMAIL = 'MAIN_MUT_CHECK_EMAIL'
export const MAIN_MUT_CON_STATUS = 'MAIN_MUT_CON_STATUS'
export const MAIN_MUT_HELD_AWAY_CONNECTION_STATUS = 'MAIN_MUT_HELD_AWAY_CONNECTION_STATUS'
export const MAIN_MUT_GET_NOTIFICATIONS = 'MAIN_MUT_GET_NOTIFICATIONS'
export const MAIN_MUT_DOCUMENTS = 'MAIN_MUT_DOCUMENTS'
export const MAIN_MUT_NOTIFICACTION_STATUS = 'MAIN_MUT_NOTIFICACTION_STATUS'
export const MAIN_MUT_UPDATE_ACT_SIGNATURE = 'MAIN_MUT_UPDATE_ACT_SIGNATURE'
export const MAIN_MUT_ACT_OPEN_CLI = 'MAIN_MUT_ACT_OPEN_CLI'
export const MAIN_MUT_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS = "MAIN_MUT_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS";
export const MAIN_MUT_SET_SELECTED_NOTIFICATION = 'MAIN_MUT_SET_SELECTED_NOTIFICATION'

export const MAIN_MUT_COIN_MARKET_CAP_LATEST_LISTINGS = 'MAIN_MUT_COIN_MARKET_CAP_LATEST_LISTINGS';
export const MAIN_MUT_LINK_BANK_CLOSE = 'MAIN_GETTER_MUT_BANK_CLOSE'
export const MAIN_MUT_CON_RESP = 'MAIN_MUT_CON_RESP'

// price mutations
export const PRICE_MUT_UPDATE = 'PRICE_MUT_UPDATE';
export const PRICE_MUT_IS_EARLY_UPGRADE = 'PRICE_MUT_IS_EARLY_UPGRADE';

// trading mutations
export const TRADING_MUT_PT_BALANCES = 'TRADING_MUT_PT_BALANCES'
export const TRADING_MUT_GET_PT_QUOTE = 'TRADING_MUT_GET_PT_QUOTE'
export const TRADING_MUT_PT_TRADE = 'TRADING_MUT_PT_TRADE'
export const TRADING_MUT_GET_GEM_PRICES = 'TRADING_MUT_GET_GEM_PRICES'
export const TRADING_MUT_NOTIONAL_BALANCES = 'TRADING_MUT_NOTIONAL_BALANCES';
export const TRADING_MUT_ACTIVE_ACCOUNT_ID = 'TRADING_MUT_ACTIVE_ACCOUNT_ID';
export const TRADING_MUT_SET_CANCEL_MODAL_DATA = 'TRADING_MUT_SET_CANCEL_MODAL_DATA'
export const TRADING_MUT_TRADEABLE_SYMBOLS  = "TRADING_MUT_TRADEABLE_SYMBOLS";

// organization actions
export const ORGANIZATION_ACT_GET_USERS = 'ORGANIZATION_ACT_GET_USERS'
export const ORGANIZATION_ACT_GET_CLIENTS = 'ORGANIZATION_ACT_GET_CLIENTS'
export const ORGANIZATION_ACT_GET_ALL_ACCOUNTS_FOR_ADVISOR = 'ORGANIZATION_ACT_GET_ALL_ACCOUNTS_FOR_ADVISOR'
export const ORGANIZATION_ACT_GET_STATS = 'ORGANIZATION_ACT_GET_STATS'
export const ORGANIZATION_ACT_GET_CLIENT_STATS = 'ORGANIZATION_ACT_GET_CLIENT_STATS'
export const ORGANIZATION_ACT_DETAIL = 'ORGANIZATION_ACT_DETAIL'
export const ORGANIZATION_CLIENT_DETAIL = 'ORGANIZATION_CLIENT_DETAIL'
export const ORGANIZATION_ADVISOR_CHART = 'ORGANIZATION_ADVISOR_CHART'
export const ORGANIZATION_CLIENT_CHART = 'ORGANIZATION_CLIENT_CHART'
export const ORGANIZATION_ACCOUNT_CHART = 'ORGANIZATION_ACCOUNT_CHART'
export const ORGANIZATION_GET_ADVISOR_FEATURE = 'ORGANIZATION_GET_ADVISOR_FEATURE'
export const ORGANIZATION_ACT_NEW_ACCOUNT_STATUS = 'ORGANIZATION_ACT_NEW_ACCOUNT_STATUS'
export const ORGANIZATION_GET_FORM_PERFORMANCE_DELTAS = 'ORGANIZATION_GET_FORM_PERFORMANCE_DELTAS';
// export const ADMIN_ACT_UPDATE_USER = 'admin/ADMIN_ACT_UPDATE_USER'
// export const ADMIN_ACT_CREATE_USER = 'admin/DMIN_ACT_CREATE_USER'
export const ORGANIZATION_RESET_NEW_CLIENT_ACCOUNT_FORM = "ORGANIZATION_RESET_NEW_CLIENT_ACCOUNT_FORM";
export const ORGANIZATION_ACT_GET_ADVISOR_TOTAL_AUM = "ORGANIZATION_ACT_GET_ADVISOR_TOTAL_AUM";
export const ORGANIZATION_GET_AM_ACCOUNT_DASHBOARD_DATA = "ORGANIZATION_GET_AM_ACCOUNT_DASHBOARD_DATA"

export const ORGANIZATION_ACT_GET_ORGANIZATION_COMPLIANCE_CONTACT = "ORGANIZATION_ACT_GET_ORGANIZATION_COMPLIANCE_CONTACT";
export const ORGANIZATION_GETTER_ORGANIZATION_COMPLIANCE_CONTACT = "ORGANIZATION_GET_ORGANIZATION_COMPLIANCE_CONTACT";
export const ORGANIZATION_MUT_GET_ORGANIZATION_COMPLIANCE_CONTACT = "ORGANIZATION_MUT_GET_ORGANIZATION_COMPLIANCE_CONTACT";
export const ORGANIZATION_ACT_UPSERT_ORGANIZATION_COMPLIANCE_CONTACT = "ORGANIZATION_ACT_UPSERT_ORGANIZATION_COMPLIANCE_CONTACT";


// main actions
export const MAIN_ACT_LOGIN = 'MAIN_ACT_LOGIN'
export const MAIN_ACT_GET_USER_PROFILE = 'MAIN_ACT_GET_USER_PROFILE'
export const MAIN_ACT_UPDATE_USER_PROFILE = 'MAIN_ACT_UPDATE_USER_PROFILE'
export const MAIN_ACT_CHECK_LOGGED_IN = 'MAIN_ACT_CHECK_LOGGED_IN'
export const MAIN_ACT_REMOVE_LOGIN = 'MAIN_ACT_REMOVE_LOGIN'
export const MAIN_ACT_LOGOUT = 'MAIN_ACT_LOGOUT'
export const MAIN_ACT_USER_LOGOUT = 'MAIN_ACT_USER_LOGOUT'
export const MAIN_ACT_CHECK_API_ERROR = 'MAIN_ACT_CHECK_API_ERROR'
export const MAIN_ACT_REMOVE_NOTIFICATION = 'MAIN_ACT_REMOVE_NOTIFICATION'
export const MAIN_ACT_PASSWORD_RECOVERY = 'MAIN_ACT_PASSWORD_RECOVERY'
export const MAIN_ACT_PASSWORD_RECOVERY_VALIDATE = 'MAIN_ACT_PASSWORD_RECOVERY_VALIDATE'
export const MAIN_ACT_RESET_PASSWORD = 'MAIN_ACT_RESET_PASSWORD'
export const MAIN_ACT_REGISTER_USER = 'MAIN_ACT_REGISTER_USER'

export const MAIN_ACT_CHECK_EMAIL = 'MAIN_ACT_CHECK_EMAIL'
export const MAIN_ACT_REGISTER_CON = 'MAIN_ACT_REGISTER_CON'
export const MAIN_ACT_DISCONNECT_PROVIDER = 'MAIN_ACT_DISCONNECT_PROVIDER';
export const MAIN_ACT_RESEND_CONF_EMAIL = 'MAIN_ACT_RESEND_CONF_EMAIL'
export const MAIN_CLIENT_PROV_ACCOUNT_DETAIL = 'MAIN_CLIENT_PROV_ACCOUNT_DETAIL'

export const MAIN_ACT_GET_NOTIFICATIONS = 'MAIN_ACT_GET_NOTIFICATIONS'
export const MAIN_ACT_SET_NOTIFICATION_STATUS = 'MAIN_ACT_SET_NOTIFICATION_STATUS'
export const MAIN_ACT_GET_DOCUMENTS = 'MAIN_ACT_GET_DOCUMENTS'
export const MAIN_ACT_UPDATE_ACT_SIGNATURE = 'MAIN_ACT_UPDATE_ACT_SIGNATURE'
export const MAIN_ACT_PT_ACT_OPEN_CLI = 'MAIN_ACT_PT_ACT_OPEN_CLI'
export const MAIN_ACT_GEM_ACT_OPEN_CLI = 'MAIN_ACT_GEM_ACT_OPEN_CLI'
export const MAIN_ACT_GET_COIN_MARKET_CAP_LATEST_LISTINGS = 'MAIN_ACT_GET_COIN_MARKET_CAP_LATEST_LISTINGS'
export const MAIN_ACT_ACCEPT_PRIMETRUST_KYC = "MAIN_ACT_ACCEPT_PRIMETRUST_KYC";
export const MAIN_ACT_GET_INVITATIONS_FOR_REPCODE = "MAIN_ACT_GET_INVITATIONS_FOR_REPCODE";
export const MAIN_ACT_GET_VERIFICATION = 'MAIN_ACT_GET_VERIFICATION'
export const MAIN_SET_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS = "MAIN_SET_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS"
export const MAIN_ACT_SET_SELECTED_NOTIFICATION = 'MAIN_ACT_SET_SELECTED_NOTIFICATION'

// price actions 
export const PRICE_ACT_UPDATE = 'PRICE_ACT_UPDATE';
export const PRICE_ACT_IS_EARLY_UPGRADE = 'PRICE_ACT_IS_EARLY_UPGRADE';

//trade actions
export const TRADING_GET_BALANCES = 'TRADING_GET_BALANCES'
export const TRADING_GET_PT_QUOTE = 'TRADING_GET_PT_QUOTE'
export const TRADING_GET_PT_EXECUTE = 'TRADING_GET_PT_EXECUTE'
export const TRADING_GET_GEM_BALANCES = 'TRADING_GET_GEM_BALANCES'
export const TRADING_ACT_GET_GEM_PRICES = 'TRADING_ACT_GET_GEM_PRICES'
export const TRADING_ACT_NOTIONAL_BALANCES = 'TRADING_ACT_NOTIONAL_BALANCES';
export const TRADING_ACT_GET_TRANSACTIONS = 'TRADING_ACT_GET_TRANSACTIONS'
export const TRADING_ACT_CANCEL_TRADE_MODAL = 'TRADING_ACT_CANCEL_TRADE_MODAL'
export const TRADING_LOAD_TRADEABLE_SYMBOLS = "TRADING_LOAD_TRADEABLE_SYMBOLS";

/******************** Lookup ********************/
export const LOOKUP_PREFIX = "lookup/";

// actions

// getters
export const LOOKUP_GET_PROVIDERS            = "LOOKUP_GET_PROVIDERS"
export const LOOKUP_GETTER_GENDERS = "LOOKUP_GETTER_GENDERS";
export const LOOKUP_GETTER_GENDERS_LAST_UPDATED_TIME = "LOOKUP_GETTER_GENDERS_LAST_UPDATED_TIME";
export const LOOKUP_GETTER_PROVIDER_DOCUMENT_TYPES = "LOOKUP_GETTER_PROVIDER_DOCUMENT_TYPES";
export const LOOKUP_GETTER_PROVIDER_DOCUMENT_TYPES_LAST_UPDATED_TIME = "LOOKUP_GETTER_PROVIDER_DOCUMENT_TYPES_LAST_UPDATED_TIME";

// mutations


/** Funding **/
export const FUNDING_PREFIX = "funding/";
// getters
export const FUND_GETTER_WITHDRAW_MODAL_OPEN = "FUND_GETTER_WITHDRAW_MODAL_OPEN";
export const FUND_GETTER_DEPOSIT_MODAL_OPEN = "FUND_GETTER_DEPOSIT_MODAL_OPEN";
// mutations
export const FUND_MUTATION_WITHDRAW_MODAL_OPEN = "FUND_MUTATION_WITHDRAW_MODAL_OPEN";
export const FUND_MUTATION_DEPOSIT_MODAL_OPEN = "FUND_MUTATION_DEPOSIT_MODAL_OPEN";
export const FUND_MUTATION_DEPOSIT_FROM_WALLET_MODAL_OPEN = "FUND_MUTATION_DEPOSIT_FROM_WALLET_MODAL_OPEN";

