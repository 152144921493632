<template>
    <v-dialog v-model="isVisible" :width="width" persistent :hide-overlay="hideOverlay" no-click-animation :transition="transition">
        <Card>
            <slot name="header">
                <div class="c-dialog__header-container">
                    <NocturneSerifHeader alignment="start" :value="title" color="var(--color__secondary)" />
                </div>
            </slot>
            <slot name="footer">
                <div class="c-dialog__footer-container">
                    <DialogSeperator />
                    <div class="logout-msg" style="width:92%">
                        <slot></slot>
                    </div>
                </div>
            </slot>
        </Card>
    </v-dialog>
</template>

<script>
import Card from "@/components/Card";
import DialogSeperator from "@/components/Dialogs/Dialog Seperator";
import NocturneSerifHeader from "@/components/Typographical/Subtitle/Subtitle-bold-nocturne";

export default {
    components:{
        Card,
        DialogSeperator,
        NocturneSerifHeader,
    },
    props: {
        /**
         * Toggles the display of this dialog container.
         * 
         * @default false
         */
        show: { type: Boolean, default: () => false },
        /**
         * Sets a title on the card, this is standardized to be nocturne serif H4.
         * If using your own custom content then it will be the responsibility of the extending header to set this text.
         * 
         * @default ""
         */
        title: {
            type: String, default: () => ""
        },
        /**
         * Sets the width of the dialog control.
         * 
         * @default "500px"
         */
        width: {
            type: String,
            default: "500px"
        },
        /**
         * Hides the backdrop, useful when the dialog composes the only content on a page.
         * Setting this property also disables the load-in animation as the content would be considered static 95% of the time.
         */
        hideOverlay: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isVisible: {
            get () {
                return this.show;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        initializeLoadInAnimation() {
            this.transition = this.hideOverlay ? "none" : "dialog-transition";
        }
    },
    data() {
        return {
            transition: "dialog-transition"
        }
    }, 
    created() {
        this.initializeLoadInAnimation();
    }
}
</script>
<style scoped lang="scss">
@import "@/styles/_colors.scss";
.v-dialog__content:deep(.v-dialog) {
    border-radius: 40px !important;
}
.c-dialog__header-container {
    display: flex;
    padding: 24px 24px 8px 24px;
}
.c-dialog__footer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.c-dialog__footer-button-container {
    display: flex;
    padding: 24px;
}
.divider-1 {
    margin-left: 16px;
}
.logout-msg {
    padding: 24px;
    width: 92% !important;
}
</style>
