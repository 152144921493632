import {
  canUserManageAccount,
  isAdvisor,
  isAssetManager,
  isClient,
} from "@/utils";

export const cleanLogin = (hasExpiredTrial) => {
  sessionStorage.clear()
  console.debug("cleanLogin()", "sessionStorage:", JSON.parse(JSON.stringify(sessionStorage)))
  return {
    name: "login",
    params: {
      hasExpiredTrial
    }
  };
}

export const routeByRole = () => {
  console.debug("routeByRole()", "sessionStorage:", JSON.parse(JSON.stringify(sessionStorage)))
  if (isClient()) {
    return {name: "portfolio"}
  }
  if (isAdvisor()) {
    return {name: "dashboard"}
  }
  if (isAssetManager()) {
    return {name: "sma-dashboard"}
  }
  // if user is neither should return to login or fallback page
  return cleanLogin()
}


const returnUserToStartingPage = (next) => {
  if (isAdvisor()) {
    next("/main/admin/dashboard");
  } 
  else if (isClient()) {
    next("/main/portfolio")
  } else if (isAssetManager()) {
    next("/main/admin/views/sma-dashboard");
  }
};

export const restrictToAdvisorsOnly = (next) => {
  if (isAdvisor()) {
    next();
  } 
  else {
    returnUserToStartingPage(next);
  }
};

export const restrictToAssetManagersOnly = (next) => {
  if (isAssetManager()) {
      next();
  } 
  else {
    returnUserToStartingPage(next);
  }
};

export const disableForAssetManagers = (next) => {
  if (isAssetManager()) {
    returnUserToStartingPage(next);
  } 
  else {
    next();
  }
}

export const restrictToClientsOnly = (next) => {
  if (isClient()) {
      next();
  } 
  else {
    returnUserToStartingPage(next);
  }
};

export const restrictToClientsAndAdvisorsOnly = (next) => {
  if (isClient() || isAdvisor()) {
      next();
  } 
  else {
    returnUserToStartingPage(next);
  }
};

export const restrictToUsersWhoCanManageAccount = (next) => {
  if (canUserManageAccount()) {
      next();
  } 
  else {
    returnUserToStartingPage(next);
  }
};
