<template>
    <div>
        <img v-if="color === 'white'" alt="Onramp Invest" :style="cssProps" class="branding-icon" :src="require('@/assets/svg/onramp_logo_white.svg')" />
        <img v-else-if="color === 'green'" alt="Onramp Invest" :style="cssProps" class="branding-icon" :src="require('@/assets/svg/onramp_logo_green.svg')" />
        <img v-else alt="Onramp Invest" :style="cssProps" class="branding-icon" :src="require('@/assets/svg/onramp_logo_blue.svg')"/>
    </div>
</template>

<script>
/**
 * @author Team Awesome
 * The Onramp invest icon only.
* For the icon appended with "Onramp Invest" see Branding/icon-with-text
* 'Onramp Invest' is displayed as alternate text for screen readers.
**/
export default {
    props: {
        /**
        * The size of the icon in pixels.
        * Sets both the height and width of the icon in px.
        * @default 48
        * @values 24, 44, 64
        **/
        size: { type: Number, default: 44 },
        /**
        * The color of the icon.
        * Limited to the colors set in our design system.
        * @default "blue"
        * @values blue, green, white
        **/
        color: { type: String, default: "blue"}
    },
    computed: {
        cssProps() {
            return {
                '--size': this.size + 'px'
            }
        }
    }
};
</script>
<style scoped lang="scss">
    .branding-icon {
        width: var(--size);
        height: var(--size);
    }
</style>