import actions  from './actions';
import getters from './getters';
import mutations  from './mutations';


const state = {        
    providerDocumentTypes: [],
    providerDocumentTypesLastUpdatedTime: undefined
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
