import * as types from "../types";
import { PROVIDER_CONNECTION_STATUS } from "@/utils";

export default {
    [types.MAIN_MUT_SET_LOGGED_IN]: (state, payload) => {
        state.isLoggedIn = payload;
    },
    [types.MAIN_MUT_SET_LOGIN_ERROR]: (state, payload) => {
        state.loginError = payload;
    },
    [types.MAIN_MUT_SET_LOGIN_EXPIRED_TRIAL_ERROR]: (state, payload) => {
        state.loginErrorExpiredTrial = payload;
    },
    [types.MAIN_MUT_CHECK_EMAIL]: (state, payload) => {
        state.duplicateEmail = payload;
    },
    [types.MAIN_MUT_SET_USER_PROFILE]: (state, payload) => {
        if(payload) {
            payload.isStale = false
        }
        state.userProfile = payload;     
    },
    [types.MAIN_MUT_CON_RESP]: (state, payload) => {
        // payload.connections is an array of keys that should hold status: true [ie]; a connection
        // [ex]: payload.connections = [1]
        state.connectionStatus = payload;
    },
    [types.MAIN_MUT_CON_STATUS]: (state, payload) => {
        if (payload?.connections?.length) {
            for (const con of payload.connections) {
                state.connections[con.providerId].status = con.status;
            }
        }
        else {
            Object.entries(state.connections).forEach(([key, value]) => value.status = PROVIDER_CONNECTION_STATUS.NOT_CONNECTED);
        }
    },
    [types.MAIN_MUT_HELD_AWAY_CONNECTION_STATUS]: (state, status) => {
        state.isConnectionSuccessful = status;
    },
    [types.MAIN_MUT_CON_RESP]: (state, payload) => {
        // payload.connections is an array of keys that should hold status: true [ie]; a connection
        // [ex]: payload.connections = [1]
        state.connectionStatus = payload;
    },
    [types.MAIN_MUT_SET_DASHBOARD_MINI_DRAWER]: (state, payload) => {
        state.dashboardMiniDrawer = payload;
    },
    [types.MAIN_MUT_SET_DASHBOARD_SHOW_DRAWER]: (state, payload) => {
        state.dashboardShowDrawer = payload;
    },
    [types.MAIN_MUT_ADD_NOTIFICATION]: (state, payload) => {
        state.notifications.push(payload);
    },
    [types.MAIN_MUT_REMOVE_NOTIFICATION]: (state, payload) => {
        state.notifications = state.notifications.filter(
            (notification) => notification !== payload
        );
    },
    [types.MAIN_MUT_GET_NOTIFICATIONS]: (state, payload) => {
        state.notifications = payload;
    },
    [types.MAIN_MUT_NOTIFICACTION_STATUS]: (state, payload) => {
        state.setNotificationResponse = payload;
    },
    [types.MAIN_MUT_DOCUMENTS]: (state, payload) => {
        state.documents = payload;

    },
    [types.MAIN_MUT_UPDATE_ACT_SIGNATURE]: (state, payload) => {
        state.actSignature = payload;

    },
    [types.MAIN_MUT_ACT_OPEN_CLI]: (state, payload) => {
        state.actOpenConf = payload;
    },
    [types.MAIN_MUT_COIN_MARKET_CAP_LATEST_LISTINGS]: (state, payload) => {
        state.latestListings = payload;
    },
    [types.MAIN_MUT_LINK_BANK_CLOSE]: (state, payload) => {
        state.linkBankClose = payload
    },
    [types.MAIN_MUT_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS]: (state, payload) => {
        state.refreshDashboardGraphs = payload;
    },
    [types.MAIN_MUT_SET_SELECTED_NOTIFICATION]: (state, payload) => {
        state.selectedNotification = payload;
    }
};

// export default mutations;