import { modelsApi } from "@/api/models-api";
import { OnrampError } from "@/error";
import { api } from "@/api.js";
import { logger } from "@sentry/utils";
import {get} from "lodash"
import { convertCustodianListToCacheKey } from "@/model-utils.js";
import {
    ACTION_GET_ALL_MODELS,
    ACTION_GET_ALL_MODELS_FOR_ACCOUNT,
    MUTATION_SET_ALL_MODELS,
    ACTION_GET_SETTINGS,
    MUTATION_SET_SETTINGS,                        
    MUTATION_INSERT_SETTINGS,
    MUTATION_MODEL_GRAPH_DATAPOINTS,
    ACTION_GET_MODEL_GRAPH_DATAPOINTS,
    MUTATION_UPDATE_SETTINGS,
    ACTION_INSERT_UPDATE_SETTINGS,
    ACTION_ALLOCATION_PREVIEW,
    MUTATION_ALLOCATION_PREVIEW,
    ACTION_GET_CONFIRMATION,
    MUTATION_CONFIRMATION,
    MUTATION_REBALANCE_ERROR,
    MUTATION_SETTINGS_ERROR,    
    ACTION_GET_MODEL_SUMMARY,
    MUTATION_MODEL_SUMMARY,
    ACTION_GET_CUSTOM_MODEL,
    MUTATION_CUSTOM_MODEL,
    ACTION_MODEL_TYPE,
    MUTATION_MODEL_TYPE,
    ACTION_MODEL_VIEW,
    MUTATION_MODEL_VIEW,
    ACTION_CUSTOM_MODEL_DATA,
    MUTATION_CUSTOM_MODEL_DATA,
    ACTION_MODEL_DELETE,
    MUTATION_MODEL_DELETE,
    ACTION_DELETE_MODEL_MODAL,
    MUTATION_DELETE_MODEL_MODAL,
    ACTION_DUPLICATE_MODEL,
    MUTATION_DUPLICATE_MODEL,
    ACTION_EDIT_MODEL,
    MUTATION_EDIT_MODEL,
    ACTION_SAVE_CUSTOM_MODEL_SETTINGS,
    ACTION_EDIT_CUSTOM_MODEL_SETTINGS,
    ACTION_DUPLICATE_CUSTOM_MODEL_SETTINGS,
    ACTION_ACCOUNT_DEPOSITS,
    MUTATION_ACCOUNT_DEPOSITS,
    ACTION_GET_AVAILABLE_ASSETS, MUTATION_SET_AVAILABLE_ASSETS,
    ACTION_GET_LINKED_ASSET_MANAGEMENT_FIRMS_INFO,
    MUTATION_LINKED_ASSET_MANAGEMENT_FIRMS_INFO,
    ACTION_GET_MULTIPLE_CUSTOM_MODELS, MUTATION_MULTPLE_CUSTOM_MODELS,
    ACTION_BATCH_EDIT_ACCOUNT_SETTINGS, MUTATION_BATCH_EDIT_ACCOUNT_SETTINGS,
    ACTION_BATCH_SET_ACCOUNT_SETTINGS, MUTATION_BATCH_SET_ACCOUNT_SETTINGS,
    ACTION_BATCH_REBALANCE_ACCOUNTS, MUTATION_BATCH_REBALANCE_ACCOUNTS,
    ACTION_BATCH_UNSUBSCRIBE_ACCOUNTS, MUTATION_BATCH_UNSUBSCRIBE_ACCOUNTS
} from "./types";
import {PARENT_MODEL_DETECTED_ERR_CODE} from "@/error";


export default {
    [ACTION_GET_ALL_MODELS]: ({ commit }) => {
        return modelsApi.getAllModels().then(
            (response) => commit(MUTATION_SET_ALL_MODELS, response.data),
            (_e) => null
        );
    },
    [ACTION_GET_ALL_MODELS_FOR_ACCOUNT]: ({ commit }, parameter) => {
        commit(MUTATION_SET_ALL_MODELS, []);
        return modelsApi.getAllModelsForAccount(parameter.accountId).then(
            (response) => commit(MUTATION_SET_ALL_MODELS, response.data),
            (_e) => null
        );
    },
    [ACTION_GET_SETTINGS]: ({ commit }, parameter) => {
        commit(MUTATION_SET_SETTINGS, {});
        return modelsApi.getSettingsForAccount(parameter.accountID).then(
            (response) => commit(MUTATION_SET_SETTINGS, response.data),
            (_e)       => commit(MUTATION_SET_SETTINGS, {})
        );
    },
    [ACTION_ACCOUNT_DEPOSITS]: ({ commit }, accountId) => {
        return modelsApi.getAccountDeposits(accountId).then(
            (response) => {
                commit(MUTATION_ACCOUNT_DEPOSITS, response.data)
            },
            (_e) => null
        )
    },
    [ACTION_GET_MODEL_GRAPH_DATAPOINTS]: ({ commit }, params) => {
        return modelsApi.getModelGraphDataPoints(params[0], params[1]).then(
            (response) =>
                commit(MUTATION_MODEL_GRAPH_DATAPOINTS, response.data),
            (_e) => null
        );
    },
    [ACTION_GET_MODEL_SUMMARY]: async ({ commit }) => {
        return modelsApi.getModelSummary().then(
            (response) => commit(MUTATION_MODEL_SUMMARY, response.data),
            (_e) => null
        );
    },
    [ACTION_GET_AVAILABLE_ASSETS]: async (context, requestObj) => {
      const key            = convertCustodianListToCacheKey(requestObj.modelCustodian);
      const availableCoins = requestObj.useCache ? context.state.availableCoinsForModels.get(key) 
                                                 : null;      

      if (!availableCoins)
      {
        let data = null;

        try
        {
          const response = await modelsApi.getCustodianAvailableAllocations(requestObj.modelCustodian);
          data = response.data;
        }
        finally
        {
          context.commit(MUTATION_SET_AVAILABLE_ASSETS, { "modelCustodian" : key, "data" : data });
        }                
      }      
    },
    [ACTION_GET_CUSTOM_MODEL]: ({ commit }, portfolioId) => {
        commit(MUTATION_CUSTOM_MODEL, {});
        return modelsApi.getCustomPortfolio(portfolioId).then(
            (response) => {
                commit(MUTATION_CUSTOM_MODEL, response.data);
            },
            (_e) => null
        );
    },
    [ACTION_GET_MULTIPLE_CUSTOM_MODELS]: async (context, portfolioIds) => {
        let data = null;
        
        context.commit(MUTATION_MULTPLE_CUSTOM_MODELS, []);
            
        try
        {
            const response = await modelsApi.getCustomPortfolios(portfolioIds);
            data = response.data;
        }
        finally
        {          
            context.commit(MUTATION_MULTPLE_CUSTOM_MODELS, data);
        }                             
    },
    [ACTION_DELETE_MODEL_MODAL]: async ({ commit }, portfolioId) => {
        return await Promise.all([
            modelsApi.deactivateCustomModelPortfolio(portfolioId),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(
            ([result, timeout]) => {
                if (get(result, "data.parent_models", []).length > 0) {
                    throw new OnrampError(get(result, "data.message"), PARENT_MODEL_DETECTED_ERR_CODE)
                }
                commit(MUTATION_DELETE_MODEL_MODAL, true);
            },
            (e) => { throw e }
        );
    },
    [ACTION_GET_CONFIRMATION]: ({ commit }, parameter) => {
        return modelsApi
            .getConfirmationForTransaction(parameter.accountId)
            .then(
                (response) => commit(MUTATION_CONFIRMATION, response.data),
                (_e) => null
            );
    },
    [ACTION_MODEL_TYPE]: ({ commit }) => {
        return modelsApi.getModelTypes().then(
            (response) => commit(MUTATION_MODEL_TYPE, response.data),
            (_e) => null
        );
    },
    [ACTION_ALLOCATION_PREVIEW]: ({ commit }, accountId) => {
        return modelsApi
            .getPortfolioAccountAllocation(accountId)
            .then((response) => {
                commit(MUTATION_ALLOCATION_PREVIEW, response.data);
            });
    },            
    [ACTION_SAVE_CUSTOM_MODEL_SETTINGS]: async (
        context,
        saveCustomModelSettings
    ) => {
        await modelsApi.createCustomPortfolio(saveCustomModelSettings);
    },
    [ACTION_EDIT_CUSTOM_MODEL_SETTINGS]: async (
        context,
        editCustomModelSettings
    ) => {
        await modelsApi.editCustomPortfolio(editCustomModelSettings);
    },
    [ACTION_DUPLICATE_CUSTOM_MODEL_SETTINGS]: async (
        context,
        saveCustomModelSettings
    ) => {
        await modelsApi.createCustomPortfolio(saveCustomModelSettings);
    },
    [ACTION_MODEL_VIEW]: ({ commit }, payload) => {
        commit(MUTATION_MODEL_VIEW, {});
        commit(MUTATION_MODEL_VIEW, payload);
    },
    [ACTION_CUSTOM_MODEL_DATA]: ({ commit }, payload) => {
        commit(MUTATION_CUSTOM_MODEL_DATA, {});
        commit(MUTATION_CUSTOM_MODEL_DATA, payload);
    },
    [ACTION_DUPLICATE_MODEL]: ({ commit }, payload) => {
        commit(MUTATION_DUPLICATE_MODEL, payload);
    },
    [ACTION_MODEL_DELETE]: ({ commit }, payload) => {
        commit(MUTATION_MODEL_DELETE, {});
        commit(MUTATION_MODEL_DELETE, payload);
    },
    [ACTION_EDIT_MODEL]: ({ commit }, payload) => {
        commit(MUTATION_EDIT_MODEL, {});
        commit(MUTATION_EDIT_MODEL, payload);
    },
    [ACTION_GET_LINKED_ASSET_MANAGEMENT_FIRMS_INFO]: async (context) =>
    {
      if (context.state.linkedAssetManagementFirmsInfo === null)
      {
        let data = [];
        context.commit(MUTATION_LINKED_ASSET_MANAGEMENT_FIRMS_INFO, data);

        try
        {
          const response = await modelsApi.getAvailableAssetManagementFirms();    
          data = response.data;
        }
        finally
        {
          context.commit(MUTATION_LINKED_ASSET_MANAGEMENT_FIRMS_INFO, data);
        }
      }
    },
    [ACTION_BATCH_EDIT_ACCOUNT_SETTINGS]: async (context, payload) => {
        let data = null;
        
        context.commit(MUTATION_BATCH_EDIT_ACCOUNT_SETTINGS, []);
            
        try
        {
            const response = await modelsApi.batchEditAccountSettings(payload);
            data = response.data;
        }
        finally
        {          
            context.commit(MUTATION_BATCH_EDIT_ACCOUNT_SETTINGS, data);
        }                             
    },
    [ACTION_BATCH_SET_ACCOUNT_SETTINGS]: async (context, payload) => {
        let data = null;
        
        context.commit(MUTATION_BATCH_SET_ACCOUNT_SETTINGS, []);
            
        try
        {
            const response = await modelsApi.batchSetAccountSettings(payload);
            data = response.data;
        }
        finally
        {          
            context.commit(MUTATION_BATCH_SET_ACCOUNT_SETTINGS, data);
        }                             
    },
    [ACTION_BATCH_REBALANCE_ACCOUNTS]: async (context, payload) => {
        let data = null;
        
        context.commit(MUTATION_BATCH_REBALANCE_ACCOUNTS, {});
            
        try
        {
            const response = await modelsApi.rebalanceAccountList(payload);
            data = { "submitted" : true };
        }
        catch (e)
        {
            data = { "submitted" : false };            
        }
        finally
        {          
            context.commit(MUTATION_BATCH_REBALANCE_ACCOUNTS, data);
        }                             
    },
    [ACTION_BATCH_UNSUBSCRIBE_ACCOUNTS]: async (context, payload) => {
        let data = null;
        
        context.commit(MUTATION_BATCH_UNSUBSCRIBE_ACCOUNTS, []);
            
        try
        {
            const response = await modelsApi.batchUnsubscribeAccounts(payload);
            data = response.data;
        }
        catch (e) 
        {
            data = [];
        }
        finally
        {          
            context.commit(MUTATION_BATCH_UNSUBSCRIBE_ACCOUNTS, data);
        }                                
    },
};
