//HEADER CONSTANTS
export const MODEL_MANAGEMENT_HEADER_TOOLTIP_TEXT = "The Model Management screen is the central hub for creating and maintaining your firm's model portfolios. Leverage model portfolios to more easily invest client cryptoassets into one or multiple custom-created portfolios. Models allow you to establish target percentage allocations for the custom portfolio, and to setup an automated rebalance frequency using the Onramp Model Service."

//CREATE MODEL MODAL CONSTANTS
export const CREATE_MODEL_DESCRIPTION = "Create model portfolios to easily invest client cryptoassets into one or multiple custom-created portfolios, rather than managing several individual investments within the same account. Models allow you to establish target percentage allocations for the custom portfolio, and to rebalance as desired using the Onramp Model Service."

export const MODEL_TYPE_TOOLTIP_TEXT = "Set the Model Portfolio as either Dynamic or Static. Dynamic model weights float with the market and new accounts added inherit the current weights. Static model weights remain constant and any new accounts added inherit those original weights. Trades are automatically allocated as specified among the invested cryptoassets."

export const CUSTODIAN_TOOLTIP_TEXT = "The Custodian choice will determine what crypto assets are available for the model."

export const CREATE_MODEL = "Create Model"

export const EDIT_MODEL = "Edit Model"

export const DELETE_MODEL = "Delete Model"

export const DUPLICATE_MODEL = "Duplicate Model"

export const ERROR_DIALOGUE_HEADER = "Error"

export const MODEL_MANAGEMENT_PAGE_LOAD_FAILED = "We were unable to get model information for your account. If this problem persists, please contact support."

export const MODEL_DELETED = "Model Deleted"

export const MODEL_EDITED = "Model Edited"

export const MODEL_CREATED = "Model Created"

export const MODEL_CREATION_FAILED = "Model Creation Failed"

export const MODEL_EDITING_FAILED = "Model Editing Failed"

export const MODEL_DELETION_FAILED = "Model Deletion Failed"

export const MODEL_CREATION_SUCCESS_MESSAGE = "Your model has been successfully created! You can now assign accounts to the model and begin trading."

export const MODEL_CREATION_FAILED_MESSAGE = "We were unable to create your custom models. Please try again later, or if this problem persist please contact support."

export const MODEL_EDITING_SUCCESS_MESSAGE = "Your model has been successfully edited! The new allocations are now ready to trade."

export const MODEL_EDITING_FAILED_MESSAGE = "We were unable to save your model changes. Please try again later, or if this problem persist please contact support."

export const MISSING_CREATE_MODEL_PERMISSION = "Missing permissions to create a model"

//Graph Constants

export const MODEL_GRAPH_BASE_INVESTMENT = 10000
export const MODEL_GRAPH_COLORS = [
    "#FFD05Eaa",
    "#00C7EAaa",
    "#5CCEB7aa",
    "#9100FFaa",
    "#023E8Aaa",
    "#264653aa",
];
export const MODEL_GRAPH_DAY_RANGES = {
    thirty: 'Thirty Days',
    sixty: 'Sixty Days',
    ytd: 'Year to Date',
    oneyear: 'One Year'
  }
Object.freeze(MODEL_GRAPH_DAY_RANGES)
  
export const MODEL_GRAPH_SELECTIONS = {
    top3  : 'Top 3 Models',
    top10 : 'Top 10 Models'
  }
Object.freeze(MODEL_GRAPH_SELECTIONS)
