import * as types from "../types";
import { PROVIDER_CONNECTION_STATUS, fmtActBal, fmtPhoneNumber, PROVIDER_ID, createPortfolioKeyMetricsObj, updateBankLinkEnabledAccountArray } from "@/utils";

export default {
    [types.MAIN_GETTER_HAS_ADMIN_ACCESS]: (state) => {
        //if (state.userProfile.role == 1) {
        if (state.userProfile.act_holder === false) {
            
            return true;
        } else {
            return false;
        }
    },
    [types.MAIN_GETTER_LOGIN_ERROR]: (state) => {
        return state.loginError;
    },
    [types.MAIN_GETTER_LOGIN_ERROR_EXPIRED_TRIAL]: (state) => {
        return state.loginErrorExpiredTrial;
    },
    [types.MAIN_GETTER_CHECK_EMAIL]: (state) => {
        return state.duplicateEmail;
    },
    [types.MAIN_GETTER_DASHBOARD_SHOW_DRAWER]: (state) => {
        return state.dashboardShowDrawer;
    },
    [types.MAIN_GETTER_DASHBOARD_MINI_DRAWER]: (state) => {
        return state.dashboardMiniDrawer;
    },
    [types.MAIN_GETTER_USER_PROFILE]: (state) => {
        let fmtUserProfile = state.userProfile;
        if(fmtUserProfile) {
          if (fmtUserProfile.phone_number){
              fmtUserProfile.phone_number = fmtPhoneNumber(fmtUserProfile.phone_number)
              if (fmtUserProfile.phone_number == null) 
                  fmtUserProfile.phone_number = state.phone_number
          }
          else{
              fmtUserProfile.cell_number = fmtPhoneNumber(fmtUserProfile.cell_number)
              if (fmtUserProfile.cell_number == null) 
                  fmtUserProfile.cell_number = state.cell_number
          }
        }

        return fmtUserProfile;
    },
    [types.MAIN_GETTER_FUNDABLE_ACCOUNTS]: (state, rootState, rootGetters, getters) => {
        var fundReadyCustodianAccountArr = []
        var providersListArr = []
        let providerGetter = types.LOOKUP_PREFIX+types.LOOKUP_GET_PROVIDERS
        providersListArr = getters[providerGetter]
        if (state.userProfile.fundable_accounts && providersListArr.length > 0) {

            fundReadyCustodianAccountArr = updateBankLinkEnabledAccountArray(
                providersListArr,
                state.userProfile.fundable_accounts,
                state.userProfile.first_name,
                state.userProfile.last_name
            )
        }

        return fundReadyCustodianAccountArr        
    },
    [types.MAIN_GETTER_BANK_LINKABLE_ACCOUNTS]: (state, rootGetters, rootState, getters) => {
        var bankLinkReadyCustodianAccountArr = []
        var providersListArr = []
        let providerGetter = types.LOOKUP_PREFIX+types.LOOKUP_GET_PROVIDERS
        providersListArr = getters[providerGetter]
        if (providersListArr.length > 0) {

            bankLinkReadyCustodianAccountArr = updateBankLinkEnabledAccountArray(
                providersListArr,
                state.userProfile.linkable_accounts,
                state.userProfile.first_name,
                state.userProfile.last_name
            )
        }

        return bankLinkReadyCustodianAccountArr 
    },
    [types.MAIN_GETTER_USER_FIRST_NAME]: (state) => {
        return state.userProfile?.first_name || "";
    },
    [types.MAIN_GETTER_IS_LOGGED_IN]: (state) => {
        return state.isLoggedIn;
    },
    [types.MAIN_GETTER_FIRST_NOTIFICATION]: (state) => {
        return state.notifications.length > 0 && state.notifications[0];
    },
    [types.MAIN_GETTER_FIRST_NOTIFICATION_CONTENT]: (state) => {
        if (
            typeof state.notifications !== "undefined" &&
            state.notifications.length > 0
        ) {
            return state.notifications[0];
        }
    },
    // [ex]: state connections object
    // 1 : { provider: 'Coinbase', status: < boolean > },
    // 2 : { provider: 'Gemini', status: < boolean > }
    [types.MAIN_GETTER_CHECK_CB]: (state) => {
        return state.connections[PROVIDER_ID.COINBASE].status === PROVIDER_CONNECTION_STATUS.CONNECTED;
    },
    [types.MAIN_GETTER_CHECK_GEM]: (state) => {
        return state.connections[PROVIDER_ID.GEMINI].status === PROVIDER_CONNECTION_STATUS.CONNECTED;
    },
    [types.MAIN_GETTER_CHECK_SEC]: (state) => { 
        return state.connections[PROVIDER_ID.SECURITIZE].status === PROVIDER_CONNECTION_STATUS.CONNECTED;
    },
    [types.MAIN_GETTER_ALL_CONNECTIONS]: (state) => {
        return state.connections;
    },
    [types.MAIN_GETTER_CONNECTED_LIST]: (state) => {
        // intended for displaying connections in client dashboard
        let connectionList = [];
        for (const prov in state.connections) {
            connectionList.push(state.connections[prov]);
        }
        return connectionList;
    },
    [types.MAIN_GETTER_EMAIL_CONFIRMED]: (state) => {
        return state.userProfile.email_confirmed;
    },
    [types.MAIN_GETTER_ACCOUNTS]: (state, getters) => {
        const conList = getters[types.MAIN_GETTER_CONNECTED_LIST];
        if (conList.length > 0) {
            return state.userProfile.account;
        } else {
            return [];
        }
    },
    [types.MAIN_GETTER_ACT_TABLE_SORT]: (state, getters) => {
        const conList = getters[types.MAIN_GETTER_ALL_CONNECTIONS];
        const actList = getters[types.MAIN_GETTER_ACCOUNTS];
        const accounts = [];
        for (const act in actList) {
            var fmtAccount = new Object();
            // use act properties to create new object
            // to be used to populate data on client dashboard,
            fmtAccount.asset = actList[act].currency;
            // format balance
            var fmtBal = fmtActBal(actList[act].balance);
            fmtAccount.balance = fmtBal;
            fmtAccount.name = actList[act].act_id_name;
            // get provider name by provider id
            var exName = conList[actList[act].provider_id].provider;
            fmtAccount.provider = exName;


            accounts.push(fmtAccount);
        }
        return accounts;
    },    
    [types.MAIN_GETTER_ACCOUNT_GROUP_SORTED]: (state, getters) => {        
        const sortedRepCodes = []
        if (state.userProfile.account_groups) {
            for (const actGroup in state.userProfile.account_groups) {
                var id = null;
                if (state.userProfile.account_groups[actGroup].label === "") {
                    var label = state.userProfile.account_groups[actGroup].id;
                    id = state.userProfile.account_groups[actGroup].id;
                } else {
                    label = state.userProfile.account_groups[actGroup].label;
                    id = state.userProfile.account_groups[actGroup].id;
                }
                //fmtRepCode.agValue = state.userProfile.account_groups[actGroup].id;
                sortedRepCodes.push({label:label, id:id})  
            }
        }
        return sortedRepCodes;
    },
    [types.MAIN_GETTER_FIRM_CODES]: (state, getters) => {  
        if (state.userProfile?.firm_codes) {
            return state.userProfile.firm_codes
        }
    },
    [types.MAIN_GETTER_AG_LINK_OUTSIDE_ACT]: (state, getters) => {
        if (state.userProfile.account_groups) {            
            return state.userProfile.account_groups
        }  
    },
    [types.MAIN_GETTER_ACCOUNT_GROUP_INFO]: (state, getters) => {
        const sortedRepCodes = [];        
        for (const actGroup in state.userProfile.account_groups) {
            var fmtRepCode = new Object();            
            if (state.userProfile.account_groups[actGroup].label === "") {
                fmtRepCode.agLabel =
                    state.userProfile.account_groups[actGroup].id;
            } else {
                fmtRepCode.agLabel =
                    state.userProfile.account_groups[actGroup].label;
            }
            fmtRepCode.agValue = state.userProfile.account_groups[actGroup].id;            
            sortedRepCodes.push(fmtRepCode);            
        }

        return sortedRepCodes;

    },
    [types.MAIN_GETTER_ACT_OPEN_NOTIFICATION]: (state, getters) => {
        var actOpenNotification = new Object();
        state.notifications.some(notification => {
            if (notification.type === "pt-kyc-started" || notification.type === "gem-mgmt-act-pending") {   
                if (notification.status ===0 ){
                    actOpenNotification.notification = notification;
                    return actOpenNotification;
                }
                else if (actOpenNotification.status !== 0){
                    actOpenNotification.notification = notification;
                } 
            }
        });
        
        return actOpenNotification;   
    },
    [types.MAIN_GETTER_NOTIFICATION_STATUS_RESPONSE]: (state, getters) => {        
        return state.setNotificationResponse;
        
    },
    [types.MAIN_GETTER_DOCUMENTS]: (state, getters) => {        
        return state.documents;
        
    },
    [types.MAIN_GETTER_ACT_SIGNATURE_STATUS_RESPONSE]: (state, getters) => {        
        return state.actSignature;
        
    },
    [types.MAIN_GETTER_ACT_OPEN_CONF]: (state, getters) => {        
        return state.actOpenConf;
        
    },
    [types.MAIN_GETTER_COIN_MARKET_CAP_LATEST_LISTINGS]: (state, getters) => {        
        return state.latestListings;
    },
    [types.MAIN_GETTER_LINK_BANK_CLOSE]: (state) => {
        return state.linkBankClose
    },
    [types.MAIN_GETTER_CONNECTION_STATUS]: (state) => {
        return state.isConnectionSuccessful
    },
    [types.MAIN_GETTER_ACCOUNT_HOLDER_PORTFOLIO_METRICS]: (state) => {
        return createPortfolioKeyMetricsObj(
            state.userProfile.port_stats.act_holder_balance,
            state.userProfile.port_stats.act_holder_cash
        )
    },
    [types.MAIN_GETTER_REFRESH_ASSET_MANAGER_DASHBOARD_GRAPHS]: (state) => {
        return state.refreshDashboardGraphs
    },
    [types.MAIN_GETTER_SELECTED_NOTIFICATION]: (state) => {
        return state.selectedNotification
    }
};