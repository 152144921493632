export const ORDER_MAKER_CONSTRUCTION_TAB_ID    = 0
export const ORDER_MAKER_REVIEW_TAB_ID          = 1
export const ORDER_MAKER_EXECUTION_TAB_ID       = 2
export const ORDER_MAKER_HISTORY_TAB_ID         = 3

export const CONSTRUCTION_TAB_NAME = "Construction"
export const REVIEW_TAB_NAME = "Review"
export const EXEC_TAB_NAME = "Execution"
export const HISTORY_TAB_NAME = "History"
export const ORDER_MAKER_CONSTRUCTION_TAB = { title: CONSTRUCTION_TAB_NAME , id: ORDER_MAKER_CONSTRUCTION_TAB_ID };
export const ORDER_MAKER_REVIEW_TAB       = { title: REVIEW_TAB_NAME       , id: ORDER_MAKER_REVIEW_TAB_ID };
export const ORDER_MAKER_EXECUTION_TAB    = { title: EXEC_TAB_NAME         , id: ORDER_MAKER_EXECUTION_TAB_ID };
export const ORDER_MAKER_HISTORY_TAB      = { title: HISTORY_TAB_NAME      , id: ORDER_MAKER_HISTORY_TAB_ID };

export const ORDER_MAKER_ALL_TABS = [
    ORDER_MAKER_CONSTRUCTION_TAB,
    ORDER_MAKER_REVIEW_TAB,
    ORDER_MAKER_EXECUTION_TAB,
    ORDER_MAKER_HISTORY_TAB,
]


export const UNDEFINED_PORTFOLIO_ID = -1

export const ORDER_MAKER_REBALANCE_TAB_ID = 0
export const ORDER_MAKER_NEW_ORDERS_TAB_ID = 1

export const TABLE_IS_TOP_LEVEL             = (params) => params.node.group && params.node.level === 0;
export const SHOULD_SHOW_CHECKBOX           = (params) => {
    // Show checkbox on first row, but group selection doesn't work
    // because of groupHideOpenParents
    // return params.node?.firstChild && params.node?.level === 3 && params.node?.data?.permissions;
    

    // Show the checkbox on the first row of the batch, with all the group rows suppressed and using custom selection code
    if (params.node?.firstChild && params.node.groupData && !params.node.footer) {                
        return Object.keys(params.node.groupData).length === 2 && Object.values(params.node.groupData).every(value => value !== undefined);
    }

    return false;
    


    // Show checkbox on group row    
    // return params?.node && 
    //        params.node.group && 
    //        params.node.footer && 
    //        params.node.allLeafChildren[0]?.data?.permissions;    
};
export const TABLE_IS_2ND_TOP_LEVEL         = (params) => params.node.group && params.node.level === 1;
export const TABLE_HIDE_GROUP_CELLS         = {'hide-cell-value': params => params.node.group};
export const TABLE_HIDE_TOP_GROUP_CELLS     = {'hide-cell-value': params => TABLE_IS_TOP_LEVEL(params) || TABLE_IS_2ND_TOP_LEVEL(params)};
export const TABLE_HIDE_ALL_EXCEPT_FIRST    = {'hide-cell-value': params => params.node.firstChild === false };

export const CUSTOM_AGG_FUNCS               = {
    "sumOfAbs": params => {
        let sum = 0;

        params.values.forEach(value => {
            sum += Math.abs(value);
        });

        return sum;
    },
    "sumOfAbsExcludesAllCash": params => {
        let sum = 0;

        params.values.forEach((value, index) => {
            const ticker = params?.rowNode?.allLeafChildren[index]?.data?.ticker;

            if (ticker !== CASH_RESERVE_TICKER && ticker !== USD_TICKER) {
                sum += Math.abs(value);
            }
        });

        return sum;
    },
    "sumExcludesCashReserve": params => {
        // The Target Weights column should not include the Cash Reserve row
        let sum = 0;        

        params.values.forEach((value, index) => {
            const ticker = params?.rowNode?.allLeafChildren[index]?.data?.ticker;

            if (ticker !== CASH_RESERVE_TICKER) {
                sum += value;
            }
        });

        return sum;
    }
};

export const BOLD_GROUP_CELLS   = {'bold-cell': params => params.node.group};
export const CELL_HIGHLIGHT     = { backgroundColor: "#157DEC" };


export const CASH_RESERVE_TICKER                        = "CASH_RESERVE"; 
export const USD_TICKER                                 = "USD";
export const CASH_TICKERS                               = [CASH_RESERVE_TICKER, USD_TICKER];
export const VALID_TOTAL_TARGET_WEIGHTS_MAX_THRESHOLD   = 0.0001;   // The sum of all target weights excluding cash reserve will be considered to be effectively 100% if it is less than this threshold
export const ZERO_DOLLAR_TOLERANCE                      = 0.01;     // If the amount is less than 1 cent, it will be considered as zero
export const ZERO_PERCENT_TOLERANCE                     = 0.0001;   // If the percentage is less than 0.01%, it will be considered as zero
export const REVIEWER_ACTION_APPROVE                    = "Approve";
export const REVIEWER_ACTION_REJECT                     = "Reject";
export const REVIEWER_ACTIONS                           = [REVIEWER_ACTION_APPROVE, REVIEWER_ACTION_REJECT];

export const TRADE_WORKFLOW_EVENT_TYPE_CREATION          = 1
export const TRADE_WORKFLOW_EVENT_TYPE_PENDING_REVIEW    = 2
export const TRADE_WORKFLOW_EVENT_TYPE_REVIEW_APPROVED   = 3
export const TRADE_WORKFLOW_EVENT_TYPE_REVIEW_REJECTED   = 4
export const TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_ACCEPTED= 6
export const TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_FAILED   = 7
export const TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_PARTIAL  = 8
export const TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_COMPLETED= 9
export const TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_PENDING  = 10

export const BATCH_ORDER_STATUS = {
    [TRADE_WORKFLOW_EVENT_TYPE_PENDING_REVIEW ]             : "Pending Review",
    [TRADE_WORKFLOW_EVENT_TYPE_REVIEW_APPROVED]             : "Approved",
    [TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_ACCEPTED]          : "Executed", // this is deprecated
    [TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_FAILED]    : "Failed",
    [TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_PARTIAL]   : "Partial",
    [TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_COMPLETED] : "Completed",
    [TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_PENDING]   : "Pending",
};

export const REVIEWER_ACTIONS_MAPPING = {
    [REVIEWER_ACTION_REJECT]    : 0,
    [REVIEWER_ACTION_APPROVE]   : 1,
};

export const ACCOUNT_EXECUTED_TRADE_BATCH_STATUS_REJECTED = 0
export const ACCOUNT_EXECUTED_TRADE_BATCH_STATUS_ACCEPTED = 1

export const ACCOUNTS_EXECUTED_FAILURE_MSG = "Some accounts failed to execute. Please try again or contact support."

export const FIT_ALL_COLUMNS_CODE = "fit-all-columns"
export const EXPAND_ALL_COLUMNS_CODE = "expand-all-columns"

export const FIT_ALL_COLUMNS_STR = "Fit all columns"
export const EXPAND_ALL_COLUMNS_STR = "Expand all columns"

export const FIT_ALL_COLUMNS_TOOLTIP = "Fits all columns on the screen - contents might be truncated"
export const EXPAND_ALL_COLUMNS_TOOLTIP = "Expand all columns to show their contents"

export const CONSTRUCTION_NO_ROWS_TEXT = "Select one or more accounts above and click the Create Batch button."
export const REVIEW_NO_ROWS_TEXT = "Select one or more batches above and click the View Batch button."
export const EXECUTION_NO_ROWS_TEXT = "Select one or more batches above and click the View Batch button."
export const HISTORY_NO_ROWS_TEXT = "Select one or more batches above and click the View Batch button."

export function isCash(ticker) {
    return ticker === CASH_RESERVE_TICKER || ticker === USD_TICKER;
}

export const BUY_ACTION = "Buy"
export const SELL_ACTION = "Sell"
export const COMPLETED_STATUS = "Completed"
export const FAILED_STATUS = "Failed"

export const DETAILED_GRID_HEIGHT  = "74vh";
export const DETAILED_GRID_WIDTH   = "100%";