<template>
  <div id="app">
    <v-app>
      <ModalIdle v-if="authenticated"/>
      <router-view/>
      <NotificationsManager/>
    </v-app>
  </div>
</template>


<script>

import { WHITE_LABELED_ORG_IDS, MIXPANEL_PROJECT_TOKEN } from "@/env";
import { styleTheDOMbyOrgID } from "@/whitelabel.js"
import ModalIdle from "@/components/Modals/Idle.vue";
import NotificationsManager from "@/components/NotificationsManager.vue";
window.mixpanel.init(MIXPANEL_PROJECT_TOKEN, { debug: false, track_pageview: true, persistence: 'localStorage' });

export default {
  name: 'App',
  components: {
    ModalIdle,
    NotificationsManager,
  },
  //When a user refreshes, we don't have access to their identity right away. When they first log IN, we store a refernce to their
  //poid if they are a client for white labeling reasons. This allows here to apply their theme before the screen loads and presents itself. 
  //An advisor who creates an account of a client can get a poid value in their browser, so we need to still check if this is a client even tho
  //just clients should have poid, it can be possible for an advisor to get one under certain circumstances for a limited time.
  async created() {
    if(this.$authRequired && !this.$kc.authenticated) {
      await this.$kc.login()
    }
    this.styleDom()
  },
  computed: {
    authenticated() {
      return this.$kc.authenticated
    }
  },
  mounted() {
    this.styleDom()
  },
  updated() {
    this.styleDom()
  },
  methods: {
    styleDom(){
      const poid = localStorage.getItem("onrampinvest.poid")
      if (poid && WHITE_LABELED_ORG_IDS.includes(poid))  {
        styleTheDOMbyOrgID(poid)
      }
    }
  }
};
</script>
