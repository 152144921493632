<template>
  <div :style="cssProps" class="card__container">
      <div class="c-card-close-button__container-row" v-if="isClosable">
          <IconButton icon="close" @btnClick="close" />
      </div>
      <slot></slot>
  </div>
</template>

<script>
import IconButton from "@/components/Buttons/Icon";
/**
 * @author Team Awesome
 * Card used as either a background to a section of content or used as an overlay 
 * when attached to another control to display content in an attached popup.
 * @fires close - notifies that the card has been closed.
 */
export default {
    components: {
        IconButton
    },
    name: "CardContainer",
    props: {
        /**
        * The width of the card when used as it's own container.
        * May be specified in px, % or any unit that a width will accept.
        * @default 100%
        */
        width: { type: String, default: () => `100%`},
        /**
        * The height of the card when used as it's own container.
        * May be specified in px, % or any unit that a width will accept.
        * @default 100%
        */
        height: { type: String, default: () => `100%`},
        /**
        * Enables the visibility of a drop-shadow on the card.
        * For use when a card is used as part of a platform page.
        * @default false
        */
        withShadow: { type: Boolean, default: () => false },
        /**
        * The size of the border radius, right now there is only a variation
        * between registration cards (40px) and background cards on the dashboard.
        * Should we find more variations we will have to consider splitting out
        * the card types.
        * @typedef {"small" | "medium"} borderRadiusSize
        * @default "medium" - 40px
        */
        borderRadiusSize: { type: String, default: "medium" },
        /**
         * Displays an x icon in the top right, that when clicked emit a close event.
         * @default false
         */
        isClosable: { type: Boolean, default: false}
    },
    computed: {
        cssProps() {
            return {
                '--width': this.width,
                '--height': this.height,
                '--box-shadow': this.withShadow ? '4px 2px 16px rgba(0, 0, 0, 0.08)' : 'none',
                '--border-radius': this.borderRadiusSize === 'small' ? '14px' : '40px'
            }
        }
    },
    methods: {
        close() {
            this.$emit("close");
        }
    }
};
</script>
<style scoped lang="scss">
    @use "../../styles/themes/_default.scss";
    .card__container {
        width: var(--width);
        height: var(--height);
        border-radius: var(--border-radius);
        background-color: default.$c__light;
        box-shadow: var(--box-shadow);
    }
    .c-card-close-button__container-row {
        display: flex;
        padding: 12px 24px 12px 12px;
        justify-content: flex-end;
    }
</style>