//I take a DOM object and modify the primary and secondary colors.
export const styleTheDOMbyOrgID = (orgID) => {
    var DOM = document.querySelector(':root');
    orgID = Number(orgID)
    switch(orgID) {
        //Home of the whitelabel Definitions.
        case 2575: 
            DOM.style.setProperty('--color__secondary', '#33456B'); 
            DOM.style.setProperty('--color__primary', '#007aff');
            DOM.style.setProperty('--primary__color', '#007aff');
            DOM.style.setProperty('--secondary__color', '#33456B');
            DOM.style.setProperty('--primary-color', '#007aff');
            DOM.style.setProperty('--secondary-color', '#33456B');    
            DOM.style.setProperty('--color__button--disabled', '#F3F3F3'); 
            break;
        case 119: 
            DOM.style.setProperty('--color__secondary', '#33aaff'); 
            DOM.style.setProperty('--color__primary', '#007aff');
            DOM.style.setProperty('--primary__color', '#007aff');
            DOM.style.setProperty('--secondary__color', '#33aaff');
            DOM.style.setProperty('--primary-color', '#007aff');
            DOM.style.setProperty('--secondary-color', '#33aaff');       
            DOM.style.setProperty('--color__button--disabled', '#F3F3F3'); 
            break
        case 759:
            DOM.style.setProperty('--color__secondary', '#231F20');
            DOM.style.setProperty('--color__primary', '#2aad92');
            DOM.style.setProperty('--primary__color', '#2aad92');
            DOM.style.setProperty('--secondary__color', '#231F20');
            DOM.style.setProperty('--primary-color', '#231F20');
            DOM.style.setProperty('--secondary-color', '#2aad92');
            DOM.style.setProperty('--color__button--disabled', '#F3F3F3');
            break
        case 2802:
            DOM.style.setProperty('--color__secondary', '#051F20'); 
            DOM.style.setProperty('--color__primary', '#8eb69b');
            DOM.style.setProperty('--primary__color', '#8eb69b');
            DOM.style.setProperty('--secondary__color', '#051F20');
            DOM.style.setProperty('--primary-color', '#051F20');
            DOM.style.setProperty('--secondary-color', '#8eb69b');     
            DOM.style.setProperty('--color__button--disabled', '#F3F3F3');
            break
        // case [some org id to whitelabel that has been listed in WHITE_LABELED_ORG_IDS ]:
        //     DOM.style.setProperty('--color__secondary', 'their color'); 
        //     DOM.style.setProperty('--color__primary', 'their color'); 
        //     break;
        default:
            //No WL Def found
    }
};

export const resetWhitelabelEffect = () => {
    var DOM = document.querySelector(':root');
    localStorage.removeItem("onrampinvest.poid");
    DOM.style.setProperty('--color__secondary', '#001864');
    DOM.style.setProperty('--color__primary', '#00DB0B'); 
    DOM.style.setProperty('--color__button--disabled', '#4A5A8F'); 
};
