import { apiUrl } from "@/env";
import axios from "axios";
import { USER_ID, requestOptions } from "./shared-api-constants";
import { getTokenHeaders } from "./api-utils";
import { cloneDeep } from "lodash";

export const beneficiary_api = {
    async saveBeneficiaries(account_beneficiaries) {
        console.log(account_beneficiaries);
        requestOptions["headers"] = getTokenHeaders();
        const userId = sessionStorage.getItem(USER_ID);
        return await axios.post(
            `${apiUrl}/api/v1/accounts/user/${userId}/account/${account_beneficiaries.accountId}/beneficiaries`,
            account_beneficiaries,
            requestOptions
        );
    },
    async getBeneficiaries(account) {
        requestOptions["headers"] = getTokenHeaders();
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(
            `${apiUrl}/api/v1/accounts/user/${userId}/account/${account.accountId}/beneficiaries`,
            requestOptions
        );
    },
    async uploadSpousalConsentForm(spousalConsentFormUpload) 
    {
        try 
        {
            requestOptions["headers"] = getTokenHeaders();
            const userId = sessionStorage.getItem(USER_ID);
            let spousalConsentForm= spousalConsentFormUpload.spousalConsentForm
            const reader = new FileReader();
            reader.readAsDataURL(spousalConsentForm);
            reader.onload = () => {
                let signedConsentFormSave={file_name: spousalConsentForm.name, contents:reader.result.split(',')[1]};
                console.log(signedConsentFormSave)
                axios.post(`${apiUrl}/api/v1/accounts/user/${userId}/account/${spousalConsentFormUpload.accountID}/spousal-consent/signed`, signedConsentFormSave, requestOptions);
            }
        } 
        catch (error) 
        {
            throw new Error(error.response.data.message);
        }
    },
    async downloadSignedSpousalConsentForm(accountID) {
        const userId = sessionStorage.getItem(USER_ID);
        const requestOptionsClone = cloneDeep(requestOptions);
        requestOptionsClone.headers = getTokenHeaders();
        requestOptionsClone.responseType = "blob";

        return axios.get(
            `${apiUrl}/api/v1/accounts/user/${userId}/account/${accountID}/spousal-consent/signed`, {...requestOptionsClone}
        ).then((response) => {
            downloadDocumentContents(response);
        });
    },
    async downloadNewSpousalConsentForm(accountID) {
        const userId = sessionStorage.getItem(USER_ID);
        const requestOptionsClone = cloneDeep(requestOptions);
        requestOptionsClone.headers = getTokenHeaders();
        requestOptionsClone.responseType = "blob";

        return axios.get(
            `${apiUrl}/api/v1/accounts/user/${userId}/account/${accountID}/spousal-consent/new`, {...requestOptionsClone}
        ).then((response) => {
            downloadDocumentContents(response);
        });
    },
};
function downloadDocumentContents(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'spousal_consent.pdf');
    document.body.appendChild(link);
    link.click();
}

