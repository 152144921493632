import * as types from "../types";
import { unixToLocal } from "@/utils";

export default {
	[types.PRICE_GETTER]: (state) => {
		return state.current_price;
	},
	[types.PRICE_LAST_UPDATED_GETTER]: (state) => {
		return unixToLocal(state.last_updated);
	},
	[types.PRICE_GETTER_IS_EARLY_UPGRADE]: (state) => {
		return state.is_early_upgrade;
	}
};