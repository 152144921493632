export const CUSTODIAN_PRICE_LABEL                      = "Use custodian prices";
export const FTSE_PRICE_LABEL                           = "Use FTSE prices";
export const PRICE_SOURCE_NOT_APPLICABLE_CODE           = 0;
export const PRICE_SOURCE_CUSTODIAN_PRICE_CODE          = 1;
export const PRICE_SOURCE_FTSE_PRICE_CODE               = 2;
export const PRICE_SOURCE_ONRAMP_PRICE_CODE             = 3;
export const CUSTODIAN_PRICE_TOOLTIP                    = "The prices will be sourced from the custodian";
export const FTSE_PRICE_TOOLTIP                         = "The prices will be sourced from the model revision at the time the batch was created, if available.";

export const DEFAULT_PRICE_SOURCE                       = PRICE_SOURCE_CUSTODIAN_PRICE_CODE;
export const PRICE_PROVIDER_ID_ONRAMP                   = -10

export const PRICE_SOURCE_LOOKUP = {
    [PRICE_SOURCE_NOT_APPLICABLE_CODE   ] : "-",
    [PRICE_SOURCE_CUSTODIAN_PRICE_CODE  ] : "Custodian",
    [PRICE_SOURCE_FTSE_PRICE_CODE       ] : "FTSE",
    [PRICE_SOURCE_ONRAMP_PRICE_CODE     ] : "Onramp"
}

export const CASH_PRICE_DATA = new Map([
    ["price"    , "1.0"                             ],
    ["source"   , PRICE_SOURCE_NOT_APPLICABLE_CODE  ]
]);
