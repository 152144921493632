export const SMA_DASHBOARD_ACCOUNTS_TAB_ID  = 0;
export const SMA_DASHBOARD_MODELS_TAB_ID    = 1;
export const SMA_DASHBOARD_TRADING_TAB_ID   = 2;
export const SMA_DASHBOARD_FEES_TAB_ID      = 3;
export const ROUND_TO_6_DECIMAL_PLACES      = 6;

export const SMA_DASHBOARD_ACCOUNTS_TAB = {id: SMA_DASHBOARD_ACCOUNTS_TAB_ID, title: "Accounts",  icon:"sma_details_tabs_icons_account"}
export const SMA_DASHBOARD_MODELS_TAB   = {id: SMA_DASHBOARD_MODELS_TAB_ID  , title: "Models"  ,  icon:"sma_details_tabs_icons_pie_chart"}
export const SMA_DASHBOARD_TRADING_TAB  = {id: SMA_DASHBOARD_TRADING_TAB_ID , title: "Trading" ,  icon:"sma_details_tabs_icons_arrows"}
export const SMA_DASHBOARD_FEES_TAB     = {id: SMA_DASHBOARD_FEES_TAB_ID , title: "Fees"    ,  icon:"sma_details_tabs_icons_cadusd"}

export const SMA_TABS = [
    SMA_DASHBOARD_ACCOUNTS_TAB,
    SMA_DASHBOARD_MODELS_TAB,
    SMA_DASHBOARD_TRADING_TAB,
    SMA_DASHBOARD_FEES_TAB,
]

export const SMA_DETAILS_REFRESH_OVERLAY_ADDED_ACCOUNTS_ID      = 0;
export const SMA_DETAILS_REFRESH_OVERLAY_REMOVED_ACCOUNTS_ID    = 1;

export const SMA_DETAILS_REFRESH_OVERLAY_ADDED_ACCOUNTS_TAB     = {id: SMA_DETAILS_REFRESH_OVERLAY_ADDED_ACCOUNTS_ID  , title: "Added",}
export const SMA_DETAILS_REFRESH_OVERLAY_REMOVED_ACCOUNTS_TAB   = {id: SMA_DETAILS_REFRESH_OVERLAY_REMOVED_ACCOUNTS_ID, title: "Removed",}


const SMA_DASHBOARD_SUMMARY_TOTAL_ASSETS = "Total Assets";
const SMA_DASHBOARD_SUMMARY_NUM_CLIENTS  = "Number of Clients";
const SMA_DASHBOARD_SUMMARY_NUM_ACCOUNTS = "Number of Accounts";

export const SMA_DASHBOARD_SUMMARY_DATA = [
    { "title": SMA_DASHBOARD_SUMMARY_TOTAL_ASSETS   , "content": "$0.00"    }
,   { "title": SMA_DASHBOARD_SUMMARY_NUM_CLIENTS    , "content": "0"        }
,   { "title": SMA_DASHBOARD_SUMMARY_NUM_ACCOUNTS   , "content": "0"        }
]

export const REPORT_FORMAT_ITEMS = [
    { title: "HTML",  value: "HTML" }, 
    { title: "PDF",   value: "PDF" }
]

export const SMA_MODEL_TRADING_TAB_ID                    = 0;
export const SMA_MODEL_ASSIGNMENT_TAB_ID                 = 1;
export const SMA_MODEL_RESTRICTION_TAB_ID                 = 2;
export const SMA_MODEL_TRADING_TAB                       = {id: SMA_MODEL_TRADING_TAB_ID   , title: "Trading"};
export const SMA_MODEL_ASSIGNMENT_TAB                    = {id: SMA_MODEL_ASSIGNMENT_TAB_ID, title: "Assignment"};
export const SMA_MODEL_RESTRICTION_TAB                    = {id: SMA_MODEL_RESTRICTION_TAB_ID, title: "Restrictions"};
export const MODEL_ALLOCATION_GENERATION_FAILURE         = "Unable to retrieve model allocations. If the issue persists, please contact support."
export const MODEL_ALLOCATION_GENERATION_FAILURE_HEADER  = "Allocation Preview Error"

export const SMA_TRADE_BUY_TAB_ID                        = 0
export const SMA_TRADE_SELL_TAB_ID                       = 1
export const SMA_TRADE_BUY_TAB                           = {id: SMA_TRADE_BUY_TAB_ID  , title: "Buy", value:"buy"};
export const SMA_TRADE_SELL_TAB                          = {id: SMA_TRADE_SELL_TAB_ID , title: "Sell", value:"sell"};
export const SMA_TRADE_BUY_BUTTON                        = "Preview Buys"
export const SMA_TRADE_SELL_BUTTON                       = "Preview Sells"
export const SMA_TRADE_SMA_TRADE_STATUS_CAN_AFFORD       = "Sufficient Funds"
export const SMA_TRADE_SMA_TRADE_STATUS_CANT_AFFORD      = "Insufficient Funds"
export const SMA_TRADE_SMA_TRADE_STATUS_INVALID          = "Invalid Trade"
export const SMA_TRADE_SMA_TRADE_STATUS_CONSTRAINED      = "Constrained";
export const SMA_TRADE_SMA_TRADE_STATUS_EXECUTED         = "Executed"
export const SMA_TRADE_SMA_TRADE_STATUS_FAILED           = "Failed"
export const SMA_TRADE_SMA_TRADE_STATUS_NA               = "N/A"
export const SMA_TRADE_PURCHASE_HEADER_BUY               = "Purchase"
export const SMA_TRADE_PURCHASE_HEADER_SELL              = "Sell"
export const SMA_TRADE_PURCHASE_TYPE_SELL                = "SELL BY"
export const SMA_TRADE_PURCHASE_TYPE_BUY                 = "PURCHASE BY"   
export const SMA_TRADE_PREVIEW_CURRENCY_PLACEHOLDER      = "$0.00" 
export const SMA_TRADE_GENERIC_ERROR_MESSAGE             = "The requested action failed to complete. Please try again or contact support if the problem persists."
export const SMA_TRADE_GENERIC_ERROR_MESSAGE_TITLE       = "Trade Unavailable"

export const SMA_MODEL_TABS = [SMA_MODEL_TRADING_TAB, SMA_MODEL_ASSIGNMENT_TAB, SMA_MODEL_RESTRICTION_TAB]
export const SMA_TRADE_TABS = [SMA_TRADE_BUY_TAB, SMA_TRADE_SELL_TAB]
    

export const TRADE_UNIT_USD_AMOUNT           = { code : "TRADE_UNIT_USD_AMOUNT",       title: "USD Amount"};
export const TRADE_UNIT_CRYPTO_QUANTITY      = { code : "TRADE_UNIT_CRYPTO_QUANTITY",  title: "Asset Quantity"};

export const REBALANCE_UNIT_CRYPTO_QUANTITY  = { code : "REBALANCE_UNIT_CRYPTO_QUANTITY",  text : "Asset Quantity"};

export const TRADE_UNIT_LABEL_MAP            = { "TRADE_UNIT_USD_AMOUNT" : "AMOUNT", "TRADE_UNIT_CRYPTO_QUANTITY" : "QUANTITY" };

export const SMA_TRADE_UNIT_TYPES = [TRADE_UNIT_USD_AMOUNT, TRADE_UNIT_CRYPTO_QUANTITY ]

export const UNDEFINED_UNREALIZED_GAINLOSS  = "--";
export const UNDEFINED_REBALANCE_TYPE       = "--";
export const UNASSIGNED_ACCOUNT_MODELS      = "--";
export const GENERIC_NOT_AVAILABLE          = "N/A";


export const SMA_ACCOUNTS_SELECTED_LABEL    = " ACCOUNTS SELECTED - WITH ";
export const SMA_TRADABLE_ACCOUNT_LABEL     = " TRADABLE ACCOUNTS";
export const SMA_REPORTS_BUTTON_LABEL       = " View Report";
export const SMA_REPORTS_BUTTON_COLOR       = "blue";
export const REPORTS_TYPE_HTML              = "HTML";
export const REPORTS_TYPE_PDF               = "PDF";
export const SMA_REPORTS_TYPES              = [REPORTS_TYPE_HTML, REPORTS_TYPE_PDF];
export const SMA_DOWNLOAD_PDF_LABEL         = "Download PDF";


export const SMA_DETAILS_REFRESH_OVERLAY_TOP_LABEL    = "Your selection was updated."
export const SMA_DETAILS_REFRESH_OVERLAY_BOTTOM_LABEL = "Please click to reload with the new account selection."

export const DEFAULT_RANGE   = {title: "7 Days", value: 7}
export const SUPPORTED_RANGE = [DEFAULT_RANGE , {title: "30 Days", value: 30}]
export const FAILED_TO_LOAD_PRICE = "Failed to load historical price graph, try again later"
export const CHART_TITLE = "Asset Price Over Time"

export const ASSET_ALLOCATION_PREVIEW_TITLE = "Asset Allocation Preview"
export const REBALANCE_SUBMITTED_TITLE = "Rebalance Submitted"
export const REBALANCE_SUBMISSION_FAILED = "Rebalance Submission Failed"
export const REBALANCE_SUCCESSFULLY_SUBMITTED = "Your rebalance was successfully submitted. All accounts below will be rebalanced as soon as possible. We will reach out to you if we encounter any issues!"
export const REBALANCE_COULD_NOT_BE_PROCESSED = "We were unable to submit your rebalance at this time. Please try again later, or contact support."

export const REBALANCE_PREVIEW_BUTTON_TEXT = "Rebalance Preview"
export const PROJECTED_TRANSACTION_SUMMARY = "Projected Transaction Summary"

export const FAILED_TO_LOAD_FEES    = "Failed to load fees bar graph, try again later"
export const FEE_TITLE              = "Fee Collection History and Projection"
export const FEE_GRAPH_LABEL        = "History"
export const FEE_GRAPH_LABEL_ALT    = "Projected"

export const MODEL_ASSSIGNMENT_GRAPH_TITLE = "Performance Over Time"
export const INSUFFICIENT_PERMISSIONS = "You do not have sufficient permissions to access this tab."
export const VIEW_ONLY_PERMISSION_MSG = "You do not have sufficient permissions to edit fees."
