import {
    GETTER_ALL_MODELS,
    GETTER_SETTINGS,
    GETTER_REBALANCE_TYPE,
    GETTER_REBALANCE_FREQUENCY,
    GETTER_ALLOCATION_PREVIEW,
    GETTER_CONFIRMATION,
    GETTER_REBALANCE_ERROR,
    GETTER_SETTINGS_ERROR,
    GETTER_MODEL_SUMMARY,
    GETTER_MODEL_GRAPH_DATAPOINTS,
    GETTER_MODEL_TYPE,
    GETTER_MODEL_VIEW,
    GETTER_CUSTOM_MODEL_DATA,
    GETTER_MODEL_DELETE,
    GETTER_CUSTOM_MODEL,
    GETTER_MULTIPLE_CUSTOM_MODELS,
    GETTER_DUPLICATE_MODEL,
    GETTER_EDIT_MODEL,
    GETTER_AVAILABLE_ASSETS,
    GETTER_ALL_AVAILABLE_ASSETS,
    GETTER_ACCOUNT_DEPOSITS,
    GETTER_LINKED_ASSET_MANAGEMENT_FIRMS_INFO,
    GETTER_BATCH_EDIT_ACCOUNT_SETTINGS, GETTER_BATCH_SET_ACCOUNT_SETTINGS,
    GETTER_BATCH_REBALANCE_ACCOUNTS, GETTER_BATCH_UNSUBSCRIBE_ACCOUNTS
} from "./types";
import * as STATIC_CONTENTS from "@/static_contents"
import { ALLOCATION_SYMBOL_TYPE_COIN, ALLOCATION_SYMBOL_TYPE_MODEL, MODEL_STRUCTURE_TYPE_NORMAL
  , MODEL_ALLOCATION_RESPONSE_COINS_KEY, MODEL_ALLOCATION_RESPONSE_ADVISOR_MODELS_KEY, MODEL_ALLOCATION_RESPONSE_AM_MODELS_KEY } from "@/model-constants";
import { Map } from "core-js";

function generateModelSlugToTypeMap(models) {
  const slugToTypeMap         = new Map();

  if (models) {        
    for (const entry of models) {          
      slugToTypeMap.set(entry.slug, entry.model_type) === MODEL_STRUCTURE_TYPE_NORMAL;
    }
  }

  return slugToTypeMap;
}

function filterAdvisorModels(slugToTypeMap, models) {  
  return models.filter(model => slugToTypeMap.get(model.symbol) === MODEL_STRUCTURE_TYPE_NORMAL);        
}

export default {
    [GETTER_ALL_MODELS]: (state) => {
        return state.allModels;
    },
    [GETTER_AVAILABLE_ASSETS]:(state) => (custodian) => {        
      const allSymbols            = state.availableCoinsForModels.get(custodian);      
      const slugToTypeMap         = generateModelSlugToTypeMap(state.modelSummary);

      const filteredCoins         = allSymbols ? allSymbols[MODEL_ALLOCATION_RESPONSE_COINS_KEY]                                              : []
      const filteredAdvisorModels = allSymbols ? filterAdvisorModels(slugToTypeMap, allSymbols[MODEL_ALLOCATION_RESPONSE_ADVISOR_MODELS_KEY]) : [];
      const filteredAmModels      = allSymbols ? allSymbols[MODEL_ALLOCATION_RESPONSE_AM_MODELS_KEY]                                          : [];

      return { [MODEL_ALLOCATION_RESPONSE_COINS_KEY]          : filteredCoins
             , [MODEL_ALLOCATION_RESPONSE_ADVISOR_MODELS_KEY] : filteredAdvisorModels
             , [MODEL_ALLOCATION_RESPONSE_AM_MODELS_KEY]      : filteredAmModels };
    },
    [GETTER_ALL_AVAILABLE_ASSETS]:(state) => {        
      return state.availableCoinsForModels;
    },
    [GETTER_SETTINGS]: (state) => {
        return state.settings;
    },
    [GETTER_REBALANCE_TYPE]: (state) => {
        return STATIC_CONTENTS.SUPPORTED_REBALANCE_TYPES;
    },
    [GETTER_REBALANCE_FREQUENCY]: (state) => {
        return STATIC_CONTENTS.SUPPORTED_REBALANCE_FREQS;
    },
    [GETTER_ALLOCATION_PREVIEW]: (state) => {
        return state.allocationData;
    },
    [GETTER_CONFIRMATION]: (state) => {
        return state.confirmation;
    },
    [GETTER_REBALANCE_ERROR]: (state) => {
        return state.rebalanceError;
    },
    [GETTER_SETTINGS_ERROR]: (state) => {
        return state.settingsError;
    },
    [GETTER_MODEL_SUMMARY]: (state) => {
        return state.modelSummary;
    },
    [GETTER_MODEL_GRAPH_DATAPOINTS]: (state) => {
        return state.modelGraphDatapoints;
    },
    [GETTER_MODEL_TYPE]: (state) => {
        return state.modeltypes;
    },
    [GETTER_MODEL_VIEW]: (state) => {
        return state.openViewModal;
    },
    [GETTER_EDIT_MODEL]: (state) => {
        return state.openEditModel;
    },
    [GETTER_CUSTOM_MODEL_DATA]: (state) => {
        return state.dataViewModal;
    },
    [GETTER_MODEL_DELETE]: (state) => {
        return state.openDeleteModel;
    },
    [GETTER_CUSTOM_MODEL]: (state) => {
        return state.customModel;
    },
    [GETTER_MULTIPLE_CUSTOM_MODELS]: (state) => {
        return state.multipleCustomModels;
    },
    [GETTER_DUPLICATE_MODEL]: (state) => {
        return state.openDuplicateModal;
    },
    [GETTER_ACCOUNT_DEPOSITS]: (state) => {
        return state.accountDeposits;
    },
    [GETTER_LINKED_ASSET_MANAGEMENT_FIRMS_INFO]: (state) =>
    {
      return state.linkedAssetManagementFirmsInfo;
    },
    [GETTER_BATCH_EDIT_ACCOUNT_SETTINGS]: (state) =>
    {
      return state.batchEditAccountSettingsData;
    },
    [GETTER_BATCH_SET_ACCOUNT_SETTINGS]: (state) =>
    {
      return state.batchSetAccountSettingsData;
    },
    [GETTER_BATCH_REBALANCE_ACCOUNTS]: (state) =>
    {
      return state.batchRebalanceAccountsData;
    },
    [GETTER_BATCH_UNSUBSCRIBE_ACCOUNTS]: (state) =>
    {
      return state.batchUnsubscribeAccountsData;
    }
};
