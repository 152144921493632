import {
    GETTER_ACCOUNT_BENEFICIARY,
    GETTER_RELATIONSHIP_TYPE,
    GETTER_SIGNED_SPOUSAL_CONSENT_FORM, 
} from "./types";
import * as STATIC_CONTENTS from "@/static_contents"

export default {
    [GETTER_ACCOUNT_BENEFICIARY]: (state) => {
        return state.allBeneficiaries;
    },
    [GETTER_SIGNED_SPOUSAL_CONSENT_FORM]: (state) => {
        return state.signedSpousalConsentFormFile;
    },
    [GETTER_RELATIONSHIP_TYPE]: (state) => {
        return STATIC_CONTENTS.PRIMETRUST_BENEFICIARIES_RELATIONSHIP_TYPES;
    },
};
