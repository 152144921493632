import {
    ORDER_TYPE_LIMIT,
    ORDER_TYPE_MARKET,
    DIRECTION_SELL,
    DIRECTION_BUY,
    TIF_DAY,
    CLEARING_TRADE_INVALID_QUOTE_ID,
    CLEARING_TRADE_INVALID_ONRAMP_PRICE
} from "../../constants/TRA-trading-state";
import {
    SHOW_INSUFFICIENT_FUNDS,
    SHOW_TRADE_ERROR,
    SHOW_TRADE_CONFIRMATION
} from "../../constants/TRA-modals";
import {api} from "../../TRA-api";
import { CustodianService } from "./TRA-custodian-service";
import {PRICE_REFRESH_INTERVAL_GEM} from "@/env";
import { isClearingAccount } from "../../utils";

const TRADING_PAIR = "USD";
const GEMINI_API_ERROR_INSUFFICIENT_FUNDS = "InsufficientFunds";
const DEFAULT_PRECISION = 6;

export class GeminiService extends CustodianService {
    static getApiPathName() {
        return "gemini"
    }
    static async getAccountHealth(accountId) {
        try {
            const response = await api.getAccountHealth(this.getApiPathName(), accountId);
            return response.data;
        } catch (error) {            
            return this.handleGetAccountHealthError(error);
        }
    }
    static async getPrice(priceData, accountClearingPartyId) {
        return isClearingAccount(accountClearingPartyId) ? this.getQuote(priceData)
                                                         : this.getPriceFromGemini(priceData);        
    }
    static async getQuote(priceData) {
        const payload = this.generateQuoteRequestPayload(priceData)

        try {
            const response = await api.geminiGetQuote(payload);
            return this.mapQuoteResponse(response.data);
        } catch (error) {
            return this.handleGetPriceError(error);            
        }
    }
    static async getPriceFromGemini(priceData) {
        try {
            let response = await Promise.all([
                api.gemPrices(`${priceData.currency}${TRADING_PAIR}`),
                api.getGeminiFeePct(priceData.account_id)
            ]);
            let mappedResponse = this.mapPriceResponse(response, priceData.currency)
            return mappedResponse
        } catch (error) {
            return this.handleGetPriceError(error);
        }
    }
    static generateQuoteRequestPayload(priceData) {
        return {
            'account_id': priceData.account_id,
            'symbol'    : priceData.currency,
            'side'      : priceData.direction,            
            'quantity'  : priceData.quantity,
            'amount'    : priceData.amount.toFixed(2)            
        }
    }
    static async getBalances(accountId) {
        try {
            let response = await api.gemBalances( {'account_id': accountId} );
            let mappedResponse = this.formatBalanceData(accountId, response.data);
            return mappedResponse;
        } catch (error) {            
            return this.handleGetBalancesError(error);
        }
    }
    static mapPriceResponse(response, currency) {
        return {
            'symbol': currency,
            'buy': parseFloat(response[0].data.price.buy),
            'sell': parseFloat(response[0].data.price.sell),
            'max_buy': parseFloat(response[0].data.price.max_buy),
            'min_sell': parseFloat(response[0].data.price.min_sell),
            'taker_fee': response[1].data.taker_fee
        }
    }
    static mapQuoteResponse(response) {
        const quantity      = parseFloat(response.quantity);
        const amount        = parseFloat(response.amount);
        const unit_price    = quantity > 0 ? amount / quantity : 0.0;

        return {
            'quote_id'      : response.quote_id,
            'cost_per_unit' : unit_price,
            'unit_count'    : quantity,
            'trade_amount'  : amount,     
            'fee_amount'    : 0.0,       
            'total'         : amount
        }
    }
    static async getTradableAssets(clearingPartyId) {
        try {
            let response = await api.getGeminiTradableAssets(clearingPartyId);
            return response;
        } catch (error) {
            return this.errorHandler(error);
        }
    }
    static setOrderTypeOptions(accountClearingPartyId) {
        let options = [ORDER_TYPE_MARKET];

        if (!isClearingAccount(accountClearingPartyId)) {
            options.push(ORDER_TYPE_LIMIT);
        }
    
        return options;
    }
    static findPriceToUseForTrade(prices, activeDirection, activeOrderType, accountClearingPartyId) {
        /*
            param prices: obj: see mapPriceResponse
        */
        switch (activeOrderType) {
            case ORDER_TYPE_LIMIT:
                return this.setLimitPriceBasedOnActiveOrderDirection(prices, activeDirection);
            case ORDER_TYPE_MARKET:
                return this.getMarketOrderExecutionPrice(prices, activeDirection, accountClearingPartyId);
            default:
                return this.getMarketOrderExecutionPrice(prices, activeDirection, accountClearingPartyId);
        }
    }
    static setLimitPriceBasedOnActiveOrderDirection(prices, activeDir = DIRECTION_BUY) {
        /*
            param prices: Obj
            [Example]:
            {
                'symbol': currency,
                'buy': response[0].data.buy,
                'sell': response[0].data.sell,
                'max_buy': response[0].max_buy,
                'max_sell': response[0].max_sell,
                'taker_fee': response[1].taker_fee
            }
        */
        switch (activeDir) {
            case DIRECTION_BUY:
                return prices.buy;
            case DIRECTION_SELL:
                return prices.sell;
            default:
                return prices.buy;
        }
    }
    static formTradeDetails(
        selectedCurrency,
        tradeInputs,
        activeDirection,
        activeOrderType,
        activeTimeInForce,
        latestTradeData,
        accountId,
        accountClearingPartyId
    ) {

        let costDetails = this.tradeFeeAndTotalCalculation(activeOrderType, { ...tradeInputs, ...latestTradeData }, accountClearingPartyId);
        let totalBeforeFee =  costDetails.total - costDetails.fee;
        
        let price = 
            activeOrderType === ORDER_TYPE_LIMIT ?
            tradeInputs.limitOrderPrice :
            this.getMarketOrderExecutionPrice(latestTradeData, activeDirection, accountClearingPartyId);

        const quoteId               = this.getQuoteId(latestTradeData, accountClearingPartyId);
        const clearingOnrampPrice   = this.getClearingOnrampPrice(latestTradeData, accountClearingPartyId);

        return {
            'accountId': accountId,
            'unitCount': tradeInputs.baseCurrency === "USD" ? totalBeforeFee / price.toFixed(selectedCurrency.decPrecision) : (tradeInputs.toAmount / price).toFixed(selectedCurrency.decPrecision),
            'unitCost': price,
            'tradeAmount': totalBeforeFee,
            'feeAmount':  costDetails.fee,
            'totalAmount': costDetails.total,
            'timeInForce': activeTimeInForce,
            'currency': selectedCurrency,
            'direction': activeDirection,
            'type': activeOrderType,
            'quoteId'               : quoteId,
            'clearingOnrampPrice'   : clearingOnrampPrice
        }
    }
    static getQuoteId(priceResponse, accountClearingPartyId) {
        return isClearingAccount(accountClearingPartyId) ? priceResponse.quote_id : CLEARING_TRADE_INVALID_QUOTE_ID;
    }
    static getClearingOnrampPrice(priceResponse, accountClearingPartyId) {
        return isClearingAccount(accountClearingPartyId) ? priceResponse.trade_amount : CLEARING_TRADE_INVALID_ONRAMP_PRICE;
    }
    static tradeFeeAndTotalCalculation(orderType, tradeInputs, accountClearingPartyId) {
        const fee_pct = isClearingAccount(accountClearingPartyId) ? 0.0 : tradeInputs.taker_fee;

        if (tradeInputs.baseCurrency === "USD" || orderType === ORDER_TYPE_MARKET) {
            return {
                'fee': this.calculateTradeFee(tradeInputs.fromAmount, fee_pct) || 0,
                'total': tradeInputs.fromAmount || 0
            } 
        } else {
            /*
                this condition may not be neccessary after adding setToAmountOnLimitOrderChange()
                to TradeDetailSetter
            */
            let feeBasis = tradeInputs.fromAmount * tradeInputs.limitOrderPrice;
            return {
                'fee': this.calculateTradeFee(feeBasis, fee_pct) || 0,
                'total': feeBasis || 0
            }
        }

    }
    static getRefreshInterval() {
        return PRICE_REFRESH_INTERVAL_GEM;
    }
    static calculateTradeFee(fromAmount, feePct) {
      /*
      The from_amount (the USD) always include the fee built in.  Thus, regardless of whether it is user-entered or calculated
      (when the user enters the crypto amount instead), I need to derive the amount without the fee first.
      */
      const amountWithoutFee = fromAmount / (1 + feePct);

      return amountWithoutFee * feePct;
    }
    static calculateTotalBeforeFee(total, fee, activeDirection) {
        switch (activeDirection) {
            case DIRECTION_BUY:
                return total - fee;
            case DIRECTION_SELL:
                return total + fee;
        }
    }
    static getMarketOrderExecutionPrice(tradeData, activeDirection, accountClearingPartyId) {
        return isClearingAccount(accountClearingPartyId) ? this.getQuotePrice(tradeData)
                                                         : this.getGeminiMarketOrderExecutionPrice(tradeData, activeDirection);        
    }
    static getQuotePrice(tradeData) {
        return tradeData?.cost_per_unit ?? 0.0;
    }
    static getGeminiMarketOrderExecutionPrice(tradeData, activeDirection) {        
        if (tradeData?.max_buy && tradeData?.min_sell) {
            return activeDirection === DIRECTION_BUY ?
                tradeData.max_buy :
                tradeData.min_sell;
        }
        return 0;
    }
    static calculateFeeBasis(tradeInputs) {
        if (tradeInputs.baseCurrency === "USD") {
            return tradeInputs.fromAmount * tradeInputs.limitOrderPrice;
        } else {
            return tradeInputs.toAmount *
                tradeInputs.limitOrderPrice;
        }
    }
    static submitOrder(tradeDetails, accountClearingPartyId) {
        switch (tradeDetails.type) {
            case ORDER_TYPE_MARKET:
                return this.submitMarketOrder(tradeDetails, accountClearingPartyId);
            case ORDER_TYPE_LIMIT:
                return this.submitLimitOrder(tradeDetails);
        }
    }
    static async submitMarketOrder(tradeDetails, accountClearingPartyId) {
        return isClearingAccount(accountClearingPartyId) ? this.submitExecuteQuoteRequest(tradeDetails)
                                                         : this.submitGeminiMarketOrder(tradeDetails)        
    }
    static async submitExecuteQuoteRequest(tradeDetails) {
        try {        
            const payload = this.generateExecuteQuoteRequestPayload(tradeDetails);
            const response = await api.geminiExecuteQuote(payload);
            const orderSubmissionDetails = this.handleExecuteQuoteResponse(response);

            return {...tradeDetails, ...orderSubmissionDetails};
        }
        catch (error) {
            return this.handleSubmitOrderError(error);
        }
    }
    static generateExecuteQuoteRequestPayload(tradeDetails) {
        // HT - Not specifying "quantity" because getQuote always uses amount and not quantity
        return {
            "account_id"    : tradeDetails.accountId,
            "quote_id"      : tradeDetails.quoteId,
            "symbol"        : tradeDetails.currency.abbr,            
            "amount"        : tradeDetails.tradeAmount,
            "side"          : tradeDetails.direction,
            "onramp_price"  : tradeDetails.clearingOnrampPrice
        }
    }
    static handleExecuteQuoteResponse(res) {        
        if (res?.status === 200) {
            return (SHOW_TRADE_CONFIRMATION, this.getConfirmationDetails(res.data, SHOW_TRADE_CONFIRMATION));
        }
        else {
            return this.getConfirmationDetails(res.data, SHOW_TRADE_ERROR);
        }                
    }
    static async submitGeminiMarketOrder(tradeDetails) {
        try {        
            const response = await api.placeGeminiOrder(
                tradeDetails.accountId,
                tradeDetails.currency.abbr,
                tradeDetails.unitCost,
                tradeDetails.unitCount,
                tradeDetails.direction,
            );
            const orderSubmissionDetails = this.handleTradeResponse(response);

            return {...orderSubmissionDetails, ...tradeDetails};
        }
        catch (error) {
            return this.handleSubmitOrderError(error);
        }
    }
    static async submitLimitOrder(tradeDetails) {
        try
        {
            const expiry = this.calculateExpiry(tradeDetails.timeInForce);
            const response = await api.placeGeminiLimitOrder(
                tradeDetails.accountId,
                tradeDetails.currency.abbr,
                tradeDetails.unitCost,
                tradeDetails.unitCount,
                tradeDetails.direction,
                expiry
            )
            const orderSubmissionDetails = this.handleTradeResponse(response);

            return {...orderSubmissionDetails, ...tradeDetails};
        }
        catch (error) {
            return this.handleSubmitOrderError(error);
        }
    }
    static handleTradeResponse(res) {
        if (res?.data?.order_id !== null) {
            return (SHOW_TRADE_CONFIRMATION, this.getConfirmationDetails(res.data, SHOW_TRADE_CONFIRMATION));
        }

        return this.getConfirmationDetails(res.data, SHOW_TRADE_ERROR);
    }
    static getConfirmationDetails(data, status) {        
        return {
            'unitCountExecuted': data.executed_quantity || null,
            'transaction': data.custodian_order_id || null,
            'modalType': status
        }
    }
    static calculateExpiry(timeInForce) {
        let now = new Date();
        return timeInForce === TIF_DAY ?
            // last second of current day
            new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) - 1 :
            // [NOTE]: Standard convention for GTC is to expiry automatically after at most 90 days
            null;
    }
}