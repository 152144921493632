import { PROVIDER_ID_TO_BACKEND_AVAILABLE_ASSETS_PROVIDER_MAP, UNDEFINED_ACCOUNT_ID, UNDEFINED_SUBACCOUNT_ID,
  ALLOCATION_SYMBOL_TYPE_MODEL, MODEL_ALLOCATION_RESPONSE_ADVISOR_MODELS_KEY, ASSIGNMENT_UI_ADVISOR_ALLOCATION_NAME_MAX_LENGTH,
  ALLOCATION_SYMBOL_TYPE_FIRM, ALLOCATION_SYMBOL_TYPE_AM_MODEL, MODEL_ALLOCATION_RESPONSE_AM_MODELS_KEY,
  ASSIGNMENT_UI_ASSET_MANAGEMENT_FIRM_NAME_MAX_LENGTH, ASSIGNMENT_UI_ASSET_MANAGEMENT_MODEL_NAME_MAX_LENGTH,
  MODEL_AVAILABLE_ASSETS_INTERSECTION, ACCOUNT_SUBACCOUNT_ID_DELIMITER, ASSET_MANAGER_ALLOCATION_SYMBOL_TYPES,
  MODEL_ACCESS_PERMISSION } from "@/model-constants.js";
import { truncateWithEllipsis } from "@/string_operations.js";
import { DEFAULT_RESERVE_PERCT, SUPPORTED_REBALANCE_TYPES, SUPPORTED_REBALANCE_FREQS, PERCT_TYPE, REBALANCE_FREQ_NA } from "@/static_contents.js";

// The endpoints return the provider ID in two format:
//  1. Numeric
//  2. String
// This function converts a numeric provider ID
export function convertProviderIdToBackendAvailableAssetsProviderId(providerId) {
  return PROVIDER_ID_TO_BACKEND_AVAILABLE_ASSETS_PROVIDER_MAP.get(providerId);
}

export function convertProviderIdsToBackendAvailableAssetsProviderIds(providerIds) {  
  const backendAvailableAssetsProviderIds = new Set();

  providerIds.forEach(providerId => {
    const backendId = convertProviderIdToBackendAvailableAssetsProviderId(providerId);

    if (backendId !== undefined) {
      backendAvailableAssetsProviderIds.add(backendId);
    }    
  });

  return Array.from(backendAvailableAssetsProviderIds);
}

// The endpoints return the provider ID in two format:
//  1. Numeric
//  2. String
// This function takes the portfolio custodians (which is already in a string format)
export function convertPortfolioCustodiansToBackendAvailableAssetsProviderIds(portfolioCustodians) {
  if (portfolioCustodians?.length) {
    return portfolioCustodians.length === 1 ? portfolioCustodians[0]
                                            : MODEL_AVAILABLE_ASSETS_INTERSECTION;
  }

  return undefined;
}

export function generateAccountDataKey(account_id, subaccount_id) {  
  return `${account_id ? account_id : UNDEFINED_ACCOUNT_ID}${ACCOUNT_SUBACCOUNT_ID_DELIMITER}${subaccount_id ? subaccount_id : UNDEFINED_SUBACCOUNT_ID}`;
}

export function parseAccountDataKey(key) {
  const parts         = key.split(ACCOUNT_SUBACCOUNT_ID_DELIMITER);
  let accountId       = parseInt(parts[0]);
  let subaccountId    = parseInt(parts[1]);

  accountId     = isNaN(accountId)    ? UNDEFINED_ACCOUNT_ID    : accountId;
  subaccountId  = isNaN(subaccountId) ? UNDEFINED_SUBACCOUNT_ID : subaccountId;

  return {
    "account_id"    : accountId,
    "subaccount_id" : subaccountId
  }
}

export function isValidId(id, undefinedId) {
  return id !== undefinedId && id !== undefined && id !== null;
}

export function isValidAccountId(id) {
  return isValidId(id, UNDEFINED_ACCOUNT_ID);
}

export function isValidSubaccountId(id) {
  return isValidId(id, UNDEFINED_SUBACCOUNT_ID);
}

export function generateUserFriendlyAllocations(allocations, allKnownSymbols) {  
  const friendlyAllocations = structuredClone(allocations);

  if (allKnownSymbols) {
    friendlyAllocations.forEach(allocation => {
      allocation.asset_symbol = getUserFriendlyAssetSymbol(allocation, allKnownSymbols);
    });
  }

  return friendlyAllocations;
}

export function getUserFriendlyAssetSymbol(allocation, allKnownSymbols) {
  let friendlyName = allocation.asset_symbol;

  if (allKnownSymbols) {
    switch (allocation.asset_type) 
    {
      case ALLOCATION_SYMBOL_TYPE_MODEL:
      {
        const matchingEntry = allKnownSymbols[MODEL_ALLOCATION_RESPONSE_ADVISOR_MODELS_KEY].find(entry => entry.symbol === allocation.asset_symbol);

        if (matchingEntry) {
          friendlyName = truncateWithEllipsis(matchingEntry.name, ASSIGNMENT_UI_ADVISOR_ALLOCATION_NAME_MAX_LENGTH);
        }

        break;
      }

      case ALLOCATION_SYMBOL_TYPE_FIRM:
      case ALLOCATION_SYMBOL_TYPE_AM_MODEL:
      {
        const matchingEntry = allKnownSymbols[MODEL_ALLOCATION_RESPONSE_AM_MODELS_KEY].find(entry => entry.symbol === allocation.asset_symbol);

        if (matchingEntry) {
          friendlyName = truncateWithEllipsis(matchingEntry.firm_name, ASSIGNMENT_UI_ASSET_MANAGEMENT_FIRM_NAME_MAX_LENGTH) + " - " + truncateWithEllipsis(matchingEntry.model_name, ASSIGNMENT_UI_ASSET_MANAGEMENT_MODEL_NAME_MAX_LENGTH);
        }

        break;  
      }        
    }
  }  

  return friendlyName;
}

export function generateEditSettingsData(cashReserveAmount, cashReserveType, rebalanceType, rebalanceFrequency) {
  return {
    "cash_reserve_amount" : cashReserveAmount,
    "cash_reserve_type"   : cashReserveType,
    "rebalance_type"      : rebalanceType,
    "rebalance_frequency" : rebalanceFrequency
  }
}

export function generateDefaultEditSettingsData() {
  return generateEditSettingsData(DEFAULT_RESERVE_PERCT, PERCT_TYPE.value, SUPPORTED_REBALANCE_TYPES[0].code, SUPPORTED_REBALANCE_FREQS[0].code);  
}

export function generateEditSettingsDataFromEndpointResponse(reserveType, dollarCashReserved, pctCashReserved, rebalanceType, rebalanceFreq) {
  // The endpoint to get account-settings returns the following properties:
  //   - cash_reserved_type
  //   - dollar_cash_reserved
  //   - pct_cash_reserved
  //   - rebalance_frequency
  //   - rebalance_type
  const reserveAmount = reserveType === PERCT_TYPE.value ? pctCashReserved 
                                                         : dollarCashReserved;

  return generateEditSettingsData(reserveAmount.toString(), reserveType, rebalanceType, rebalanceFreq);
}

export function getCashReserveAmountForDisplay(amount, type) {
  if (amount !== undefined || amount !== null && type !== undefined || type !== null) {
    return type === PERCT_TYPE.value ? amount + "%" : "$" + amount;
  }

  return "";
}

export function getRebalanceTypeForDisplay(type) {
  if (type) {
    const matchingEntry = SUPPORTED_REBALANCE_TYPES.find(entry => entry.code === type);

    if (matchingEntry) {
      return matchingEntry.text;
    }
  }

  return "";
}

export function getRebalanceFrequencyForDisplay(frequency) {
  if (frequency) {
    const matchingEntry = SUPPORTED_REBALANCE_FREQS.find(entry => entry.code === frequency);

    if (matchingEntry) {
      return matchingEntry.title;
    }
  }

  return REBALANCE_FREQ_NA.title;
}

export function getNumAssetManagerAllocations(allocations) {
  return (allocations || []).filter(allocation => ASSET_MANAGER_ALLOCATION_SYMBOL_TYPES.includes(allocation.asset_type)).length;
}

export function generateBackendTarget(accountId, subaccountId) {  
  return {
    "account_id"    : isValidAccountId(accountId)       ? accountId     : null,
    "subaccount_id" : isValidSubaccountId(subaccountId) ? subaccountId  : null
  }  
}

export function generateBackendPortfolio(id, type) {
  return {
    "portfolio_id"    : id,
    "portfolio_type"  : type
  }
}

export function userHasFullModelPermission(userProfile) {
  return userProfile?.permissions?.model_permission.id === MODEL_ACCESS_PERMISSION.FULL_ACCESS;
}

export function userHasViewModelPermission(userProfile) {
  return userHasFullModelPermission(userProfile) || userProfile?.permissions?.model_permission.id === MODEL_ACCESS_PERMISSION.VIEW_ONLY;
}

export function convertCustodianListToCacheKey(custodians) {
  return custodians ? custodians.join(",") : "";
}
