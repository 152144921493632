import * as types from "../types";

export default {
	[types.PRICE_MUT_UPDATE]: (state, payload) => {		
		state.current_price = payload.price;
		state.last_updated = payload.last_updated;
	},
	[types.PRICE_MUT_IS_EARLY_UPGRADE]: (state, payload) => {
		state.is_early_upgrade = payload.isEarlyUpgrade;
	},
};