<template>
    <div class="reset-password-enter-email__container">
        <Banner/>
        <div class="reset-password-enter-email__content-container">
            <div class="divider-2" />
            <HeaderFourBenton value="Something's not right." />
            <div class="divider-1" />
            <ErrorLabel  :value="`Please contact support@onrampinvest.com with the error message: ${$route.params.msg}`" />
            <div class="divider-2" />
            <v-form ref="form" lazy-validation>
                <div class="reset-password-enter-email__content">
                    <div class="reset-password-enter-email__interaction-divider">
                        <v-divider class="reset-password-enter-email__interaction-divider-left"></v-divider>OR<v-divider class="reset-password-enter-email__interaction-divider-right"></v-divider>
                    </div>
                    <SecondaryButton
                        text="Return to Login"
                        :loading="isProcessing"
                        @btnClick="onReturnToLoginClicked"
                    />
                    <div class="divider-3" />
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import {MAIN_ACT_LOGOUT} from "@/store/types";
import HeaderFourBenton from "@/components/Typographical/Header/Header-four";
import ErrorLabel from "@/components/Typographical/Label/index.vue"
import SecondaryButton from "@/components/Buttons/Secondary";
import Banner from "@/components/Branding/banner";

export default {
    components: {
        Banner,
        HeaderFourBenton,
        ErrorLabel,
        SecondaryButton,
    },
    props: {
        isProcessing: { type: Boolean, default: false },
    },
    methods: {
        ...mapActions({
            logout: "main/" + MAIN_ACT_LOGOUT,
        }),
    
        onReturnToLoginClicked() {
            this.logout();
        },
    },
}
</script>

<style lang="scss" scoped>
    .reset-password-enter-email__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .reset-password-enter-email__content-container {
        width: 500px;
    }
    .reset-password-enter-email__interaction-divider {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 12px 0;
    }
    .reset-password-enter-email__interaction-divider-left {
        margin-right: 10px;
    }
    .reset-password-enter-email__interaction-divider-right {
        margin-left: 10px;
    }
    .divider-1 {
        margin-top: 12px;
    }
    .divider-2 {
        margin-top: 24px;
    }
    .divider-3 {
        margin-top: 12px;
    }
</style>
