import {
    GETTER_ALL_TRADE_ORDERS,
    GETTER_OPEN_TRADE_ORDERS,
    GETTER_ALL_ORDERS_FOR_ADVISOR_CLIENT,
    GETTER_ALL_ORDERS_FOR_ADVISOR_ACCOUNT,
    GETTER_OPEN_ORDERS_FOR_ADVISOR_CLIENT,
    GETTER_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT,
    GETTER_BATCH_TRADE_ORDERS,
    GETTER_BATCH_TRADE_PRICES,
    GETTER_REVIEWABLE_BATCHES,
    GETTER_REVIEWABLE_BATCH_MODELS,
    GETTER_STAGED_REVIEWABLE_BATCHES,
    GETTER_APPROVED_BATCHES,
    GETTER_APPROVED_BATCH_MODELS,
    GETTER_PENDING_TRADE_BATCHES,
    GETTER_EXECUTABLE_TRADE_BATCHES,
    GETTER_EXECUTED_BATCHES,
    GETTER_EXECUTED_BATCH_MODELS,
    GETTER_EXECUTION_ACCEPTED_TRADE_BATCHES, GETTER_EXECUTED_BATCH_STATUS,
    GETTER_ORDER_MAKER_PRICE_SOURCE
} from "./types";

import { get } from "lodash";
export default {
    [GETTER_ALL_TRADE_ORDERS]: (state) => {
        return state.allTradeOrders
    },
    [GETTER_OPEN_TRADE_ORDERS]: (state) => {
        return state.openTradeOrders
    },
    [GETTER_ALL_ORDERS_FOR_ADVISOR_CLIENT]: (state) => {
        return state.allTradeOrdersForClient;
    },
    [GETTER_ALL_ORDERS_FOR_ADVISOR_ACCOUNT]: (state) => {
        return state.allTradeOrdersForAccount;
    },
    [GETTER_OPEN_ORDERS_FOR_ADVISOR_CLIENT]: (state) => {
        return state.openTradeOrdersForClient;
    },
    [GETTER_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT]: (state) => {
        return state.openTradeOrdersForAccount;
    },
    [GETTER_BATCH_TRADE_ORDERS]: (state) => {
        return state.batchTradeOrders;
    },
    [GETTER_BATCH_TRADE_PRICES]: (state) => {
        return state.batchTradePrices;
    },
    [GETTER_REVIEWABLE_BATCHES]: (state) => {
        return get(state.reviewableBatches, 'batches', {});
    },
    [GETTER_REVIEWABLE_BATCH_MODELS]: (state) => {
        return get(state.reviewableBatches, 'models', {});
    },
    [GETTER_STAGED_REVIEWABLE_BATCHES]: (state) => {
        return state.stagedReviewableBatches;
    },
    [GETTER_PENDING_TRADE_BATCHES]: (state) => {
        return state.pendingTradeBatches;
    },
    [GETTER_APPROVED_BATCHES]: (state) => {
        return get(state.approvedBatches, 'batches', {});
    },
    [GETTER_EXECUTED_BATCHES]: (state) => {
        return get(state.executedBatches, 'batches', {});
    },
    [GETTER_APPROVED_BATCH_MODELS]: (state) => {
        return get(state.approvedBatches, 'models', {});
    },
    [GETTER_EXECUTED_BATCH_MODELS]: (state) => {
        return get(state.executedBatches, 'models', {});
    },
    [GETTER_EXECUTABLE_TRADE_BATCHES]: (state) => {
        return state.executableBatches;
    },
    [GETTER_EXECUTION_ACCEPTED_TRADE_BATCHES]: (state) => {
        return state.executionAcceptedBatches;
    },
    [GETTER_EXECUTED_BATCH_STATUS]: (state) => {
        return state.executedTradeBatchStatus;
    },
    [GETTER_ORDER_MAKER_PRICE_SOURCE]: (state) => {
        return state.orderMakerPriceSource;
    },
};
