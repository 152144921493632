import { api } from "@/api/user-preferences-api";
import { MAIN_MUT_ADD_NOTIFICATION } from "@/store/types";
import {
    ACTION_GET_USER_PREFERENCES,
    ACTION_PERSIST_USER_PREFERENCES,
    ACTION_UPDATE_USER_PREFERENCES,
    ACTION_SET_USER_PREFERENCES,
    MUTATION_USER_PREFERENCES
} from "@/store/userPreferences/types";

export default {
    [ACTION_GET_USER_PREFERENCES]: async({ commit }) => {
        return Promise.all([
            api.getUserPreferences(),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(([result, _timeout]) => {
            commit(MUTATION_USER_PREFERENCES, result.data);
        }).catch((_error) => {
            commit(MAIN_MUT_ADD_NOTIFICATION, {
                content: `Failed to get user preferences`,
                color: "error",
            });
        });
    },
    [ACTION_PERSIST_USER_PREFERENCES]: async(context, preferences) => {
        return preferences?.id == null ? context.dispatch(ACTION_SET_USER_PREFERENCES, preferences)
                                       : context.dispatch(ACTION_UPDATE_USER_PREFERENCES, preferences);
    },
    [ACTION_SET_USER_PREFERENCES]: async({ commit }, userPreferenceChange) => {
        return Promise.all([
            api.setUserPreferences(userPreferenceChange),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(([result, _timeout]) => {
            commit(MUTATION_USER_PREFERENCES, result.data);
        }).catch((_error) => {
            commit(MAIN_MUT_ADD_NOTIFICATION, {
                content: `Failed to set user preferences for ${userPreferenceChange}`,
                color: "error",
            });
        });
    },
    [ACTION_UPDATE_USER_PREFERENCES]: async({ commit }, userPreferenceChange) => {
        return Promise.all([
            api.updateUserPreferences(userPreferenceChange),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(([result, _timeout]) => {
            commit(MUTATION_USER_PREFERENCES, result.data);
        }).catch((_error) => {
            commit(MAIN_MUT_ADD_NOTIFICATION, {
                content: `Failed to set user preferences for ${userPreferenceChange}`,
                color: "error",
            });
        });
    },
};