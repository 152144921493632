import actions  from './actions';
import getters from './getters';
import mutations  from './mutations';


const state = {
    advisorHeldawayAccounts: [],
    organizationUsers: [],
    formPartOne: null,
    formPartTwo: null,
    formPartThree: null,
    selectedCustodian: null,
    optionalAgreement: null,
    canMoveBack: false,
    formSet: null,
    advisorClients: [],
    clients: [],
    accounts:[],    
    leftNavNote: false,
    aum: null,
    dyn_aum: null,
    num_clients: null,
    numHeldawayAccounts: 0,
    num_accounts: null,
    curr_act_detail: null,
    curr_client_detail: {
        phone: '',
        email: '',
        first_name: '',
        last_name: '',
        birth_date: '',
        accounts: [],

    },
    one_day_cli_diff: null,
    five_day_cli_diff: null,
    mtd_cli_diff: null,
    qtd_cli_diff: null,
    prev_q_cli_diff: null,
    ytd_cli_diff: null,
    one_y_cli_diff: null,
    one_day_act_diff: null,
    five_day_act_diff: null,
    mtd_act_diff: null,
    qtd_act_diff: null,
    prev_q_act_diff: null,
    ytd_act_diff: null,
    one_y_act_diff: null,
    chart_data:[],
    aum_diff_1d: null,
    aum_diff_filter:null,
    newAccounts:[],
    totalHeldawayAccountAssetsFormatted: "0.00",
    totalHeldawayAccountAssetsNumeric: 0,
    totalAdvisorAccountAssetsFormatted: "0.00",
    totalAdvisorAccountAssetsNumeric: 0,
    dashboardFirmTotalsNumberOfClients: 0,
    dashboardFirmTotalsNumberOfAccounts: 0,
    firm_onboarding_info    : null,
    firm_onboarding_info_loading: true,    
    amDashboardAccountData  : {},
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
