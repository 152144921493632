import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	current_price: "",
	is_early_upgrade: false,
	last_updated: "",
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};