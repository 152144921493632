import { api } from "../../api";
import * as types from "../types";
import { AssetManagerApi } from "@/api/asset-manager-api";

export default {
    [types.ORGANIZATION_ACT_GET_USERS]: async (context) => {
        try {
            const response = await api.getGroupUsers();
            if (response) {
                context.commit(types.ORGANIZATION_MUT_SET_USERS, response.data);
            }
        } catch (error) {
            await context.dispatch(types.MAIN_ACT_CHECK_API_ERROR, error);
        }
    },
    //@deprecated 
    [types.ORGANIZATION_ACT_GET_STATS]: async (context, payload = null) => {
        let filterValue = payload ? payload.filter : null;
        try {
            const response = (
                await Promise.all([
                    api.getStats(filterValue),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            context.commit(types.ORGANIZATION_MUT_SET_STATS, {
                aum: response.data.aum,
                num_accounts: response.data.num_accounts,
                num_clients: response.data.num_clients,
                one_day_cli_diff: response.data.one_day_cli_diff,
                five_day_cli_diff: response.data.five_day_cli_diff,
                mtd_cli_diff: response.data.mtd_cli_diff,
                qtd_cli_diff: response.data.qtd_cli_diff,
                prev_q_cli_diff: response.data.prev_q_cli_diff,
                ytd_cli_diff: response.data.ytd_cli_diff,
                one_y_cli_diff: response.data.one_y_cli_diff,
                one_day_act_diff: response.data.one_day_act_diff,
                five_day_act_diff: response.data.five_day_act_diff,
                mtd_act_diff: response.data.mtd_act_diff,
                qtd_act_diff: response.data.qtd_act_diff,
                prev_q_act_diff: response.data.prev_q_act_diff,
                ytd_act_diff: response.data.ytd_act_diff,
                one_y_act_diff: response.data.one_y_act_diff,
            });
            return response.data;
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.ORGANIZATION_GET_FORM_PERFORMANCE_DELTAS]: async (context, payload = null) => {
        let filterValue = payload ? payload.filter : null;
        const accountGroupIds = (filterValue?.id || []).filter(Boolean);
        try {
            const response = (
                await Promise.all([
                    api.getFirmPerformanceDeltas(accountGroupIds),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            context.commit(types.ORGANIZATION_MUT_SET_STATS, {
                aum: response.data.aum,
                num_accounts: response.data.num_accounts,
                num_clients: response.data.num_clients,
                one_day_cli_diff: response.data.one_day_cli_diff,
                five_day_cli_diff: response.data.five_day_cli_diff,
                mtd_cli_diff: response.data.mtd_cli_diff,
                qtd_cli_diff: response.data.qtd_cli_diff,
                prev_q_cli_diff: response.data.prev_q_cli_diff,
                ytd_cli_diff: response.data.ytd_cli_diff,
                one_y_cli_diff: response.data.one_y_cli_diff,
                one_day_act_diff: response.data.one_day_act_diff,
                five_day_act_diff: response.data.five_day_act_diff,
                mtd_act_diff: response.data.mtd_act_diff,
                qtd_act_diff: response.data.qtd_act_diff,
                prev_q_act_diff: response.data.prev_q_act_diff,
                ytd_act_diff: response.data.ytd_act_diff,
                one_y_act_diff: response.data.one_y_act_diff,
            });
            return response.data;
        } catch (error) {      
            // Do nothing      
        }
    },
    [types.ORGANIZATION_ACT_GET_CLIENT_STATS]: async (context) => {
        try {
            const response = (
                await Promise.all([
                    api.getClientStats(),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            context.commit(types.ORGANIZATION_MUT_SET_CLIENT_STATS, {
                aum: response.data.aum,
                account_list: response.data.account_list,
            });
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.ORGANIZATION_ACT_DETAIL]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.actDetail(payload.account_id),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            context.commit(
                types.ORGANIZAITON_MUT_ACT_DETAIL,
                response.data
            );
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }
    },
    [types.ORGANIZATION_CLIENT_DETAIL]: async (context, payload) => {
        try {
            const response = (
                await Promise.all([
                    api.clientDetail(payload.client_id),
                    await new Promise((res) => setTimeout(res, 500)),
                ])
            )[0];
            context.commit(
                types.ORGANIZATION_MUT_CLIENT_DETAIL,
                response.data
            );
            //return response
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
        }

    },
    [types.ORGANIZATION_ADVISOR_CHART]: async (context, payload) => {
        let returnData = {chart_data: [], failedResponse: false};

        try {
            const response = (
                await Promise.all([
                    api.getAdvisorChart(payload.filter),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            returnData = {chart_data: response.data, failedResponse: false};
            context.commit(types.ORGANIZATION_MUT_CHART, returnData);
            return returnData;
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
            returnData = {chart_data: [], failedResponse: true};
            context.commit(types.ORGANIZATION_MUT_CHART, returnData);
            return returnData;  
        }
    },
    [types.ORGANIZATION_CLIENT_CHART]: async (context, payload) => {
        let returnData = {chart_data: [], failedResponse: false};
        try {
            const response = (
                await Promise.all([
                    api.getClientChart(
                        payload.filter,
                        payload.client_id
                    ),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            returnData = {chart_data: response.data.balance_over_time, failedResponse: false};
            context.commit(types.ORGANIZATION_MUT_CHART, returnData);
            return returnData;
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
            returnData = {chart_data: [], failedResponse: true};
            context.commit(types.ORGANIZATION_MUT_CHART, returnData);
            return returnData;
        }
    },
    [types.ORGANIZATION_ACCOUNT_CHART]: async (context, payload) => {
        let returnData = {chart_data: [], failedResponse: false};
        try {
            const response = (
                await Promise.all([
                    api.getAccountChart(
                        payload.filter,
                        payload.account_id
                    ),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];
            returnData = {chart_data: response.data, failedResponse: true};
            context.commit(types.ORGANIZATION_MUT_CHART, returnData);
            return returnData;
        } catch (error) {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to pull act. info.",
                color: "error",
            });
            returnData = {chart_data: [], failedResponse: true};
            context.commit(types.ORGANIZATION_MUT_CHART, returnData);
            return returnData;

        }
    },
    [types.ORGANIZATION_GET_ADVISOR_FEATURE]: async (context, payload) => {        
        try {            
            if (context.state.firm_onboarding_info === null) {                
                context.state.firm_onboarding_info_loading = true;
                context.commit(types.ORGANIZATION_MUT_SET_ADVISOR_FEATURE, {});
                const response = (await Promise.all([api.getAdvisorFeatures(), await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),]))[0];
                context.commit(types.ORGANIZATION_MUT_SET_ADVISOR_FEATURE, response.data);                
                context.state.firm_onboarding_info_loading = false;
            }   
            else return context.state.firm_onboarding_info;                
        } catch (error) {
            // this does nothing
        }
    },
    [types.ORGANIZATION_ACT_NEW_ACCOUNT_STATUS]: async (context) => {
        return Promise.all([
            api.getAccountStatuses(),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(([result, _timeout]) => {
           context.commit(types.ORGANIZATION_MUT_SET_NEW_ACCOUNT_STATUS, result.data);
        }).catch((_error) => {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: `Failed to get account statuses`,
                color: "error",
            });
        });
    },
    [types.ORGANIZATION_RESET_NEW_CLIENT_ACCOUNT_FORM]: (context) => {
        context.commit(types.ORGANIZATION_NEW_FORM_ONE, null);
        context.commit(types.ORGANIZATION_NEW_FORM_TWO, null);
        context.commit(types.ORGANIZATION_NEW_FORM_THREE, null);
        context.commit(types.ORGANIZATION_MUT_SELECTED_CUSTODIAN, null);
        context.commit(types.ORGANIZATION_MUT_ADV_AGREEMENT, null);
        context.commit(types.ORGANIZATION_SET_FORM, null);
    },
    [types.ORGANIZATION_GET_ALL_CLIENTS_FOR_ADVISOR]: (context, advisorID) => {
        return Promise.all([
            api.getAllClientsForAdvisor(advisorID),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(([result, _timeout]) => {
            const clients = result.data;
            context.commit(types.ORGANIZATION_MUT_SET_CLIENTS_FOR_ADVISOR, clients);
            return clients;
        }).catch((_error) => {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to fetch clients for advisor",
                color: "error",
            });
        });
    },
    [types.ORGANIZATION_ACT_GET_ALL_ACCOUNTS_FOR_ADVISOR]: (context, advisorID) => {
        return Promise.all([
            api.getAllAccountsForAdvisor(advisorID),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(([result, _timeout]) => {
            const accounts = result.data;
            context.commit(types.ORGANIZATION_MUT_SET_ACCOUNTS_FOR_ADVISOR, accounts);
            return accounts;

        }).catch((_error) => {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to fetch accounts for advisor",
                color: "error",
            });
        });
    },
    [types.ORGANIZATION_ACT_GET_ADVISOR_TOTAL_AUM]: (context) => {
        return Promise.all([
            api.getAdvisorTotalAum(),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(([result, _timeout]) => {
            context.commit(types.ORGANIZATION_MUT_GET_ADVISOR_TOTAL_AUM, result.data);
        }).catch((_error) => {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to fetch total AUM value for advisor.",
                color: "error",
            })
        })
    },
    [types.ORGANIZATION_GET_ALL_HELDAWAY_ACCOUNTS_FOR_ADVISOR]: (context, advisorID) => {
        return Promise.all([
            api.getAllHeldawayForAdvisor(advisorID),
            new Promise((res) => setTimeout(res, 500)),
        ]).then(([result, _timeout]) => {
            const accounts = result.data;
            context.commit(types.ORGANIZATION_MUT_SET_HELDAWAY_ACCOUNTS_FOR_ADVISOR, accounts);
            return accounts;
        }).catch((_error) => {
            context.commit(types.MAIN_MUT_ADD_NOTIFICATION, {
                content: "Failed to fetch read only accounts for advisor",
                color: "error",
            });
        });
    },
    [types.ORGANIZATION_GET_AM_ACCOUNT_DASHBOARD_DATA]: async (context) => 
    {
        let dashboardData = {data: [], failedResponse: false}
        
        try 
        {
            const response = await AssetManagerApi.getDashboardAccountData();    
            dashboardData.data = response.data;                        
        }         
        catch (error)
        {
            dashboardData.failedResponse = true;
        }
        finally
        {
            context.commit(types.ORGANIZATION_MUT_AM_ACCOUNT_DASHBOARD_DATA, dashboardData);            
        }
    },
    [types.ORGANIZATION_ACT_GET_ORGANIZATION_COMPLIANCE_CONTACT]: async (context, payload) => {
        let data = null;
            
        try
        {
            const response = await api.getOrganizationComplianceContactInformation(payload);
            data = response.data;
        }
        catch (e) 
        {
            data = [];
        }
        finally
        {          
            context.commit(types.ORGANIZATION_MUT_GET_ORGANIZATION_COMPLIANCE_CONTACT, data);
        }                                
    },
    [types.ORGANIZATION_ACT_UPSERT_ORGANIZATION_COMPLIANCE_CONTACT]: async (context, payload) => {
        let data = null;
            
        try
        {
            const response = await api.upsertOrganizationComplianceContactInformation(payload);
            data = response.data;
        }
        catch (e) 
        {
            data = [];
        }
        finally
        {          
            context.commit(types.ORGANIZATION_MUT_GET_ORGANIZATION_COMPLIANCE_CONTACT, data);
        }                                
    },
 };

