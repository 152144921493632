import * as types from "../types";
import {
    formatGainLoss,
    fmtActBal,
    getProviderBaseLogo,
    getProviderName,
    updateBankLinkEnabledAccountArray,
    providerNameMapLookup,
    modelNameLookup,
    mapAccountAccessStatus,
} from "@/utils";
import { HELD_AWAY, TRADABLE } from "@/account_access_types";
import { mapUnixDateToLocaleDate } from "@/services/mappers/common/mapUnixDateToLocaledate.js";
import { cloneDeep, pick, map, get } from "lodash";
import { TICKER_LOOKUP } from "@/constants";
import { Date } from "core-js";

export default {
    [types.ORGANIZATION_GETTER_ORGANIZATION_USERS]: (state) => {
        return state.users;
    },
    [types.ORGANIZATION_GET_CLIENTS_FOR_ADVISOR]:
        (state) => (providersLookup) => {
            return (cloneDeep(state.advisorClients) || []).map((client) => {
                client.providers = client.custodians;
                client.custodians = (client.custodians || []).map(
                    (custodian) => {
                        custodian = providerNameMapLookup(
                            providersLookup,
                            custodian
                        );
                        return custodian;
                    }
                );
                return client;
            });
        },
    [types.ORGANIZATION_GETTER_NUM_HELDAWAY_ACCOUNTS]: (state) => {
        return state.numHeldawayAccounts;
    },
    [types.ORGANIZATION_GETTER_TOTAL_HELDAWAY_ACCOUNTS_ASSETS]: (state) => {
        return state.totalHeldawayAccountAssetsFormatted;
    },
    [types.ORGANIZATION_GETTER_TOTAL_HELDAWAY_ACCOUNTS_ASSETS_NUMERIC]: (
        state
    ) => {
        return state.totalHeldawayAccountAssetsNumeric;
    },
    [types.ORGANIZATION_GETTER_CLIENT_TABLE_SORT]:
        (state) => {
            const clientData = [];
            if (state.clients) {
                for (const client of state.clients) {
                    var fmtClient = new Object();

                    fmtClient.id = client.id;
                    fmtClient.first_name = client.first_name;
                    fmtClient.last_name = client.last_name;
                    fmtClient.email = client.email;

                    if (client.phone_number != null)
                        fmtClient.phone = client.phone_number;
                    else if (client.cell_number != null)
                        fmtClient.phone = client.cell_number;
                    else fmtClient.phone = "Not Available";

                    let act_list = [];
                    // [TODO] check reference to account_holder_id
                    let filtered = state.accounts.filter(
                        (cli_act) => cli_act.account_holder_id === client.id
                    );
                    for (const cli_act of filtered) {
                        act_list.push(cli_act.provider_id);
                    }
                    if (act_list.length == 0)
                        act_list.push("No Accounts Connected");
                    fmtClient.custodians = act_list;
                    fmtClient.total_assets = client.total_assets;
                    fmtClient.cost_basis = client.cost_basis;
                    fmtClient.gain_loss = client.cost_basis === "" ? "--" : client.total_assets - client.cost_basis;

                    clientData.push(fmtClient);
                }
            }
            return clientData;
        },
    [types.ORGANIZATION_GETTER_TOTAL_CLIENT_ASSETS]: (state) => {
        return state.advisorClientsTotalAssetsFormatted;
    },
    [types.ORGANIZATION_GETTER_TOTAL_CLIENT_ASSETS_NUMERIC]: (state) => {
        return state.advisorClientsTotalAssetsNumeric;
    },
    [types.ORGANIZATION_CLIENT_BASIC]: (state) => {
        return state.advisorClients.map((client) => {
            const fmtClient = new Object();
            fmtClient.id = client.id;
            fmtClient.fullName = client.first_name + " " + client.last_name;
            fmtClient.email = client.email;
            fmtClient.icon = client.custodians
                .map((id) => parseInt(id))
                .sort((a, b) => a - b)
                .map(getProviderBaseLogo);
            return fmtClient;
        });
    },
    //FIXME: This method might need some work
    [types.ORGANIZATION_GETTER_ACCOUNTS]: (state) => (providersLookup) => {
        return (state.accounts || []).map((account) => {
            const providerName = providerNameMapLookup(
                providersLookup,
                account.provider_id
            );
            const fmtAccount = new Object();
            fmtAccount.onramp_id = account.id;
            fmtAccount.rep_code = account.rep_code;
            fmtAccount.account_holder =
                account.last_name + ", " + account.first_name;
            fmtAccount.status = account.status;
            fmtAccount.open_date = account.onramp_open_datetime
                ? mapUnixDateToLocaleDate(account.onramp_open_datetime)
                : "Not Available";
            fmtAccount.provider_id = account.provider_id;
            fmtAccount.custodian = providerName;
            fmtAccount.market_value = account.total_assets;
            fmtAccount.cost_basis = account.cost_basis;
            fmtAccount.heldAway = mapAccountAccessStatus(account.status);
            fmtAccount.account_type = account.account_type;
            fmtAccount.account_legal_entity = account.account_legal_entity || "--";
            return fmtAccount;
        });
    },
    [types.ORGANIZATION_GETTER_ACCOUNTS_TABLE]:
        (state) => (providersLookup, allModels) => {
            return (state.accounts || []).map((account) => {
                const providerName = providerNameMapLookup(
                    providersLookup,
                    account.provider_id
                );
                const modelName = modelNameLookup(allModels, account.model_id);
                const fmtAccount = new Object();
                fmtAccount.onramp_id = account.id;
                fmtAccount.rep_code = account.rep_code;
                fmtAccount.firm_name = account.firm_name;
                fmtAccount.account_holder =
                    account.last_name + ", " + account.first_name;
                fmtAccount.status = account.status;
                fmtAccount.open_date = account.onramp_open_datetime
                    ? mapUnixDateToLocaleDate(account.onramp_open_datetime)
                    : "Not Available";
                fmtAccount.provider_id = account.provider_id;
                fmtAccount.custodian = providerName;
                fmtAccount.market_value = account.total_assets;
                fmtAccount.cost_basis = account.cost_basis;
                fmtAccount.gain_loss = account.cost_basis === "" ? "--" : account.total_assets - account.cost_basis;
                fmtAccount.model_id = modelName;
                fmtAccount.cash = account.cash;
                fmtAccount.buying_power = account.buying_power;
                fmtAccount.pct_cash_reserved = account.pct_cash_reserved;
                // [TODO] check reference to account holder id
                fmtAccount.account_holder_id = account.client_id;
                fmtAccount.rebalance_type = account.rebalance_type || "--";
                fmtAccount.heldAway = mapAccountAccessStatus(account.status);
                fmtAccount.account_type = account.account_type;
                fmtAccount.account_legal_entity = account.account_legal_entity || "--";
                return fmtAccount;

            });
        },
    [types.ORGANIZATION_GETTER_TOTAL_ACCOUNT_ASSETS_NUMERIC]: (state) => {
        return state.totalAdvisorAccountAssetsNumeric;
    },
    [types.ORGANIZATION_GETTER_TOTAL_ACCOUNT_ASSETS]: (state) => {
        return state.totalAdvisorAccountAssetsFormatted;
    },
    [types.ORGANIZATION_GETTER_NUM_ACCOUNTS]: (state) => {
        return state.num_accounts;
    },
    [types.ORGANIZATION_GETTER_NUM_CLIENTS]: (state) => {
        return state.num_clients;
    },
    [types.ORGANIZATION_GETTER_DASHBOARD_FIRM_TOTALS_NUMBER_OF_CLIENTS]: (
        state
    ) => {
        return state.dashboardFirmTotalsNumberOfClients;
    },    
    [types.ORGANIZATION_GETTER_DASHBOARD_FIRM_TOTALS_NUMBER_OF_ACCOUNTS]: (
        state
    ) => {
        return state.dashboardFirmTotalsNumberOfAccounts;
    },
    [types.ORGANIZATION_GETTER_CLI_ACT_DIFFS]: (state) => {
        return {
            one_day_cli_diff: state.one_day_cli_diff,
            five_day_cli_diff: state.five_day_cli_diff,
            mtd_cli_diff: state.mtd_cli_diff,
            qtd_cli_diff: state.qtd_cli_diff,
            prev_q_cli_diff: state.prev_q_cli_diff,
            ytd_cli_diff: state.ytd_cli_diff,
            one_y_cli_diff: state.one_y_cli_diff,
            one_day_act_diff: state.one_day_act_diff,
            five_day_act_diff: state.five_day_act_diff,
            mtd_act_diff: state.mtd_act_diff,
            qtd_act_diff: state.qtd_act_diff,
            prev_q_act_diff: state.prev_q_act_diff,
            ytd_act_diff: state.ytd_act_diff,
            one_y_act_diff: state.one_y_act_diff,
        };
    },
    [types.ORGANIZATION_GETTER_AUM]: (state) => {
        if (state.aum) {
            return fmtActBal(state.aum, 2);
        } else {
            return state.aum;
        }
    },
    [types.ORGANIZATION_GETTER_UNFORMATTED_AUM]: (state) => {
        return state.aum;
    },
    [types.ORGANIZATION_GETTER_BOT_GRAPH_CHART_DATA]: (state) => {        
        return state.chart_data;        
    },
    [types.ORGANIZATION_GETTER_DYN_AUM]: (state) => {
        if (state.dyn_aum != null) {
            return fmtActBal(state.dyn_aum, 2);
        } else {
            return state.dyn_aum;
        }
    },
    [types.ORGANIZATION_GETTER_AUM_DIFF_FILTER]: (state) => {
        if (state.aum_diff_filter) {
            return fmtActBal(state.aum_diff_filter, 2, true);
        } else {
            return state.aum_diff_filter;
        }
    },
    [types.ORGANIZATION_GETTER_UNFORMATTED_AUM_DIFF_FILTER]: (state) => {
        return state.aum_diff_filter;
    },
    [types.ORGANIZATION_GETTER_AUM_DIFF_1D]: (state) => {
        if (state.aum_diff_1d) {
            return fmtActBal(state.aum_diff_1d, 2, true);
        } else {
            return state.aum_diff_1d;
        }
    },
    [types.ORGANIZATION_GETTER_ACT_CLIENT_INFO]: (
        state,
        rootGetters,
        rootState,
        getters
    ) => {
        var actGenInfo = new Object();
        // providersList for formatting
        var custodianAccountArr = [];
        var providersListArr = [];
        let providerGetter = types.LOOKUP_PREFIX + types.LOOKUP_GET_PROVIDERS;
        providersListArr = getters[providerGetter];
        // providersList for formatting - end
        if (state.curr_act_detail) {
            actGenInfo.client =
                state.curr_act_detail.last_name +
                ", " +
                state.curr_act_detail.first_name;
            actGenInfo.accountInceptionDate =
                state.curr_act_detail.onramp_open_datetime;
            actGenInfo.clearingPartyId = state.curr_act_detail.clearing_party_id;
            actGenInfo.type = state.curr_act_detail.account_type;
            actGenInfo.repcode = state.curr_act_detail.repcode[0].label;
            actGenInfo.status = state.curr_act_detail.status;
            actGenInfo.provider = state.curr_act_detail.provider_id;
            actGenInfo.id = state.curr_act_detail.id;
            actGenInfo.openDate = state.curr_act_detail.onramp_open_datetime
                ? mapUnixDateToLocaleDate(
                    state.curr_act_detail.onramp_open_datetime
                )
                : "Not Available";
            if (state.curr_act_detail?.custodian_account_id) {
                let custodian_account_obj = {
                    'custodian_account_id': state.curr_act_detail.custodian_account_id,
                    'provider_id': state.curr_act_detail.provider_id
                }
                custodianAccountArr = updateBankLinkEnabledAccountArray(
                    providersListArr,
                    [custodian_account_obj],
                    state.curr_act_detail.first_name,
                    state.curr_act_detail.last_name
                );
                actGenInfo.custodianAccount = custodianAccountArr;
            }
            actGenInfo.model_name = state.curr_act_detail.portfolio_name;
            actGenInfo.account_legal_entity = state.curr_act_detail.account_legal_entity;
            actGenInfo.organizationId = state.curr_act_detail.organization_id;
        }
        if (state.advisorClients) {
            for(const c of state.advisorClients){

                if (c.account_id === actGenInfo.id){
                    actGenInfo.account_holder = c.id
                }
            }
        }
        return actGenInfo
    },
    [types.ORGANIZATION_GETTER_ACT_OTHER_ACTS]:
        (state) => (providersLookup) => {
            return (state.curr_act_detail?.other_accounts || [])
                .filter(Boolean)
                .sort((a, b) => b.balance - a.balance)
                .map((account) => {
                    const accountView = new Object();
                    accountView.provider_name = getProviderName(
                        account.provider_id,
                        providersLookup
                    );
                    accountView.icon = getProviderBaseLogo(account.provider_id);
                    accountView.provider = account.provider_id;
                    accountView.id = account.id;
                    accountView.value = fmtActBal(account.balance);
                    accountView.heldAway = account.status.is_account_held_away;
                    return accountView;
                });
        },
    [types.ORGANIZATION_GETTER_ACT_POSITIONS]: (state) => {
        return (state.curr_act_detail.asset || [])
            .filter((asset) => asset.balance !== 0 || asset.cold_balance !== 0)
            .map((asset) => {
                let gain_loss = isNaN(parseFloat(asset.cost_basis)) ? "" : asset.balance + asset.cold_balance - asset.cost_basis;
                const position = pick(asset, ["id", "currency"]);
                position.status = asset.status;
                position.rawBalance = asset.balance;
                position.balance = fmtActBal(asset.balance + asset.cold_balance);
                position.quantity = asset.quantity + asset.cold_quantity;
                position.costBasis = formatGainLoss(asset.cost_basis);
                position.gainLoss = formatGainLoss(gain_loss);
                position.currencyName = get(
                    TICKER_LOOKUP,
                    asset.currency,
                    asset.currency
                );
                const lowerCaseTicker = asset.currency.toLowerCase();
                try {
                    position.icon = require("@/assets/svg/Crypto/" +
                        lowerCaseTicker +
                        ".svg");
                } catch {
                    position.icon = require("@/assets/svg/Crypto/unknown-icon.svg");
                }
                return position;
            })
            .sort((a, b) => b.rawBalance - a.rawBalance);
    },
    [types.ORGANIZATION_GETTER_COST_BASIS]: (state) => {
        return (state.curr_act_detail?.asset || [])
            .filter((asset) => asset.balance !== 0)
            .map((asset) => ({
                currency: asset?.currency,
                costBasis: asset?.cost_basis,
            }))
            .sort((a, b) => b.currency - a.currency);
    },
    [types.ORGANIZATION_GETTER_COST_BASIS_FORMATED]: (_state, getters) => {
        if (!getters[types.ORGANIZATION_GETTER_COST_BASIS].length) {
          return [];
        }
        return getters[types.ORGANIZATION_GETTER_COST_BASIS]
          .map((asset) => {
            const costBasis = parseFloat(asset.costBasis);
            return {
              currency: asset.currency,
              costBasis: isNaN(costBasis) ? "--" : fmtActBal(costBasis),
            };
          })
          .sort((a, b) => b.currency - a.currency);
      },
    [types.ORGANIZATION_GETTER_COST_BASIS_CLIENT]: (state) => {
        if (state.curr_client_detail == undefined) {
            return [];
        }
        if (!state.curr_client_detail.accounts.length) {
            return [];
        }
        let currencyList = Array.from(
            new Set(
                state.curr_client_detail.accounts.map(account => account.asset)
                    .flat()
                    .map(asset => asset.currency)
                )
            )
        let costBasis = {}
        for (let currency of currencyList) {
            costBasis[currency] = null
        }
        for (let account of state.curr_client_detail.accounts) {
            for (let asset of account.asset) {
                costBasis[asset.currency] += asset.cost_basis
            }
        }
        // convert costBasis to array of objects
        let costBasisArray = []
        for (let [currency, value] of Object.entries(costBasis)) {
            costBasisArray.push({
                'currency': currency,
                'costBasis': value
            })
        }
        return costBasisArray.sort((a, b) => b.currency - a.currency);
    },
    [types.ORGANIZATION_GETTER_ACT_SUMMARY]: (state) => {
        var actSum = new Object();
        actSum.marketVal = fmtActBal(state.curr_act_detail.value.toString());
        actSum.cash = fmtActBal(state.curr_act_detail.cash.toString());
        actSum.buyingPower = fmtActBal(
            state.curr_act_detail.buying_power.toString()
        );
        return actSum;
    },
    [types.ORGANIZATION_GETTER_CLI_PERSONAL]: (state) => {
        const personalInfo = {};
        personalInfo.ssn = state.curr_client_detail?.ssn || "Not Avaliable";
        personalInfo.dob = state.curr_client_detail.birth_date
            ? state.curr_client_detail.birth_date
            : "Not Available";
        personalInfo.first_name = state.curr_client_detail.first_name;
        personalInfo.last_name = state.curr_client_detail.last_name;
        personalInfo.advisor =
            state.curr_client_detail.account_holders_x_account_group;
        const repcodes = state.curr_client_detail.repcode || [];
        personalInfo.repcode = repcodes[0]?.label;
        return personalInfo;
    },
    [types.ORGANIZATION_GETTER_CLI_CONTACT]: (state) => {
        var contactInfo = new Object();
        contactInfo.phone = state.curr_client_detail.phone_number;
        contactInfo.email = state.curr_client_detail.email;
        // modify to handle multiple
        if (
            state.curr_client_detail.address1 &&
            state.curr_client_detail.city
        ) {
            contactInfo.homeAddress = `${state.curr_client_detail.address1}, ${state.curr_client_detail.city}, ${state.curr_client_detail.state} ${state.curr_client_detail.zip_code}`;
            contactInfo.mailAddress = `${state.curr_client_detail.address1}, ${state.curr_client_detail.city}, ${state.curr_client_detail.state} ${state.curr_client_detail.zip_code}`;
        } else {
            contactInfo.homeAddress = "Not Available";
        }

        return contactInfo;
    },
    [types.ORGANIZATION_GETTER_CLI_FORM]: (state) => {
        if (state.curr_client_detail.first_name) {
            return state.curr_client_detail.first_name;
        }
    },
    [types.ORGANIZATION_GETTER_CLI_INFO]: (state) => {
        if (state.curr_client_detail) {
            return state.curr_client_detail;
        }
    },
    [types.ORGANIZATION_GETTER_CLI_ACCOUNTS]: (state) => (providersLookup) => {
        return (state.curr_client_detail.accounts || [])
            .filter(Boolean)
            .sort((a, b) => b.total_assets - a.total_assets)
            .map((account) => {
                let calculatedCostBasis = 0;
                
                for (let asset of account.asset) 
                {
                    if (isNaN(parseFloat(asset.cost_basis))) 
                    {
                        calculatedCostBasis = "";
                        break;                        
                    } 
                    else 
                    {
                        calculatedCostBasis += asset.cost_basis;
                    }
                }

                // calculatedCostBasis now is either a number, or an empty string - so let's test it                
                let gain_loss               = isNaN(parseFloat(calculatedCostBasis)) ? "" : account.total_assets - calculatedCostBasis;
                const accountView           = new Object();
                accountView.value           = fmtActBal(account.total_assets.toString());
                accountView.provider_name   = getProviderName(account.provider_id, providersLookup);
                accountView.provider_id     = account.provider_id,
                accountView.icon            = getProviderBaseLogo(account.provider_id);
                accountView.id              = account.id;
                accountView.status          = account.status;
                accountView.heldAway        = account.status.is_account_held_away;
                accountView.costBasis       = formatGainLoss(calculatedCostBasis);
                accountView.gain_loss       = formatGainLoss(gain_loss);

                return accountView;
            });
    },
    [types.ORGANIZATION_GETTER_CLI_ALLOC_CHART]: (state) => {
        return state.curr_client_detail?.portfolio || {};
    },
    [types.ORGANIZATION_GETTER_ACT_ALLOC_CHART]: (state) => {
        return state.curr_act_detail?.portfolio || {};
    },
    [types.ORGANIZATION_GETTER_CHART]: (state) => {
        let fmtData = [];
        for (let point in state.chart_data) {
            var dataPoint = new Object();

            dataPoint.x = new Date(state.chart_data[point].Date);
            dataPoint.y = parseFloat(state.chart_data[point].Balance);
            fmtData.push(dataPoint);
        }

        return fmtData;
    },
    [types.ORGANIZATION_GETTER_NEW_FORM_ONE]: (state) => {
        // @deprecated
        if (state.formPartOne) {
            return state.formPartOne;
        }
    },
    [types.ORGANIZATION_GETTER_NEW_FORM_TWO]: (state) => {
        // @deprecated
        if (state.formPartTwo) {
            return state.formPartTwo;
        }
    },
    [types.ORGANIZATION_GETTER_NEW_FORM_THREE]: (state) => {
        // @deprecated
        if (state.formPartThree) {
            return state.formPartThree;
        }
    },
    [types.ORGANIZATION_GETTER_SELECTED_CUSTODIAN]: (state) => {
        // @deprecated
        return state.selectedCustodian;
    },
    [types.ORGANIZATION_GETTER_ADV_AGREEMENT]: (state) => {
        // @deprecated
        if (state.optionalAgreement) {
            return state.optionalAgreement;
        }
    },
    [types.ORGANIZATION_GETTER_FORM_SET]: (state) => {
        // @deprecated
        return state.formSet;
    },
    [types.ORGANIZATION_GETTER_ACTIVE_PERMISSIONS]: (state) => {        
        return state.firm_onboarding_info;
    },
    [types.ORGANIZATION_GETTER_ACTIVE_PERMISSIONS_IS_LOADING]: (state) => {        
        return state.firm_onboarding_info_loading;
    },
    [types.ORGANIZATION_GETTER_LEFT_NAV_DISABLE]: (state) => {
        return state.leftNavNote;
    },
    [types.ORGANIZATION_GETTER_NEW_ACCOUNT_STATUS]: (state) => {
        return state.newAccounts;
    },
    [types.ORGANIZATION_ACT_GET_ADVISOR_TOTAL_AUM]: (state) => {
        return state.advisorClientsTotalAssetsFormatted;
    },
    [types.ORGANIZATION_GETTER_AM_ACCOUNT_DASHBOARD_DATA]: (state) => {
        return state.amDashboardAccountData;
    },
    [types.ORGANIZATION_GETTER_ORGANIZATION_COMPLIANCE_CONTACT]: (state) => {
        return state.organizationComplianceContacts;
    }
};
