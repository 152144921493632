<template>
  <div :style="cssProps" class="c-label center-align">{{ displayText }}</div>
</template>

<script>
/**
 * @author Team Awesome
 */
export default {
  props: {
    value: {
        type: String, default: ""
    },
    color: { type: String, default: "#000000"}
  },
  computed: {
        displayText: function() {
            return this.value;
        },
        cssProps() {
            return {
                '--color': this.color
            }
        }
    },
};
</script>
<style scoped lang="scss">
  @import "../../../styles/objects/_paragraphs.scss";
  .center-align {
      text-align: center;
  }
  .c-label {
      @include o-paragraph;
      font-family: "Benton-Sans", Helvetica, Arial;
      color: var(--color);
  }
</style>