// ORDER TYPES OPTIONS
export const ORDER_TYPE_LIMIT   = "Limit";
export const ORDER_TYPE_MARKET  = "Market";
// TIME IN FORCE OPTIONS 
export const TIF_DAY                 = "Day";
export const TIF_GOOD_TILL_CANCELLED = "Good 'Til Canceled";
// DEFAULT PRECISION VALUES
export const DEFAULT_PRECISION = 6;
export const DOLLAR_PRECISION  = 2;
export const MAX_PRECISION     = 8;
// Currency Input Options
export const DOLLAR_DENOMINATION = "dollar";
export const LIMIT_PRICE_OPTIONS = "limit";
export const CRYPTO_DENOMINATION = "crypto";
// Dollar
export const dollarOptions = {
    locale: "en-US",
    currency: "USD",
    currencyDisplay: "symbol",
    valueRange: {
      min: 0,
      max: 1000000000,
    },
    precision: DOLLAR_PRECISION,
    hideCurrencySymbolOnFocus: false,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoDecimalDigits: false,
    exportValueAsInteger: false,
    autoSign: true,
    useGrouping: true,
};
// Limit Price - Different Object to Force Reactive (maybe use lodash instead; Find better way for this)
export const limitPriceOptions = {
    locale: "en-US",
    currency: "USD",
    currencyDisplay: "symbol",
    valueRange: {
      min: 0,
      max: 1000000000,
    },
    precision: DEFAULT_PRECISION,
    hideCurrencySymbolOnFocus: false,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoDecimalDigits: false,
    exportValueAsInteger: false,
    autoSign: true,
    useGrouping: true,
};
// Crypto Asset
export const cryptoOptions = {
    locale: undefined,
    currency: "USD",
    currencyDisplay: "hidden",
    valueRange: {
      min: 0,
      max: undefined,
    },
    precision: DEFAULT_PRECISION,
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: true,
    hideNegligibleDecimalDigitsOnFocus: true,
    autoDecimalDigits: false,
    exportValueAsInteger: false,
    autoSign: true,
    useGrouping: true,
};
// Trading Directions
export const DIRECTION_BUY  = "buy";
export const DIRECTION_SELL = "sell";
// Input Types
export const INPUT_TO_AMOUNT   = "toAmount";
export const INPUT_FROM_AMOUNT = "fromAmount";
export const INPUT_LIMIT_PRICE = "limitOrderPrice";

export const CLEARING_TRADE_INVALID_QUOTE_ID        = "";
export const CLEARING_TRADE_INVALID_ONRAMP_PRICE    = 0;
