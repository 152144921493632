export function mapPendingBatchOrdersValuesToFloat(orders) {
    return orders.map((order) => {
        return {
            account                : order.account,
            batch_id               : order.batch_id,
            model                  : order.model,
            order_id               : order.order_id,
            order_maker            : parseInt(order.order_maker),
            order_reviewer         : order.order_reviewer ? parseInt(order.order_reviewer) : undefined,
            ticker                 : order.ticker,
            provider_id            : order.provider_id,
            portfolio_id           : order.portfolio_id,
            inc_trade_dollar_amount: parseFloat(order.inc_trade_dollar_amount),
            inc_trade_quantity     : parseFloat(order.inc_trade_quantity),
            inc_trade_pct          : parseFloat(order.inc_trade_pct),
            model_index_weight     : parseFloat(order.model_index_weight),
            price                  : parseFloat(order.price),
            price_source           : order.price_source,
            real_time_price        : parseFloat(order.real_time_price),
            spot_data_quantity     : parseFloat(order.spot_data_quantity),
            target_weight          : parseFloat(order.target_weight),
            notes                  : order.notes,
            status                 : order.status,
        }
    });
}