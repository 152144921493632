import * as types from '../types';

export default {
    [types.FUND_MUTATION_WITHDRAW_MODAL_OPEN]: (state, payload) => {
        //*
        // args:
        // payload: bool
        state.withdrawModalOpen = payload
    },
    [types.FUND_MUTATION_DEPOSIT_MODAL_OPEN]: (state, payload) => {
        // args:
        // payload: bool
        state.depositModalOpen = payload
    },
}