import { apiUrl } from '@/env';
import axios from 'axios';
import { USER_ID, requestOptions } from './shared-api-constants';
import { getTokenHeaders } from './api-utils';

export const api = {
    async getUserPreferences() {
        const userId = sessionStorage.getItem(USER_ID);
        const config = requestOptions;
        config['headers'] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/user-preferences/${userId}`, config);
    },
    async setUserPreferences(userPreferences) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.post(`${apiUrl}/api/v1/user-preferences/${userId}`, userPreferences, requestOptions);
    },
    async updateUserPreferences(userPreferences) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions['headers'] = getTokenHeaders();
        return axios.put(`${apiUrl}/api/v1/user-preferences/${userId}`, userPreferences, requestOptions);
    },
}