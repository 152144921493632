export const ACTION_GET_ALL_MODELS = "ACTION_GET_ALL_MODELS";
export const ACTION_GET_ALL_MODELS_FOR_ACCOUNT =
    "ACTION_GET_ALL_MODELS_FOR_ACCOUNT";
export const ACTION_GET_SETTINGS = "ACTION_GET_SETTINGS";
export const ACTION_INSERT_SETTINGS = "ACTION_INSERT_SETTINGS";
export const ACTION_INSERT_UPDATE_SETTINGS = "ACTION_INSERT_UPDATE_SETTINGS";
export const ACTION_ALLOCATION_PREVIEW = "ACTION_ALLOCATION_PREVIEW";
export const ACTION_GET_CONFIRMATION = "ACTION_GET_CONFIRMATION";
export const ACTION_GET_MODEL_SUMMARY = "ACTION_GET_MODEL_SUMMARY";
export const ACTION_GET_AVAILABLE_ASSETS = "ACTION_GET_AVAILABLE_ASSETS";
export const ACTION_GET_MODEL_GRAPH_DATAPOINTS =
    "ACTION_GET_MODEL_GRAPH_DATAPOINTS";
export const ACTION_MODEL_TYPE = "ACTION_MODEL_TYPE";
export const ACTION_MODEL_VIEW = "ACTION_MODEL_VIEW";
export const ACTION_CUSTOM_MODEL_DATA = "ACTION_CUSTOM_MODEL_DATA";
export const ACTION_MODEL_DELETE = "ACTION_MODEL_DELETE";
export const ACTION_GET_CUSTOM_MODEL            = "ACTION_GET_CUSTOM_MODEL";
export const ACTION_GET_MULTIPLE_CUSTOM_MODELS  = "ACTION_GET_MULTIPLE_CUSTOM_MODELS";
export const ACTION_DELETE_MODEL_MODAL = "ACTION_DELETE_MODEL_MODAL";
export const ACTION_DUPLICATE_MODEL = "ACTION_DUPLICATE_MODEL";
export const ACTION_EDIT_MODEL = "ACTION_EDIT_MODEL";
export const ACTION_SAVE_CUSTOM_MODEL_SETTINGS = "ACTION_SAVE_CUSTOM_MODEL_SETTINGS";
export const ACTION_EDIT_CUSTOM_MODEL_SETTINGS = "ACTION_EDIT_CUSTOM_MODEL_SETTINGS";
export const ACTION_DUPLICATE_CUSTOM_MODEL_SETTINGS = "ACTION_DUPLICATE_CUSTOM_MODEL_SETTINGS";
export const ACTION_ACCOUNT_DEPOSITS = "ACTION_ACCOUNT_DEPOSITS";
export const ACTION_GET_LINKED_ASSET_MANAGEMENT_FIRMS_INFO = "ACTION_GET_LINKED_ASSET_MANAGEMENT_FIRMS_INFO";
export const ACTION_BATCH_EDIT_ACCOUNT_SETTINGS = "ACTION_BATCH_EDIT_ACCOUNT_SETTINGS";
export const ACTION_BATCH_SET_ACCOUNT_SETTINGS  = "ACTION_BATCH_SET_ACCOUNT_SETTINGS";
export const ACTION_BATCH_REBALANCE_ACCOUNTS    = "ACTION_BATCH_REBALANCE_ACCOUNTS";
export const ACTION_BATCH_UNSUBSCRIBE_ACCOUNTS  = "ACTION_BATCH_UNSUBSCRIBE_ACCOUNTS";

export const GETTER_ALL_MODELS = "GETTER_ALL_MODELS";
export const GETTER_SETTINGS = "GETTER_SETTINGS";
export const GETTER_REBALANCE_TYPE = "GETTER_REBALANCE_TYPE";
export const GETTER_REBALANCE_FREQUENCY = "GETTER_REBALANCE_FREQUENCY";
export const GETTER_ALLOCATION_PREVIEW = "GETTER_ALLOCATION_PREVIEW";
export const GETTER_CONFIRMATION = "GETTER_CONFIRMATION";
export const GETTER_REBALANCE_ERROR = "GETTER_REBALANCE_ERROR";
export const GETTER_SETTINGS_ERROR = "GETTER_SETTINGS_ERROR";
export const GETTER_MODEL_SUMMARY = "GETTER_MODEL_SUMMARY";
export const GETTER_MODEL_GRAPH_DATAPOINTS = "GETTER_MODEL_GRAPH_DATAPOINTS";
export const GETTER_MODEL_TYPE = "GETTER_MODEL_TYPE";
export const GETTER_MODEL_VIEW = "GETTER_MODEL_VIEW";
export const GETTER_CUSTOM_MODEL_DATA = "GETTER_CUSTOM_MODEL_DATA";
export const GETTER_MODEL_DELETE = "GETTER_MODEL_DELETE";
export const GETTER_CUSTOM_MODEL            = "GETTER_CUSTOM_MODEL";
export const GETTER_MULTIPLE_CUSTOM_MODELS  = "GETTER_MULTIPLE_CUSTOM_MODELS";
export const GETTER_DUPLICATE_MODEL = "GETTER_DUPLICATE_MODEL";
export const GETTER_EDIT_MODEL = "GETTER_EDIT_MODEL";
export const GETTER_ACCOUNT_DEPOSITS = "GETTER_ACCOUNT_DEPOSITS";
export const GETTER_LINKED_ASSET_MANAGEMENT_FIRMS_INFO = "GETTER_LINKED_ASSET_MANAGEMENT_FIRMS_INFO";
export const GETTER_BATCH_EDIT_ACCOUNT_SETTINGS = "GETTER_BATCH_EDIT_ACCOUNT_SETTINGS";
export const GETTER_BATCH_SET_ACCOUNT_SETTINGS  = "GETTER_BATCH_SET_ACCOUNT_SETTINGS";
export const GETTER_BATCH_REBALANCE_ACCOUNTS    = "GETTER_BATCH_REBALANCE_ACCOUNTS";
export const GETTER_BATCH_UNSUBSCRIBE_ACCOUNTS  = "GETTER_BATCH_UNSUBSCRIBE_ACCOUNTS";
export const GETTER_AVAILABLE_ASSETS = "GETTER_AVAILABLE_ASSETS";
export const GETTER_ALL_AVAILABLE_ASSETS = "GETTER_ALL_AVAILABLE_ASSETS";

export const MUTATION_SET_ALL_MODELS = "MUTATION_ALL_MODELS";
export const MUTATION_SET_SETTINGS = "MUTATION_SET_SETTINGS";
export const MUTATION_INSERT_SETTINGS = "MUTATION_INSERT_SETTINGS";
export const MUTATION_UPDATE_SETTINGS = "MUTATION_UPDATE_SETTINGS";
export const MUTATION_ALLOCATION_PREVIEW = "MUTATION_ALLOCATION_PREVIEW";
export const MUTATION_CONFIRMATION = "MUTATION_CONFIRMATION";
export const MUTATION_REBALANCE_ERROR = "MUTATION_REBALANCE_ERROR";
export const MUTATION_SETTINGS_ERROR = "MUTATION_SETTINGS_ERROR";
export const MUTATION_MODEL_SUMMARY = "MUTATION_MODEL_SUMMARY";
export const MUTATION_SET_AVAILABLE_ASSETS = "MUTATION_SET_AVAILABLE_ASSETS";
export const MUTATION_CLEAR_AVAILABLE_ASSETS = "MUTATION_CLEAR_AVAILABLE_ASSETS";
export const MUTATION_MODEL_GRAPH_DATAPOINTS = "MUTATION_MODEL_GRAPH_DATAPOINTS";
export const MUTATION_MODEL_TYPE = "MUTATION_MODEL_TYPE";
export const MUTATION_MODEL_VIEW = "MUTATION_MODEL_VIEW";
export const MUTATION_CUSTOM_MODEL_DATA = "MUTATION_CUSTOM_MODEL_DATA";
export const MUTATION_MODEL_DELETE = "MUTATION_MODEL_DELETE";
export const MUTATION_CUSTOM_MODEL          = "MUTATION_CUSTOM_MODEL";
export const MUTATION_MULTPLE_CUSTOM_MODELS = "MUTATION_MULTPLE_CUSTOM_MODELS";
export const MUTATION_DELETE_MODEL_MODAL = "MUTATION_DELETE_MODEL_MODAL";
export const MUTATION_DUPLICATE_MODEL = "MUTATION_DUPLICATE_MODEL";
export const MUTATION_EDIT_MODEL = "MUTATION_EDIT_MODEL";
export const MUTATION_ACCOUNT_DEPOSITS = "MUTATION_ACCOUNT_DEPOSITS";
export const MUTATION_LINKED_ASSET_MANAGEMENT_FIRMS_INFO = "MUTATION_LINKED_ASSET_MANAGEMENT_FIRMS_INFO";
export const MUTATION_BATCH_EDIT_ACCOUNT_SETTINGS   = "MUTATION_BATCH_EDIT_ACCOUNT_SETTINGS";
export const MUTATION_BATCH_SET_ACCOUNT_SETTINGS    = "MUTATION_BATCH_SET_ACCOUNT_SETTINGS";
export const MUTATION_BATCH_REBALANCE_ACCOUNTS      = "MUTATION_BATCH_REBALANCE_ACCOUNTS";
export const MUTATION_BATCH_UNSUBSCRIBE_ACCOUNTS    = "MUTATION_BATCH_UNSUBSCRIBE_ACCOUNTS";

export default {
    namespaced: true,
    ACTION_GET_ALL_MODELS_FOR_ACCOUNT,
    ACTION_GET_ALL_MODELS,
    ACTION_GET_SETTINGS,
    GETTER_ALL_MODELS,
    GETTER_SETTINGS,
    MUTATION_SET_ALL_MODELS,
    MUTATION_SET_SETTINGS,        
    GETTER_REBALANCE_TYPE,
    GETTER_REBALANCE_FREQUENCY,        
    ACTION_INSERT_SETTINGS,    
    MUTATION_INSERT_SETTINGS,
    MUTATION_UPDATE_SETTINGS,
    ACTION_INSERT_UPDATE_SETTINGS,    
    ACTION_ALLOCATION_PREVIEW,
    GETTER_ALLOCATION_PREVIEW,
    MUTATION_ALLOCATION_PREVIEW,
    ACTION_GET_CONFIRMATION,
    GETTER_CONFIRMATION,
    MUTATION_CONFIRMATION,
    GETTER_REBALANCE_ERROR,
    MUTATION_REBALANCE_ERROR,
    GETTER_SETTINGS_ERROR,
    MUTATION_SETTINGS_ERROR,    
    GETTER_MODEL_SUMMARY,
    GETTER_MODEL_GRAPH_DATAPOINTS,
    ACTION_GET_MODEL_GRAPH_DATAPOINTS,
    MUTATION_MODEL_GRAPH_DATAPOINTS,
    MUTATION_MODEL_SUMMARY,
    ACTION_GET_MODEL_SUMMARY,
    ACTION_MODEL_TYPE,
    GETTER_MODEL_TYPE,
    MUTATION_MODEL_TYPE,
    ACTION_MODEL_VIEW,
    ACTION_CUSTOM_MODEL_DATA,
    GETTER_MODEL_VIEW,
    GETTER_CUSTOM_MODEL_DATA,
    MUTATION_MODEL_VIEW,
    MUTATION_CUSTOM_MODEL_DATA,
    MUTATION_MODEL_DELETE,
    GETTER_MODEL_DELETE,
    ACTION_MODEL_DELETE,
    ACTION_GET_CUSTOM_MODEL,
    GETTER_CUSTOM_MODEL,
    MUTATION_CUSTOM_MODEL,
    ACTION_DUPLICATE_MODEL,
    MUTATION_DUPLICATE_MODEL,
    GETTER_DUPLICATE_MODEL,
    ACTION_EDIT_MODEL,
    MUTATION_EDIT_MODEL,
    GETTER_EDIT_MODEL,
    ACTION_SAVE_CUSTOM_MODEL_SETTINGS,
    ACTION_EDIT_CUSTOM_MODEL_SETTINGS,
    ACTION_DUPLICATE_CUSTOM_MODEL_SETTINGS,
    GETTER_ACCOUNT_DEPOSITS,
    MUTATION_ACCOUNT_DEPOSITS,
    ACTION_ACCOUNT_DEPOSITS,
};
