<template>
  <div>
    <v-tooltip location="bottom" :disabled="!tooltip" color="black">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" icon @click="onButtonClick" :disabled="inactive" :loading="loading" variant="flat">
          <v-icon :color="color" >{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
/**
 * @author Team Awesome
 */
export default {
  props: {
    icon    : { type: String, default: "", },
    color   : { type: String, default: "var(--primary-color)", },
    inactive: { type: Boolean, default: false, },
    loading : { type: Boolean, default: false, },
    tooltip : { type: String, default: null, }
  },
  methods: {
    onButtonClick() {
      this.$emit("btnClick");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../styles/_colors.scss";

</style> 
 
 
