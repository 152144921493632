<template>
  <div>
    <v-snackbar 
    v-model="show">
      <v-progress-circular
        class="ma-2"
        indeterminate
        v-show="showProgress"
      ></v-progress-circular
      >{{ currentNotificationContent }}

      <v-btn @click="close">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters  } from 'vuex';
import * as types from '@/store/types';

export default {
  data() {
    return {
      show: false,
      text: '',
      showProgress: false,
      currentNotification: false,
    };
  },

  computed: {
    ...mapGetters({
      readFirstNotification: 'main/' + types.MAIN_GETTER_FIRST_NOTIFICATION
    })
    
  },

  methods: {
    ...mapActions({
      removeNotification: 'main/' + types.MAIN_ACT_REMOVE_NOTIFICATION
    }),
    async hide() {
      this.show = false;
      await new Promise((resolve, reject) => setTimeout(() => resolve(), 500));
    },

    async close() {
      await this.hide();
      await this.removeCurrentNotification();
    },

    async removeCurrentNotification() {
      if (this.currentNotification) {
        // commitRemoveNotification(this.$store, this.currentNotification);
        this.$store.commit('main' + types.MAIN_MUT_REMOVE_NOTIFICATION, this.currentNotification)
      }
    },

    firstNotification() {
      return this.readFirstNotification;
    },

    async setNotification(notification) {
      if (this.show) {
        await this.hide();
      }

      if (notification) {
        this.currentNotification = notification;
        this.showProgress = notification.showProgress || false;
        this.show = true;
      } else {
        this.currentNotification = false;
      }
    },

    async onNotificationChange(newNotification, oldNotification) {
      if (newNotification !== this.currentNotification) {
        await this.setNotification(newNotification);

        if (newNotification) {
          this.removeNotification({notification: newNotification, timeout: 6500});
        }
      }
    },

    currentNotificationContent() {
      return (
        (this.currentNotification && this.currentNotification.content) || ''
      );
    },

    currentNotificationColor() {
      return (
        (this.currentNotification && this.currentNotification.color) || 'info'
      );
    },
  },
};
</script>