export const ACTION_GET_ACCOUNT_BENEFICIARY = "ACTION_GET_ACCOUNT_BENEFICIARY";
export const ACTION_RELATIONSHIP_TYPE = "ACTION_RELATIONSHIP_TYPE";
export const ACTION_SET_ACCOUNT_BENEFICIARY = "ACTION_SET_ACCOUNT_BENEFICIARY";
export const ACTION_UPLOAD_SPOUSAL_CONSENT_FORM = "ACTION_UPLOAD_SPOUSAL_CONSENT_FORM";
export const ACTION_DOWNLOAD_NEW_SPOUSAL_CONSENT_FORM = "ACTION_DOWNLOAD_NEW_SPOUSAL_CONSENT_FORM";
export const ACTION_DOWNLOAD_SIGNED_SPOUSAL_CONSENT_FORM = "ACTION_DOWNLOAD_SIGNED_SPOUSAL_CONSENT_FORM";

export const GETTER_SIGNED_SPOUSAL_CONSENT_FORM = "GETTER_SIGNED_SPOUSAL_CONSENT_FORM";
export const GETTER_ACCOUNT_BENEFICIARY = "GETTER_ACCOUNT_BENEFICIARY";
export const GETTER_RELATIONSHIP_TYPE = "GETTER_RELATIONSHIP_TYPE";

export const MUTATION_SIGNED_SPOUSAL_CONSENT_FORM = "MUTATION_SIGNED_SPOUSAL_CONSENT_FORM";
export const MUTATION_ACCOUNT_BENEFICIARY = "MUTATION_ACCOUNT_BENEFICIARY";
export const MUTATION_RELATIONSHIP_TYPE = "MUTATION_RELATIONSHIP_TYPE";

export default {
    namespaced: true,
    ACTION_GET_ACCOUNT_BENEFICIARY,
    GETTER_ACCOUNT_BENEFICIARY,
    MUTATION_ACCOUNT_BENEFICIARY,
    GETTER_SIGNED_SPOUSAL_CONSENT_FORM,
    MUTATION_SIGNED_SPOUSAL_CONSENT_FORM,
    ACTION_RELATIONSHIP_TYPE,
    GETTER_RELATIONSHIP_TYPE,
    MUTATION_RELATIONSHIP_TYPE,
    ACTION_SET_ACCOUNT_BENEFICIARY,
    ACTION_UPLOAD_SPOUSAL_CONSENT_FORM,
    ACTION_DOWNLOAD_NEW_SPOUSAL_CONSENT_FORM,
    ACTION_DOWNLOAD_SIGNED_SPOUSAL_CONSENT_FORM,
};
