import { beneficiary_api } from "@/api/beneficiary-api";
import {
    ACTION_GET_ACCOUNT_BENEFICIARY,
    MUTATION_ACCOUNT_BENEFICIARY,
    ACTION_RELATIONSHIP_TYPE,
    MUTATION_RELATIONSHIP_TYPE,
    ACTION_SET_ACCOUNT_BENEFICIARY,
    ACTION_UPLOAD_SPOUSAL_CONSENT_FORM,
    ACTION_DOWNLOAD_NEW_SPOUSAL_CONSENT_FORM,
    ACTION_DOWNLOAD_SIGNED_SPOUSAL_CONSENT_FORM,
    MUTATION_SIGNED_SPOUSAL_CONSENT_FORM,
} from "./types";

export default {
    [ACTION_GET_ACCOUNT_BENEFICIARY]: ({ commit }, account) => {
        commit(MUTATION_ACCOUNT_BENEFICIARY, {});
        beneficiary_api.getBeneficiaries(account).then(
            (response) => {
                commit(MUTATION_ACCOUNT_BENEFICIARY, response.data)
            },
            (_e) => null
        )
    },    
    [ACTION_RELATIONSHIP_TYPE]: ({ commit }) => {
        return beneficiary_api.getRelationship().then(
            (response) => commit(MUTATION_RELATIONSHIP_TYPE, response.data),
            (_e) => null
        );
    },     
    [ACTION_SET_ACCOUNT_BENEFICIARY]: async (
        context, 
        beneficiaries
    ) => {
        await  beneficiary_api.saveBeneficiaries(beneficiaries);
    },
    [ACTION_UPLOAD_SPOUSAL_CONSENT_FORM]: async (
        context,
        spousalConsentFormUpload
    ) => {
        await beneficiary_api.uploadSpousalConsentForm(spousalConsentFormUpload);
    },
    [ACTION_DOWNLOAD_NEW_SPOUSAL_CONSENT_FORM]: async (context, accountId) => {
        return await beneficiary_api.downloadNewSpousalConsentForm(accountId)
    },  
    [ACTION_DOWNLOAD_SIGNED_SPOUSAL_CONSENT_FORM]:  ({ commit }, accountId) =>  {
        commit(MUTATION_SIGNED_SPOUSAL_CONSENT_FORM, {});
        beneficiary_api.downloadSignedSpousalConsentForm(accountId).then(
            (signedSpousalConsentFormFile) => {
                commit(MUTATION_SIGNED_SPOUSAL_CONSENT_FORM, signedSpousalConsentFormFile)
            },
            (_e) => null
        )
    },  
};