import { api } from "../../TRA-api";
import { CustodianService } from "./TRA-custodian-service";
import {
    ORDER_TYPE_MARKET,
} from "../../constants/TRA-trading-state";
import {PRICE_REFRESH_INTERVAL_PT} from "@/env";
import { SHOW_TRADE_CONFIRMATION, SHOW_TRADE_ERROR, SHOW_TRADE_PREVIEW } from "../../constants/TRA-modals";

const PRIME_TRUST_QUOTE_EXECUTED_STATUS = "Executed";
const PRIME_TRUST_QUOTE_EXECUTED_PENDING_SETTLEMENT_STATUS = "Executed_pending_settlement";
const PRIME_TRUST_EXPIRED_QUOTE_CODE = 16009;

export class PrimeTrustService extends CustodianService {
    static getApiPathName() {
        return "prime-trust"
    }
    static async getAccountHealth(accountId) {
        try {
            const response = await api.getAccountHealth(this.getApiPathName(), accountId);
            return response.data;
        } catch (error) {            
            return this.handleGetAccountHealthError(error);
        }
    }
    static async getPrice(priceData, accountClearingPartyId) {
        let formattedRequest = this.mapDataForPriceRequest(priceData)
        try {
            let response = await api.ptQoute(formattedRequest);
            return this.mapPriceResponse(response.data);
        } catch (error) {
            return this.handleGetPriceError(error);
        }
    }
    static formTradeDetails(
        selectedCurrency,
        tradeInputs,
        activeDirection,
        activeOrderType,
        activeTimeInForce,
        latestTradeData,
        accountId,
        accountClearingPartyId
    ) {
        return {
            'quoteId': latestTradeData.quote_id,
            'accountId': accountId,
            'unitCost': latestTradeData.cost_per_unit,
            'tradeAmount': latestTradeData.trade_amount - Math.abs(latestTradeData.fee_amount),
            'feeAmount': Math.abs(latestTradeData.fee_amount),
            'type': activeOrderType,
            'totalAmount': latestTradeData.trade_amount,
            'unitCount': latestTradeData.unit_count,
            'direction': activeDirection,
            'amount': latestTradeData.unit_count * latestTradeData.cost_per_unit,
            'currency': selectedCurrency,

        }
    }
    static async getBalances(accountId) {
        try {
            let response = await api.ptBalances({'account_id': accountId })
            return this.formatBalanceData(accountId, response.data)
        } catch (error) {
            return this.handleGetBalancesError(error);
        }
    }
    static mapDataForPriceRequest(priceData) {
        return {
            'account_id': priceData.account_id,
            'direction': priceData.direction,
            'currency': priceData.currency,
            'amount': priceData.amount.toFixed(2),
            'quantity': priceData.quantity
        }
    }
    static mapPriceResponse(response) {
        return {
            'quote_id': response.quote_id,
            'cost_per_unit': response.price_per_unit, 
            'unit_count': response.unit_count,
            'trade_amount': response.base_amount,
            'fee_amount': response.fee_amount,
            'total': response.total
        }
    }
    static async getTradableAssets(clearingPartyId) {
        try {
            return await api.getPrimeTrustTradableAssets();
        } catch (error) {
            return this.errorHandler(error);
        }
    }
    static setOrderTypeOptions(accountClearingPartyId) {
        return [ORDER_TYPE_MARKET];
    }
    static findPriceToUseForTrade(prices) {
        /*
            param prices: Obj : see mapPriceResponse method for format
        */
       return prices?.cost_per_unit ? prices.cost_per_unit : 0;
    }
    static tradeFeeAndTotalCalculation(orderType = ORDER_TYPE_MARKET, lastQuote) {
        let tradeAmt = lastQuote.fee_amount > 0 ? lastQuote.total : lastQuote.trade_amount
        return {
            'fee': Math.abs(lastQuote.fee_amount),
            'total': tradeAmt - Math.abs(lastQuote.fee_amount)
        }
    }
    static getRefreshInterval() {
        return PRICE_REFRESH_INTERVAL_PT;
    }
    static async submitOrder(tradeDetails, accountClearingPartyId) {
        try {        
            const orderData = this.mapDataForOrder(tradeDetails);
            const response = await api.ptExecute(orderData);
            const orderSubmissionDetails = this.handleTradeResponse(response);

            return {...tradeDetails, ...orderSubmissionDetails};        
        }
        catch (error) {
            return this.handleSubmitOrderError(error);
        }
    }
    static handleTradeResponse(res) {
        if (res?.data?.custodian_order_id !== null) {
            // Executed
            return this.getConfirmationDetails(res.data, SHOW_TRADE_CONFIRMATION);
        } else {
            // Failed to place trade
            return this.getConfirmationDetails(res.data, SHOW_TRADE_ERROR);
        }
    }
    static getConfirmationDetails(data, status) {
        return {
            'unitCountExecuted': data.executed_quantity,
            'transaction': data.custodian_order_id,
            'modalType': status
        }
    }
    static mapDataForOrder(tradeDetails) {
        return {
            'quote_id': tradeDetails.quoteId,
            'account_id': tradeDetails.accountId,
            'direction': tradeDetails.direction,
            'currency': tradeDetails.currency.abbr,
            'amount': tradeDetails.tradeAmount
        }
    }
}