import { apiUrl } from "@/env";
import axios from "axios";
import { USER_ID, requestOptions } from "./shared-api-constants";
import { getTokenHeaders } from "./api-utils";
import {API_ASSET_MANAGER_PATH, API_ADVISOR_PATH, API_ACCOUNT_IDS_PATH} from "./asset-manager-api-constants";
import {get} from "lodash"
import { api } from "@/api";
import { MAIN_GETTER_ACCOUNT_GROUP_INFO } from "@/store/types";
export const AssetManagerApi =
{
    async getDashboardAccountData()
    {
        try 
        {
            const userId = sessionStorage.getItem(USER_ID);

            requestOptions["headers"] = getTokenHeaders();

            return await axios.get(`${apiUrl}/api/v1/accounts/${API_ADVISOR_PATH}/${userId}/dashboard_data`, requestOptions);            
        } 
        catch (error) 
        {
            throw new Error(error.response.data.message);
        }
    },
    async getQuotes( currency_symbol, accountInfo, direction ) {
        try 
        {
            const userId = sessionStorage.getItem(USER_ID);
            requestOptions["headers"] = getTokenHeaders();                           

            const payload = { "account_ids": accountInfo.account_ids, "provider_ids": accountInfo.provider_ids, "currency_symbol": currency_symbol, "direction": direction }            
            const response = await axios.post(`${apiUrl}/api/v1/trade/shared/${API_ADVISOR_PATH}/${userId}/${API_ACCOUNT_IDS_PATH}/selected-provider-quotes`, payload, requestOptions);            
            response.data = response.data.map(quote => {
                quote.fee = Math.abs(quote.fee);
                return quote
            });
            return response;
        }   
        catch (error) 
        {
            return error
        }
    },
    async submitSubaccountTrade(subaccountTrades) {
         const userId = sessionStorage.getItem(USER_ID);
         requestOptions["headers"] = getTokenHeaders();
         const resp = await axios.post(`${apiUrl}/api/v1/sma/${API_ADVISOR_PATH}/${userId}/${API_ACCOUNT_IDS_PATH}/trade`,
             subaccountTrades,
             requestOptions);
         return get(resp, "data.result",[])
    }
}

