<template>
  <div class="banner-container">
    <Logo color="white" />
  </div>
</template>

<script>
/**
 * @author Team Awesome
 */
import Logo from "@/components/Branding/icon"
export default {
    name: "Banner",
    components: {
        Logo
    }
};
</script>
<style lang="scss" scoped>
    @use "../../../styles/themes/_default.scss";
    $design-banner-height: 72px;
    .banner-container {
        width: 100%;
        height: $design-banner-height;
        display: flex;
        align-items: center;
        justify-content: center;
        background: default.$c__gradient-background;
    }
</style>
