import {fmtActBal } from "@/utils";
import {    
    CLEARING_TRADE_INVALID_ONRAMP_PRICE
} from "./constants/TRA-trading-state";

export const checkSessionForAccountToReload = () => {
    return sessionStorage.getItem("ref-trading-act") || null;
};

export const removePresetAccountFromSession = () => {
    sessionStorage.removeItem("ref-trading-act");
}

export const setReloadAccountToSession = (accountId) => {
    sessionStorage.setItem("ref-trading-act", accountId);
}

export const formatCurrencyValue = (currencyVal = 0) => {
    return fmtActBal(currencyVal.toString(), 2);

}

export const isClearingAccount = (accountClearingPartyId) => {
    return Boolean(accountClearingPartyId);
}

export const canClearingAccountQuoteBeExecuted = (quoteId, onrampPrice) => {
    const price = parseFloat(onrampPrice)
    return quoteId && price && price > CLEARING_TRADE_INVALID_ONRAMP_PRICE;
}