export function extractValuesAsListByKeys(keyList, obj) {
  return keyList.filter(key => Object.hasOwn(obj, key)).map(key => obj[key]);
}

export function convertObjectToMap(obj) {
    const map = new Map();

    Object.entries(obj).forEach(([key, value]) => {
        if (value !== null && typeof value === "object") {
            map.set(key, convertObjectToMap(value));
        }
        else {
            map.set(key, value);
        }
    });

    return map;
}

export function isObjectEmpty(obj) {
    return !obj || (Object.keys(obj).length === 0);
}