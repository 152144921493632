/**
 * Returns numeric string as as a signed value with a signed prefix (eg. -1234.56 to -$1234.56)
 *
 * @param {string | number} value
 * @param {currency} defaults to "$"
 * @param {formatPositiveSigned} defaults to "true"
 * @returns {string}
 */
export const formatCurrencyWithSign = (value, currency = "$", formatPositiveSigned = true) => {
    if (value == null) {
        return "";
    }
    return isNaN(value) ? formatStringCurrencyWithSign(value, currency, formatPositiveSigned)
                        : formatNumericCurrencyWithSign(value, currency, formatPositiveSigned);
}

/**
 * Returns numeric string as as a signed value with a signed prefix (eg. -1234.56 to -$1234.56)
 *
 * @param {string} value
 * @param {currency} defaults to "$"
 * @param {formatPositiveSigned} defaults to "true"
 * @returns {string}
 */
export const formatStringCurrencyWithSign = (value, currency = "$", formatPositiveSigned = true) => {
    if (!(typeof value === 'string' || value instanceof String)) {
        return "";
    } else {
        const numericValue = Number(value.replace(/[^0-9.-]+/g,""));
        return formatNumericCurrencyWithSign(numericValue, currency, formatPositiveSigned);
    }
};

/**
 * Returns number as as a signed value with a signed prefix (eg. -1234.56 to -$1234.56)
 *
 * @param {number} value
 * @param {currency} defaults to "$"
 * @param {formatPositiveSigned} defaults to "true"
 * @returns {string}
 */
 export const formatNumericCurrencyWithSign = (value, currency = "$", formatPositiveSigned = true) => {
    if (isNaN(value)) {
        return "";
    }

    const absoluteValue = Math.abs(value);
    const parsedValue = parseFloat(absoluteValue) || 0;
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    var formattedAmount = formatter.format(parsedValue);

    if (value === 0) {
        return `${currency}${value}`;
    } else if (value > 0 && formatPositiveSigned) {
        return `+${formattedAmount}`;
    } else if (value > 0 && !formatPositiveSigned) {
        return `${formattedAmount}`;
    } else if (value < 0) {
        return `-${formattedAmount}`;
    } else {
        return "";
    }
};

/**
 * Returns number formatted into USD
 *
 * @param {number} value
 * @param {number} minDecimals defaults to 2
 * @param {number} maxDecimals defaults to 2
 * @returns {string}
 */
export const currency = (value, minDecimals = 2, maxDecimals = 2) => {
    const parsedValue = parseFloat(value) || 0;
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
    });

    return formatter.format(parsedValue);
}

/**
 * Returns lookup text for entry matching provided id
 *
 * @param {number} id
 * @param {{id: number, text: string}[]} lookupEntries
 * @returns {string}
 */
 export const lookupTextByID = (id, lookupEntries) => {
    const matchingEntry = (lookupEntries || []).find((lookupEntry) => lookupEntry.id === id);
    return matchingEntry?.text || "";
}
