import {apiUrl} from "@/env";
import axios from "axios";
import {USER_ID, requestOptions} from "./shared-api-constants";
import {getTokenHeaders} from "./api-utils";
import {HTTP_404_SERVER_ERROR, HTTP_500_INTERNAL_SERVER} from "@/utils.js";
import {
    MODEL_GRAPH_BASE_INVESTMENT,
} from "@/views/Model Management/services/MODEL_MANAGEMENT-constants.service";
import { API_ACCOUNT_IDS_PATH, API_ADVISOR_PATH } from "./asset-manager-api-constants";
import {MODEL_TARGET_TYPE_ACCOUNT} from "@/model-constants.js";

function mapAccountSettingsToPayload(settings) {
  if (settings.target_type !== MODEL_TARGET_TYPE_ACCOUNT) {
    throw new Error("Failed to map account settings payload")
  }

  let dto = structuredClone(settings);

  delete dto.target_id;
  delete dto.target_type;
  
  return [settings.target_id, dto]
}

export const modelsApi = {
    async getModelGraphDataPoints(periodRange, modelCount) {
        try {
            const dayCount = periodRange;
            const initialInvestment = MODEL_GRAPH_BASE_INVESTMENT;
            const numberOfModelsToFetch = modelCount;
            const userId = sessionStorage.getItem(USER_ID);
            requestOptions["headers"] = getTokenHeaders();
            const response = await axios.get(
                `${apiUrl}/api/v1/model-trading/advisor/${userId}/models/graph-data-points/${dayCount}/${initialInvestment}/${numberOfModelsToFetch}`,
                {...requestOptions}
            );
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    },
    async getModelSummary() {
        try {
            const userId = sessionStorage.getItem(USER_ID);
            requestOptions["headers"] = getTokenHeaders();
            const response = await axios.get(
                `${apiUrl}/api/v1/model-trading/advisor/${userId}/models/summary`,
                {...requestOptions}
            );
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    },
    async getCustomPortfolio(portfolioId) {
        try {
            const userId = sessionStorage.getItem(USER_ID);
            requestOptions["headers"] = getTokenHeaders();
            const response = await axios.get(
                `${apiUrl}/api/v1/portfolios/advisor/${userId}/portfolio/${portfolioId}/custom-portfolio`,
                {...requestOptions}
            );
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    },
    async getCustomPortfolios(portfolioIds) {
        try {          
            const userId = sessionStorage.getItem(USER_ID);
            const payload = { "portfolio_ids" : portfolioIds };
            requestOptions["headers"] = getTokenHeaders();
            const response = await axios.post(
                `${apiUrl}/api/v1/portfolios/advisor/${userId}/portfolio/custom-portfolios`,
                payload,
                {...requestOptions}
            );
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    },
    async deactivateCustomModelPortfolio(portfolioId) {
        try {
            const userId = sessionStorage.getItem(USER_ID);
            requestOptions["headers"] = getTokenHeaders();
            const response = await axios.put(
                `${apiUrl}/api/v1/portfolios/advisor/${userId}/deactivate-custom-portfolio`,
                {portfolio_id: portfolioId},
                requestOptions
            );
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    },
    async getModelTypes() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.get(
            `${apiUrl}/api/v1/model-trading/lookup/model-type/advisor/${userId}`,
            {...requestOptions}
        );
    },
    async getAllModels() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.get(`${apiUrl}/api/v1/portfolios/advisor/${userId}`, {
            ...requestOptions,
        });
    },
    async getAllModelsForAccount(account_id) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.get(
            `${apiUrl}/api/v1/portfolios/advisor/${userId}/account/${account_id}`,
            {...requestOptions}
        );
    },
    async getSettingsForAccount(accountID) {
        try {
            const userId = sessionStorage.getItem(USER_ID);
            requestOptions["headers"] = getTokenHeaders();
            const response = await axios.get(
                `${apiUrl}/api/v1/model-trading/advisor/${userId}/account/${accountID}/account-settings`,
                {...requestOptions}
            );
            return response;
        } catch (res) {            
            if (res.response.status === HTTP_404_SERVER_ERROR) {
                return {data: {}};
            }
            
            throw new Error(res.response.data.message);
        }
    },
    async reallocateAccount(accountId) {
      const userId = sessionStorage.getItem(USER_ID);
      requestOptions["headers"] = getTokenHeaders();      
      const payload = {};

      return axios.post(`${apiUrl}/api/v1/reallocation/${API_ADVISOR_PATH}/${userId}/reallocate/${accountId}`, payload, requestOptions);
    },
    async getConfirmationForTransaction(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.post(
            `${apiUrl}/api/v1/model-trading/advisor/${userId}/rebalance/${accountId}`,
            {},
            requestOptions
        );
    },
    async getPortfolioAccountAllocation(accountId) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.get(
            `${apiUrl}/api/v1/model-trading/advisor/${userId}/account/${accountId}/asset-allocation`,
            {...requestOptions}
        );
    },
    async getModelPerformanceData(model) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.post(
            `${apiUrl}/api/v1/model-trading/advisor/${userId}/model_performance_data`,
            model,
            {...requestOptions}
        );
    },
    async getAccountDeposits(account) {
        requestOptions["headers"] = getTokenHeaders();
        const userId = sessionStorage.getItem(USER_ID);
        return axios.get(`${apiUrl}/api/v1/accounts/advisor/${userId}/account/${account.accountId}/account-deposits`, requestOptions)
    },
    async getAllRebalanceFrequency() {
        const userId = sessionStorage.getItem(USER_ID);
        let config = requestOptions;
        requestOptions["headers"] = getTokenHeaders();
        return axios.get(
            `${apiUrl}/api/v1/model-trading/lookup/rebalance-frequency/advisor/${userId}`,
            {...requestOptions}
        );
    },
    async getAllRebalanceTypes(settings) {
        const userId = sessionStorage.getItem(USER_ID);
        let config = requestOptions;
        requestOptions["headers"] = getTokenHeaders();
        return axios.get(
            `${apiUrl}/api/v1/model-trading/lookup/rebalance-type/advisor/${userId}`,
            {...requestOptions}
        );
    },
    async getRelationship() {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.get(
            `${apiUrl}/api/v1/lookup/user/${userId}/relationship-types`,
            {...requestOptions}
        );
    },
    async assignModelToAccounts(modelAssignment) {
        const userId = sessionStorage.getItem(USER_ID);
        
        requestOptions["headers"] = getTokenHeaders();

        return await axios.post(`${apiUrl}/api/v1/model-trading/advisor/${userId}/${API_ACCOUNT_IDS_PATH}/model-assignment`, modelAssignment, requestOptions);
    },    
    async batchEditAccountSettings(payload) {
        const userId = sessionStorage.getItem(USER_ID);        
  
        requestOptions["headers"] = getTokenHeaders();
        
        return axios.put(
            `${apiUrl}/api/v1/model-trading/advisor/${userId}/batch-account-settings`,
            payload,
            {...requestOptions, validateStatus:(_)=>true}
        );
    },
    async batchSetAccountSettings(payload) {
        const userId = sessionStorage.getItem(USER_ID);        
  
        requestOptions["headers"] = getTokenHeaders();
        
        return axios.post(
            `${apiUrl}/api/v1/model-trading/advisor/${userId}/batch-account-settings`,
            payload,
            {...requestOptions, validateStatus:(_)=>true}
        );
    },
    async batchUnsubscribeAccounts(payload) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.put(
            `${apiUrl}/api/v1/model-trading/advisor/${userId}/batch-unsubscribe`,
            payload,
            requestOptions
        );
    },
    async getCustodianAvailableAllocations(custodians) {
        const userId = sessionStorage.getItem(USER_ID);
        const payload = { "custodians" : custodians };
          
        requestOptions["headers"] = getTokenHeaders();        

        return await axios.post(`${apiUrl}/api/v1/portfolios/advisor/${userId}/portfolio-allocations`, payload, requestOptions);                
    },
    async getAvailableAssetManagementFirms() {
      const userId = sessionStorage.getItem(USER_ID);
      requestOptions["headers"] = getTokenHeaders();      

      return await axios.get(`${apiUrl}/api/v1/portfolios/advisor/${userId}/available-am-firms`, requestOptions);      
    },
    async createCustomPortfolio(customPortfolio) {
        try {
            const userId = sessionStorage.getItem(USER_ID);
            requestOptions["headers"] = getTokenHeaders();
            const response = await axios.post(
                `${apiUrl}/api/v1/portfolios/advisor/${userId}/custom-portfolio`,
                customPortfolio,
                requestOptions
            );
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    },
    async editCustomPortfolio(customPortfolio) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.put(
            `${apiUrl}/api/v1/portfolios/advisor/${userId}/custom-portfolio`,
            customPortfolio,
            requestOptions
        );
    },
    async getModelAllocationPreview(accountsList, refreshValuatioin) {
        try {
            const userId = sessionStorage.getItem(USER_ID);
            requestOptions["headers"] = getTokenHeaders();
            return axios.post(
                `${apiUrl}/api/v1/model-trading/advisor/${userId}/asset-allocation?refresh_valuation=${refreshValuatioin}`,
                accountsList,
                requestOptions
            );
        } catch (res) {
            if (res.response.status === HTTP_404_SERVER_ERROR || res.response.status === HTTP_500_INTERNAL_SERVER) {
                return {data: {
                    asset_allocations: []
                }};
            }
            throw new Error(res.response.data.message);
        } 
    },
    async rebalanceAccountList(accountsList) {
        const userId = sessionStorage.getItem(USER_ID);
        requestOptions["headers"] = getTokenHeaders();
        return axios.post(
            `${apiUrl}/api/v1/model-trading/advisor/${userId}/rebalance`,
            accountsList,
            requestOptions
        );
    }   
};
