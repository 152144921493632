import { api } from "../../api.js";
import {
    SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_TRIAL_AVAILABLE,
    SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL,
    SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL_ACADEMY_ACCESS_ONLY
} from "@/utils.js";
// import router from '@/router.js';
import * as types from "@/store/types.js";

export default {
    // authentication checks for accessing this endpoint will be handled...
    // ...prior ie. it is called in lifecycle hooks of the two dashboards
    [types.PRICE_ACT_UPDATE]: async (context) => {
        const mainActStr = "main/" + types.MAIN_ACT_CHECK_API_ERROR;
        try {                
            const response = await api.getPrice();                
            const data = {
                price: response.data.ask,
                last_updated: response.data.queried_at,
            };
            context.commit(types.PRICE_MUT_UPDATE, data);
            // loading notification
        } catch (error) {                
            const mainActStr = "main/" + types.MAIN_ACT_CHECK_API_ERROR;
            await context.dispatch(mainActStr, error);
        }
    },
    [types.PRICE_ACT_IS_EARLY_UPGRADE]: (context, payload) => {
        const validEarlyUpgradeStatusCodes = [
            SUBSCRIPTION_STATUS_CODE_REQUIRED_AND_TRIAL_AVAILABLE,
            SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL,
            SUBSCRIPTION_STATUS_CODE_REQUIRED_BUT_ON_TRIAL_ACADEMY_ACCESS_ONLY
        ];
        const isEarlyUpgrade = 
            (payload?.route?.path == "login" || payload?.route?.path == "/signup/account/pricing") &&
            payload?.route?.query?.utm_campaign === "earlyupgrade" &&
            validEarlyUpgradeStatusCodes.some((statusCode) => statusCode === payload.subscriptionStatusCode);

        context.commit(types.PRICE_MUT_IS_EARLY_UPGRADE, { isEarlyUpgrade });
    }
};