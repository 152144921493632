import models from ".";
import {
    MUTATION_SET_ALL_MODELS,
    MUTATION_SET_SETTINGS,
    MUTATION_ALLOCATION_PREVIEW,
    MUTATION_CONFIRMATION,
    MUTATION_REBALANCE_ERROR,
    MUTATION_SETTINGS_ERROR,
    MUTATION_MODEL_SUMMARY,
    MUTATION_MODEL_TYPE,
    MUTATION_MODEL_VIEW,
    MUTATION_CUSTOM_MODEL_DATA,
    MUTATION_MODEL_GRAPH_DATAPOINTS,
    MUTATION_MODEL_DELETE,
    MUTATION_CUSTOM_MODEL,
    MUTATION_MULTPLE_CUSTOM_MODELS,
    MUTATION_DELETE_MODEL_MODAL,
    MUTATION_DUPLICATE_MODEL,
    MUTATION_EDIT_MODEL,
    MUTATION_SET_AVAILABLE_ASSETS,
    MUTATION_CLEAR_AVAILABLE_ASSETS,
    MUTATION_ACCOUNT_DEPOSITS,
    MUTATION_LINKED_ASSET_MANAGEMENT_FIRMS_INFO,
    MUTATION_BATCH_EDIT_ACCOUNT_SETTINGS, MUTATION_BATCH_SET_ACCOUNT_SETTINGS,
    MUTATION_BATCH_REBALANCE_ACCOUNTS, MUTATION_BATCH_UNSUBSCRIBE_ACCOUNTS
} from "./types";

export default {
    [MUTATION_SET_ALL_MODELS]: async (state, models) => {
        state.allModels = models;
    },
    [MUTATION_SET_SETTINGS]: async (state, settings) => {        
        state.settings = settings;        
    },        
    [MUTATION_ALLOCATION_PREVIEW]: async (state, data) => {
        state.allocationData = data;
    },
    [MUTATION_CONFIRMATION]: async (state, data) => {
        state.confirmation = data;
    },
    [MUTATION_REBALANCE_ERROR]: async (state, data) => {
        state.rebalanceError = data;
    },
    [MUTATION_SETTINGS_ERROR]: async (state, data) => {
        state.settingsError = data;
    },
    [MUTATION_MODEL_SUMMARY]: async (state, models) => {
        state.modelSummary = models;
    },
    [MUTATION_SET_AVAILABLE_ASSETS]: async (state, dataPack) => {
        state.availableCoinsForModels.set(dataPack.modelCustodian, dataPack.data);
    },
    [MUTATION_CLEAR_AVAILABLE_ASSETS]: async (state) => {      
      state.availableCoinsForModels.clear();
    },
    [MUTATION_MODEL_GRAPH_DATAPOINTS]: async (state, models) => {
        state.modelGraphDatapoints = models;
    },
    [MUTATION_MODEL_TYPE]: async (state, modeltypes) => {
        state.modeltypes = modeltypes;
    },
    [MUTATION_MODEL_VIEW]: async (state, status) => {
        state.openViewModal = status;
    },
    [MUTATION_CUSTOM_MODEL_DATA]: async (state, payload) => {
        state.dataViewModal = payload;
    },
    [MUTATION_MODEL_DELETE]: async (state, status) => {
        state.openDeleteModel = status;
    },
    [MUTATION_CUSTOM_MODEL]: async (state, models) => {
        state.customModel = models;
    },
    [MUTATION_MULTPLE_CUSTOM_MODELS]: async (state, data) => {
        state.multipleCustomModels = data;
    },
    [MUTATION_DELETE_MODEL_MODAL]: async (state, deleteModelModal) => {
        state.deleteModelModal = deleteModelModal;
    },
    [MUTATION_DUPLICATE_MODEL]: async (state, payload) => {
        state.openDuplicateModal = payload;
    },
    [MUTATION_EDIT_MODEL]: async (state, payload) => {
        state.openEditModel = payload;
    },
    [MUTATION_ACCOUNT_DEPOSITS]: async (state, deposits) => {
        state.accountDeposits = deposits;
    },
    [MUTATION_LINKED_ASSET_MANAGEMENT_FIRMS_INFO]: async (state, data) => 
    {
      state.linkedAssetManagementFirmsInfo = data;
    },
    [MUTATION_BATCH_EDIT_ACCOUNT_SETTINGS]: async (state, data) => 
    {
      state.batchEditAccountSettingsData = data;
    },
    [MUTATION_BATCH_SET_ACCOUNT_SETTINGS]: async (state, data) => 
    {
      state.batchSetAccountSettingsData = data;
    },
    [MUTATION_BATCH_REBALANCE_ACCOUNTS]: async (state, data) => 
    {
      state.batchRebalanceAccountsData = data;
    },
    [MUTATION_BATCH_UNSUBSCRIBE_ACCOUNTS]: async (state, data) => 
    {
      state.batchUnsubscribeAccountsData = data;
    }
};
