import { BASE_URL, ACADEMY_URL } from "@/env";
import {
    hasAccessToAcademyOnly, 
    userHasAccessToApp,
    userMustChangePassword,
} from "./utils";

import { createRouter, createWebHistory } from 'vue-router';

import { 
    cleanLogin,
    routeByRole,
    restrictToAdvisorsOnly,
    restrictToAssetManagersOnly,
    disableForAssetManagers,
    restrictToClientsOnly,
    restrictToUsersWhoCanManageAccount
 } from "@/router-utils";
import ErrorView from "@/views/Onboarding/Login/login-error-page/index.vue";
import InactivityView from "@/views/Onboarding/Login/login-timeout-page/index.vue";

const isLoggedIn = () => {
    const result = sessionStorage.getItem("loggedIn") === "true";
    console.debug("isLoggedIn()", "result:", result)
    return result
};


const router = createRouter({
    history: createWebHistory(BASE_URL),    
    routes: [
        {
            path: "/error/:msg",
            name: "error",
            component: ErrorView,
        },
        {
            path: "/inactive",
            name: "inactive",
            component: InactivityView,
        },
        {
            path: "/loginCallBack",
            name: "login",
            component: () =>
                import(
                    /* webpackChunkName: "login" */ "@/views/Onboarding/Login/login-container/index.vue"
                ),
          },
        {
            path: "/force-password-change",
            name: "force-password-change",
            component: () =>
                import(
                    /* webpackChunkName: "login" */ "@/views/Onboarding/Change-password/change-password-container/index.vue"
                ),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/signup",
            name: "signup",
            component: () =>
                import(
                    /* webpackChunkName: "signup" */ "./views/Signup/index.vue"
                ),
            children: [
                {
                    path: "",
                    name: "welcome",
                    component: () =>
                        import(
                            /* webpackChunkName: "signup" */ "@/views/Onboarding/Welcome/welcome-container/index.vue"
                        ),
                },
                {
                    path: "account",
                    component: () =>
                        import(
                            /* webpackChunkName: "signup" */ "./views/Signup/Account/index.vue"
                        ),
                    children: [
                        {
                            path: "",
                            name: "info",
                            component: () =>
                                import(
                                    /* webpackChunkName: "signup" */ "@/views/Onboarding/Account-information/account-information-container/index.vue"
                                ),
                        },
                        {
                            path: "password",
                            name: "password",
                            component: () =>
                                import(
                                    /* webpackChunkName: "signup" */ "@/views/Onboarding/Password-setup/password-setup-container/index.vue"
                                ),
                            beforeEnter: (to, from, next) => {
                                sessionStorage.clear();
                                if (from.name === "info") {
                                    next();
                                } else {
                                    next("login");
                                }
                            },
                        },
                        {
                            path: "verification",
                            name: "verification",
                            component: () =>
                                import(
                                    /* webpackChunkName: "signup" */ "@/views/Onboarding/Email-verification/email-verification-container/index.vue"
                                ),
                            beforeEnter: (to, from, next) => {
                                if (
                                    to.params.okta ||
                                    from.name === "password"
                                ) {
                                    next();
                                } else {
                                    next("login");
                                }
                            },
                        },
                        {
                            path: "pricing",
                            name: "pricing",
                            component: () =>
                                import(
                                    /* webpackChunkName: "signup" */ "@/views/Onboarding/Pricing/pricing-container/index.vue"
                                ),
                            meta: { requiresAuth: true },
                            beforeEnter: (to, from, next) => {
                                if (
                                    to.params.okta ||
                                    from.name === "verification"
                                ) {
                                    next();
                                } else {
                                    next("login");
                                }
                            },
                        },
                        {
                            path: "confirm",
                            name: "confirm",
                            beforeEnter: (to, from, next) => {
                                if (from.name === "pricing") {
                                    next();
                                } else {
                                    next("login");
                                }
                            },
                            component: () =>
                                import(
                                    /* webpackChunkName: "signup" */ "./views/Signup/Account/Confirm/index.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: "/forgot-password",
            component: () =>
                import(
                    /* webpackChunkName: "forgot-pass" */ "@/views/Onboarding/Reset Password/reset-password-container/index.vue"
                ),
            children: [
                {
                    path: "",
                    name: "forgot-password",
                    component: () =>
                        import(
                            /* webpackChunkName: "forgot-pass" */ "@/views/Onboarding/Reset Password/reset-password-enter-email-container/index.vue"
                        ),
                },
                {
                    path: "validate",
                    name: "validate",
                    component: () =>
                        import(
                            /* webpackChunkName: "forgot-pass" */ "@/views/Onboarding/Reset Password/reset-password-one-time-passcode-container/index.vue"
                        ),
                },
                {
                    path: "resetPassword",
                    name: "resetPassword",
                    component: () =>
                        import(
                            /* webpackChunkName: "forgot-pass" */ "@/views/Onboarding/Reset Password/reset-password-change-container/index.vue"
                        ),
                },
            ],
        },
        {
            path: "/main",
            name: "main",
            component: () =>
                import(
                    /* webpackChunkName: "main" */ "@/views/main/ConstructionMain.vue"
                ),
            children: [
                {
                    path: "reports-and-statements",
                    name: "reports-and-statements",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-ad" */ "@/views/Reports and Statements/reports-and-statements-container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "advisory-fee-statuses",
                    name: "advisory-fee-statuses",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-ad" */ "@/views/Consolidated Views/Advisory Fee Status/advisory-fee-status-container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "new-account-status",
                    name: "newAccountStatus",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-port" */ "@/views/Account Management/New Account Status/index.vue"
                        ),

                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "/main/addOutSideAccount",
                    name: "addOutSideAccount",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-ad" */ "@/views/Account Management/Link Account/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "selectClient",
                    name: "selectClient",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-ad" */ "@/views/Account Management/Open Account/Client Selection/client-selection-container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "open-account/:clientid",
                    name: "openAccount",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-ad" */ "@/views/Account Management/Open Account/Open Account Container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "update-account/:clientid/:custodian/:accountid",
                    name: "updateAccount",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-ad" */ "@/views/Account Management/Open Account/Open Account Container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "integration/open-account",
                    name: "integration-open-account",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-ad" */ "@/views/Account Management/Open Account/Open Account Container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "trade/:id?",
                    name: "clienttradeview",
                    component: () => import( /* webpackChunkName: "main-port" */ "@/views/main/clients/trading/components/smart/TRA-HostPage.vue" ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "portfolio",
                    name: "portfolio",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-port" */ "@/views/Dashboard/Client/client-dashboard-container"
                        ),
                    props: true,
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToClientsOnly(next)
                    },
                },
                {
                    path: "portfolio/:accountId",
                    name: "portfolio-account",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-port" */ "@/views/Dashboard/Client/client-dashboard-container"
                        ),
                    props: true,
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToClientsOnly(next)
                    },
                },
                {
                    path: "fund-withdraw/:id",
                    name: "fund-withdraw",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-port" */ "@/views/Account Management/Funding/Client/fund-withdraw-container"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToClientsOnly(next)
                    },
                },
                {
                    path: "portfolio/link",
                    name: "link",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-port" */ "@/views/Link Account to Custodian/link-account-container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToClientsOnly(next)
                    },
                },
                {
                    path: "portfolio/open/review/provider/:providerID",
                    name: "review",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-port" */ "@/views/Paperwork Reviewal/paperwork-reviewal-stepper.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToClientsOnly(next)
                    },
                },
                {
                    path: "portfolio/open/details",
                    name: "account-open-details",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-port" */ "@/views/Paperwork Reviewal/Paperwork Reviewal Form/paperwork-reviewal-form-container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToClientsOnly(next)
                    },
                },
                {
                    path: "portfolio/open/confirmed",
                    name: "account-open-confirmed",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-port" */ "@/views/Paperwork Reviewal/Paperwork Reviewal Accepted and Completed/paperwork-reviewal-accepted-and-completed-container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToClientsOnly(next)
                    },
                },
                {
                    path: "settings",
                    name: "settings",
                    component: () =>
                        import(
                            /* webpackChunkName: "settings" */ "@/views/Settings/settings-container/index.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        requiresSubscriptionCheck: true,
                    },
                    beforeEnter: (to, from, next) => {
                        restrictToAdvisorsOnly(next)
                    },
                },
                {
                    path: "admin",
                    redirect: "dashboard",
                    component: () =>
                        import(
                            /* webpackChunkName: "main-ad" */ "@/views/main/admin/Admin.vue"
                        ),
                    beforeEnter: (to, from, next) => {
                        restrictToUsersWhoCanManageAccount(next)
                    },
                    children: [
                        {
                            path: "dashboard",
                            name: "dashboard",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Dashboard/dashboard-container/index.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                disableForAssetManagers(next)
                            }
                            //beforeEnter not required as everyone can acccess this view who meets the requirements of the parent's beforeEnter.
                        },
                        {
                            path: "views/clients",
                            name: "clients-view",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Consolidated Views/Clients/consolidated-clients-container"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToAdvisorsOnly(next)
                            },
                        },
                        {
                            path: "views/accounts",
                            name: "accounts-view",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Consolidated Views/Accounts/consolidated-accounts-container/index.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToAdvisorsOnly(next)
                            },
                        },
                        {
                            path: "views/sma-dashboard",
                            name: "sma-dashboard",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Consolidated Views/sma/dashboard/page/index.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToUsersWhoCanManageAccount(next)
                            },
                        },
                        {
                            path: "views/securitize",
                            name: "securitize",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Onboarding/Securitize/Securitize Proposals/index.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToAdvisorsOnly(next)
                            },
                        },
                        {
                            path: "views/trades-and-transactions",
                            name: "trades-and-transactions",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Consolidated Views/Orders and Transactions/index.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            //beforeEnter not required as everyone can acccess this view who meets the requirements of the parent's beforeEnter.
                        },
                        {
                            path: "views/client/:id",
                            name: "client",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Consolidated Views/Clients/Client Details/index.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToAdvisorsOnly(next)
                            },
                        },
                        {
                            path: "views/account/:id",
                            name: "account",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Consolidated Views/Accounts/Account Details/index.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToAdvisorsOnly(next)
                            },
                        },
                        {
                            path: "views/fund-account/:id?",
                            name: "fund-account",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Account Management/Funding/Advisor/fund-account-container-wrapper"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToAdvisorsOnly(next)
                            },
                        },
                        {
                            path: "views/order-maker",
                            name: "order-maker",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Account Management/order-maker/order-maker-container/order-maker-container.vue"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToAdvisorsOnly(next)
                            },
                        },
                        {
                            path: "views/transfers",
                            name: "transfers",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Account Management/Transfers/Advisor/transfers-container"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            beforeEnter: (to, from, next) => {
                                restrictToAdvisorsOnly(next)
                            },
                        },
                        {
                            path: "views/model-management",
                            name: "model-management",
                            component: () =>
                                import(
                                    /* webpackChunkName: "main-ad" */ "@/views/Model Management/model-management-container"
                                ),
                            meta: {
                                requiresAuth: true,
                                requiresSubscriptionCheck: true,
                            },
                            //beforeEnter not required as everyone can acccess this view who meets the requirements of the parent's beforeEnter.
                        },
                    ],
                },
            ],
            beforeEnter: (to, from, next) => {
                if (
                    !isLoggedIn() &&
                    to.path !== "/forgot-password/resetPassword"
                ) {
                    return next({ name: "login" }); // todo: add redirect to login page
                }
                const shouldRouteByRole = ["/main", "/main/"];
                if (userHasAccessToApp()) {
                    if (shouldRouteByRole.includes(to.fullPath)) {
                        next(routeByRole());
                    } else {
                        next();
                    }
                }
                if (hasAccessToAcademyOnly()) {
                    window.location.href = ACADEMY_URL;
                }
                // should throw error or to default page currently don't have one
            },
        },
        {
            path: '/:pathMatch(.*)*',
            beforeEnter: (to, from, next) => {
                /* if within app try to go to unknown route should go to default page currently missing */
                next({ name: "main" });
            },
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (
        isLoggedIn() &&
        userMustChangePassword() &&
        to.path !== "/force-password-change"
    ) {
        next("/force-password-change");
    } else {
        next();
    }
});


export default router;