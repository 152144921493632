import { ordersApi } from "@/api/orders-api";
import {
    ACTION_GET_ALL_ORDERS,
    ACTION_GET_OPEN_ORDERS,
    MUTATION_SET_ALL_ORDERS,
    MUTATION_SET_OPEN_ORDERS,
    ACTION_GET_ALL_ORDERS_FOR_ADVISOR_CLIENT,
    ACTION_GET_ALL_ORDERS_FOR_ADVISOR_ACCOUNT,
    ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_CLIENT,
    ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT,
    ACTION_GET_EXECUTED_BATCH_STATUS,
    MUTATION_ALL_ORDERS_FOR_ADVISOR_CLIENT,
    MUTATION_ALL_ORDERS_FOR_ADVISOR_ACCOUNT,
    MUTATION_OPEN_ORDERS_FOR_ADVISOR_CLIENT,
    MUTATION_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT,
    MUTATION_GET_BATCH_TRADE_ORDERS,
    ACTION_GET_BATCH_TRADE_ORDERS,
    ACTION_REFRESH_BATCH_TRADE_PRICES,
    MUTATION_REFRESH_BATCH_TRADE_PRICES,
    ACTION_GET_APPROVED_BATCHES,
    MUTATION_GET_APPROVED_BATCHES,
    ACTION_GET_REVIEWABLE_BATCHES,
    MUTATION_REVIEWABLE_TRADE_BATCHES,
    ACTION_STAGE_REVIEWABLE_BATCHES,
    MUTATION_STAGE_REVIEWABLE_BATCHES,
    ACTION_GET_PENDING_TRADE_BATCHES,
    MUTATION_SET_PENDING_TRADE_BATCHES,
    ACTION_GET_EXECUTABLE_TRADE_BATCHES,
    MUTATION_SET_EXECUTABLE_TRADE_BATCHES,
    ACTION_GET_EXECUTED_BATCHES,
    MUTATION_SET_EXECUTED_BATCHES,
    ACTION_GET_EXECUTION_ACCEPTED_TRADE_BATCHES, MUTATION_SET_EXECUTION_ACCEPTED_TRADE_BATCHES,
    ACTION_EXECUTE_TRADE_BATCHES,
    MUTATION_EXECUTE_TRADE_BATCHES, MUTATION_EXECUTED_BATCHE_STATUS,
    ACTION_SAVE_ORDER_MAKER_PRICE_SOURCE, MUTATION_ORDER_MAKER_PRICE_SOURCE
} from "./types";
import { TRADE_WORKFLOW_EVENT_TYPE_CREATION, TRADE_WORKFLOW_EVENT_TYPE_PENDING_REVIEW, TRADE_WORKFLOW_EVENT_TYPE_REVIEW_APPROVED, TRADE_WORKFLOW_EVENT_TYPE_REVIEW_REJECTED, TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_ACCEPTED } from "@/views/Account Management/order-maker/order-maker-constants.js"
import {mapPendingBatchOrdersValuesToFloat} from "./mappers";



const EMPTY_ORDERS = { orders: [], models: [] }

export default {
    [ACTION_GET_ALL_ORDERS]: async ({ commit }, params) => {
        commit(MUTATION_SET_ALL_ORDERS, EMPTY_ORDERS);  
        return ordersApi.getAllOrders(params.dateRange)
            .then(
                (response) => commit(MUTATION_SET_ALL_ORDERS, { orders: response.data, models: params.models }),
                (_e) => null
            );
    
    },
    [ACTION_GET_ALL_ORDERS_FOR_ADVISOR_CLIENT]: async ({ commit }, params) => {
        commit(MUTATION_ALL_ORDERS_FOR_ADVISOR_CLIENT, EMPTY_ORDERS); 
        return ordersApi.getOrdersByClientID(params.clientId)
            .then(
                (response) => commit(MUTATION_ALL_ORDERS_FOR_ADVISOR_CLIENT, { orders: response.data, models: params.models }),
                (_e) => null
            );
    },
    [ACTION_GET_ALL_ORDERS_FOR_ADVISOR_ACCOUNT]: async ({ commit }, params) => {
        commit(MUTATION_ALL_ORDERS_FOR_ADVISOR_ACCOUNT, EMPTY_ORDERS); 
        return ordersApi.getOrdersByAccountID(params.accountId)
            .then(
                (response) => commit(MUTATION_ALL_ORDERS_FOR_ADVISOR_ACCOUNT, { orders: response.data, models: params.models }),
                (_e) => null
            );
    },
    [ACTION_GET_OPEN_ORDERS]: async({ commit }, params) => {
        commit(MUTATION_SET_OPEN_ORDERS, EMPTY_ORDERS); 
        return ordersApi.getOpenOrders(params.dateRange)
            .then(
                (response) => commit(MUTATION_SET_OPEN_ORDERS, { orders: response.data, models: params.models }),
                (_e) => null
            );
    },
    [ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_CLIENT]: async ({ commit }, params) => {
        commit(MUTATION_OPEN_ORDERS_FOR_ADVISOR_CLIENT, EMPTY_ORDERS); 
        return ordersApi.getOpenOrdersByClientID(params.clientId)
            .then(
                (response) => commit(MUTATION_OPEN_ORDERS_FOR_ADVISOR_CLIENT, { orders: response.data, models: params.models }),
                (_e) => null
            );
    },
    [ACTION_GET_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT]: async ({ commit }, params) => {
        commit(MUTATION_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT, EMPTY_ORDERS); 
        return ordersApi.getOpenOrdersByAccountID(params.accountId)
            .then(
                (response) => commit(MUTATION_OPEN_ORDERS_FOR_ADVISOR_ACCOUNT, { orders: response.data, models: params.models }),
                (_e) => null
            );
    },
    [ACTION_GET_BATCH_TRADE_ORDERS]: async ({commit}, priceSource) => {
        let data = null;
        
        commit(MUTATION_GET_BATCH_TRADE_ORDERS, []);
            
        try
        {
            const response = await ordersApi.getTradeBatchOrders([TRADE_WORKFLOW_EVENT_TYPE_CREATION, TRADE_WORKFLOW_EVENT_TYPE_REVIEW_REJECTED], priceSource);
            data = response.data;
        }
        finally
        {          
            commit(MUTATION_GET_BATCH_TRADE_ORDERS, data);
        }                             
    },
    [ACTION_REFRESH_BATCH_TRADE_PRICES]: async ({commit}, payload) => {
        let data = null;
        
        commit(MUTATION_REFRESH_BATCH_TRADE_PRICES, []);
            
        try
        {
            const response = await ordersApi.getTradeBatchPrices(payload);            
            data = response.data;
        }
        finally
        {          
            commit(MUTATION_REFRESH_BATCH_TRADE_PRICES, data);
        }                             
    },
    [ACTION_GET_REVIEWABLE_BATCHES]: async ({commit}) => {
        let data = null;

        commit(MUTATION_REVIEWABLE_TRADE_BATCHES, {});

        try
        {
            const response = await ordersApi.getReviewableTradeBatches();
            data = response.data;
        }
        finally
        {
            commit(MUTATION_REVIEWABLE_TRADE_BATCHES, data);
        }
    },

    [ACTION_STAGE_REVIEWABLE_BATCHES]: async ({commit}, payload) => {
        commit(MUTATION_STAGE_REVIEWABLE_BATCHES, {});
        const response = await ordersApi.stageReviewedOrders(payload);
        const data = response.data.data;
        commit(MUTATION_STAGE_REVIEWABLE_BATCHES, data);
        return data;
    },
    [ACTION_GET_APPROVED_BATCHES]: async ({commit}) => {
        let data = null;

        commit(MUTATION_GET_APPROVED_BATCHES, []);

        try
        {
            const response = await ordersApi.getApprovedTradeBatches();
            data = response.data;
        }
        finally
        {
            commit(MUTATION_GET_APPROVED_BATCHES, data);
        }
    },
    [ACTION_GET_EXECUTED_BATCHES]: async ({commit}) => {
        commit(MUTATION_SET_EXECUTED_BATCHES, []);
        const response = await ordersApi.getExecutedTradeBatches();
        const data = response.data;
        commit(MUTATION_SET_EXECUTED_BATCHES, data);
    },
    [ACTION_GET_PENDING_TRADE_BATCHES]: async ({commit}, payload) => {
        commit(MUTATION_SET_PENDING_TRADE_BATCHES, {});
        const response = await ordersApi.fetchBatchTradeOrders({stage_code: TRADE_WORKFLOW_EVENT_TYPE_PENDING_REVIEW, batch_ids: payload});
        const data = response.data;
        data.batch_orders = mapPendingBatchOrdersValuesToFloat(data.batch_orders);
        commit(MUTATION_SET_PENDING_TRADE_BATCHES, data);
        return data;
    },
    [ACTION_GET_EXECUTABLE_TRADE_BATCHES]: async ({commit}, payload) => {
        commit(MUTATION_SET_EXECUTABLE_TRADE_BATCHES, {});
        const response = await ordersApi.fetchBatchTradeOrders({stage_code: TRADE_WORKFLOW_EVENT_TYPE_REVIEW_APPROVED, batch_ids: payload});
        const data = response.data;
        data.batch_orders = mapPendingBatchOrdersValuesToFloat(data.batch_orders);
        commit(MUTATION_SET_EXECUTABLE_TRADE_BATCHES, data);
        return data;
    },
    [ACTION_GET_EXECUTION_ACCEPTED_TRADE_BATCHES]: async ({commit}, payload) => {
        commit(MUTATION_SET_EXECUTION_ACCEPTED_TRADE_BATCHES, {});
        const response = await ordersApi.fetchBatchTradeOrders({stage_code: TRADE_WORKFLOW_EVENT_TYPE_EXECUTION_ACCEPTED, batch_ids: payload});
        const data = response.data;
        data.batch_orders = mapPendingBatchOrdersValuesToFloat(data.batch_orders);
        commit(MUTATION_SET_EXECUTION_ACCEPTED_TRADE_BATCHES, data);
        return data;
    },
    [ACTION_EXECUTE_TRADE_BATCHES]: async ({commit}, payload) => {
        commit(MUTATION_EXECUTE_TRADE_BATCHES, {});
        const response = await ordersApi.executeTradeBatches(payload);
        const data = response.data.data;
        commit(MUTATION_EXECUTE_TRADE_BATCHES, data);
        return data;
    },
    [ACTION_GET_EXECUTED_BATCH_STATUS]: async ({commit}, batchId) => {
        commit(MUTATION_EXECUTED_BATCHE_STATUS, {});
        try {
            const response = await ordersApi.getExecutedBatchOrderStatus(batchId);
            const data = response.data;
            commit(MUTATION_EXECUTED_BATCHE_STATUS, data);
            return data;
        } catch (e) {
            commit(MUTATION_EXECUTED_BATCHE_STATUS, []);
            return [];
        }
    },
    [ACTION_SAVE_ORDER_MAKER_PRICE_SOURCE]: ({commit}, value) => {
        commit(MUTATION_ORDER_MAKER_PRICE_SOURCE, value);
    },
}
