import { PROVIDER_CONNECTION_STATUS } from "@/utils";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	connections: {
		1: {
			provider: "Coinbase",
			status: PROVIDER_CONNECTION_STATUS.NOT_CONNECTED,
		},
		2: {
			provider: "Gemini",
			status: PROVIDER_CONNECTION_STATUS.NOT_CONNECTED,
		},	
		55: {
			provider: "Securitize",
			status: PROVIDER_CONNECTION_STATUS.NOT_CONNECTED,
		}	
	},
	isLoggedIn: false,
	loginError: false,
	duplicateEmail: null,
	// FIX: gets overwritten by the payload
	userProfile: {
		account_groups: [],
		accounts: null,
		act_holder: null,
		address1: "",
		address2: "",
		city: "",
		connections: null,
		country: "",
		email_confirmed: true, // if default is true, the notification badges will not show auto
		email: "",
		full_name: "",
		id: null,
		is_active: true,
		isStale: true,
		organization_id: null,
		role: null,
		state: "",
		zipcode: "",
	},
	dashboardMiniDrawer: false,
	dashboardShowDrawer: false, // should be set to true with left navigation
	notifications: [],
	documents:[],
	setNotificationResponse:{},
	actSignature:{},
	actOpenConf:{},
	latestListings:{},
	linkBankClose: null,
	connectionStatus: {},
	loginErrorExpiredTrial: false,
	refreshDashboardGraphs: false,
	isConnectionSuccessful: false
    
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};